import React, { useEffect, useState } from "react";
import {
	PaymentElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import DonationThankYou from "../Donation/Donation/DonationThankYou";

interface StripeCheckoutDetailProps {
	type: string;
	details: any;
}
const StripeCheckoutForm: React.FC<StripeCheckoutDetailProps> = observer(
	({ type, details }) => {
		const stripe = useStripe();
		const elements = useElements();

		const [paymentResponse, setPaymentResponse] = useState<any>();
		const [message, setMessage] = useState("");
		const [isLoading, setIsLoading] = useState(false);
		const { t } = useTranslation();

		const handleSubmit = async (e: any) => {
			e.preventDefault();

			if (!stripe || !elements) {
				// Stripe.js has not yet loaded.
				// Make sure to disable form submission until Stripe.js has loaded.
				return;
			}

			setIsLoading(true);
			let redirectUrl = "";
			if (type == "donation") {
				redirectUrl = window.location.origin + "/donation/" + details.id + "/pay";
			}

			const { error }: any = await stripe.confirmPayment({
				elements,
				confirmParams: {
					// Make sure to change this to your payment completion page
					return_url: redirectUrl,
				},
			});

			// This point will only be reached if there is an immediate error when
			// confirming the payment. Otherwise, your customer will be redirected to
			// your `return_url`. For some payment methods like iDEAL, your customer will
			// be redirected to an intermediate site first to authorize the payment, then
			// redirected to the `return_url`.
			if (error.type === "card_error" || error.type === "validation_error") {
				if (error.type === "card_error") {
					setMessage(error.message);
				} else {
					setMessage("");
				}
			} else {
				setMessage("An unexpected error occured.");
			}

			setIsLoading(false);
		};

		useEffect(() => {
			if (!stripe) {
				return;
			}

			const clientSecret = new URLSearchParams(window.location.search).get(
				"payment_intent_client_secret"
			);

			if (!clientSecret) {
				return;
			}

			stripe
				.retrievePaymentIntent(clientSecret)
				.then(({ paymentIntent }: any) => {
					setPaymentResponse(paymentIntent);
				});
		}, [stripe]);

		return (
			<>
			{!paymentResponse && 
			<div className="main">
				<Row justify="center">
					<Col span={24} className="pay__info">
					<span><b>{t("DONATION_SUBJECT")}</b> : {details?.subject.name}</span>
					<span> <b>{t("AMOUNT")}</b> : {details?.amount + " " + details?.payment_currency}</span>
					<span><b>{t("DONATING_TO")}</b> : {details.group?.name ? details.group.name : t("VSLA")}</span>
					</Col>
				</Row>
				<form id="payment-form" onSubmit={handleSubmit}>
					<Row justify="center">
						<Col span={12} className="payDonation">
							<PaymentElement id="payment-element" />
							<div className="btn btn-wrap">
								<Button
									disabled={isLoading || !stripe || !elements}
									className="btn-secondary"
									type="primary"
									id="submit"
									htmlType="submit"
									loading={isLoading}
								>
									{t("PAY")}
								</Button>
							</div>
						</Col>
					</Row>
					{/* Show any error or success messages */}
					{message && <div id="payment-message">{message}</div>}
				</form>
			</div>}
			{paymentResponse && <DonationThankYou paymentDetail={paymentResponse} donationDetails={details} />}
			</>
		);
	}
);

export default StripeCheckoutForm;
