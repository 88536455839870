import { Col, Modal, Row } from "antd";
import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { generateOTP } from "../../../requests/GroupRequest";
import amount from "../../../assets/images/amount-icon.svg"
import Calendar from "../../../assets/images/Calendar.svg"
import profile_icon from "../../../assets/images/profile-icon.svg";
import duration from "../../../assets/images/duration.svg";
import OTP from "../../../assets/images/OTP-Icon.svg"
import SequenceNumber from "../../../assets/images/Sequence-Icon.svg"
import { observer } from "mobx-react";
import moment from "moment";

interface PayRequestedProps {
	openPayCashModal: any;
	setOpenPayCashModal: (show: boolean) => void;
	activeTabName?: any
	data?: any
	handleGenerateOtp?: any;
	handleSubmit?: any;
	form?: any;
	displayOtp?: any;
	setDisplayOtp: (show: boolean) => any;
}
const PayRequestedLoan: React.FC<PayRequestedProps> = observer(({ openPayCashModal, setOpenPayCashModal, activeTabName, data,
	handleGenerateOtp, handleSubmit, form, displayOtp, setDisplayOtp }) => {
	const { t } = useTranslation();

	return (
		<>
			<div>
				<Modal
					centered
					visible={openPayCashModal}
					onOk={() => setOpenPayCashModal(false)}
					onCancel={() => {
						setOpenPayCashModal(false);
						form.resetFields();
						setDisplayOtp(false)
					}}
					className="custom-popup info-popup generate-otp"
					title={t("GENERATE_OTP")}
					footer={null}
					width={10}
				>

					<div className="register-section section-wrap">
						<div className="main">
							<FormBox form={form} onFinish={handleSubmit}>
								<Row>
									<Col span={12}>
										<div className="field">
											<i className="iconAlignment">
												<img src={profile_icon} alt="Profile" />
											</i>
											<InputBox.Text label={t("MEMBER_NAME")} value={data?.user_name} disabled />
										</div>
										{activeTabName != "LoanRequest" && <div className="field">
											<i className="iconAlignment">
												<img src={Calendar} alt="Profile" />
											</i>
											<InputBox.DatePicker label={t("PAY_INSTALLMENT_DATE")} value={moment(data?.installment?.installment_pay_date)} format={"MM-DD-YYYY"} disabled />
										</div>}
										<div className="field">
											<i className="iconAlignment">
												<img src={amount} alt="Amount" />
											</i>
											<InputBox.Text label={t("AMOUNT")} value={activeTabName == "LoanRequest" ? data?.loan_amount : data?.installment?.installment_amount} disabled />
										</div>
									</Col>
									<Col span={12}>
										<div className="field">
											<i className="iconAlignment">
												<img src={Calendar} alt="Amount" />
											</i>
											<InputBox.TextArea label={t("DESCRIPTION")} value={data?.description} disabled />
										</div>
										{activeTabName == "LoanRequest" ? <div className="field">
											<i className="iconAlignment">
												<img src={duration} alt="Profile" />
											</i>
											<InputBox.Text label={t("DURATION")} value={data?.number_of_installments} disabled />
										</div> : <div className="field">
											<i className="iconAlignment">
												<img src={SequenceNumber} alt="Profile" />
											</i>
											<InputBox.Text
												name="number_of_installments"
												placeholder={t("INSTALLMENT_NO")}
												label={t("INSTALLMENT_NO")}
												defaultValue={data?.installment_no}
												disabled
											/>
										</div>}
									</Col>
								</Row>
								<Row>
								</Row>
								{/* <Row>
                                    <Col span={12} style={{ width: "100%" }}>
                                        <div className="field">
                                            <i className="iconAlignment">
                                                <img src={amount} alt="Amount" />
                                            </i>
                                            <InputBox.Text label={t("AMOUNT")} value={data?.loan_amount} disabled />
                                        </div>
                                    </Col>
                                </Row><br /> */}
								<Row>
								</Row>
								<Row style={{ justifyContent: "center" }}>
								{
									<Col span={12} style={{ width: "100%" }}>
									<Button onClick={handleGenerateOtp}
										className="btn btn-primary otpBtn" hidden={displayOtp}
									>{t("GENERATE_OTP")}</Button>
								</Col>
								}
							</Row>
							<Row style={{ justifyContent: "center" }}>
								<Col span={12} style={{ width: "100%" }}>
									<div className="field">
										<i className="iconAlignment">
											<img src={OTP} alt="Amount" />
										</i>
										<InputBox.Password
											name="otp"
											placeholder={t("ENTER_OTP")}
											label={t("ENTER_OTP")}
											rules={generateOTP(t).otp}
										/>
									</div>
								</Col>
							</Row><br />
								<Row>
									<Col span={24}>
										<div className="btn btn-wrap">
											<Button
												type="primary"
												htmlType="submit"
												className="btn btn-primary"
											>
												{t("SAVE")}
											</Button>
											<Button
												type="primary"
												className="btn btn-danger"
												onClick={() => {
													setOpenPayCashModal(false)
													form.resetFields();
													setDisplayOtp(false)
												}}
											>
												{t("CANCEL")}
											</Button>
										</div>
									</Col>
								</Row>
							</FormBox>
						</div>
					</div>
				</Modal>
			</div>
		</>
	);
});

export default PayRequestedLoan;
