import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button, Tabs } from "antd";
import { useHistory } from "react-router";
import { GoPlus } from "react-icons/go";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import { AiOutlineEye } from "react-icons/ai";
import { observer } from "mobx-react";
import AllPermissionList from "../../../config/AllPermissionList";
import moment from "moment";
import { displayFormats } from "../../../config/Global";
import DateComparator from "../../../utils/DateComparator";
import { GrDocumentExcel } from "react-icons/gr";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";

const Election: React.FC = observer(() => {
	const history = useHistory();
	const { t } = useTranslation();
	const { TabPane } = Tabs;
	const [isElection, setIsElection] = useState<boolean>(false);

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);
	const [width, setWidth] = useState(window.innerWidth);


	const {
		AUTH: { user, selectedGroup, permissions },
		ELECTION: { setupElectionListGrid, electionType, changeElectionType, setElectionDetail, electionListData, exportElectionList, electionListGrid },
	} = useStore();
	
	const goToAddElection = () => {
		history.push("/election/add");
	};

	const getElectionDetails = (params: any) => {
		setElectionDetail();
		history.push("/election/" + params.data?.id + "/details");
	};

	const getElectionVoting = (id: any, isVoted: any) => {
		if(isVoted==0){
			history.push("/election/" + id + "/vote");
		} else {
			history.push("/election/" + id + "/vote-thank-you");
		}
	};

	const setElectionType = (key:any) => {
		changeElectionType(key);
	}

	const actionUpcomingRenderer = (params: any) => {
		return (
			<>
				<Button className="edit-button" title={t("ELECTION_DETAIL")} type="text" onClick={() => getElectionDetails(params)}><AiOutlineEye /></Button>

				{(moment(params.data?.election_date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) && params.data?.status == 1 && <Button
					type="default"
					style={{ color: "green" }}
					onClick={() => getElectionVoting(params.data?.id, params.data?.isVoted)}
				>
					{(params.data?.isVoted == 0) ? t("VOTE") : t("SUMMARY")}
				</Button>}
			</>
		)
	}
	const actionRenderer = (params: any) => {
		return (
			<Button className="edit-button" title={t("ELECTION_DETAIL")} type="text" onClick={() => getElectionDetails(params)}><AiOutlineEye /></Button>
		)
	}

	const exportElection = (type: string) => {
		setExportSaving(true);
		exportElectionList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	useEffect(() => {
		if(!isElection) {
			selectedGroup && setElectionType(1);
			setIsElection(true);
		}
	}, [user, permissions, isElection, setIsElection, selectedGroup, electionType]);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	return (
		<>
			{user && <div className="election-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<div className="title-wrap">
							<h3>{t("ELECTIONS")}</h3>
						</div>
						<div className="heading-btn-wrap">
							{electionListData && electionListData.canAddElection && <Button
								hidden={permissions && permissions.indexOf(AllPermissionList.ADD_ELECTION) < 0}
								className="btn-secondary"
								type="primary"
								onClick={goToAddElection}
							>
								{t("ADD_ELECTION")} <GoPlus />
							</Button>}
							{electionListGrid && electionListGrid[electionType] && electionListGrid[electionType].api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={()=> showExportPopup(true)}>
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>}
							
							<ExportPopupComponent
								callbackExportFunction={exportElection}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>
						</div>
					</div>
					<div className="custom-tabs justify-cenete">
						<Tabs defaultActiveKey="1" onChange={setElectionType} style={{ marginBottom: 32 }}>
							<TabPane tab={t("UPCOMING_ELECTIONS")} key="1" active={electionType == 1}>
								<AgGridWrapper
									type="serverSide"
									onGridReady={setupElectionListGrid}
									rowSelection={"multiple"}
									className="member-table"
									immutableData={true}
									frameworkComponents={{ actionUpcomingRenderer }}
									overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
								>
									<AgGridColumn field="id" headerName={"# " + t("ID")} width={120}
										pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter"
									/>
									<AgGridColumn field="election_title"
										headerName={t("ELECTION_TITLE")} width={230}
									/>
									<AgGridColumn
										field="election_date"
										headerName={t("DATE")}
										filter={"agDateColumnFilter"}
										maxWidth={130}
										valueFormatter={(data:any) =>
											data?.data.election_date && moment.utc(data?.data.election_date).local().format(displayFormats.DATE_FORMAT)
										}
										filterParams={{
											comparator: DateComparator,
										}}
									/>
									<AgGridColumn field="election_referee_name" headerName={t("ELECTION_REFEREE")} />
									{user && !user.isVillageAgent && <AgGridColumn
										width={150}
										wrapText={true}
										autoHeight={true}
										field="action"
										headerName={t("ACTION")}
										cellRenderer="actionUpcomingRenderer"
										pinned={width > 769 ? "right" : ""}
									/>}
								</AgGridWrapper>
							</TabPane>
							<TabPane tab={t("PENDING_ELECTIONS")} key="2" active={electionType == 2}>
								<AgGridWrapper
									type="serverSide"
									onGridReady={setupElectionListGrid}
									rowSelection={"multiple"}
									className="member-table"
									immutableData={true}
									overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
								>
									<AgGridColumn field="id" headerName={"# " + t("ID")} width={100}
										pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter"
									/>
									<AgGridColumn field="election_title"
										headerName={t("ELECTION_TITLE")} width={230}
									/>
									<AgGridColumn
										field="election_date"
										headerName={t("DATE")}
										filter={"agDateColumnFilter"}
										valueFormatter={(data:any) =>
											data?.data.election_date && moment.utc(data?.data.election_date).local().format(displayFormats.DATE_FORMAT)
										}
										filterParams={{
											comparator: DateComparator,
										}}
									/>
									<AgGridColumn field="election_referee_name" headerName={t("ELECTION_REFEREE")} />
								</AgGridWrapper>
							</TabPane>
							<TabPane tab={t("COMPLETED_ELECTION")} key="3" active={electionType == 3}>
								<AgGridWrapper
									type="serverSide"
									onGridReady={setupElectionListGrid}
									rowSelection={"multiple"}
									className="member-table"
									immutableData={true}
									frameworkComponents={{ actionRenderer }}
									overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
								>
									<AgGridColumn field="id" headerName={"# " + t("ID")} width={120}
										pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter"
									/>
									<AgGridColumn field="election_title"
										headerName={t("ELECTION_TITLE")} width={230}
									/>
									<AgGridColumn
										field="election_date"
										headerName={t("DATE")}
										filter={"agDateColumnFilter"}
										valueFormatter={(data:any) =>
											data?.data.election_date && moment.utc(data?.data.election_date).local().format(displayFormats.DATE_FORMAT)
										}
										filterParams={{
											comparator: DateComparator,
										}}
									/>
									<AgGridColumn field="election_referee_name" headerName={t("ELECTION_REFEREE")} />
									{user && !user.isVillageAgent && <AgGridColumn
										width={120}
										field="action"
										wrapText={true}
										autoHeight={true}
										headerName={t("ACTION")}
										cellRenderer="actionRenderer"
										pinned={width > 769 ? "right" : ""}
									/>}
								</AgGridWrapper>
							</TabPane>
						</Tabs>
					</div>
				</div>
			</div>}
		</>
	);
});

export default Election;
