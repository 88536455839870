import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ProfileImg from "../../../assets/images/profile-img.svg";
import useStore from "../../../store";
import { Button, Col, Form, Modal, Row } from "antd";
import JoinGroup from "./JoinGroup";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import PaymentIcon from "../../../assets/images/money.svg";
import { CreateSavingGroupRequest } from "../../../requests/GroupRequest";

const GroupDetails: React.FC = observer(() => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const { id }: any = useParams();
	const {
		SAVING_GROUP: { groupDetails, getGroupDetails, acceptJoinGroupRequest },
		COMMON: { paymentListData },
		ROOT: { AssignErrorToInput },
	} = useStore();
	const [showJoinGroup, showJoinGroupModel] = useState<boolean>(false);
	const [groupId, setGroupId] = useState<any>(null);
	const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
	const [acceptStatus, setAcceptStatus] = useState<number>();

	const handleAcceptJoinGroupRequest = () => {
		acceptJoinGroupRequest(groupDetails.id, { status: acceptStatus, preference: form.getFieldValue("preference") }).then(() => {
			setTimeout(() => {
				location.reload();
			}, 1000);
		}).catch((e: any) => {
			const errors = e?.errors || e?.data?.errors;
			errors && AssignErrorToInput(form, errors);
			openConfirmationPopup(0)
			//location.reload();
		});
	}

	const closeConfirmationPopup = () => {
		setAcceptStatus(0);
		setConfirmationModal(false);
	};

	const openConfirmationPopup = (status: any) => {
		setAcceptStatus(status);
		setConfirmationModal(true);
	};

	useEffect(() => {
		//
	}, [groupDetails]);

	useEffect(() => {
		id && getGroupDetails(id);
	}, [id]);

	const openJoinGroupModel = () => {
		setGroupId(groupDetails.id);
		showJoinGroupModel(true);
	}

	const handleSubmit = () => {
		openConfirmationPopup(1)
	}
	return (
		<>
			{groupDetails && <div className="profile-section">
				<div className="main">
					<div className="profile-wrap">
						<div className="edit-profile">
							{(groupDetails.publish_status == 1) && <div className="edit-wrap">
								<Button className="btn btn-primary" title={t('JOIN_GROUP')} onClick={openJoinGroupModel} disabled={(groupDetails.is_joined == 1 || groupDetails.numbers_of_member == groupDetails.existing_member_count)}>
									{groupDetails.is_joined == 1 ? t("JOINED") : groupDetails && groupDetails.numbers_of_member == groupDetails.existing_member_count ? t("LIMIT_EXCEEDED") : t('JOIN_GROUP')}
								</Button>
								<JoinGroup setGroupId={setGroupId} groupId={groupId} showJoinGroupModel={showJoinGroupModel} showJoinGroup={showJoinGroup} />
							</div>}
						</div>

						<div className="profile-thumb">
							<span>
								<img src={groupDetails.group_image ?? ProfileImg} alt="Profile" />
							</span>
							<h3>{groupDetails.name}</h3>
						</div>
					</div>
					<FormBox form={form} onFinish={handleSubmit}>
						<div className="Profile-description">
							<h3>{t("DESCRIPTION")}</h3>
							<p>{groupDetails.group_description}</p>

							<table>
								<thead>
									<tr>
										<th>
											<strong>{t("PRESIDENT")}: </strong>
											{groupDetails.president.firstname ? groupDetails.president.firstname + " " + groupDetails.president.lastname + " (" + groupDetails.president.email + ")" : "N/A"}
										</th>
										<td>
											<strong>{t("MEMBER")}: </strong> {groupDetails.existing_member_count + "/" + groupDetails.numbers_of_member}
										</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th>
											<strong>{t("SECRETARY")}: </strong>
											{groupDetails?.secretary?.firstname ? groupDetails?.secretary?.firstname + " " + groupDetails?.secretary?.lastname + " (" + groupDetails?.secretary?.email + ")" : "N/A"}
										</th>
										<td>
											<strong>{t("GROUP_WELFARE_RATE")}: </strong> {groupDetails.group_welfare_rate}
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t("ACCOUNTANT")}: </strong>
											{groupDetails?.accountant?.firstname ? groupDetails?.accountant?.firstname + " " + groupDetails?.accountant?.lastname + " (" + groupDetails?.accountant?.email + ")" : "N/A"}
										</td>
										<td>
											<strong>{t("SHARE_PRICE")}: </strong> {groupDetails?.share_price}
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t("VILLAGE_AGENT")}: </strong>
											{groupDetails?.villageAgent?.firstname ? groupDetails?.villageAgent?.firstname + " " + groupDetails?.villageAgent?.lastname + " (" + groupDetails?.villageAgent?.email + ")" : "N/A"}
										</td>
										<td>
											<strong>{t("INTEREST_RATE")}: </strong> {groupDetails?.interest_rate + " %"}
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t("CONFEDERATION")}: </strong>
											{groupDetails?.confederation?.name ? groupDetails?.confederation?.name : "N/A"}
										</td>
										<td></td>
									</tr>
									{groupDetails?.publish_status == 0 && groupDetails?.is_created_by_admin == 1 && (
										<tr>
											<td>
												<Row gutter={[50, 50]}>
													<Col span={12}>
														<div className="field">
															<i className="iconAlignment">
																<img src={PaymentIcon} alt="Profile" />
															</i>
															<InputBox.Select label={t("WHAT_IS_YOUR_PREFERENCE")}
																name={"preference"}
																placeholder={t("PREFERENCE")}
																options={{
																	list: paymentListData,
																	valueKey: "id",
																	textKey: "name",
																}}
																rules={CreateSavingGroupRequest(t).preference}
															/>
														</div>
													</Col>
												</Row>
											</td>
										</tr>)}
								</tbody>
							</table>

							{groupDetails?.publish_status == 0 && groupDetails?.is_created_by_admin == 1 && (
								<div className="section-wrap">
									<div className="btn-wrap">
										<Button
											type="primary"
											className="btn-secondary"
											htmlType="submit"
										>
											{t("ACCEPT")}
										</Button>
										<Button type="primary" className="btn-danger"
											onClick={() => openConfirmationPopup(2)}
										>
											{t("REJECT")}
										</Button>
									</div>
								</div>
							)}

							{acceptStatus && <Modal
								centered
								visible={confirmationModal}
								onOk={closeConfirmationPopup}
								onCancel={closeConfirmationPopup}
								className="custom-popup info-popup"
								footer={null}
							>
								<div className="info-text">
									<p>
										{acceptStatus == 1 ? t("ACCEPT_THE_REQUEST") : t("REJECT_THE_REQUEST")}
									</p>
								</div>
								<div className="btn">
									<Button className="btn-secondary" onClick={handleAcceptJoinGroupRequest}>{t("YES")}</Button>
									<Button className="btn-grey" onClick={closeConfirmationPopup}>{t("NO")} </Button>
								</div>
							</Modal>}
						</div>
					</FormBox>
				</div>
			</div>}
		</>
	);
});

export default GroupDetails;
