import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { AgGridColumn } from "ag-grid-react";
import { GridReadyEvent } from "ag-grid-community";
import moment from "moment";
import { Cash_Module, displayFormats } from "../../../config/Global";
import DateComparator from "../../../utils/DateComparator";
import Config from "../../../config/Config";

interface MyWalletListProps {
	setupGrid: (params: GridReadyEvent) => void;
}

const MyWalletList: React.FC<MyWalletListProps> = ({
	setupGrid
}) => {
	const { t } = useTranslation();
	const [width, setWidth] = useState(window.innerWidth);
	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);
	return (
		<>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupGrid}
				rowSelection={"multiple"}
				className="member-table"
				immutableData={true}
				overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
				// frameworkComponents={{ detailUserLoanRequestRenderer }}
			>
				<AgGridColumn
					field="id"
					headerName={"# " + t("ID")}
					width={100}
					pinned={width > 769 ? "left" : ""} 
					filter="agNumberColumnFilter"
				/>
				<AgGridColumn field="group_name" headerName={t("GROUP_NAME")} />
				<AgGridColumn field="amount" headerName={t("AMOUNT")}
					filter="agNumberColumnFilter" />
				<AgGridColumn
					field="transaction_details"
					headerName={t("DESCRIPTION")}
				/>
				<AgGridColumn
					field="created_at"
					headerName={t("DATE")}
					valueGetter={(data:any) =>
						data?.data?.created_at &&
						moment(data?.data.created_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
				/>
				<AgGridColumn field="payment_type" headerName={t("TRANSACTION_TYPE")} />
				{Cash_Module &&<AgGridColumn field="transaction_payment_mode" headerName={t("PAYMENT_MODE")} />}
				<AgGridColumn field="display_payment_status" headerName={t("STATUS")} />
			</AgGridWrapper>
		</>
	);
};

export default MyWalletList;
