import { Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Button, } from "antd";
import description from "../../../assets/images/description.svg";
import useStore from "../../../store";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { MeetingSummaryRequest } from "../../../requests/MeetingRequest";
import { observer } from "mobx-react";

const MeetingRemark: React.FC = observer(() => {
	const [form] = Form.useForm();
	const {
		ROOT: {AssignErrorToInput}, 
		AUTH: { selectedGroup },
		MEETING: { endMeeting, getGroupMeetingDetails },
	} = useStore();
	const history = useHistory();
	const { t } = useTranslation();
	const { id }: any = useParams();
	const [saving, setSaving] = useState<boolean>(false);
	const [endMeetingModal, setEndMeetingModal] = useState(false);
	const [isEndMeeting, setIsEndMeeting] = useState(true);

	const handleSubmit = (data: any) => {
		setSaving(true);
		endMeeting(id, data).then(() => {
			history.replace("/meetings/" + id + "/details");
		}).catch((e:any) => {
			setSaving(false);
			const errors = e?.data?.errors || e?.errors
			AssignErrorToInput(form, errors);
		});
	};

	const goBack = () => {
		history.replace("/meetings/" + id + "/details");
	};

	useEffect(() => {
		selectedGroup && getGroupMeetingDetails(id).then((response: any) => {
			form.setFieldsValue({summary: response.summery})
			setIsEndMeeting(response.status != 3)
		});
	}, [selectedGroup]);

	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t("MEETING_SUMMARY")}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<Row>
							<Col span={24}>
								<div className="field">
									<i>
										<img src={description} alt="Profile" />
									</i>
									<InputBox.TextArea
										name="summary"
										placeholder={t("MEETING_SUMMARY")}
										rules={MeetingSummaryRequest(t).summary}
									/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<div className="btn btn-wrap">
									{isEndMeeting ? <Button
										type="primary"
										loading={saving}
										className="btn btn-primary"
										onClick={() => form.validateFields().then(()=>{setEndMeetingModal(true)})}
									>
										{t("SAVE")}
									</Button> : <Button
										htmlType="submit"
										type="primary"
										loading={saving}
										className="btn btn-primary"
									>
										{t("SAVE")}
									</Button>}
									<Button
										type="primary"
										onClick={goBack}
										className="btn btn-danger"
									>
										{t("CANCEL")}
									</Button>
								</div>
							</Col>
						</Row>

						<Modal
							centered
							visible={endMeetingModal}
							onOk={() => setEndMeetingModal(false)}
							onCancel={() => setEndMeetingModal(false)}
							className="custom-popup info-popup"
							footer={null}
						>
							<div className="info-text">
								<p>{t("ARE_YOU_SURE_YOU_WANT_TO_END_THIS_MEETING")}</p>
							</div>
							<div className="btn">
								<Button
									className="btn-danger"
									onClick={form.submit}
								>
									{t("END")}
								</Button>
								<Button className="btn-grey" onClick={() => setEndMeetingModal(false)}>{t("CANCEL")} </Button>
							</div>
						</Modal>
					</FormBox>
				</div>
			</div>
		</>
	);
});

export default MeetingRemark;
