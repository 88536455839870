import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Button } from "antd";
import Lock_icon from "../../../assets/images/lock-icon.svg";
import useStore from "../../../store";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import { CreateMyAlIzzaAccountRequest } from "../../../requests/WalletRequest";
import OtpInput from "react-otp-input";

const CreateMyAlizzaAccount: React.FC = observer(() => {
	const [form] = Form.useForm();
	const [verifyForm] = Form.useForm();
	const {
		USER: { createMyAlIzzaAccount, verifyAlIzzaAccount },
		ROOT: { AssignErrorToInput },
	} = useStore();
	const { t } = useTranslation();
	const history = useHistory();
	const [saving, setSaving] = useState<boolean>(false);
	const [verifyOtpFormVisible, setVerifyOtpFormVisible] = useState<boolean>(false);

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.password && formData.append("password", data.password);
		createMyAlIzzaAccount(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				setVerifyOtpFormVisible(true)
			})
			.catch((e: any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors;
				AssignErrorToInput(form, errors);
			});
	};
	const handleVerifySubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.otp && formData.append("otp", data.otp);
		verifyAlIzzaAccount(formData)
			.then(() => {
				setSaving(false);
				verifyForm.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors;
				AssignErrorToInput(verifyForm, errors);
			});
	};
	const listRoute = () => {
		history.push("/my-wallet");
	};

	useEffect(() => {
		//
	}, [])
	

	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t('CREATE_AL_IZZA_ACCOUNT')}</h3>
					<FormBox form={form} onFinish={handleSubmit} hidden={verifyOtpFormVisible}>
						<Row>
							
							<Col span={12}>
								<div className="field">
									<i>
										<img src={Lock_icon} alt="Profile" />
									</i>
									<InputBox.Text
										name="password"
										label={t('AL_IZZA_PASSWORD')}
										placeholder={t('AL_IZZA_PASSWORD')}
										rules={CreateMyAlIzzaAccountRequest(t).password}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<div className="btn btn-wrap">
									<Button type="primary" htmlType="submit" loading={saving} className="btn btn-secondary">{t("CREATE")}</Button>
									<Button type="primary" onClick={listRoute} className="btn btn-danger">{t("CANCEL")} </Button>
								</div>
							</Col>
						</Row>
					</FormBox>
					<FormBox form={verifyForm} onFinish={handleVerifySubmit} hidden={!verifyOtpFormVisible}>
						<Row>
							<Col span={12}>
								<div className="field otp-field">
									<Form.Item name="otp"
										rules={CreateMyAlIzzaAccountRequest(t).otp}>
										<OtpInput
											className="otp-input"
											numInputs={4}
											separator={<span>-</span>}
										/>
									</Form.Item>
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<div className="btn btn-wrap">
									<Button type="primary" htmlType="submit" loading={saving} className="btn btn-secondary">{t("VERIFY")}</Button>
									<Button type="primary" onClick={listRoute} className="btn btn-danger">{t("CANCEL")} </Button>
								</div>
							</Col>
						</Row>
					</FormBox>
				</div>
			</div>
		</>
	);
});

export default CreateMyAlizzaAccount;
