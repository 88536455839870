import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";

export default class NewsStore {
    private rootStore: RootStore;
    public myNewDetails?: any;

    constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

    // Setter Functions
	private setMeetingType = (newType:any):void => {
		this.myNewDetails = newType;
	}

    public getNewsList = (): Promise<any> => {
		return axios
			.post(API_URL.NEWS.MY_NEWS_LIST)
			.then(({ data }) => {
				return data.data;
			});
	};

    public getNewsDetails = (newID: number): Promise<any> => {
        return axios.get(API_URL.NEWS.NEWS_DETAILS(newID)).then(({ data }) => {
            this.setMeetingType(data.data)
            return data.data;
        })
    }
}