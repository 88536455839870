import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";
import { createOtp } from "./GenerateOtpInterface";

export default class GenerateOTPStore {
	private rootStore: RootStore;
    public generatedOtpListGrid?: any;
	public rowData?:any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

    //Setter Function
	private setRowData = (value: any) => {
		this.rowData = value;
	};
	
    public getGenerateOtpList = (payload:any): Promise<any> => {
		return axios
			.post(API_URL.GENERATED_OTP.LIST,payload)
			.then(({ data }) => {
				this.setRowData(data?.data?.rows)
				return data.data?.rows;
			});
	};

   

	public createGeneratedOtp = (payload: createOtp): Promise<any> => {
		return axios
			.post(API_URL.GENERATED_OTP.CREATE, payload)
			.then(({ data }) => {
				return data;
			});
	};

}
