import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Button, } from "antd";
import description from "../../../assets/images/description.svg";
import duration from "../../../assets/images/duration.svg";
import transaction from "../../../assets/images/transaction.svg";
import Calendar from "../../../assets/images/Calendar.svg";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { observer } from "mobx-react";
import { UserOutlined } from "@ant-design/icons";
import { MeetingRequest } from "../../../requests/MeetingRequest";
import moment from "moment";
import { displayFormats } from "../../../config/Global";

interface FormDataProps {
	form:any;
	meetingDetails:any;
	handleSubmit:(data: any) => void |any;
	saving:boolean;
	listRoute:() => void;
	isEdit:boolean;
}
const MeetingForm: React.FC<FormDataProps> = observer(({
	form,
	meetingDetails,
	handleSubmit,
	saving,
	listRoute,
	isEdit,
}) => {
	const [meetingType, setMeetingType] =  useState<any>(null);
	const { t } = useTranslation();

	const disableStartDate = (current:any) =>{
		return current && current <= moment(moment().add(24, 'hours').format("YYYY-MM-DD HH:mm"));
	}

	const disableEndDate = (current:any) =>{
		return (form.getFieldValue('meeting_start_date_time') ? current && current <  moment(form.getFieldValue('meeting_start_date_time')) : current && current < moment(moment().add(24, 'hours').format("YYYY-MM-DD HH:mm")));
	}

	useEffect(() => {
		meetingDetails && meetingDetails.type && setMeetingType(meetingDetails.type);
	}, [form, meetingDetails]);

	return (
		<>
			<FormBox form={form} onFinish={handleSubmit}>
				<Row>
					<Col span={12}>
						<div className="field">
							<i>
								<img src={duration} alt="Profile" />
							</i>
							<InputBox.Text
								name="title"
								rules={MeetingRequest(t).title}
								placeholder={t("MEETING_TITLE")}
								label={t("MEETING_TITLE")}
							/>
						</div>
						<div className="field">
							<i>
								<img src={description} alt="Profile" />
							</i>
							<InputBox.TextArea
								name="description"
								rules={MeetingRequest(t).description}
								placeholder={t("MEETING_DESCRIPTION")}
								label={t("MEETING_DESCRIPTION")}
							/>
						</div>
					</Col>
					<Col span={12}>
						<div className="field">
							<i>
								<img src={transaction} alt="Profile" />
							</i>
							<InputBox.Select
								options={{
									list:[{id:1, name:"Online"}, {id:2, name:"Live"}],
									valueKey:"id",
									textKey:"name"
								}}
								placeholder={t('MEETING_TYPE')}
								label={t('MEETING_TYPE')}
								name="type"
								rules={MeetingRequest(t).type}
								disabled={isEdit}
								onChange={(id:any) => {setMeetingType(id)}}
							/>
						</div>
						{(meetingType == 2) && <div className="field">
							<i>
								<img src={transaction} alt="Profile" />
							</i>
							<InputBox.TextArea
								name="location"
								placeholder={t("MEETING_LOCATION_LINK")}
								label={t("MEETING_LOCATION_LINK")}
								prefix={<UserOutlined />}
								rules={MeetingRequest(t).location}
							/>
						</div>}
						<div className="field">
							<i>
								<img src={Calendar} alt="Profile" />
							</i>
							<InputBox.DateTimePicker
								name="meeting_start_date_time"
								rules={MeetingRequest(t).meeting_start_date_time}
								placeholder={t("START_DATE_TIME")}
								label={t("START_DATE_TIME")}
								disabledDate={disableStartDate}
								onChange={(e: any) => {form.setFieldsValue({'meeting_start_date_time': moment(e),'meeting_end_date_time': moment(e.add(10, 'minute'))})}}
								format={displayFormats.DATE_TIME_FORMAT}
							/>
						</div>
						<div className="field">
							<i>
								<img src={Calendar} alt="Profile" />
							</i>
							<InputBox.DateTimePicker
								name="meeting_end_date_time"
								rules={MeetingRequest(t).meeting_end_date_time}
								placeholder={t("END_DATE_TIME")}
								label={t("END_DATE_TIME")}
								disabledDate={disableEndDate}
								format={displayFormats.DATE_TIME_FORMAT}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col span={24}>
						<div className="btn btn-wrap">
							<Button
								type="primary"
								htmlType="submit"
								loading={saving}
								className="btn btn-primary">
								{t("SAVE")}
							</Button>
							<Button type="primary" className="btn btn-danger" onClick={listRoute}>
								{t("CANCEL")}
							</Button>
						</div>
					</Col>
				</Row>
			</FormBox>
		</>
	);
});

export default MeetingForm;
