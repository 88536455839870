import React, { useEffect } from "react";
import useStore from "../../../store";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

const NotificationRedirect: React.FC = observer(() => {
	const { id }: any = useParams();
	const {
		COMMON: { redirectNotification },
	} = useStore();
	useEffect(() => {
		id && redirectNotification(id);
	}, [id]);

	return <></>;
});

export default NotificationRedirect;
