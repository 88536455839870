import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "antd"
import { FormBox, InputBox } from "../../../components/AntdAddons"
import { JoinGroupRequest } from "../../../requests/GroupRequest";
import { useTranslation } from "react-i18next";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import amount from "../../../../src/assets/images/amount-icon.svg";
import shareCount from "../../../../src/assets/images/number_of_share.svg";

interface JoinGroupProps {
	showJoinGroup: boolean;
	showJoinGroupModel: (show: boolean) => void;
	setGroupId: (id: any) => void;
	groupId: any;
}

const JoinGroup: React.FC<JoinGroupProps> = ({
	showJoinGroup,
	showJoinGroupModel,
	setGroupId,
	groupId,
}) => {
	const [form] = Form.useForm();
	const [buyShareError, setError] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [groupDetails, setGroupDetails] = useState<any>(null);
	const { t } = useTranslation();
	const {
		AUTH: { user },
		ROOT: { AssignErrorToInput },
		SAVING_GROUP: { getGroupDetails, joinGroup },
	} = useStore();

	const onChangeOfShares = (shareCount: any) => {
		const shareprice = groupDetails?.share_price || 0;
		const groupWelfareRate = groupDetails?.group_welfare_rate || 0;
		form.setFieldsValue({
			shareprice: (shareprice * shareCount) + groupWelfareRate,
		});
		if (
			user.wallet_balance < form.getFieldValue("shareprice")
		) {
			setError(true);
		} else {
			setError(false);
		}
	}

	const handleJoinGroup = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		formData.append("share_count", data.share_purchase);
		formData.append("group_id", groupDetails.id);
		joinGroup(formData).then(() => {
			history.go(0);
		}).catch((e: ErrorProps) => {
			const errors = e?.errors || e?.data.errors;
			AssignErrorToInput(form, errors);
			setSaving(false);
		}).finally(() => { setSaving(false) })
	}

	const closeModel = () => {
		setGroupDetails(null);
		form.resetFields();
		showJoinGroupModel(false);
		setGroupId(null);
	};

	useEffect(() => {
		!groupDetails && showJoinGroup && getGroupDetails(groupId)
			.then((data: any) => {
				form.setFieldsValue({
					share_price: data?.share_price,
					shareprice: data?.share_price + data?.group_welfare_rate,
					walletbalance: user?.wallet_balance,
				});
				setGroupDetails(data);
			});
		!showJoinGroup && setGroupDetails(null);
		groupDetails && onChangeOfShares(1);
	}, [form, groupDetails, setGroupDetails, user, getGroupDetails, groupId]);
	return (
		<>
			{(groupDetails) && <Modal
				title={t("JOIN_GROUP")}
				centered
				visible={showJoinGroup}
				onOk={closeModel}
				onCancel={closeModel}
				afterClose={closeModel}
				className="custom-popup"
				footer={null}
			>
				<FormBox form={form} onFinish={handleJoinGroup}>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className="field buyShares">
								<small>{t("NUMBER_OF_SHARES")}</small>
								<i>
									<img src={shareCount} alt={t("NUMBER_OF_SHARES")} />
								</i>
								<InputBox.Text
									name="share_purchase"
									initialValue={1}
									onChange={(e: any) => onChangeOfShares(e.target.value)}
									rules={JoinGroupRequest(t, groupDetails.max_share_purchase_limit).share_purchase}
								/>
							</div>
						</Col>
						<Col span={12}>
							<div className="field">
								<small>{t("SHARE_PRICE")} + {t("GROUP_WELFARE_RATE")}</small>
								<i>
									<img src={amount} alt={t("SHARE_PRICE")} />
								</i>
								<InputBox.Text name="shareprice" value={form.getFieldValue("share_price")} disabled={true} />
							</div>
						</Col>
						<Col span={12}>
							<div className="field">
								<small>{t("WALLET_BALANCE")}</small>
								<i>
									<img src={amount} alt={t("WALLET_BALANCE")} />
								</i>
								<InputBox.Text name="walletbalance" initialValue={user.wallet_balance} disabled={true} />
							</div>
						</Col>
					</Row>
					{buyShareError ? (
						<div className="info-msg">
							<p>
								{t(
									"YOU_DO_NOT_HAVE_ENOUGH_WALLET_BALANCE_TO_BUY_A_SHARE_PLEASE_ADD_BALANCE"
								)}
							</p>
						</div>
					) : null}
					<div className="btn">
						<Button loading={saving} className="btn-secondary" htmlType="submit">
							{t("JOIN_GROUP")}
						</Button>
						<Button className="btn-danger" onClick={closeModel}>{t("CANCEL")}</Button>
					</div>
				</FormBox>
			</Modal>}
		</>
	)
}

export default JoinGroup;
