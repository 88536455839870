import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const ContactUsRequest = (t: any): RequestProps => ({
	name: [
		{ required: true, message: Message(t).required(t("NAME")) },
		{ pattern: /^([A-Za-z ]+)$/, message: Message(t).type.pattern(t("NAME")) },
		{ min: 3, message: Message(t).min(3, t('NAME')) },
		{ max: 50, message: Message(t).max(50, t('NAME')) },
		({ getFieldValue }) => ({
			validator() {
				const name = getFieldValue("name");
				if (name && !name.trim()) {
					return Promise.reject(Message(t).required(t('NAME')));
				}
				return Promise.resolve();
			},
		}),
	],
	email: [
		{ required: true, message: Message(t).required(t("EMAIL_ID")) },
		{ type: "email", message: Message(t).type.email(t("EMAIL_ID")) },
		{ min: 7, message: Message(t).min(7, t('EMAIL_ID')) },
		{ max: 50, message: Message(t).max(50, t('EMAIL_ID')) },
	],
	phone_number: [
		{ required: true, message: Message(t).required(t("PHONE_NUMBER")) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits()},
		({ getFieldValue }) => ({
			validator() {
				const phone_number = getFieldValue("phone_number");
				if (phone_number && phone_number.charAt(0)==0) {
					return Promise.reject(t("PHONE_NUMBER_CANNOT_START_FROM_ZERO"));
				}
				if (phone_number < 10000000 || phone_number > 99999999) {
					return Promise.reject(t("PLEASE_ENTER_8_DIGIT_PHONE_NUMBER"));
				}
				return Promise.resolve();
			},
		}),
	],
	description: [
		{ required: true, message: Message(t).required(t("MESSAGE")) },
		{ min: 3, message: Message(t).min(3, t('MESSAGE')) },
		{ max: 5000, message: Message(t).max(5000, t('MESSAGE')) },
		({ getFieldValue }) => ({
			validator() {
				const description = getFieldValue("description");
				if (description && !description.trim()) {
					return Promise.reject(Message(t).required(t('MESSAGE')));
				}
				return Promise.resolve();
			},
		}),
	],	
});
