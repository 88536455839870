import { Modal } from "antd"
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserLoanStatus } from "../../../config/Global";

interface UserLoanDetailsProps {
	showUserLoanDetails: boolean;
	showUserLoanDetailsModel: (show:boolean) => void;
	setUserLoanRequestDetails: any;
	userLoanRequestDetails: any;
	userLoanRequestType: string;
}
const UserLoanDetails : React.FC<UserLoanDetailsProps> = observer(({
	showUserLoanDetails,
	showUserLoanDetailsModel,
	setUserLoanRequestDetails,
	userLoanRequestDetails,
	userLoanRequestType,
}) =>{

	const { t } = useTranslation();
	const [isPaid, setIsPaid] = useState<boolean>(false);

	const closeModel =() => {
		setUserLoanRequestDetails(null);
		showUserLoanDetailsModel(false);
	}

	const displayStatus = (param:any)  => {
		const status = UserLoanStatus(t).filter((item:any) => item.id==param)
		return status[0]?.status
	}

	useEffect(() => {
		const paymentStatus: any = new Set();
		userLoanRequestDetails && userLoanRequestDetails?.installments && userLoanRequestDetails.installments.map((element: any)=>{
			!paymentStatus.has(element.payment_status) && paymentStatus.add(element.payment_status);
		});

		setIsPaid(!paymentStatus.has(0));

	}, [userLoanRequestDetails]);
	

	return (
		<Modal
			centered
			visible={showUserLoanDetails}
			onOk={() => closeModel()}
			onCancel={() => closeModel()}
			className="custom-popup info-popup details-popup"
			footer={null}
		>
			{userLoanRequestDetails && (
				<div className="details-info">
					<div className="info-header">
						<div className="info-thumb">
							<div className="title">
								<h3>{t("USER_LOAN_DETAILS")}</h3>
								<span>
									{userLoanRequestDetails.user &&
										userLoanRequestDetails.user.firstname +
											" " +
											userLoanRequestDetails.user.lastname +
											" (" +
											userLoanRequestDetails.user.email +
											")"}
								</span>
							</div>
						</div>
					</div>
					<div className="info-body">
						<div className="item">
							<ul>
								<li>
									<strong>{t("NAME")}: </strong>
									{userLoanRequestDetails.user &&
										userLoanRequestDetails.user.firstname +
											" " +
											userLoanRequestDetails.user.lastname +
											" (" +
											userLoanRequestDetails.user.email +
											")"}
								</li>
								<li>
									<strong>{t("LOAN_AMOUNT")}: </strong>
									{userLoanRequestDetails.loan_amount}
								</li>
								<li>
									<strong>{t("REPAY_AMOUNT")}: </strong>
									{userLoanRequestDetails.repay_amount}
								</li>
								<li>
									<strong>{t("NUMBER_OF_INSTALLMENTS")}: </strong>
									{userLoanRequestDetails.number_of_installments}
								</li>
								<li>
									<strong>{t("DESCRIPTION")}: </strong>
									{userLoanRequestDetails.description}
								</li>
							</ul>
						</div>

						<div className="item">
							<ul>
								<li>
									<strong>{t("GROUP_NAME")}: </strong>
									{userLoanRequestDetails.group &&
										userLoanRequestDetails.group.name}
								</li>
								<li>
									<strong>{t("INTEREST_RATE")}: </strong>
									{userLoanRequestDetails.interest_rate + " %"}
								</li>
								<li>
									<strong>{t("APPROVED_BY_PRESIDENT")}: </strong>
									{displayStatus(
										userLoanRequestDetails.president_approve_status
									)}
								</li>
								<li>
									<strong>{t("APPROVED_BY_SECRETARY")}: </strong>
									{displayStatus(
										userLoanRequestDetails.secretary_approve_status
									)}
								</li>
								<li>
									<strong>{t("APPROVED_BY_ACCOUNTANT")}: </strong>
									{displayStatus(
										userLoanRequestDetails.accountant_approve_status
									)}
								</li>
							</ul>
						</div>
					</div>
					{(userLoanRequestType == "my-loan-detail" && userLoanRequestDetails.president_approve_status == 1 && userLoanRequestDetails.secretary_approve_status == 1 && userLoanRequestDetails.accountant_approve_status == 1 && !isPaid) && (
						<div className="info-footer">
								<div className="btn btn-wrap text-left">
									<Link
										to={"/my-loan/" + userLoanRequestDetails.id + "/installments"}
									>
										{t("PAY_INSTALLMENT")}
									</Link>
								</div>
						</div>
					)}
				</div>
			)}
		</Modal>
	);
})
export default UserLoanDetails;
