import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStore from "../../../store";
import { Button, Tabs } from "antd";
import { GrDocumentExcel } from "react-icons/gr";
import { useHistory } from "react-router";
import { FiSettings } from "react-icons/fi";
import { Cash_Module, TransactionType } from "../../../config/Global";
import GroupWalletViewList from "./GroupWalletViewList";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";

const GroupWalletView: React.FC = observer(() => {
	const history = useHistory();
	const { t } = useTranslation();
	const { TabPane } = Tabs;
	const [isTransaction, setIsTransaction] = useState<boolean>(false);

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const {
		WALLET: {
			setupGroupTransactionListGrid,
			transactionType,
			changeTransactionType,
			exportGroupTransactionList,
			groupTransactionListGrid,
		},
		AUTH: { user, selectedGroup },
		SAVING_GROUP: { groupDetails, getGroupDetails },
	} = useStore();

	const goToAddWalletSettings = () => {
		history.push("/wallet-settings");
	};

	const setTransactionType = (key: any) => {
		changeTransactionType("group", key);
	};

	const exportGroupTransaction = (type: string) => {
		setExportSaving(true);
		exportGroupTransactionList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	};

	useEffect(() => {
		if (!isTransaction) {
			setTransactionType(0);
			setIsTransaction(true);
		}
	}, [user, groupDetails, isTransaction, setIsTransaction, setTransactionType]);

	useEffect(() => {
		selectedGroup && getGroupDetails(selectedGroup);
	}, [selectedGroup]);

	return (
		<>
			<div className="member-details-section text">
				<div className="main grid-class">
					{/* <div className="heading-title-wrap">
						<h3>{t("GROUP_WALLET")}</h3>
						{groupDetails && <><small>
							{t("WALLET_BALANCE")} : {groupDetails.wallet_balance}
						</small>
						<small>
							{t("WELFARE_WALLET_BALANCE")} : {groupDetails.welfare_wallet_balance}
						</small></>
						}
					</div> */}
					<div className="walletviewsection">
						{Cash_Module && (
							<div className="heading-title-wrap">
								<h4>{t("GROUP_WALLET")}</h4>
								<>
									<small>
										{t("TOTAL_BALANCE")} -{" "}
										{(
											parseFloat(groupDetails?.wallet?.cash_balance) +
											parseFloat(groupDetails?.wallet?.balance)
										).toFixed(2)}{" "}
									</small>
									<small>
										{t("CASH")} - {groupDetails?.wallet?.cash_balance}{" "}
									</small>
									<small>
										{t("DIGITAL")} - {groupDetails?.wallet?.balance}{" "}
									</small>
								</>
							</div>
						)}

						{Cash_Module && (
							<div className="heading-title-wrap">
								<h4>{t("WELFARE_WALLET")}</h4>
								<>
									<small>
										{t("TOTAL_BALANCE")} -{" "}
										{(
											parseFloat(groupDetails?.wallet?.welfare_cash_balance) +
											parseFloat(groupDetails?.wallet?.welfare_balance)
										).toFixed(2)}{" "}
									</small>
									<small>
										{t("CASH")} - {groupDetails?.wallet?.welfare_cash_balance}
									</small>
									<small>
										{t("DIGITAL")} - {groupDetails?.wallet?.welfare_balance}{" "}
									</small>
								</>
							</div>
						)}
					</div>
					<div className="heading-btn-wrap heading-with-icon">
						<div className="text-center btn btn-primary btn-yellow">
							<Link to={"/my-wallet"} type="primary">
								{t("BACK")}
							</Link>
						</div>
						{groupTransactionListGrid &&
							groupTransactionListGrid[transactionType] &&
							groupTransactionListGrid[
								transactionType
							].api.getDisplayedRowCount() > 0 && (
								<Button
									className="btn-yellow"
									type="primary"
									onClick={() => showExportPopup(true)}
								>
									{t("GENERATE_REPORT")} <GrDocumentExcel />
								</Button>
							)}

						<ExportPopupComponent
							callbackExportFunction={exportGroupTransaction}
							setVisible={showExportPopup}
							visible={exportPopup}
							saving={exportSaving}
						/>
						<div className="setting">
							<Button
								type="primary"
								title="Settings"
								onClick={goToAddWalletSettings}
							>
								<FiSettings />
							</Button>
						</div>
					</div>
					<div className="custom-tabs">
						<Tabs
							defaultActiveKey={"0"}
							onChange={setTransactionType}
							style={{ marginBottom: 32 }}
						>
							<TabPane
								tab={t("TRANSACTIONS")}
								key={"0"}
								active={transactionType == "0"}
							>
								<GroupWalletViewList
									setupGrid={setupGroupTransactionListGrid}
								/>
							</TabPane>
							<TabPane
								tab={t("LOANS")}
								key={TransactionType.LOAN}
								active={transactionType == TransactionType.LOAN}
							>
								<GroupWalletViewList
									setupGrid={setupGroupTransactionListGrid}
								/>
							</TabPane>
							<TabPane
								tab={t("FINE")}
								key={TransactionType.FINE}
								active={transactionType == TransactionType.FINE}
							>
								<GroupWalletViewList
									setupGrid={setupGroupTransactionListGrid}
								/>
							</TabPane>
							<TabPane
								tab={t("WELFARE_FUND")}
								key={TransactionType.WELFARE_COLLECTION}
								active={transactionType == TransactionType.WELFARE_COLLECTION}
							>
								<GroupWalletViewList
									setupGrid={setupGroupTransactionListGrid}
									key={TransactionType.WELFARE_COLLECTION}
									type={transactionType}
								/>
							</TabPane>
						</Tabs>
					</div>
				</div>
			</div>
		</>
	);
});

export default GroupWalletView;
