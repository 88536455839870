import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Button } from "antd";
import PaymentIcon from "../../../assets/images/money.svg";
import amount from "../../../assets/images/amount-icon.svg";
import useStore from "../../../store";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import { AddMoneyToWalletRequest } from "../../../requests/WalletRequest";

const AddMoneyToWallet: React.FC = observer(() => {
	const [form] = Form.useForm();
	const {
		COMMON: { getAddMoneyToWalletFormData },
		WALLET: { addMoneyToWallet },
		ROOT: { AssignErrorToInput },
	} = useStore();
	const { t } = useTranslation();
	const history = useHistory();
	const [paymentFromList, setPaymentFromList] = useState<any>([]);
	const [saving, setSaving] = useState<boolean>(false);

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.payment_from && formData.append("payment_from", data.payment_from);
		data.amount && formData.append("amount", data.amount);
		addMoneyToWallet(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors;
				AssignErrorToInput(form, errors);
			});
	};
	const listRoute = () => {
		history.push("/my-wallet");
	};

	useEffect(() => {
		getAddMoneyToWalletFormData().then((data: any) => {
			setPaymentFromList(data.paymentFrom);
		});
	}, [])
	

	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t('ADD_MONEY_TO_WALLET')}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<Row>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={PaymentIcon} alt="Profile" />
									</i>
									<InputBox.Select
										name="payment_from"
										options={{
											list: paymentFromList,
											valueKey: "id",
											textKey: "name"
										}}
										placeholder={t("PAYMENT_FROM")}
										label={t("PAYMENT_FROM")}
										rules={AddMoneyToWalletRequest(t).payment_from}
									/>
								</div>
							</Col>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={amount} alt="Amount" />
									</i>
									<InputBox.Text
										name="amount"
										label={t('AMOUNT')}
										placeholder={t('AMOUNT')}
										rules={AddMoneyToWalletRequest(t).amount}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<div className="btn btn-wrap">
									<Button type="primary" htmlType="submit" loading={saving} className="btn btn-secondary">{t("ADD_MONEY")}</Button>
									<Button type="primary" onClick={listRoute} className="btn btn-danger">{t("CANCEL")} </Button>
								</div>
							</Col>
						</Row>
					</FormBox>
				</div>
			</div>
		</>
	);
});

export default AddMoneyToWallet;
