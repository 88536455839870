import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import useStore from "../../../store";
import RoleList from "../../../config/RoleList";
import AllPermissionList from "../../../config/AllPermissionList";
import { EyeOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import DesignationsToBeElectedForElection from "../Election/DesignationsToBeElectedForElection";
import moment from "moment";
import { displayFormats } from "../../../config/Global";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import CancelElectionReason from "../ElectionReferee/CancelElectionReason";
import ShowMoreText from "react-show-more-text";

const About: React.FC = observer(() => {
	const [reasonForm] = Form.useForm();
	const { t } = useTranslation();
	const history = useHistory();
	const [electionDetailModal, setElectionDetailModal] = useState(false);
	const [userRoleInThisGroup, setUserRoleInThisGroup] = useState<any>();
	const [electionId, setElectionId] = useState<any>();
	const [cancelElectionModel, setCancelElectionModal] = useState(false);
	const [cancelElectionId, setCancelElectionId] = useState(null);
	const [saving, setSaving] = useState<boolean>(false);
	// const [moreText, setMoreText] = useState<boolean>(true);
	// const [tempArry, setTempArry] = useState<any>([]);

	const {
		ROOT: { AssignErrorToInput },
		AUTH: { selectedGroup, user, permissions },
		COMMON: { selectedLanguage, siteSettings, WithoutLoginHomeContentList, webCommonData, setWithoutLoginHomeContentListData },
		ELECTION: {
			electionRefereeElectionList,
			getElectionRefereeElectionList,
			setElectionRefereeElectionDetail,
			cancelElection,
		},
	} = useStore();

	const openElectionCancelModal = (electionId: any) => {
		reasonForm.resetFields();
		setCancelElectionId(electionId);
		setCancelElectionModal(true);
	}

	const closeCancelElectionModal = () => {
		setCancelElectionId(null);
		setCancelElectionModal(false);
	}

	const handleModalSubmit = (data: any) => {
		setSaving(true);
		cancelElection(cancelElectionId, data.end_election_reason)
			.then(() => {
				reasonForm.resetFields();
				closeCancelElectionModal();
			})
			.catch((e: ErrorProps) => {
				AssignErrorToInput(reasonForm, e?.data?.errors);
			}).finally(() => {
				getElectionRefereeElectionList({ election_status_type: 4, per_page: 20 });
				setCancelElectionId(null);
				setCancelElectionModal(false);
				setSaving(false);
			});
	};

	const openElectionDetailModal = (id: any) => {
		setElectionId(id);
		setElectionDetailModal(true);
	};

	const hideElectionDetailModal = () => {
		setElectionId(null);
		setElectionDetailModal(false);
	};

	const goToElectionResult = (id: any, electionDate: any) => {
		if (moment(electionDate) > moment()) {
			openElectionDetailModal(id);
		} else {
			history.push("/election/" + id + "/voting-result");
		}
	};

	useEffect(() => {
		user && user?.roles.forEach((element: any) => {
			setUserRoleInThisGroup(element?.name ?? "");
		});
		user && setElectionRefereeElectionDetail();
	}, [user, permissions, selectedGroup, siteSettings, WithoutLoginHomeContentList]);

	useEffect(() => {
		user && getElectionRefereeElectionList({ election_status_type: 4, per_page: 20 });
	}, [user, selectedLanguage]);

	useEffect(() => {
		webCommonData && selectedLanguage && setWithoutLoginHomeContentListData(webCommonData.homePageContent[selectedLanguage.id])
	}, [selectedLanguage, webCommonData, WithoutLoginHomeContentList]);

	return (
		<>
			<section className="about-section">
				<div className="main">
					{WithoutLoginHomeContentList?.map((data: any, index: number) => {
						return (
							<>
								<Row align="middle" key={"Content" + index}>
									<Col span={12}>
										<div className="about-thumb">
											<img src={data.home_page_content_image} alt="About VSLA" />
										</div>
									</Col>

									<Col span={12}>
										<div className="content-wrap">
											<h2>
												{WithoutLoginHomeContentList && selectedLanguage && data[selectedLanguage.local_key]?.title}
												{data.title}
											</h2>
											{/* <div className={moreText ? "more-text" : "show-text more-data"} dangerouslySetInnerHTML={{ __html: data.description }} />

											<div className={!moreText ? "more-text" : "show-text  less-data"} dangerouslySetInnerHTML={{ __html: data.description }} />
											
											<a href="javascript:void(0)" className="read-more" onClick={()=> setMoreText(!moreText)}>{moreText ? 'Read More' : 'Read Less'}</a> */}
											<ShowMoreText
												/* Default options */
												lines={2}
												more="Read More"
												less="Read Less"
												className="content-wrap"
												anchorClass="show-more-less-clickable"
												expanded={false}
												truncatedEndingComponent={"... "}
											>
												<div dangerouslySetInnerHTML={{ __html: data.description }} />
											</ShowMoreText>

											{data.link_url && data.link_title && <div className="btn primary-btn">
												<Link to={{ pathname: data.link_url }} title={data.link_title}>
													{data.link_title}
												</Link>

											</div>}
										</div>
									</Col>
								</Row>
							</>
						);
					})}
				</div>
			</section>

			{userRoleInThisGroup && userRoleInThisGroup == RoleList.ELECTION_REFREE && (
				<>
					<section className="group-list-table-section">
						<div className="main">
							<div className="heading-title">
								<Row>
									<Col span={24}>
										<h4>{t("GROUP_LIST")}</h4>
									</Col>
								</Row>
							</div>
							{/* <div className="title-overlap title-with-border">
								<h3>{t("GROUP_LIST")}</h3>
							</div> */}
							{permissions && electionRefereeElectionList && electionRefereeElectionList.rows && <div className="responsive-table">
								{electionRefereeElectionList.rows.length > 0 && <table>
									<thead>
										<tr>
											<th scope="col">{t("ID")}</th>
											<th scope="col">{t("GROUP_NAME")}</th>
											<th scope="col">{t("ELECTION_TITLE")}</th>
											<th scope="col">{t("DESIGNATION")}</th>
											<th scope="col">{t("DATE")}</th>
											<th
												scope="col"
												hidden={
													permissions.indexOf(
														AllPermissionList.ELECTION_DETAILS
													) < 0
												}
											>
												{t("DETAIL")}
											</th>
										</tr>
									</thead>
									<tbody>
										{electionRefereeElectionList.rows.map((item: any) => {
											return (
												<tr key={"election-list" + item.id}>
													<td data-label={t("ID")}>{item.id}</td>
		
													<td data-label={t("GROUP_NAME")}>
														{item.group_name}
													</td>
													<td data-label={t("ELECTION_TITLE")}>
														{item.election_title}
													</td>
													<td data-label={t("DESIGNATION")}>
														{item.election_for_roles}
													</td>
													<td data-label={t("DATE")}>{moment.utc(item.election_date).local().format(displayFormats.DATE_FORMAT)}</td>
													<td
														data-label={t("DETAIL")}
														hidden={
															permissions.indexOf(
																AllPermissionList.ELECTION_DETAILS
															) < 0
														}
														>
														<Button
															className="edit-button grey-btn"
															title={t("DETAIL")}
															type="text"
															onClick={() => goToElectionResult(item.id, item.election_date)}
														>
															<EyeOutlined />
														</Button>

														{(item.election_status == 0) && <Button
															title={t("CANCEL")} type="default" style={{ color: "red" }} onClick={() => openElectionCancelModal(item.id)}><span>{t("CANCEL")}</span></Button>}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>}


								{electionRefereeElectionList.rows.length == 0 && <caption className="text-center">{t('NO_UPCOMING_ELECTIONS')}</caption>}
							</div>}
						</div>
					</section>
					{electionId && <DesignationsToBeElectedForElection
						showDesignationsToBeElectedForElection={electionDetailModal}
						electionId={electionId}
						hideElectionDetailModal={hideElectionDetailModal}
					/>}

					<CancelElectionReason
						closeCancelElectionModal={closeCancelElectionModal}
						handleModalSubmit={handleModalSubmit}
						cancelElectionModel={cancelElectionModel}
						reasonForm={reasonForm}
						saving={saving}
					/>

				</>
			)}
		</>
	);
});

export default About;
