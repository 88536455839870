import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { observer } from "mobx-react";
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import FormComponent from "./FormComponent";
import { ErrorProps } from "../../../../../store/RootStore/RootInterface";

const AddDonationComponent: React.FC = observer(() => {
	const {
		ROOT: { AssignErrorToInput },
		DONATION: { create, update, getDonationDetails },
		COMMON: { donationFormData, getDonationFormData, selectedLanguage },
		AUTH:{user},
	} = useStore();
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [showGroupList, setShowGroupList] =  useState<boolean>(false);
	const [donationSubjectList, setDonationSubjectList] =  useState<boolean>();
	const [groupList, setGroupList] =  useState<boolean>();
	const [paymentFromList, setPaymentFromList] =  useState<boolean>();
	const [countryList, setCountryList] =  useState<boolean>();
	const [phoneNo, setPhoneNo] = useState<any>();
	const pathHistory = useHistory();
	const { id }: any = useParams();
	const listRoute = () => {
		pathHistory.push('/donation');
	}

	useEffect(()=>{
		form.setFieldsValue({
			name:user.firstname+" "+user.lastname,
			address:user.address,
			phone_number: user?.country_calling_code + user?.phone_number,
			email:user?.email,

		})
		setPhoneNo({ nationalNumber: user?.phone_number, countryCallingCode: user?.country_calling_code,country:user?.country_code })
	},[])


	useEffect(()=>{
		selectedLanguage && !donationFormData && getDonationFormData();
		selectedLanguage && donationFormData && setDonationSubjectList(donationFormData.donationSubjectList);
		selectedLanguage && donationFormData && setGroupList(donationFormData.groupList);
		selectedLanguage && donationFormData && setCountryList(donationFormData.countryList);
		selectedLanguage && donationFormData && setPaymentFromList(donationFormData.paymentFrom);

		form.setFieldsValue({donation_type: 1});
		id && atob(id) && getDonationDetails(atob(id), {}).then((data: any) => {
			form.setFieldsValue({
				name: data.donation?.name,
				address: data.donation?.address,
				amount: data.donation?.amount,
				organisation: data.donation?.organisation,
				group_id: data.donation?.group_id,
				phone_number: data.donation?.phone_number,
				donation_subject: data.donation?.subject.id,
				payment_from: data.donation?.payment_from,
				email: data.donation?.email,
				zipcode: data.donation?.zipcode,
				country_id: data.donation?.country_id,
				donation_type: data.donation?.donation_type,
				donation_campaign_id: data.donation?.donation_campaign_id,
			});
			setShowGroupList(form.getFieldValue("donation_type") === 2)
		})
		.catch(() => {
			pathHistory.push('/donation/add');
		});
	},[selectedLanguage, donationFormData]);

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		//setSaving(true);
		data.country_code="+" + phoneNo.countryCallingCode
		data.country_calling_code=phoneNo.countryCallingCode
		const formData = new FormData();
		data.name && formData.append('name', data.name);
		data.address && formData.append('address', data.address);
		data.amount && formData.append('amount', data.amount);
		data.organisation && formData.append('organisation', data.organisation);
		data.donation_type && formData.append('donation_type', data.donation_type);
		data.group_id && formData.append('group_id', data.group_id);
		data.phone_number && formData.append('phone_number', phoneNo.nationalNumber);
		data.donation_subject && formData.append('donation_subject', data.donation_subject);
		data.email && formData.append('email', data.email);
		data.payment_from && formData.append('payment_from', data.payment_from);
		data.zipcode && formData.append('zipcode', data.zipcode);
		data.country_id && formData.append('country_id', data.country_id);
		data.donation_campaign_id && formData.append('donation_campaign_id', data.donation_campaign_id);
		data.country_code && formData.append('country_code',phoneNo.country);
		data.country_calling_code && formData.append('country_calling_code',  "+" + phoneNo.countryCallingCode);

		if (id && atob(id)) {
			update(atob(id), formData)
				.then((response: any) => {
					setSaving(false);
					if(data.payment_from == 3){
						pathHistory.push('/donation/' + response.id + '/pay');
					} else {
						pathHistory.push('/donation');
					}
				})
				.catch((e: ErrorProps) => {
					const errors = e?.errors || e?.data?.errors;
					setSaving(false);
					AssignErrorToInput(form, errors);
				});
		} else {
			create(formData)
				.then((response: any) => {
					setSaving(false);
					if(data.payment_from == 3){
						pathHistory.push('/donation/' + response.id + '/pay');
					} else {
						pathHistory.push('/donation');
					}
				})
				.catch((e: ErrorProps) => {
					const errors = e?.errors || e?.data?.errors;
					setSaving(false);
					AssignErrorToInput(form, errors);
				});
		}
	};
	
	return (
		<>
			<FormComponent
				formId="addDonationCampaignForm"
				form={form}
				handleSubmit={handleSubmit}
				saving={saving}
				isEdit={false}
				listRoute={listRoute}
				donationSubjectList={donationSubjectList}
				groupList={groupList}
				paymentFromList={paymentFromList}
				countryList={countryList}
				showGroupList={showGroupList}
				setShowGroupList={setShowGroupList}
				isDonationCampaign={false}
				phoneNo={phoneNo}
				setPhoneNo={setPhoneNo}
				user={user}
			/>
		</>
	);
});
export default AddDonationComponent;