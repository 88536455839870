import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import Regex from "../config/Regex";
// import {
// 	isPossiblePhoneNumber,
// 	isValidPhoneNumber,
// } from "react-phone-number-input";

export const RegisterRequest = (t: any): RequestProps => ({
	firstname: [
		{ required: true, message: Message(t).required(t("FIRST_NAME")) },
		{ pattern: /^([A-Za-z ]+)$/, message: Message(t).type.pattern(t("FIRST_NAME")) },
		{ min: 3, message: Message(t).min(3, t('FIRST_NAME')) },
		{ max: 50, message: Message(t).max(50, t('FIRST_NAME')) },
		({ getFieldValue }) => ({
			validator() {
				const firstname = getFieldValue("firstname");
				if (firstname && !firstname.trim()) {
					return Promise.reject(Message(t).required(t('FIRST_NAME')));
				}
				return Promise.resolve();
			},
		}),
	],
	lastname: [
		{ required: true, message: Message(t).required(t("LAST_NAME")) },
		{ pattern: /^([A-Za-z ]+)$/, message: Message(t).type.pattern(t("LAST_NAME")) },
		{ min: 3, message: Message(t).min(3, t('LAST_NAME')) },
		{ max: 50, message: Message(t).max(50, t('LAST_NAME')) },
		({ getFieldValue }) => ({
			validator() {
				const lastname = getFieldValue("lastname");
				if (lastname && !lastname.trim()) {
					return Promise.reject(Message(t).required(t('LAST_NAME')));
				}
				return Promise.resolve();
			},
		}),
	],
	email: [
		{ required: true, message: Message(t).required(t("EMAIL_ID")) },
		{ type: "email", message: Message(t).type.email(t("EMAIL_ID")) },
		{ min: 7, message: Message(t).min(7, t('EMAIL_ID')) },
		{ max: 50, message: Message(t).max(50, t('EMAIL_ID')) },
	],
	phone_number: [
		{ required: true, message: Message(t).required(t("PHONE_NUMBER")) },
		({ getFieldValue }) => ({
			validator() {
				const phone_number = getFieldValue("phone_number");

				if (
					phone_number &&
					(!isPossiblePhoneNumber(phone_number) || !isValidPhoneNumber(phone_number))
				) {
					return Promise.reject(t("PLEASE_ENTER_VALID_FIELD_NAME", { fieldName: t("PHONE_NUMBER") }));
				}
				return Promise.resolve();
			},
		}),
	],
	password: [
		{ required: true, message: Message(t).required(t("PASSWORD")) },
		{
			pattern: Regex.password,
			message: Message(t).pattern.password(t("PASSWORD")),
		},
		{ min: 8, message: Message(t).min(8, t("PASSWORD")) },
		{ max: 20, message: Message(t).max(20, t("PASSWORD")) },
	],
	password_confirmation: [
		{ required: true, message: Message(t).required(t("CONFIRM_PASSWORD")) },
		({ getFieldValue }) => ({
			validator() {
				const password = getFieldValue("password");
				const password_confirmation = getFieldValue("password_confirmation");
				if (password != password_confirmation) {
					return Promise.reject(t("VALIDATE_PASSWORD_CONFIRM_PASSWORD"));
				}
				return Promise.resolve();
			},
		}),
	],
	gender: [{ required: true, message: Message(t).required(t("GENDER")) }],
	profession_id: [
		{ required: true, message: Message(t).selectRequired(t("PROFESSION")) },
	],
	currency_id: [
		{ required: true, message: Message(t).selectRequired(t("CURRENCY")) },
	],
	date_of_birth: [
		{ required: true, message: Message(t).required(t("DATE_OF_BIRTH")) },
	],
	address: [
		{ required: true, message: Message(t).required(t("ADDRESS")) },
		{ min: 3, message: Message(t).min(3, t("ADDRESS")) },
		{ max: 1000, message: Message(t).max(1000, t("ADDRESS")) },
	],
});

export const LoginRequest = (t: any): RequestProps => ({
	username: [
		{ required: true, message: Message(t).username() },
		{ type: "email", message: Message(t).type.email(t("EMAIL_ID")) },
	],
	password: [
		{ required: true, message: Message(t).required(t('PASSWORD')) },
		{
			pattern: Regex.password,
			message: Message(t).pattern.password(t("PASSWORD")),
		},
		{ min: 8, message: Message(t).min(8, t('PASSWORD')) },
		{ max: 20, message: Message(t).max(20, t('PASSWORD')) },
	],
	phone_number: [
		{ required: true, message: Message(t).phone_number() },
		({ getFieldValue }) => ({
			validator() {
				const phone_number = getFieldValue("phone_number");

				if (
					phone_number &&
					(!isPossiblePhoneNumber(phone_number) || !isValidPhoneNumber(phone_number))
				) {
					return Promise.reject(t("PLEASE_ENTER_VALID_FIELD_NAME", { fieldName: t("PHONE_NUMBER") }));
				}
				return Promise.resolve();
			},
		}),
	],
});

export const ChangePasswordRequest = (t: any): RequestProps => ({
	old_password: [
		{ required: true, message: Message(t).required(t('OLD_PASSWORD')) },
		{ min: 8, message: Message(t).min(8, t('OLD_PASSWORD')) },
		{ max: 20, message: Message(t).max(20, t('OLD_PASSWORD')) },
	],
	new_password: [
		{ required: true, message: Message(t).required(t('NEW_PASSWORD')) },
		{ min: 8, message: Message(t).min(8, t('NEW_PASSWORD')) },
		{ max: 20, message: Message(t).max(20, t('NEW_PASSWORD')) },
		{
			pattern: Regex.password,
			message: Message(t).pattern.password(t("NEW_PASSWORD")),
		},
	],
	confirm_password: [
		{ required: true, message: Message(t).required(t('CONFIRM_PASSWORD')) },
		{ min: 8, message: Message(t).min(8, t('PASSWORD')) },
		{ max: 20, message: Message(t).max(20, t('PASSWORD')) },
		({ getFieldValue }) => ({
			validator() {
				const password = getFieldValue("password");
				const password_confirmation = getFieldValue("password_confirmation");
				if (password != password_confirmation) {
					return Promise.reject(t("NEW_PASSWORD_DOES_NOT_MATCH_CONFIRM_PASSWORD"));
				}
				return Promise.resolve();
			},
		}),
	],
});

export const ProfileRequest = (t: any): RequestProps => ({
	profile_image: [
		({ getFieldValue }) => ({
			validator() {
				const profile_image = getFieldValue("profile_image");
				if (profile_image && profile_image?.file?.size >= 5120000) {
					return Promise.reject(Message(t)?.maxImageSize(5120));
				}
				return Promise.resolve();
			},
		}),
	],
	firstname: [
		{ required: true, message: Message(t).required(t('FIRST_NAME')) },
		{ pattern: /^([A-Za-z ]+)$/, message: Message(t).type.pattern(t("FIRST_NAME")) },
		{ min: 3, message: Message(t).min(3, t('FIRST_NAME')) },
		{ max: 50, message: Message(t).max(50, t('FIRST_NAME')) },
		({ getFieldValue }) => ({
			validator() {
				const firstname = getFieldValue("firstname");
				if (firstname && !firstname.trim()) {
					return Promise.reject(Message(t).required(t('FIRST_NAME')));
				}
				return Promise.resolve();
			},
		}),
	],
	lastname: [
		{ required: true, message: Message(t).required(t('LAST_NAME')) },
		{ pattern: /^([A-Za-z ]+)$/, message: Message(t).type.pattern(t("LAST_NAME")) },
		{ min: 3, message: Message(t).min(3, t('LAST_NAME')) },
		{ max: 50, message: Message(t).max(50, t('LAST_NAME')) },
		({ getFieldValue }) => ({
			validator() {
				const lastname = getFieldValue("lastname");
				if (lastname && !lastname.trim()) {
					return Promise.reject(Message(t).required(t('LAST_NAME')));
				}
				return Promise.resolve();
			},
		}),
	],
	email: [
		{ required: true, message: Message(t).required(t('EMAIL_ID')) },
		{ type: "email", message: Message(t).type.email(t('EMAIL_ID')) },
	],
	phone_number: [
		{ required: true, message: Message(t).required(t('PHONE_NUMBER')) },
		({ getFieldValue }) => ({
			validator() {
				const phone_number = getFieldValue("phone_number");

				if (
					phone_number &&
					(!isPossiblePhoneNumber(phone_number) || !isValidPhoneNumber(phone_number))
				) {
					return Promise.reject(t("PLEASE_ENTER_VALID_FIELD_NAME", { fieldName: t("PHONE_NUMBER") }));
				}
				return Promise.resolve();
			},
		}),
	],
	gender: [
		{ required: true, message: Message(t).required(t('GENDER')) },
	],
	address: [
		{ required: true, message: Message(t).required(t('ADDRESS')) },
		{ min: 3, message: Message(t).min(3, t("ADDRESS")) },
		{ max: 1000, message: Message(t).max(1000, t("ADDRESS")) },
		({ getFieldValue }) => ({
			validator() {
				const address = getFieldValue("address");
				if (address && !address.trim()) {
					return Promise.reject(Message(t).required(t('ADDRESS')));
				}
				return Promise.resolve();
			},
		}),
	],
	profession_id: [
		{ required: true, message: Message(t).selectRequired(t('PROFESSION')) },
	],
	currency_id: [
		{ required: true, message: Message(t).selectRequired(t("CURRENCY")) },
	],
	date_of_birth: [
		{ required: true, message: Message(t).required(t('DATE_OF_BIRTH')) },
	],

});

export const ForgotPasswordRequest = (t: any): RequestProps => ({
	username: [{ required: true, message: Message(t).required(t('EMAIL_ID')) }],
	phone_number: [
		{ required: true, message: Message(t).required(t("PHONE_NUMBER")) },
		({ getFieldValue }) => ({
			validator() {
				const phone_number = getFieldValue("phone_number");

				if (
					phone_number &&
					(!isPossiblePhoneNumber(phone_number) || !isValidPhoneNumber(phone_number))
				) {
					return Promise.reject(t("PLEASE_ENTER_VALID_FIELD_NAME", { fieldName: t("PHONE_NUMBER") }));
				}
				return Promise.resolve();
			},
		}),
	],
});

export const VerifyOtpRequest = (t: any): RequestProps => ({
	otp: [{ required: true, message: Message(t).required(t('OTP')) }],
});

export const VerifyAccountRequest = (t: any): RequestProps => ({
	otp: [{ required: true, message: Message(t).required(t('OTP')) }],
});

export const ChangePasswordWithOtpRequest = (t: any): RequestProps => ({
	new_password: [
		{ required: true, message: Message(t).required(t('NEW_PASSWORD')) },
		{ min: 8, message: Message(t).min(8, t('NEW_PASSWORD')) },
		{ max: 20, message: Message(t).max(20, t('NEW_PASSWORD')) },
		{
			pattern: Regex.password,
			message: Message(t).pattern.password(t("NEW_PASSWORD")),
		},
	],
	confirm_password: [
		{ required: true, message: Message(t).required(t('CONFIRM_PASSWORD')) },
		{ min: 8, message: Message(t).min(8, t('CONFIRM_PASSWORD')) },
		{ max: 20, message: Message(t).max(20, t('CONFIRM_PASSWORD')) },
		({ getFieldValue }) => ({
			validator() {
				const new_password = getFieldValue("password");
				const confirm_password = getFieldValue("password_confirmation");
				if (new_password != confirm_password) {
					return Promise.reject(t("NEW_PASSWORD_DOES_NOT_MATCH_CONFIRM_PASSWORD"));
				}
				return Promise.resolve();
			},
		}),
	],
});
