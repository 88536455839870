import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import useStore from "../../../store";
import { Button, Modal } from "antd";
import { CheckOutlined, CloseOutlined} from "@ant-design/icons";
import { useHistory } from "react-router";
import AllPermissionList from "../../../config/AllPermissionList";

const JoinGroupRequestList: React.FC = observer(() => {
	const history = useHistory()
	const { t } = useTranslation();
	const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
	const [updateConfirmationData, setUpdateConfirmationData] = useState<any>(null);

	const {
		AUTH,
		AUTH: { permissions },
		SAVING_GROUP: { setupJoinGroupRequestGrid, approveJoinGroupRequest },
	} = useStore();

	const goToGroupDetails = () => {
		history.push('/saving-group/details')
	}

	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Button
					type="default"
					icon={<CheckOutlined />}
					hidden={params.data?.status == 2}
					onClick= {() => {if (params.data?.status == 0) {openConfirmationPopup({join_group_request_id: params.data?.id, status: 1})}}}
					disabled={(AUTH.permissions && AUTH.permissions.indexOf(AllPermissionList.APPROVE_GROUP_JOIN_REQUEST)<0) || params.data?.status != 0}
					style={{color:"green"}}
				/>
				<Button
					type="default"
					icon={<CloseOutlined />}
					hidden={params.data?.status == 1}
					onClick= {() => {if (params.data?.status == 0) {openConfirmationPopup({join_group_request_id: params.data?.id, status: 2})}}}
					disabled={(AUTH.permissions && AUTH.permissions.indexOf(AllPermissionList.APPROVE_GROUP_JOIN_REQUEST)<0) || params.data?.status != 0}
					style={{color:"red"}}
				/>
			</div>
		);
	};

	const closeConfirmationPopup = () => {
		setUpdateConfirmationData(null);
		setConfirmationModal(false);
	};

	const openConfirmationPopup = (confirmationData: any) => {
		setUpdateConfirmationData(confirmationData);
		setConfirmationModal(true);
	};

	const handleSubmitConfirmation = () => {
		approveJoinGroupRequest(updateConfirmationData).finally(() => {
			closeConfirmationPopup();
		});
	};

	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("NEW_REQUESTS")}</h3>
						<div className="heading-btn-wrap">
							<Button className="btn-yellow" type="primary" onClick={goToGroupDetails}>{t('BACK')}</Button>
						</div>
					</div>
					{permissions && permissions.indexOf(AllPermissionList.LIST_GROUP_JOIN_REQUEST) >= 0 &&
					<AgGridWrapper
						type="serverSide"
						onGridReady={setupJoinGroupRequestGrid}
						rowSelection={"multiple"}
						className="member-table"
						frameworkComponents={{ SwitchRenderer }}
						immutableData={true}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
					>
						<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} pinned="left" filter="agNumberColumnFilter"/>
						<AgGridColumn field="user_name" headerName={t('USER_NAME')} />
						<AgGridColumn field="share_count" headerName={t("SHARE_COUNT")} filter="agNumberColumnFilter"/>
						<AgGridColumn field="email" headerName={t("EMAIL_ID")} />
						<AgGridColumn field="phone_number" headerName={t("PHONE_NUMBER")} />
						<AgGridColumn
							field="status"
							headerName={t("STATUS")}
							cellRenderer="SwitchRenderer"
							width={120}
							pinned={"right"}
							sortable={false}
							filter={false}
						/>
					</AgGridWrapper>
					}

					{updateConfirmationData && <Modal
						centered
						visible={confirmationModal}
						onOk={closeConfirmationPopup}
						onCancel={closeConfirmationPopup}
						className="custom-popup info-popup"
						footer={null}
					>
						<div className="info-text">
							<p>
								{updateConfirmationData.status == 1 ? t("ACCEPT_THE_REQUEST") : t("REJECT_THE_REQUEST")}
							</p>
						</div>
						<div className="btn">
							<Button className="btn-secondary" onClick={handleSubmitConfirmation}>{t("YES")}</Button>
							<Button className="btn-grey" onClick={closeConfirmationPopup}>{t("NO")} </Button>
						</div>
					</Modal>}
				</div>
			</div>
		</>
	);
});

export default JoinGroupRequestList;
