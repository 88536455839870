import React, { useState } from "react";
import { Modal, Form, Button } from "antd";
import FormComponent from "./FormComponent";
import { useTranslation } from "react-i18next";
import useStore from "../../../../../store";
import { ErrorProps } from "../../../../../store/RootStore/RootInterface";
interface AddProps {
	createData: (data: any) => Promise<any>;
	closeAddDonationSubjectePopup: () => void;
	visible?: boolean;
}
const AddComponent: React.FC<AddProps> = ({
	createData,
	closeAddDonationSubjectePopup,
	visible,
}) => {
	const {
		ROOT : { AssignErrorToInput },
	} = useStore();
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const { t } = useTranslation();
	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		createData(data)
			.then(() => {
				form.resetFields();
				close();
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
			})
			.finally(() => setSaving(false));
	};
	const close = () => {
		form.resetFields();
		closeAddDonationSubjectePopup();
	};
	return (
		<>
			<Modal
				centered
				title={t('ADD_DONATION_SUBJECT')}
				visible={visible}
				onCancel={close}
				className="custom-popup info-popup info-popup__min popup-wrap"
				destroyOnClose={true}
				footer={[
					<Button
						key="1"
						loading={saving}
						form="addDonationSubjecteForm"
						type="primary"
						htmlType="submit"
						className="btn-primary"
					>
						{t("SAVE")}
					</Button>,
					<Button key="2" className="btn-danger" onClick={close}>
						{t("CANCEL")}
					</Button>,
				]}
			>
				<FormComponent
					formId="addDonationSubjecteForm"
					form={form}
					handleSubmit={handleSubmit}
					isEdit={false}
				/>
			</Modal>
		</>
	);
};
export default AddComponent;