import React, { useState } from "react";
import { Modal, Button, Form, AutoComplete, Input } from "antd";
import FaSearch from "../../../assets/images/search-icon.svg";
import useStore from "../../../store";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useHistory } from "react-router";

const HeaderSearch: React.FC = observer(() => {
	const [searchVisible, setSearchVisible] = useState(false);
	const [form] = Form.useForm();
	const [groupList, setGroupList] = useState<any>([])
	const history = useHistory();
	const {
		COMMON: { getGroup },
		SAVING_GROUP: { setSearchJoinGroupListValue },
	} = useStore();
	const { t } = useTranslation();

	const handleSubmit = (data: any) => {
		setSearchJoinGroupListValue(data);
		history.push("/group-list")
		setSearchVisible(false);
		// return data;
	};
	const handleSearch = (data: any) => {
		if (data.length >= 3) {
			getGroup({ search: data }).then((data: any) => {
				const groupListData: any = [];
				data.forEach((element: any) => {
					groupListData.push({ value: element?.name, label: element?.name });
				});
				setGroupList(groupListData);
			});
		}
	};
	return (
		<>
			<Button type="primary" onClick={() => setSearchVisible(true)}>
				<img src={FaSearch} alt={t('SEARCH')} />
			</Button>
			<Modal
				title=" "
				visible={searchVisible}
				onOk={() => setSearchVisible(false)}
				onCancel={() => setSearchVisible(false)}
				width={1000}
				className="Header-top-search"
				footer={null}
			>
				<Form form={form} onFinish={handleSubmit}>
						<AutoComplete  onChange={handleSearch}  options={groupList}>
							<Input.Search
								size="large"
								placeholder={t('SEARCH')+'...'}
								enterButton={t('SEARCH')}
								onSearch={handleSubmit}
								onChange={handleSearch}
							/>
							</AutoComplete>

				</Form>
			</Modal>
		</>
	);
});

export default HeaderSearch;
