import React, { useState } from "react";
import { Button, Form, Col, Row } from "antd";
import { ChangePasswordRequest } from "../../../requests/AuthRequest";
import { useHistory } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import Lock_icon from "../../../assets/images/lock-icon.svg";
import { useTranslation } from "react-i18next";
import { aesEncryptionPassword } from "../../../config/Global";

const ChangePassword: React.FC = () => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { AUTH, ROOT } = useStore();
	const { changePassword } = AUTH;
	const { AssignErrorToInput } = ROOT;
	const { t } = useTranslation();

	const handleSubmit = async (data: any) => {
		setSaving(true);
		data.old_password=await aesEncryptionPassword(data?.old_password)
		data.password=await aesEncryptionPassword(data?.password)
		data.password_confirmation=await aesEncryptionPassword(data?.password_confirmation)

		changePassword(data)
			.then(() => {
				history.replace("/");
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	return (
		<>
			<section className="profile-change-password-section">
				<div className="main">
					<div className="heading-title-wrap" style={{marginLeft: "50px"}}>
						<h3>{t("CHANGE_PASSWORD")}</h3>
					</div>
					<Row>
						<Col span={24} className="section white-bg">
							<FormBox form={form} onFinish={handleSubmit}>
								<div className="field">
									<i>
										<img src={Lock_icon} alt="Password" />
									</i>
									<InputBox.Password
										required
										placeholder={t('OLD_PASSWORD')}
										label={t('OLD_PASSWORD')}
										size="large"
										name="old_password"
										rules={ChangePasswordRequest(t).old_password}
									/>
								</div>
								<div className="field">
									<i>
										<img src={Lock_icon} alt="Password" />
									</i>
									<InputBox.Password
										required
										name="password"
										placeholder={t('NEW_PASSWORD')}
										label={t('NEW_PASSWORD')}
										size="large"
										rules={ChangePasswordRequest(t).new_password}
									/>
								</div>
								<div className="field">
									<i>
										<img src={Lock_icon} alt="Confirm Password" />
									</i>
									<InputBox.Password
										required
										name="password_confirmation"
										placeholder={t('CONFIRM_PASSWORD')}
										label={t('CONFIRM_PASSWORD')}
										rules={ChangePasswordRequest(t).confirm_password}
									/>
									<div className="login-form-forgot text-center">
										<p>{t('PLEASE_ENTER_A_NEW_PASSWORD')}.</p>
									</div>
								</div>
								<div className="btn btn-wrap">
									<Button
										loading={saving}
										type="primary"
										size="large"
										htmlType="submit"
									>
										{t('CHANGE_PASSWORD')}
									</Button>

									<Button
										onClick={()=>{history.replace('/my-profile')}}
										className="btn-grey"
									>
										{t('CANCEL')}
									</Button>
								</div>
							</FormBox>
						</Col>
					</Row>
				</div>
			</section>
		</>
	);
};

export default ChangePassword;
