import { Col, Row, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Button } from "antd";
import amount from "../../../assets/images/amount-icon.svg";
import useStore from "../../../store";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { observer } from "mobx-react";
import { IMAGE_TYPE, dataToFormDataConverter, displayFormats, onPreview } from "../../../config/Global";
import FileIcon from "../../../assets/images/files.svg";
import moment from "moment";
import { RcFile } from "antd/lib/upload";
import Calendar from "../../../assets/images/Calendar.svg"
import profile_icon from "../../../assets/images/profile-icon.svg";
import { useHistory } from "react-router-dom";
import { TransferFundRequest } from "../../../requests/TransferFundRequest";

const AddTransferFunds: React.FC = observer(() => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const history = useHistory();
    const [saving, setSaving] = useState<boolean>(false);
    const [checkFileType, setCheckFileType] = useState<boolean>(false)
    const [fileList, setFileList] = useState<any>([])

    const {
        COMMON: { transferFundType, fundType },
        SAVING_GROUP: { groupDetails, getGroupDetails },
        AUTH: { selectedGroup },
        TRANSFER_FUND: { createTransferFund },
        ROOT: { AssignErrorToInput },
    } = useStore();

    useEffect(() => {
        selectedGroup && getGroupDetails(selectedGroup)

    }, [getGroupDetails, selectedGroup]);

    useEffect(() => {
        //
    }, [setFileList])

    const wrapperBeforeUpload = (file: RcFile) => {
        const isJpgOrPng =
            file.type === IMAGE_TYPE.jpeg ||
            file.type === IMAGE_TYPE.png ||
            file.type === IMAGE_TYPE.jpg ||
            file.type === IMAGE_TYPE.pdf ||
            file.type === IMAGE_TYPE.doc ||
            file.type === IMAGE_TYPE.docx
        setCheckFileType(!isJpgOrPng)
        if (!isJpgOrPng) {
            message.error(t("FILE_TYPE_MESSAGE"))
        }
        return false
    };

    const wrapperOnChange = (e: any) => {
        const arr = e.fileList.map((data: any) => {
            return data?.size;
        });
        const sum = arr.reduce(
            (accumulator: any, currentValue: any) =>
                accumulator + currentValue,
            0
        );

        const filteredFileList = e.fileList.slice(-5)
        if (
            filteredFileList.length !== e.fileList.length ||
            sum > 10485760
        ) {
            message.destroy()
            message.error(t("FILE_SIZE_MESSAGE"))
        } else if (checkFileType == true) {
            message.destroy()
            message.error(t("FILE_TYPE_MESSAGE"))
        } else {
            setFileList(e.fileList)
        }
        setCheckFileType(false);
    }

    const handleSubmit = (data?: any) => {
        setSaving(true)

        const payload = {
            ...data,
            attachment: fileList,
            date: moment().format("YYYY-MM-DD")
        }
        const formData = dataToFormDataConverter(payload)

        createTransferFund(formData).then(() => {
            history.push("/transfer-funds")
            form.resetFields()
        }).catch((e: any) => {
            const errors = e?.errors || e?.data?.errors;
            AssignErrorToInput(form, errors);
            setSaving(false);
        })
    }
    const onChange = () => {
        const actualAmount = parseFloat(form.getFieldValue("actual_amount"));
        const commissionFees = parseFloat(form.getFieldValue("commission_fees"));

        let finalAmount = 0;

        if (!isNaN(actualAmount) && !isNaN(commissionFees)) {
            finalAmount = actualAmount + commissionFees;
        } else if (!isNaN(actualAmount)) {
            finalAmount = actualAmount;
        } else if (!isNaN(commissionFees)) {
            finalAmount = commissionFees;
        }

        form.setFieldsValue({ "final_amount": finalAmount });
    }
    return (
        <>
            <div className="register-section section-wrap">
                <div className="main">
                    <h3>{t("TRANSFER_FUNDS")}</h3>
                    <div className="member-details-section">
                        <div className="main grid-class member-details-grid">
                            <div className="heading-title-wrap">
                                <h4>{t("GROUP_WALLET")}</h4>
                                <><small>{t('TOTAL_BALANCE')} - {parseFloat(groupDetails?.wallet?.cash_balance) + parseFloat(groupDetails?.wallet?.balance)} </small>
                                    <small>{t('CASH')} - {groupDetails?.wallet?.cash_balance} </small>
                                    <small>{t('DIGITAL')} - {groupDetails?.wallet?.balance} </small>
                                </>
                            </div>
                            <div className="heading-title-wrap">
                                <h4>{t("WELFARE_WALLET")}</h4>
                                <><small>{t('TOTAL_BALANCE')} - {parseFloat(groupDetails?.wallet?.welfare_cash_balance) + parseFloat(groupDetails?.wallet?.welfare_balance)} </small>
                                    <small>{t('CASH')} - {groupDetails?.wallet?.welfare_cash_balance}</small>
                                    <small>{t('DIGITAL')} - {groupDetails?.wallet?.welfare_balance} </small>
                                </>
                            </div>
                        </div>
                    </div>

                    <FormBox form={form} onFinish={handleSubmit} onChange={onChange}>
                        <Row>
                            <Col span={12}>
                                <div className="field">
                                    <i>
                                        <img src={profile_icon} alt="Profile" />
                                    </i>
                                    <InputBox.Select
                                        name={"transfer_fund_type"}
                                        label={t('TRANSFER')}
                                        placeholder={t("TRANSFER")}
                                        options={{
                                            list: transferFundType,
                                            valueKey: "id",
                                            textKey: "name",
                                        }}
                                        rules={TransferFundRequest(t).transfer_from}
                                    />
                                </div>
                                <div className="field">
                                    <i>
                                        <img src={amount} alt="Amount" />
                                    </i>
                                    <InputBox.Text
                                        name="actual_amount"
                                        label={t("ACTUAL_AMOUNT")}
                                        placeholder={t("ACTUAL_AMOUNT")}
                                        rules={TransferFundRequest(t).actual_amount}
                                    />
                                </div>
                                <div className="field">
                                    <i>
                                        <img src={amount} alt="Amount" />
                                    </i>
                                    <InputBox.Text
                                        name="final_amount"
                                        label={t("FINAL_AMOUNT")}
                                        placeholder={t("FINAL_AMOUNT")}
                                        disabled
                                        rules={TransferFundRequest(t).final_amount}
                                    />
                                </div>
                                <div className="field">
                                    <i>
                                        <img src={Calendar} alt="Profile" />
                                    </i>
                                    <InputBox.TextArea
                                        name="description"
                                        rules={TransferFundRequest(t).description}
                                        placeholder={t("DESCRIPTION")}
                                        label={t('DESCRIPTION')}
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="field">
                                    <i>
                                        <img src={amount} alt="Amount" />
                                    </i>
                                    <InputBox.Select
                                        name="fund_type"
                                        label={t("FUND_TYPE")}
                                        placeholder={t("FUND_TYPE")}
                                        options={{
                                            list: fundType,
                                            valueKey: "id",
                                            textKey: "name",
                                        }}
                                        rules={TransferFundRequest(t).fund_type}
                                    />
                                </div>
                                <div className="field">
                                    <i>
                                        <img src={amount} alt="Amount" />
                                    </i>
                                    <InputBox.Text
                                        name="commission_fees"
                                        label={t("COMMISSION_FEES")}
                                        placeholder={t("COMMISSION_FEES")}
                                        rules={TransferFundRequest(t).commission_fees}
                                    />
                                </div>
                                <div className="field">
                                    <i>
                                        <img src={Calendar} alt="Profile" />
                                    </i>
                                    <InputBox.DatePicker
                                        name="date"
                                        placeholder={t("DATE")}
                                        label={t("DATE")}
                                        defaultValue={moment()}
                                        format={displayFormats.DATE_FORMAT}
                                        disabled
                                    />
                                </div>
                                <div className="field">
                                    <i>
                                        <img src={FileIcon} alt="Profile" />
                                    </i>
                                    <Form.Item name="attachment"
                                        label={t("ATTACHMENT")}
                                        required={true}
                                    //rules={TransferFundRequest(t).attachment}
                                    >
                                        <Upload
                                            name="attachment"
                                            accept=".png,.jpeg,.jpg,.pdf,.doc,.docx"
                                            className="hideeyeicon"
                                            listType="picture-card"
                                            multiple={true}
                                            maxCount={5}
                                            showUploadList={true}
                                            beforeUpload={wrapperBeforeUpload}
                                            onPreview={onPreview}
                                            fileList={fileList && fileList}
                                            onChange={wrapperOnChange}
                                        >
                                            <Button className="browse-btn">{t("BROWSE")}</Button>
                                        </Upload>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <div className="btn btn-wrap">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btn btn-primary"
                                        loading={saving}
                                    >
                                        {t("TRANSFER_NOW")}
                                    </Button>
                                    <Button
                                        type="primary"
                                        className="btn btn-danger"
                                        onClick={() => history.push('/transfer-funds')}
                                    >
                                        {t("CANCEL")}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </FormBox>
                </div>
            </div>
        </>
    );
});

export default AddTransferFunds;
