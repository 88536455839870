import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button, } from "antd";
import Calendar from "../../../../../assets/images/Calendar.svg";
import profile_icon from "../../../../../assets/images/profile-icon.svg";
import union from "../../../../../assets/images/union.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { Col, Row } from "antd";
import { DonationCampaignRequest } from "../../../../../requests/DonationCampaignRequest";
import { displayFormats, donatingToConfig } from "../../../../../config/Global";
interface FormDataProps {
	formId: string;
	form: any;
	handleSubmit: (data: any) => void | any;
	saving: boolean;
	isEdit: boolean;
	listRoute: () => void;
	donationSubjectList: [] | any;
	groupList: [] | any;
	showGroupList: boolean;
	setShowGroupList: any;
}
const FormComponent: React.FC<FormDataProps> = observer(({
	formId,
	form,
	handleSubmit,
	saving,
	isEdit,
	listRoute,
	donationSubjectList,
	groupList,
	showGroupList,
	setShowGroupList
}) => {
	const disableStartDate = (current: any) => {
		return current && current < moment().endOf('day');
	}
	const disableEndDate = (current: any) => {
		return (form.getFieldValue('start_date_time') ? current && current <  moment(form.getFieldValue('start_date_time')).add(24, 'hours') : current && current < moment().endOf('day'));
	}
	const { t } = useTranslation();
	const checkGroup =  () => {
		setShowGroupList(form.getFieldValue("donation_type") === 2)
	}
	useEffect(() => {
		form.setFieldsValue({
			donation_type: 1
		})
	}, [isEdit, form])
	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{!isEdit ? t('ADD_DONATION_CAMPAIGN') : t('EDIT_DONATION_CAMPAIGN')}</h3>
					<FormBox id={formId}
						form={form}
						encType="multipart/formdata"
						onFinish={handleSubmit} key="formbasic">
						<Row>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={profile_icon} alt={t('NAME')} />
									</i>
									<InputBox.Text
										name="name"
										placeholder={t('NAME')}
										label={t('NAME')}
										rules={DonationCampaignRequest(t).name}
									/>

								</div>
								<div className="field">
									<i>
										<img src={union} alt={t('DONATION_SUBJECT')} />
									</i>
									<InputBox.Select
										name="donation_subject"
										options={{
											list: donationSubjectList,
											valueKey: "id",
											textKey: "name"
										}}
										placeholder={t("DONATION_SUBJECT")}
										label={t("DONATION_SUBJECT")}
										rules={DonationCampaignRequest(t).donation_subject}
									/>
								</div>
								<div className="field">
									<label>{t('DONATING_TO')}</label>
									<InputBox.Radio
										options={{
											list: donatingToConfig(t),
											valueKey: "id",
											textKey: "value"
										}}
										defaultValue={1}
										name="donation_type"
										onChange={() => {
											checkGroup();
										}}
									/>
								</div>
								{showGroupList && <div className="field">
									<i>
										<img src={union} alt="Profile" />
									</i>
									<InputBox.Select
										options={{
											list:groupList,
											valueKey:"id",
											textKey:"name"
										}}										
										name="group_id"
										placeholder={t("GROUP_NAME")}
										label={t("GROUP_NAME")}
										rules={DonationCampaignRequest(t).group_id}
										showSearch
										optionFilterProp="children"
										filterOption={(input, option: any) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									/>

								</div>}
							</Col>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={Calendar} alt="Profile" />
									</i>
									<InputBox.DateTimePicker
										name="start_date_time"
										rules={DonationCampaignRequest(t).start_date_time}
										placeholder={t('START_DATE_TIME')}
										label={t('START_DATE_TIME')}
										disabledDate={disableStartDate}
										onChange={(e: any) => {form.setFieldsValue({'start_date_time': moment(e),'end_date_time': moment(e.add(24, 'hours'))})}}
										format={displayFormats.DATE_TIME_FORMAT}
									/>
								</div>
								<div className="field">
									<i>
										<img src={Calendar} alt="Profile" />
									</i>
									<InputBox.DateTimePicker
										name="end_date_time"
										rules={DonationCampaignRequest(t).end_date_time}
										placeholder={t('END_DATE_TIME')}
										label={t('END_DATE_TIME')}
										disabledDate={disableEndDate}
										format={displayFormats.DATE_TIME_FORMAT}
									/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<div className="btn btn-wrap text-left">
									<Button type="primary" htmlType="submit" loading={saving} key="submit1" className="btn btn-primary">{t("SAVE")}</Button>
									<Button type="primary" htmlType="submit" key="resetCancel" className="btn btn-danger" onClick={listRoute} >{t("CANCEL")} </Button>
								</div>
							</Col>
						</Row>

					</FormBox>
				</div>
			</div>
		</>
	);
});
export default FormComponent;
