import React, { useState } from "react";
import { Button, Modal, } from "antd";
import { useTranslation } from "react-i18next";
interface DeleteProps {
	DeleteID: number;
	DeleteData: (data: any) => Promise<any>;
	closeDeleteFinePopup: () => void;
	visible?: boolean;
}
const DeleteComponent: React.FC<DeleteProps> = ({
	DeleteID,
	DeleteData,
	closeDeleteFinePopup,
	visible,
}) => {
	const [saving, setSaving] = useState<boolean>(false);
	const { t } = useTranslation();
	const close = () => {
		closeDeleteFinePopup();
	};

	// Handle submit and call function to delete record
	const handleSubmit = () => {
		setSaving(true);
		DeleteData(DeleteID)
			.then(() => {
				close();
			})
			.finally(() => {
				setSaving(false);
			});
	};

	return (
		<Modal
			centered
			title={t('DELETE_DONATION_CAMPAIGN')}
			visible={visible}
			onCancel={close}
			className="custom-popup info-popup"
			footer={null}
			destroyOnClose={true}
		>
			<div className="info-text">
				<p>
					{t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_DATA')}
				</p>
			</div>
			<div className="btn">
				<Button className="btn-danger" key="1" loading={saving} type="primary" onClick={handleSubmit}>{t("DELETE")}</Button>
				<Button className="btn-grey" onClick={close}>{t("CANCEL")} </Button>
			</div>
		</Modal>
	);
};

export default DeleteComponent;
