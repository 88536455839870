import React, { useEffect, useState } from "react";
import { Form, Menu, Dropdown, Modal, Button, Row, Col } from "antd";
import ProfileImg from "../../../assets/images/profile-img.svg";
import { AiOutlineMore } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import useStore from "../../../store";
import { observer } from "mobx-react";
import BuyShare from "./BuyShare";
import { Link } from "react-router-dom";
import AllPermissionList from "../../../config/AllPermissionList";
import { EditOutlined } from "@ant-design/icons";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import profile_icon from "../../../assets/images/profile-icon.svg";
import PaymentIcon from "../../../assets/images/money.svg";
import amount from "../../../assets/images/amount-icon.svg"
import { buyShareOfUsers, generateOTP } from "../../../requests/GroupRequest";
import OTP from "../../../assets/images/OTP-Icon.svg"
import { Cash_Module } from "../../../config/Global";
import RoleList from "../../../config/RoleList";

const SavingGroup: React.FC = observer(() => {
	const [form] = Form.useForm();
	const [otpForm] = Form.useForm();
	const [showBuyShare, showBuyShareModel] = useState(false);
	const [showLeaveGroup, showLeaveGroupModel] = useState(false);
	const [buyShareDisable, setBuyShareDisable] = useState(false);
	const [showDisableGroup, showDisableGroupModel] = useState(false);
	const [openGenerateOtpModal, setOpenGenerateOtpModal] = useState(false)
	const [displayOtp, setDisplayOtp] = useState(false)
	const [isCalculateAmount, setIsCalculateAmount] = useState(false)
	const [usersList, setUsersList] = useState<any>([]);
	const { t } = useTranslation();
	const {
		SAVING_GROUP: { groupDetails, getGroupDetails, myLeaveGroup, groupTerminate, getCalculateAmount, getPurchaseShareOfUsers },
		AUTH: { user, selectedGroup, permissions, userRoleInThisGroup },
		COMMON: { getUser, paymentListData },
		ROOT: { AssignErrorToInput },
		GENERATE_OTP: { createGeneratedOtp },
	} = useStore();

	const userPaymentMode = paymentListData?.filter((data: any) => data.id == 0)

	useEffect(() => {
		selectedGroup && getGroupDetails(selectedGroup).then((data: any) => {
			user.groups.map((element: any) => {
				if (element.id == selectedGroup && data.max_share_purchase_limit == element.share_purchased) {
					setBuyShareDisable(true);
				}
			})
		});

	}, [user, permissions, getGroupDetails, selectedGroup, buyShareDisable]);

	const LeaveGroup = () => {
		myLeaveGroup().then(() => {
			setTimeout(() => {
				window.location.href = "/";
			}, 1000);
		});
	}

	const terminateThisGroup = () => {
		groupTerminate().then(() => {
			setTimeout(() => {
				window.location.href = "/";
			}, 1000);
		});
	}

	const handleSearch = (data: any) => {
		if (data.length >= 3) {
			getUser({ user_type: 0, status: 1, search: data }).then((data: any) => {
				setUsersList(data);
			});
		}
	};

	const handleDisplayBuyShareForUsers = () => {
		setOpenGenerateOtpModal(true)
		otpForm.setFieldsValue({
			share_price: groupDetails.share_price,
		})
	}
	const handleGenerateOtp = () => {
		createGeneratedOtp({ id: otpForm.getFieldValue("user_id"), type: 6, amount: otpForm.getFieldValue("final_amount") }).then(() => {
			setDisplayOtp(true)
		}).catch((e: any) => {
			setDisplayOtp(false)
			const errors = e?.errors || e?.data.errors;
			AssignErrorToInput(otpForm, errors);
		})
	}

	const handleCalculateAmount = () => {
		otpForm.validateFields().then(() => {
			getCalculateAmount({ user_id: otpForm.getFieldValue("user_id"), number_of_shares: otpForm.getFieldValue("number_of_shares") }).then((data: any) => {
				setIsCalculateAmount(true)
				otpForm.setFieldsValue({
					sub_total: data.subTotalOfSharePrice,
					welfare_amount: data.welfareAmount,
					final_amount: data.finalAmount
				})
			}).catch((e: any) => {
				const errors = e?.errors || e?.data?.errors;
				AssignErrorToInput(otpForm, errors);
				setIsCalculateAmount(false)
			})
		})
	}

	const handleSubmit = (data: any) => {
		getPurchaseShareOfUsers({ user_id: data?.user_id, number_of_shares: data?.number_of_shares, otp: data?.otp }).then(() => {
			setOpenGenerateOtpModal(false)
			setIsCalculateAmount(false)
			setDisplayOtp(false)
			setUsersList([]);
			otpForm.resetFields();
			getGroupDetails(selectedGroup)
		}).catch((e: any) => {
			const errors = e?.errors || e?.data?.errors;
			AssignErrorToInput(otpForm, errors);
		})
	}
	return (
		<>
			{user && user.groups.length == 0 && (
				<div className="profile-section">
					<div className="main">
						<div className="profile-wrap">
							<h3 className="no__group__member">
								{t(
									"CURRENTLY_YOU_ARE_NOT_MEMBER_OF_ANY_GROUP_PLEASE_JOIN_THE_GROUP_USING_SEARCH_GROUP_FACILITY"
								)}
							</h3>
						</div>
					</div>
				</div>
			)}
			{groupDetails && (
				<div className="profile-section">
					<div className="main">
						<div className="profile-wrap saving-profile-wrap">
							<>
								{user && !user.isVillageAgent && <div className="edit-profile profile-edit-thumbnail">
									<div className="edit-wrap">
										{selectedGroup &&
											groupDetails.publish_status == 1 && groupDetails.president &&
											(groupDetails.president.id == user.id) && (
												<div className="edit">
													<div className="btn btn-primary">
														<Link
															to={"/saving-group/edit-group"}
															className="primary"
															title="Edit"
														>
															<EditOutlined />
														</Link>
													</div>
												</div>
											)}
										{/* {selectedGroup && groupDetails.publish_status == 1 && (
											<div className="setting">
												<Button type="primary" title="Settings">
													<FiSettings />
												</Button>
											</div>
										)} */}
										{selectedGroup &&
											groupDetails.publish_status == 1 &&
											permissions &&
											permissions.indexOf(
												AllPermissionList.LIST_GROUP_JOIN_REQUEST
											) >= 0 && (
												<div className="btn btn-primary">
													<Link to={"/saving-group/request-list"}>{t('REQUESTS')}</Link>
												</div>
											)}
										{selectedGroup && groupDetails.publish_status == 1 && (
											<Dropdown
												overlay={
													<Menu>
														<Menu.Item key="Group Members">
															<Link to={`/saving-group/member-details`}>
																{" "}
																{t("SHOW_GROUP_MEMBERS")}{" "}
															</Link>
														</Menu.Item>
													</Menu>
												}
												trigger={["click"]}
											>
												<div className="edit-profile-dropdown">
													<a
														className="ant-dropdown-link"
														onClick={(e) => e.preventDefault()}
													>
														<AiOutlineMore />
													</a>
												</div>
											</Dropdown>
										)}
									</div>
									{groupDetails.publish_status == 1 && (
										<>
											<div className="change">
												<div className="btn btn-danger">
													<a
														href="#"
														title={t("LEAVE_GROUP")}
														onClick={() => showLeaveGroupModel(true)}
													>
														{t("LEAVE_GROUP")}
													</a>
												</div>
											</div>

											{permissions && permissions.indexOf(
												AllPermissionList.GROUP_TERMINATE
											) >= 0 && <div className="change">
													<div className="btn btn-danger">
														<a
															href="#"
															title={t("GROUP_TERMINATE")}
															onClick={() => showDisableGroupModel(true)}
														>
															{t("GROUP_TERMINATE")}
														</a>
														<Modal
															centered
															visible={showDisableGroup}
															onOk={() => showDisableGroupModel(false)}
															onCancel={() => showDisableGroupModel(false)}
															className="custom-popup info-popup"
															footer={null}
														>
															<div className="info-text">
																<p>
																	{t("ARE_YOU_SURE_YOU_WANT_TO_TERMINATE_THIS_GROUP")}
																</p>
															</div>
															<div className="btn">
																<Button className="btn-danger" onClick={terminateThisGroup}>{t("TERMINATE")}</Button>
																<Button className="btn-grey" onClick={() => showDisableGroupModel(false)}>{t("CANCEL")} </Button>
															</div>
														</Modal>
													</div>
												</div>}
										</>
									)}
								</div>}
							</>

							<div className="profile-thumb">
								<span>
									<img
										src={groupDetails.group_image ?? ProfileImg}
										alt="Profile"
									/>
								</span>
								<h3><strong>{groupDetails.name}</strong></h3>
							</div>

							<Modal
								centered
								visible={showLeaveGroup}
								onOk={() => showLeaveGroupModel(false)}
								onCancel={() => showLeaveGroupModel(false)}
								className="custom-popup info-popup"
								footer={null}
							>
								<div className="info-text">
									<p>{t("ARE_YOU_SURE_YOU_WANT_TO_LEAVE_THE_GROUP")}</p>
								</div>
								<div className="btn">
									<Button className="btn-danger" onClick={LeaveGroup}>{t("LEAVE_GROUP")}</Button>
									<Button className="btn-grey" onClick={() => showLeaveGroupModel(false)}>{t("CANCEL")} </Button>
								</div>
							</Modal>
						</div>
						<div className="Profile-description">
							<h3>{t("DESCRIPTION")}</h3>
							<p>{groupDetails.group_description}</p>

							<table>
								<thead>
									<tr>
										<th>
											<strong>{t("PRESIDENT")}: </strong>
											{groupDetails.president.firstname ?
												groupDetails.president.firstname +
												" " +
												groupDetails.president.lastname +
												" (" +
												groupDetails.president.email +
												")" : "N/A"}
										</th>
										<td>
											<strong>{t("MEMBER")}: </strong>{" "}
											{groupDetails.existing_member_count +
												"/" +
												groupDetails.numbers_of_member}
										</td>

									</tr>
								</thead>

								<tbody>
									<tr>
										<th>
											<strong>{t("SECRETARY")}: </strong>
											{groupDetails.secretary.firstname ?
												groupDetails.secretary.firstname +
												" " +
												groupDetails.secretary.lastname +
												" (" +
												groupDetails.secretary.email +
												")" : "N/A"}
										</th>

										<td>
											<strong>{t("GROUP_WELFARE_RATE")}: </strong>{" "}
											{groupDetails.group_welfare_rate}
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t("ACCOUNTANT")}: </strong>
											{groupDetails.accountant.firstname ?
												groupDetails.accountant.firstname +
												" " +
												groupDetails.accountant.lastname +
												" (" +
												groupDetails.accountant.email +
												")" : "N/A"}
										</td>
										<td>
											<strong>{t("SHARE_PRICE")}: </strong>{" "}
											{groupDetails.share_price}
										</td>
									</tr>
									<tr>

										<td>
											<strong>{t("VILLAGE_AGENT")}: </strong>
											{groupDetails.villageAgent.firstname ?
												groupDetails.villageAgent.firstname +
												" " +
												groupDetails.villageAgent.lastname +
												" (" +
												groupDetails.villageAgent.email +
												")" : "N/A"}
										</td>
										<td>
											<strong>{t("INTEREST_RATE")}: </strong>{" "}
											{groupDetails.interest_rate + " %"}
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t("CONFEDERATION")}: </strong>
											{groupDetails.confederation.name ? groupDetails.confederation.name : "N/A"}
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>

							{user && !user.isVillageAgent && selectedGroup && groupDetails.publish_status == 1 && (
								<div className="btn btn-wrap">
									{user && <Button
										disabled={buyShareDisable}
										title={buyShareDisable ? t("YOU_HAVE_PURCHASED_MAXIMUM_NUMBER_OF_SHARES") : ""}
										type="primary" onClick={() => showBuyShareModel(true)}>
										{t("BUY_SHARES")}
									</Button>}
									{
										userRoleInThisGroup && (userRoleInThisGroup == RoleList.PRESIDENT || userRoleInThisGroup == RoleList.ACCOUNTANT || userRoleInThisGroup == RoleList.SECRETARY) && Cash_Module && <Button
											type="primary"
											onClick={handleDisplayBuyShareForUsers}>
											{t("BUY_SHARES_FOR_USERS")}
										</Button>
									}
									<div className="btn btn-primary">
										<Link to={"/my-wallet"}>{t("WALLET")}</Link>
									</div>
									<div className="btn btn-primary">
										<Link to={"/my-loans"}>{t("LOANS")}</Link>
									</div>
									<div className="btn btn-primary">
										<Link to={"/my-fines"}>{t("FINE")}</Link>
									</div>
								</div>
							)}

							{user && user.isVillageAgent && (
								<div className="btn btn-wrap">
									<div className="btn btn-primary">
										<Link to={"/saving-group/member-details"}>{t("MEMBER_DETAILS")}</Link>
									</div>
									<div className="btn btn-primary">
										<Link to={"/meetings"}>{t("MEETINGS")}</Link>
									</div>
									<div className="btn btn-primary">
										<Link to={"/election"}>{t("ELECTION")}</Link>
									</div>
								</div>
							)}
						</div>
					</div>
					<BuyShare
						showBuyShare={showBuyShare}
						showBuyShareModel={showBuyShareModel}
						groupDetails={groupDetails}
						form={form}
					/>
					<Modal
						centered
						visible={openGenerateOtpModal}
						onOk={() => setOpenGenerateOtpModal(false)}
						onCancel={() => {
							setOpenGenerateOtpModal(false);
							setIsCalculateAmount(false)
							setDisplayOtp(false)
							otpForm.resetFields();
							setUsersList([]);
						}}
						className="custom-popup info-popup generate-otp"
						title={t("GENERATE_OTP")}
						footer={null}
						width={10}
					>

						<div className="register-section section-wrap">
							<div className="main">
								<FormBox form={otpForm} onFinish={handleSubmit}>
									<Row>
										<Col span={12}>
											<div className="field">
												<i className="iconAlignment">
													<img src={profile_icon} alt="Profile" />
												</i>
												<InputBox.Select
													name={"user_id"}
													label={t("MEMBER_NAME")}
													placeholder={t("MEMBER_NAME")}
													showSearch
													filterOption={false}
													options={{
														list: usersList,
														valueKey: "id",
														textKey: ["user_name", " (", "phone_number", ")"]
													}}
													required
													//onFocus={handleSearch}
													onSearch={handleSearch}
													disabled={isCalculateAmount}
													rules={buyShareOfUsers(t).user}
												/>
											</div>
											<div className="field">
												<i className="iconAlignment">
													<img src={PaymentIcon} alt="payment" />
												</i>
												<InputBox.Text
													name={"share_price"}
													label={t("SHARE_PRICE")}
													disabled
												/>
											</div>
											{isCalculateAmount && <>
												<div className="field">
													<i className="iconAlignment">
														<img src={amount} alt="payment" />
													</i>
													<InputBox.Text
														name={"sub_total"}
														label={t("SUB_TOTAL_OF_SHARE_PRICE")}
														disabled={isCalculateAmount}
													/>
												</div>
												<div className="field">
													<i className="iconAlignment">
														<img src={amount} alt="amount" />
													</i>
													<InputBox.Text
														name={"final_amount"}
														label={t("FINAL_AMOUNT")}
														disabled={isCalculateAmount}
													/>
												</div>
											</>}
										</Col>
										<Col span={12}>
											<div className="field">
												<i className="iconAlignment">
													<img src={PaymentIcon} alt="payment" />
												</i>
												<InputBox.Text
													name={"number_of_shares"}
													label={t("NUMBER_OF_SHARES")}
													placeholder={t("NUMBER_OF_SHARES")}
													disabled={isCalculateAmount}
													rules={buyShareOfUsers(t).share}
												/>
											</div>
											<div className="field">
												<i className="iconAlignment">
													<img src={PaymentIcon} alt="payment" />
												</i>
												<InputBox.Select
													name={"payment_mode"}
													label={t("PAYMENT_MODE")}
													options={{
														list: userPaymentMode,
														valueKey: "id",
														textKey: "name",
													}}
													defaultValue={0}
													disabled
												/>
											</div>
											{isCalculateAmount && <div className="field">
												<i className="iconAlignment">
													<img src={amount} alt="payment" />
												</i>
												<InputBox.Text
													name={"welfare_amount"}
													label={t("WELFARE_AMOUNT")}
													disabled={isCalculateAmount}
												/>
											</div>}
										</Col>
									</Row>
									{
										!isCalculateAmount && <Row style={{ justifyContent: "center" }}>
											<Col span={12} style={{ width: "100%" }}>
												<Button
													onClick={() => { handleCalculateAmount() }}
													className="btn btn-primary otpBtn"
												>{t("CALCULATE_AMOUNT")}</Button>
											</Col>
										</Row>
									}
									<Row>
									</Row>
									<Row style={{ justifyContent: "center" }}>
										<Col span={12} style={{ width: "100%" }}>
											{isCalculateAmount && <Button
												onClick={handleGenerateOtp}
												className="btn btn-primary otpBtn" hidden={displayOtp}
											>{t("GENERATE_OTP")}</Button>}
										</Col>
									</Row>
									<Row style={{ justifyContent: "center" }}>
										<Col span={12} style={{ width: "100%" }}>
											{isCalculateAmount && <div className="field">
												<i className="iconAlignment">
													<img src={OTP} alt="Amount" />
												</i>
												<InputBox.Password
													name="otp"
													placeholder={t("ENTER_OTP")}
													label={t("ENTER_OTP")}
													rules={generateOTP(t).otp}
												/>
											</div>}
										</Col>
									</Row>
									<Row></Row>
									<br />
									<Row>
										<Col span={24}>
											<div className="btn btn-wrap">
												<Button
													type="primary"
													htmlType="submit"
													className="btn btn-primary"
													disabled={!isCalculateAmount}
												>
													{t("SAVE")}
												</Button>
												<Button
													type="primary"
													className="btn btn-danger"
													onClick={() => {
														setOpenGenerateOtpModal(false)
														setIsCalculateAmount(false)
														setDisplayOtp(false)
														otpForm.resetFields();
														setUsersList([]);
													}}
												>
													{t("CANCEL")}
												</Button>
											</div>
										</Col>
									</Row>
								</FormBox>
							</div>
						</div>
					</Modal>
				</div>
			)}
		</>
	);
});

export default SavingGroup;
