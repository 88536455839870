import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button } from "antd";
import { useHistory, useParams } from "react-router";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { InputBox } from "../../../components/AntdAddons";
import { observer } from "mobx-react";
import { AgGridColumn } from "ag-grid-react";

const Attendance: React.FC = observer(() => {
	const history = useHistory()
	const { t } = useTranslation();
	const {
		AUTH: { selectedGroup },
		MEETING: {saveUserMeetingRemark, saveUserMeetingAttendance, getGroupMeetingDetails, groupMeetingDetails },
	} = useStore();
	const { id }: any = useParams();
	const [meetingMemberList, setMeetingMemberList] = useState<any>([]);

	const getMeetingDetails = () => {
		getGroupMeetingDetails(id).then((data: any) => {
			setMeetingMemberList(data.meeting_users);
		});
	};

	const submitAttendance = (userId: any, isJoined: any) => {
		saveUserMeetingAttendance({meeting_id: id, user_id: userId, is_joined: isJoined}).then(() => {
			getMeetingDetails();
		});
	};

	const setRemark = (row: any, remarks: any) => {
		row.remarks = remarks.target.value;
	};

	const submitRemark = (row: any) => {
		saveUserMeetingRemark({meeting_id: id, user_id: row.user_id, remarks: row.remarks}).then(() => {
			getMeetingDetails();
		});
	};

	useEffect(() => {
		selectedGroup && getMeetingDetails();
	}, [selectedGroup, getGroupMeetingDetails]);

	const goToMeetings = () => {
		history.replace(`/meetings/${id}/details`);
	};

	const remarkRender = (params: any) => {
		return (
			<>
				<div className="field-text">
					<InputBox.TextArea
						name="remarks"
						placeholder={t('DESCRIPTION')}
						defaultValue={params.data.remarks}
						onKeyUp={(e: any)=>{setRemark(params.data, e)}}
					/>
				</div>
			</>
		)
	}

	const submitRemarkRender = (params: any) => {
		return (
			<>
				<Button
				onClick={()=>{submitRemark(params.data)}}
				title={t("SAVE")} type="default" style={{ color: "green" }} ><span>{t("SAVE")}</span></Button>
			</>
		)
	}

	const actionRenderer = (params: any) => {
		return (
			(params.data.is_joined == 0) ? <div>
				<Button
					type="default"
					icon={<CheckOutlined />}
					style={{color:"green"}}
					onClick={()=> submitAttendance(params.data.user_id, 1)}
				/>
				<Button
					type="default"
					icon={<CloseOutlined />}
					style={{ color: "red" }}
					onClick={()=> submitAttendance(params.data.user_id, 2)}
				/>
			</div> : (params.data.is_joined == 1) ? <div>
				<Button
					type="default"
					icon={<CheckOutlined />}
					style={{color:"green"}}
				/>
			</div> : <div>
				<Button
					type="default"
					icon={<CloseOutlined />}
					style={{ color: "red" }}
				/>
			</div>
		)
	}
	return (
		<>
			{groupMeetingDetails && <div className="member-details-section">
				<div className="main grid-class">
					<h3>{t("MEETING_TITLE")} : {groupMeetingDetails.title}</h3>
					<AgGridWrapper
						className="member-table"
						rowData={meetingMemberList}
						frameworkComponents={{
							actionRenderer,
							renderRemark: remarkRender,
							submitRemarkRender,
						}}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						onFilterChanged={({ api }) => {
							if (api.getDisplayedRowCount()) {
								api.hideOverlay();
							} else {
								api.showNoRowsOverlay();
							}
						}}
					>
						<AgGridColumn
							field="user_name"
							headerName={t("NAME")}
							width={200}
							pinned="left"
						/>
						<AgGridColumn field="is_joined" 
							headerName={t("ATTENDANCE")} 
							width={170}
							pinned="left"
							cellRenderer="actionRenderer"
							sortable={false}
							filter={false}/>
						<AgGridColumn
							field="remarks"
							headerName={t("REMARK")}
							cellRenderer="renderRemark"
							sortable={false}
							filter={false}
							autoHeight={true}
							cellClass="renderRemark"
						/>
						<AgGridColumn
							field="id"
							headerName={t("ACTION")}
							cellRenderer="submitRemarkRender"
							width={150}
							wrapText={true}
							autoHeight={true}
							pinned="right"
							sortable={false}
							filter={false}
						/>
					</AgGridWrapper>

					<div className="btn btn-wrap justify-center">
						<Button type="primary" onClick={goToMeetings}>
							{t("BACK")}
						</Button>
					</div>
				</div>
			</div>}
		</>
	);
});

export default Attendance;
