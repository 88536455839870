import { IServerSideGetRowsParams } from "ag-grid-community";
import { FormInstance, message } from "antd";
import axios from "axios";
import i18next from "./../../i18n";
import { makeAutoObservable } from "mobx";
import Message from "../../config/Message";
import { ErrorProps, FormatedErrorProps } from "./RootInterface";

export default class RootStore {
	public groupId : any = localStorage.getItem('groupId') || null;
	public i18nextLng : any = localStorage.getItem('i18nextLng') ?? null;

	constructor() {
		makeAutoObservable(this);
		this.setAxiosBaseUrl();
		window.addEventListener('storage', this.handleInvalidToken);
	}

	public handleInvalidToken = (e:any) => {
		if (e.key === 'token') {
			if (e.oldValue && !e.newValue) {
				window.location.href = "/";
				this.resetStore();
			} else if (!e.oldValue && e.newValue) {
				window.location.reload();
			}
		}
		if (e.key === 'groupId') {
			if (e.oldValue !== e.newValue) {
				window.location.reload();
			}
		}
		// if (e.key === 'i18nextLng') {
		// 	if (e.oldValue !== e.newValue) {
		// 		window.location.reload();
		// 	}
		// }
	}

	public setAxiosBaseUrl = (): void => {
		axios.defaults.baseURL = process.env.React_APP_API_URL || "";
	};

	public setGroupId = (groupId:any) => {
		localStorage.setItem('groupId', groupId);
		this.groupId = groupId;
	}

	public setAxiosInterceptor = async () => {
		console.log("aaaaaaaaaaaaaaaaaaaa");
		
		axios.interceptors.request.use((config) => {
			let header  = config.headers;
			header = {...header, "Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone}
			if(this.groupId) {
				header = {...header, "Group-Id":this.groupId}
			}
			this.i18nextLng = localStorage.getItem('i18nextLng') ?? null;
			console.log('this.i18nextLng', this.i18nextLng);
			
			if(this.i18nextLng) {
				header = {...header, "X-Localization": this.i18nextLng}
			}
			const authToken = localStorage.getItem("token");
			header = (authToken) ? {
				...header,
				Accept: "application/json",
				Authorization: "Bearer " + authToken,
			} : {
				...header,
				Accept: "application/json",
			};
			config.headers = header;
			/** In dev, intercepts request and logs it into console for dev */
			return config;
		});

		axios.interceptors.response.use(
			(response) => {
				if (response?.data?.notify) {
					message.success(response.data.notify);
				}
				return response;
			},
			(e) => {
				if (e.response) {
					if (e.response.data?.notify) {
						message.error(e.response.data.notify);
					}

					if (e.response.status === 423) {
						this.resetStore();
					}

					return Promise.reject(e.response);
				} else {
					message.error(Message(i18next.t).global.networkIssue);
					return Promise.reject({
						data: [],
						message: Message(i18next.t).global.networkIssue,
					});
				}
			}
		);
	};

	public resetStore = (): void => {
		localStorage.removeItem("token");
		localStorage.removeItem('groupId');
		window.location.href = "/";
	};

	public setAxiosHeaders = (token?: string): Promise<any> => {
		if (token) localStorage.setItem('token', token);
		const authToken = localStorage.getItem("token");
		if (token && authToken && authToken !== "undefined") {
			return Promise.resolve(authToken);
		} else {
			return Promise.reject();
		}
	};

	public SetNotificationConfig = (): void => {
		message.config({
			maxCount: 1,
			duration: 5,
		});
	};

	public AssignErrorToInput = (
		form: FormInstance,
		errors?: ErrorProps
	): void => {
		const formatedErrors: FormatedErrorProps[] = [];
		if (errors) {
			Object.keys(errors).forEach((key) => {
				formatedErrors.push({
					name: key,
					errors: errors[key],
				});
			});
			formatedErrors.forEach((x) => {
				if (typeof x.name === "string" && x.name.indexOf(".") !== -1) {
					const name: any = x.name.split(".");
					name.forEach((e: any) => {
						if (!isNaN(parseInt(e))) {
							e = parseInt(e);
						}
					});
				}
			});
		}
		form.setFields(formatedErrors);
	};

	public getServerListPayload = (params: IServerSideGetRowsParams) => {
		return {
			filter_data: params.request.filterModel,
			sort_data: params.request.sortModel,
			per_page: params.request.endRow - params.request.startRow,
			page: Math.ceil(
				(params.request.startRow + 1) /
					(params.request.endRow - params.request.startRow)
			),
		};
	};

	public getServerListPayloadForExport = (grid: any) => {
		return {
			filter_data: grid?.api.getFilterModel(),
			sort_data: grid?.api.getSortModel(),
			export_type: ''
		};
	};

	public downloadUrl = (url: string) => {
		const link = document.createElement('a');
			link.href = url;
			link.download = "";
			link.click();
	};
}
