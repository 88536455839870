import { observer } from "mobx-react";
import React, { useEffect } from "react";
import useStore from "../../../store";

const PrivacyPolicy: React.FC = observer(() => {
	const {
		CMS: { getCMSDetails, myCms },
		COMMON: { selectedLanguage },
	} = useStore();
	useEffect(() => {
		const obj = {
			slug: "privacy-policy",
			language_id: selectedLanguage ? selectedLanguage.id : null,
		};
		selectedLanguage && getCMSDetails(obj);
	}, [selectedLanguage, getCMSDetails]);

	return (
		<>
			<section className="common-page-section">
				<div className="main">
					<div className="heading-title">
						<h4>
							{myCms && myCms.page_title}
							{/* <span></span> */}
						</h4>
					</div>
					<p dangerouslySetInnerHTML={{ __html: myCms && myCms .page_description }} />
				</div>
			</section>
		</>
	);
});

export default PrivacyPolicy;
