const RoleList =  {
		'SUPER_ADMIN'     : 'Super Admin',
    'PRESIDENT'       : 'President',
    'SECRETARY'       : 'Secretary',
    'ACCOUNTANT'      : 'Accountant',
    'ELECTION_REFREE' : 'Election Refree',
    'VILLAGE_AGENT'   : 'Village Agent',
}

export default RoleList
