import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row } from "antd";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox } from "../../../components/AntdAddons";
import Otp_Img from "../../../assets/images/otp-img.svg";
import { VerifyAccountRequest } from "../../../requests/AuthRequest";
import { useTranslation } from "react-i18next";

const VerifyAccount: React.FC = () => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [userData, setUserData] = useState<any>(null);
	const history = useHistory();
	const { 
		AUTH: { verifyAccount, getTempToken, setupHeaders, resendOtp },
		ROOT: { AssignErrorToInput }
	} = useStore();
	const { t } = useTranslation();

	const handleSubmit = (data: any) => {
		setSaving(true);

		verifyAccount(data)
			.then(() => {
				localStorage.removeItem("userData");
				if(getTempToken()){
					setupHeaders(getTempToken());
					history.replace("/");
				} else {
					history.replace("/login");
				}
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	const submitResendOtp = () => {
		resendOtp({username: userData});
	}

	useEffect(() => {
		setUserData(localStorage.getItem("userData") ?? null);
	}, [userData]);

	return (
		<section className="login__section yellow-bg">
			<Row>
				<Col span={12}>
					<div className="section">
						<div className="content-wrap">
							<h1>{t('VERIFY_ACCOUNT')}</h1>
							<p>
							{t('PLEASE_ENTER_THE_OTP_SENT_TO_YOUR_REGISTER_PHONE_NUMBER_EMAIL_ADDRESS')} {t('TO_VERIFY_YOUR_ACCOUNT')}
							</p>
						</div>
						<div className="screen-img">
							<img src={Otp_Img} alt="Profile" />
						</div>
					</div>
				</Col>
				<Col span={12} className="section white-bg">
					<div className="form-title">
						<h3>{t('VERIFY_ACCOUNT')}</h3>
					</div>
					<FormBox form={form} onFinish={handleSubmit}>
						<div className="field otp-field">
							<Form.Item name="otp"
								rules={VerifyAccountRequest(t).otp}>
								<OtpInput
									className="otp-input"
									numInputs={4}
									separator={<span>-</span>}
								/>
							</Form.Item>
						</div>
						{userData && <div className="resendOtp">
							<Button className="resendOtp" onClick={submitResendOtp}>{t("RESEND_OTP")}</Button>
						</div>}
						<div className="info-text">
							<p>
								{t('PLEASE_ENTER_THE_OTP_SENT_TO_YOUR_REGISTER_PHONE_NUMBER_EMAIL_ADDRESS')}
							</p>
						</div>
						<div className="field">
							<Button loading={saving} type="primary" htmlType="submit">
								{t('VERIFY')}
							</Button>
							
						</div>
					</FormBox>
				</Col>
			</Row>
		</section>
	);
};

export default VerifyAccount;
