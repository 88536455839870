import React, { useEffect, useState } from "react";
import { Form, Button, Upload } from "antd";
import { Col, Row } from "antd";
import profile_icon from "../../../assets/images/profile-icon.svg";
import Email_Icon from "../../../assets/images/Email-Icon.svg";
//import Calling from "../../../assets/images/Calling.svg";
import Lock_icon from "../../../assets/images/lock-icon.svg";
import Calendar from "../../../assets/images/Calendar.svg";
import Lock from "../../../assets/images/Lock.svg";
import ProfileImg from "../../../assets/images/profile-img.svg";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { useTranslation } from "react-i18next";
import { ProfileRequest } from "../../../requests/AuthRequest";
import { observer } from "mobx-react-lite";
import { displayFormats, genderList, uploadedFileOnPreview } from "../../../config/Global";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import PaymentIcon from "../../../assets/images/money.svg";

const Profile: React.FC = observer(() => {
	const [form] = Form.useForm();
	const history = useHistory();
	const [saving, setSaving] = useState<boolean>(false);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [profileImage, setProfileImage] = useState<any>(null);
	const [fileList,setFileList]=useState<any>([])
	const [phoneNo, setPhoneNo] = useState<any>();
	const {
		AUTH: { user, fetchAuthUser, updateProfile },
		COMMON: { getProfessionList, professionListData, currencyListData, setCurrencyListData },
		ROOT: { AssignErrorToInput },
	} = useStore();
	const { t } = useTranslation();

	const handleCancel = () => {
		setEditMode(false);
		if (user) {
			form.setFieldsValue({
				firstname: user.firstname,
				lastname: user.lastname,
				email: user.email,
				address: user.address,
				profession_id: user.profession_id,
				date_of_birth: moment(user.date_of_birth),
				gender: user.gender,
				profile_image: user?.profile_image,
				phone_number: user?.country_calling_code + user?.phone_number,
				currency_id: user?.currency_id
			});
			let list:any=[]
			list=[user?.profile_image]?.map(
				(image: { id: any; document_name: any }) => {
					return {
						uid: "-1",
						name:"",
						status: "done",
						url: image,
						thumbUrl: image,
					};
				}
			),
			user?.profile_image && setFileList(list)
			setCurrencyListData([user?.currency])
			setPhoneNo({ nationalNumber: user?.phone_number, countryCallingCode: user?.country_calling_code,country:user?.country_code })
		}
	}

	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		profileImage && formData.append("profile_image", profileImage);
		data.firstname && formData.append("firstname", data.firstname);
		data.lastname && formData.append("lastname", data.lastname);
		data.email && formData.append("email", data.email);
		data.phone_number && formData.append("phone_number", phoneNo?.nationalNumber);
		data.address && formData.append("address", data.address);
		data.profession_id && formData.append("profession_id", data.profession_id);
		data.date_of_birth = formData.append("date_of_birth", moment(data.date_of_birth).format("YYYY-MM-DD"));
		data.gender && formData.append("gender", data.gender);
		data.country_code = phoneNo.country && formData.append("country_code", phoneNo?.country);
		data.countryCallingCode = phoneNo.countryCallingCode && formData.append("countryCallingCode", phoneNo?.countryCallingCode);

		updateProfile(formData)
			.then(() => {
				fetchAuthUser().then(() => {
					history.go(0);
				});
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	const disableStartDate = (current: any) => {
		return current && (current > moment().endOf("day"));
	};

	const onFileChange = (info: any) => {
		setProfileImage(info);
		return false;
	}

	useEffect(() => {
		handleCancel();
		getProfessionList();
	}, [user, form, getProfessionList]);

	const setPhone = (val: any) => {
		const phoneDetails: any = val ? parsePhoneNumber(val) : "";
		setPhoneNo(phoneDetails);
	};
	return (
		<>
			{user && (
				<div className="profile-section section-wrap create-group-section">
					<div className="main">
						<div className="edit-profile">
							<div className="change">
								<div className="btn btn-primary editProfile">
									<Button
										type="primary"
										title={t("EDIT_PROFILE")}
										onClick={() => setEditMode(true)}
									>
										{t("EDIT_PROFILE")}
									</Button>
								</div>
							</div>
							<div className="change">
								<div className="btn btn-danger changePassword">
									<Link to="/change-password" title={t("CHANGE_PASSWORD")}>
										{t("CHANGE_PASSWORD")}
									</Link>
								</div>
							</div>
						</div>
						<div className="profile-wrap">
							<div className="profile-thumb">
								<span>
									{user && user.profile_image ? <img src={user.profile_image} alt="Profile" /> : <img src={ProfileImg} alt="Profile" />}
								</span>
							</div>
						</div>
						<FormBox form={form} onFinish={handleSubmit}>
							<Row>
								<Col span={12}>
									<div className={editMode ? "field" : "field disabledInPutImage"}>
										<label className="title-lable">{t("PROFILE_IMAGE")}</label>
										<div className="upload-field">
											<i>
												<img src={profile_icon} alt="Profile" />
											</i>
											<span className="title-lable">{t("PROFILE_IMAGE")}</span>
											<Form.Item
												name="profile_image"
												rules={ProfileRequest(t).profile_image}
											>
												<Upload
													disabled={!editMode}
													name="profile_image"
													accept="image/png, image/jpeg"
													className="hideeyeicon"
													listType="picture-card"
													multiple={false}
													showUploadList={true}
													maxCount={1}
													beforeUpload={onFileChange}
													onPreview={uploadedFileOnPreview}
													fileList={fileList && fileList}
													onChange={(e)=>{
														setFileList(e.fileList)
													}}
												>
													<Button className="browse-btn">{t("BROWSE")}</Button>
												</Upload>
											</Form.Item>
										</div>
									</div>

									<div className="field">
										<i>
											<img src={profile_icon} alt="Profile" />
										</i>
										<InputBox.Text
											disabled={!editMode}
											name="firstname"
											placeholder={t("FIRST_NAME")}
											label={t("FIRST_NAME")}
											rules={ProfileRequest(t).firstname}
										/>
									</div>
									<div className="field">
										<i>
											<img src={profile_icon} alt="Profile" />
										</i>
										<InputBox.Text
											disabled={!editMode}
											name="lastname"
											placeholder={t("LAST_NAME")}
											label={t("LAST_NAME")}
											rules={ProfileRequest(t).lastname}
										/>
									</div>
									<div className="field">
										<i>
											<img src={Email_Icon} alt="Profile" />
										</i>
										<InputBox.Text
											disabled
											name="email"
											placeholder={t("EMAIL_ID")}
											label={t("EMAIL_ID")}
											rules={ProfileRequest(t).email}
										/>
									</div>
									<div className="field">
										<Form.Item
											name="phone_number"
											rules={ProfileRequest(t).phone_number}
											label={t("PHONE_NUMBER")}
											className="phoneInputContent"
										>
											<PhoneInput
												disabled
												countrySelectProps={{ unicodeFlags: true }}
												placeholder={t('PHONE_NUMBER')}
												name="phone_number"
												value={phoneNo}
												international
												defaultCountry="IN"
												countryCallingCodeEditable={false}
												onChange={setPhone}
											/>
										</Form.Item>
									</div>
									<div className="field">
										<InputBox.Radio
											options={{
												list: genderList(t),
												valueKey: "id",
												textKey: "value",
											}}
											name="gender"
											disabled={!editMode}
											rules={ProfileRequest(t).gender}
											label={t("GENDER")}
										/>
									</div>
								</Col>
								<Col span={12}>
									<div className="field">
										<i>
											<img src={Lock} alt="Profile" />
										</i>
										<InputBox.TextArea
											disabled={!editMode}
											placeholder={t("ADDRESS")}
											label={t("ADDRESS")}
											name="address"
											rules={ProfileRequest(t).address}
										/>
									</div>
									<div className="field">
										<i>
											<img src={Lock_icon} alt="Profile" />
										</i>
										{professionListData && <InputBox.Select
											name="profession_id"
											options={{
												list: professionListData,
												valueKey: "id",
												textKey: "name",
											}}
											placeholder={t("PROFESSION")}
											label={t("PROFESSION")}
											disabled={!editMode}
											rules={ProfileRequest(t).profession_id}
										/>}
									</div>
									<div className="field">
										<i>
											<img src={Calendar} alt="Profile" />
										</i>
										<InputBox.DatePicker
											name="date_of_birth"
											placeholder={t("DATE_OF_BIRTH")}
											label={t("DATE_OF_BIRTH")}
											disabled={!editMode}
											rules={ProfileRequest(t).date_of_birth}
											disabledDate={disableStartDate}
											format={displayFormats.DATE_FORMAT}
										/>
									</div>
									<div className="field">
										<i>
											<img src={PaymentIcon} alt="Profile" />
										</i>
										<InputBox.Select
											disabled
											name="currency_id"
											options={{
												list: currencyListData,
												valueKey: "id",
												textKey: "name"
											}}
											placeholder={t('CURRENCY')}
											rules={ProfileRequest(t).currency_id}
											label={t("CURRENCY")}
										/>
									</div>
									<div className="btn btn-wrap text-left">
										{editMode && (
											<>
												<Button
													loading={saving}
													className="btn btn-primary"
													htmlType="submit"
													type="primary"
												>
													{t("SAVE")}
												</Button>
												<Button
													onClick={handleCancel}
													className="btn btn-grey"
												>
													{t('CANCEL')}
												</Button>
											</>
										)}
									</div>
								</Col>
							</Row>
						</FormBox>
					</div>
				</div>
			)}
		</>
	);
});

export default Profile;
