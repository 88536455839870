import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const ElectionRequest = (t: any): RequestProps => ({
	title: [
		{ required: true, message: Message(t).required(t("ELECTION_TITLE")) },
		// { pattern: /^([A-Za-z -]+)$/, message: Message(t).type.pattern(t("ELECTION_TITLE")) },
		{ min: 3, message: Message(t).min(3, t("ELECTION_TITLE")) },
		({ getFieldValue }) => ({
			validator() {
				const title = getFieldValue("title");
				if (title && !title.trim()) {
					return Promise.reject(Message(t).required(t('ELECTION_TITLE')));
				}
				return Promise.resolve();
			},
		}),
	],
	election_role_id: [
		{ required: true, message: Message(t).required(t("DESIGNATION")) },
	],
	election_date: [
		{ required: true, message: Message(t).required(t("DATE")) },
	],
});

export const ElectionFeedbackRequest = (t: any): RequestProps => ({
	feedback: [
		{ required: true, message: Message(t).required(t("FEEDBACK")) },
		{ min: 3, message: Message(t).min(3, t("FEEDBACK")) },
		{ max: 5000, message: Message(t).max(5000, t("FEEDBACK")) },
		({ getFieldValue }) => ({
			validator() {
				const feedback = getFieldValue("feedback");
				if (feedback && !feedback.trim()) {
					return Promise.reject(Message(t).required(t("FEEDBACK")));
				}
				return Promise.resolve();
			},
		}),
	],
});

export const ElectionAddCandidatesRequest = (t: any): RequestProps => ({
	election: [
		{ required: true, message: Message(t).selectRequired(t("CANDIDATES")) },
		{
			validator: (rule, value) => {
				if (value.length < 2) {
					return Promise.reject(Message(t).minSelect(2, t("CANDIDATES")));
				}
				return Promise.resolve();
			},
		},
	],
});

export const ElectionCancelReasonRequest = (t: any): RequestProps => ({
	end_election_reason: [
		{ required: true, message: Message(t).required(t('REASON')) },
		{ min: 3, message: Message(t).min(3, t('REASON')) },
		{ max: 5000, message: Message(t).max(5000, t('REASON')) },
		({ getFieldValue }) => ({
			validator() {
				const end_election_reason = getFieldValue("end_election_reason");
				if (end_election_reason && !end_election_reason.trim()) {
					return Promise.reject(Message(t).required(t('REASON')));
				}
				return Promise.resolve();
			},
		}),
	]
});
