import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button, Col, Form, Modal, Row } from "antd";
import { GoPlus } from "react-icons/go";
import { GrDocumentExcel } from "react-icons/gr";
import { useHistory } from "react-router";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import moment from "moment";
import DateComparator from "../../../utils/DateComparator";
import Config from "../../../config/Config";
import AllPermissionList from "../../../config/AllPermissionList";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import RoleList from "../../../config/RoleList";
import { Cash_Module, displayFormats } from "../../../config/Global";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { CreateMyWelfareRequestRequest, RejectReasonRequest } from "../../../requests/WelfareRequestRequest";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";
import profile_icon from "../../../assets/images/profile-icon.svg";
import PaymentIcon from "../../../assets/images/money.svg";
import amount from "../../../assets/images/amount-icon.svg"
import Calendar from "../../../assets/images/Calendar.svg"
import OTP from "../../../assets/images/OTP-Icon.svg"
import { generateOTP } from "../../../requests/GroupRequest";

const WelfareRequestList: React.FC = observer(() => {
	const [rejectReasonForm] = Form.useForm();
	const { t } = useTranslation();
	const history = useHistory();
	const [form] = Form.useForm();
	const [form1] = Form.useForm();
	const [userRoleInThisGroup, setUserRoleInThisGroup] = useState<any>();
	const [saving, setSaving] = useState<boolean>(false);
	const [rejectReasonModel, setRejectReasonModel] = useState(false);
	const [rejectedId, setRejectedId] = useState<any>(0);

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
	const [updateConfirmationId, setUpdateConfirmationId] = useState(null);
	const [width, setWidth] = useState(window.innerWidth);
	const [openPaymentModeModal, setOpenPaymentModeModal] = useState(false)
	const [generateOtpModal, setGenerateOtpModal] = useState(false);
	const [welfareData, setWelfareData] = useState<any>()
	const [displayOtp, setDisplayOtp] = useState(false)

	const {
		AUTH,
		ROOT: { AssignErrorToInput },
		COMMON: { paymentListData },
		AUTH: { selectedGroup, user, permissions },
		GENERATE_OTP: { createGeneratedOtp },
		WELFARE_REQUEST: { setupWelfareRequestListGrid, changeStatus, exportWelfareRequestList,
			welfareRequestListGrid, changePaymentMode, payCash },
		SAVING_GROUP: { groupDetails, getGroupDetails },
	} = useStore();

	useEffect(() => {
		selectedGroup && getGroupDetails(selectedGroup)

	}, [getGroupDetails, selectedGroup]);

	const closeConfirmationPopup = () => {
		setUpdateConfirmationId(null);
		setConfirmationModal(false);
	};

	const openConfirmationPopup = (confirmationId: any) => {
		setUpdateConfirmationId(confirmationId);
		setConfirmationModal(true);
	};

	const handleSubmitConfirmation = () => {
		changeStatus({ welfare_request_id: updateConfirmationId, status: 1 }).finally(() => {
			closeConfirmationPopup();
		});
	};

	const SwitchRenderer = (params: any) => {
		if (userRoleInThisGroup && ((userRoleInThisGroup == RoleList.PRESIDENT && params.data?.president_approve_status == 0) || (userRoleInThisGroup == RoleList.SECRETARY && params.data?.secretary_approve_status == 0) || (userRoleInThisGroup == RoleList.ACCOUNTANT && params.data?.accountant_approve_status == 0))) {
			return (
				<div>
					<Button
						type="default"
						icon={<CheckOutlined />}
						hidden={params.data?.status == 2}
						disabled={(AUTH.permissions && AUTH.permissions.indexOf(AllPermissionList.WELFARE_REQUEST_APPROVE) < 0)}
						style={{ color: "green" }}
						onClick={() => { openConfirmationPopup(params.data?.id) }}
					/>
					<Button
						type="default"
						icon={<CloseOutlined />}
						hidden={params.data?.status == 1}
						disabled={(AUTH.permissions && AUTH.permissions.indexOf(AllPermissionList.WELFARE_REQUEST_APPROVE) < 0)}
						style={{ color: "red" }}
						onClick={() => { openRejectReasonModel(params.data?.id) }}
					/>
				</div>
			);
		} else if (userRoleInThisGroup && ((userRoleInThisGroup == RoleList.PRESIDENT && params.data?.president_approve_status == 1) || (userRoleInThisGroup == RoleList.SECRETARY && params.data?.secretary_approve_status == 1) || (userRoleInThisGroup == RoleList.ACCOUNTANT && params.data?.accountant_approve_status == 1))) {
			return (
				<div>
					<Button
						type="default"
						icon={<CheckOutlined />}
						style={{ color: "green" }}
					/>
				</div>
			);
		} else {
			return (
				<div>
					<Button
						type="default"
						icon={<CloseOutlined />}
						style={{ color: "red" }}
					/>
				</div>
			);
		}
	};

	const goToAddExpenses = () => {
		history.push('/welfare-request/add')
	}

	useEffect(() => {
		selectedGroup && user?.groups.forEach((element: any) => {
			if (element?.id == selectedGroup) {
				setUserRoleInThisGroup(element?.role?.name ?? "");
			}
		});
	}, [user, selectedGroup, userRoleInThisGroup]);

	const handleModalSubmit = (data: any) => {
		setSaving(true);
		changeStatus({ welfare_request_id: rejectedId, status: 2, reject_reason: data.reject_reason })
			.then(() => {
				rejectReasonForm.resetFields();
				closeRejectReasonModel();
			})
			.catch((e: ErrorProps) => {
				AssignErrorToInput(rejectReasonForm, e?.data.errors);
				setSaving(false);
			});
	};

	const openRejectReasonModel = (id: any) => {
		setRejectedId(id);
		setRejectReasonModel(true);
	}

	const closeRejectReasonModel = () => {
		setRejectedId(0);
		setRejectReasonModel(false);
		setSaving(false);
	}

	const exportWelfareRequest = (type: string) => {
		setExportSaving(true);
		exportWelfareRequestList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const changePaymentModeRenderer = (params: any) => {
		return (
			(params.data?.president_approve_status == 0) && <div className="action-column">
				<Button
					type="default"
					style={{ color: "green" }}
					onClick={() => displayChangePaymentModeDetails(params.data)}
				>{t("CHANGE_PAYMENT_MODE")}</Button>
			</div>
		);
	};
	const payCashRenderer = (params: any) => {
		return (
			(params.data.payment_mode == 0 && params.data.is_paid == 0 && params.data?.president_approve_status == 1 && params.data?.secretary_approve_status == 1 && params.data?.accountant_approve_status == 1) &&
			<div className="action-column">
				<Button
					type="default"
					style={{ color: "green" }}
					onClick={() => displayPayCashDetails(params.data)}
				>{t("PAY_CASH")}</Button>
			</div>
		);
	};
	const displayChangePaymentModeDetails = (data: any) => {
		setWelfareData(data)
		form.setFieldsValue({
			username: data?.user_name,
			description: data?.description,
			amount: data?.amount,
			duration: data?.number_of_installments,
			preference: data?.payment_mode
		})
		setOpenPaymentModeModal(true);
	};
	const displayPayCashDetails = (data: any) => {
		setGenerateOtpModal(true);
		setWelfareData(data)
		form1.setFieldsValue({
			member_name: data?.user_name,
			description: data?.description,
			amount: data?.amount,
		})
	};
	const handleGenerateOtp = () => {
		const payload = { id: welfareData?.id, type: 4 }
		createGeneratedOtp(payload).then(() => {
			setDisplayOtp(true)
		}).catch(() => {
			setDisplayOtp(false)
		})
	}
	const handleChangePaymentModeSubmit = (data: any) => {
		changePaymentMode(welfareData?.id, { preference: data?.preference }).then(() => {
			setOpenPaymentModeModal(false)
			form.resetFields();
		}).catch((e: any) => {
			setOpenPaymentModeModal(false)
			const errors = e?.errors || e?.data.errors;
			AssignErrorToInput(form, errors);
		})
	}

	const handlePayCash = (data: any) => {
		const payload = { welfare_request_id: welfareData?.id, otp: data?.otp }
		payCash(payload).then(() => {
			setGenerateOtpModal(false)
			form1.resetFields()
			setDisplayOtp(false)
		}).catch((e: any) => {
			const errors = e?.errors || e?.data.errors;
			AssignErrorToInput(form1, errors);
		})
	}
	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("WELFARE_REQUEST")}</h3>
						<div className="btn-wrap">
							<div className="text-center btn btn-primary btn-yellow">
								<Link to={"/my-welfare-request"} type="primary">
									{t("BACK")}
								</Link>
							</div>
							{welfareRequestListGrid && welfareRequestListGrid.api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={() => showExportPopup(true)}>
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>}

							<ExportPopupComponent
								callbackExportFunction={exportWelfareRequest}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>
							<Button className="btn-secondary" type="primary" onClick={goToAddExpenses}
								hidden={permissions && permissions.indexOf(AllPermissionList.ADD_WELFARE_REQUEST) < 0}
							>
								{t("ADD_WELFARE_REQUEST")} <GoPlus />
							</Button>
						</div>
						{Cash_Module &&

							<><small>{t('TOTAL_WELFARE_AMOUNT')} - {parseFloat(groupDetails?.welfare_cash_balance) + parseFloat(groupDetails?.welfare_wallet_balance)}</small>
								<small>{t('AMOUNT_IN_CASH')} - {groupDetails?.welfare_cash_balance}</small>
								<small>{t('AMOUNT_IN_DIGITAL')} - {groupDetails?.welfare_wallet_balance}</small>
							</>
						}
					</div>
					{user && userRoleInThisGroup && <AgGridWrapper
						type="serverSide"
						onGridReady={setupWelfareRequestListGrid}
						rowSelection={"multiple"}
						className="member-table"
						immutableData={true}
						frameworkComponents={{ SwitchRenderer, changePaymentModeRenderer, payCashRenderer }}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
					>
						<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter" />
						<AgGridColumn field="user_name" headerName={t('USER_NAME')} width={150} />
						<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} width={150} />
						<AgGridColumn field="description" headerName={t('DESCRIPTION')} width={150} />
						<AgGridColumn field="amount" headerName={t('AMOUNT')} filter={'agNumberColumnFilter'} />
						{Cash_Module && <AgGridColumn field="welfare_request_payment_mode" headerName={t('PAYMENT_MODE')} filter={'agTextColumnFilter'} />}
						<AgGridColumn field="display_approved_status" headerName={t('STATUS')} />
						<AgGridColumn
							field="created_at"
							headerName="Date"
							valueGetter={(data: any) =>
								data?.data?.created_at &&
								moment(data?.data.created_at).format(Config.dateTimeFormat)
							}
							filter={"agDateColumnFilter"}
							valueFormatter={(data: any) =>
								data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
							}
							filterParams={{
								comparator: DateComparator,
							}}
						/>
						<AgGridColumn
							field="status"
							headerName={t("STATUS")}
							cellRenderer="SwitchRenderer"
							pinned={width > 769 ? "right" : ""}
							width={120}
							sortable={false}
							filter={false}
						/>
						{userRoleInThisGroup && userRoleInThisGroup == RoleList.PRESIDENT && Cash_Module && <>
							<AgGridColumn
								field="id"
								headerName={t('PAYMENT_MODE')}
								cellRenderer="changePaymentModeRenderer"
								width={210}
								pinned={width > 769 ? "right" : ""}
								sortable={false}
								filter={false}
							/>
							<AgGridColumn
								field="id"
								headerName={t('PAY_CASH')}
								cellRenderer="payCashRenderer"
								width={130}
								pinned={width > 769 ? "right" : ""}
								sortable={false}
								filter={false}

							/></>}
					</AgGridWrapper>
					}

					<Modal
						title={t("REASON")}
						centered
						visible={rejectReasonModel}
						onOk={() => setRejectReasonModel(false)}
						onCancel={() => setRejectReasonModel(false)}
						afterClose={() => closeRejectReasonModel()}
						className="custom-popup"
						footer={null}
					>
						<FormBox form={rejectReasonForm} onFinish={handleModalSubmit}>
							<Row gutter={[20, 20]}>
								<Col span={24}>
									<div className="field">
										<small>{t("REASON")}</small>
										<InputBox.TextArea name="reject_reason" placeholder={t("REASON")}
											rules={RejectReasonRequest(t).reject_reason} />
									</div>
								</Col>
							</Row>
							<div className="btn">
								<Button loading={saving} className="btn-secondary" htmlType="submit">
									{t("SAVE")}
								</Button>
								<Button className="btn-danger" onClick={closeRejectReasonModel}>{t("CANCEL")}</Button>
							</div>
						</FormBox>
					</Modal>

					{/* change payment mode modal */}
					<Modal
						centered
						visible={openPaymentModeModal}
						onOk={() => setOpenPaymentModeModal(false)}
						onCancel={() => {
							setOpenPaymentModeModal(false);
							form.resetFields();

						}}
						className="custom-popup info-popup generate-otp"
						title={t("CHANGE_PAYMENT_MODE")}
						footer={null}
						width={10}
					>

						<div className="register-section section-wrap">
							<div className="main">
								<FormBox form={form} onFinish={handleChangePaymentModeSubmit}>
									<Row>
										<Col span={12}>
											<div className="field">
												<i className="iconAlignment">
													<img src={profile_icon} alt="Profile" />
												</i>
												<InputBox.Text
													name={"username"}
													label={t("MEMBER_NAME")}
													disabled
												/>
											</div>
											<div className="field">
												<i className="iconAlignment">
													<img src={Calendar} alt="Profile" />
												</i>
												<InputBox.TextArea
													name={"description"}
													label={t("DESCRIPTION")}
													disabled />
											</div>
										</Col>
										<Col span={12}>
											<div className="field">
												<i className="iconAlignment">
													<img src={amount} alt="Amount" />
												</i>
												<InputBox.Text
													name={"amount"}
													label={t("AMOUNT")}
													disabled />
											</div>
											<div className="field">
												<i className="iconAlignment">
													<img src={PaymentIcon} alt="Profile" />
												</i>
												<InputBox.Select
													name={"preference"}
													label={t("WHAT_IS_YOUR_PREFERENCE")}
													placeholder={t("PREFERENCE")}
													options={{
														list: paymentListData,
														valueKey: "id",
														textKey: "name",
													}}
													rules={CreateMyWelfareRequestRequest(t).preference}
												/>
											</div>
										</Col>
									</Row>
									<Row>
									</Row>
									<br />
									<Row>
										<Col span={24}>
											<div className="btn btn-wrap">
												<Button
													type="primary"
													htmlType="submit"
													className="btn btn-primary"
												>
													{t("SAVE")}
												</Button>
												<Button
													type="primary"
													className="btn btn-danger"
													onClick={() => {
														setOpenPaymentModeModal(false)
														form.resetFields();
													}}
												>
													{t("CANCEL")}
												</Button>
											</div>
										</Col>
									</Row>
								</FormBox>
							</div>
						</div>
					</Modal>
					{/*generate otp modal */}
					<Modal
						centered
						visible={generateOtpModal}
						onOk={() => setGenerateOtpModal(false)}
						onCancel={() => {
							setGenerateOtpModal(false);
							form1.resetFields();
							setDisplayOtp(false)
						}}
						className="custom-popup info-popup generate-otp"
						title={`${t("GENERATE_OTP")}`}
						footer={null}
						width={10}
					>

						<div className="register-section section-wrap">
							<div className="main">
								<FormBox form={form1} onFinish={handlePayCash}>
									<Row>
										<Col span={12}>
											<div className="field">
												<i className="iconAlignment">
													<img src={profile_icon} alt="Profile" />
												</i>
												<InputBox.Text label={t("MEMBER_NAME")} name={"member_name"} disabled />
											</div>
											<div className="field">
												<i className="iconAlignment">
													<img src={Calendar} alt="Profile" />
												</i>
												<InputBox.Text label={t("DESCRIPTION")} name={"description"} disabled />
											</div>
										</Col>
										<Col span={12}>
											<div className="field">
												<i className="iconAlignment">
													<img src={amount} alt="Amount" />
												</i>
												<InputBox.Text label={t("AMOUNT")} name={"amount"} disabled />
											</div>
										</Col>
									</Row>
									<Row>
									</Row>
									<Row style={{ justifyContent: "center" }}>
										{
											<Col span={12} style={{ width: "100%" }}>
												<Button onClick={handleGenerateOtp}
													className="btn btn-primary otpBtn" hidden={displayOtp}
												>{t("GENERATE_OTP")}</Button>
											</Col>
										}
									</Row>
									<Row style={{ justifyContent: "center" }}>
										<Col span={12} style={{ width: "100%" }}>
											<div className="field">
												<i className="iconAlignment">
													<img src={OTP} alt="Amount" />
												</i>
												<InputBox.Password
													name="otp"
													placeholder={t("ENTER_OTP")}
													label={t("ENTER_OTP")}
													rules={generateOTP(t).otp}
												/>
											</div>
										</Col>
									</Row><br />
									<Row>
										<Col span={24}>
											<div className="btn btn-wrap">
												<Button
													type="primary"
													htmlType="submit"
													className="btn btn-primary"
												>
													{t("SAVE")}
												</Button>
												<Button
													type="primary"
													className="btn btn-danger"
													onClick={() => {
														setGenerateOtpModal(false)
														form1.resetFields();
														setDisplayOtp(false)
													}}
												>
													{t("CANCEL")}
												</Button>
											</div>
										</Col>
									</Row>
								</FormBox>
							</div>
						</div>
					</Modal>
				</div>
			</div>

			<Modal
				centered
				visible={confirmationModal}
				onOk={closeConfirmationPopup}
				onCancel={closeConfirmationPopup}
				className="custom-popup info-popup"
				footer={null}
			>
				<div className="info-text">
					<p>
						{t('ACCEPT_THE_REQUEST')}
					</p>
				</div>
				<div className="btn">
					<Button className="btn-secondary" onClick={handleSubmitConfirmation}>{t("ACCEPT")}</Button>
					<Button className="btn-grey" onClick={closeConfirmationPopup}>{t("CANCEL")} </Button>
				</div>
			</Modal>
		</>
	);
});

export default WelfareRequestList;
