import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { AgGridColumn } from "ag-grid-react";
import {observer } from "mobx-react";
import useStore from "../../../store";
import moment from "moment";
import DateComparator from "../../../utils/DateComparator";
import { displayFormats } from "../../../config/Global";


const GeneratedOtpList: React.FC = observer(() => {
	const { t } = useTranslation();
	const [width, setWidth] = useState(window.innerWidth);
	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const {
		GENERATE_OTP: {rowData},
	} = useStore();

	return (
		<>
			<AgGridWrapper
				type="clientSide"
				rowData={rowData}
				rowSelection={"multiple"}
				className="member-table"
			>
				<AgGridColumn
					field="id"
					headerName={"# " + t("ID")}
					width={100}
					pinned={width > 769 ? "left" : ""} 
					filter="agNumberColumnFilter"
				/>
				<AgGridColumn field="otp" headerName={t("OTP")} filter="agTextColumnFilter"/>
				<AgGridColumn field="generated_by" headerName={t("GENERATED_BY")} filter="agTextColumnFilter"/>
				<AgGridColumn field="purpose" headerName={t("PURPOSE")} filter="agTextColumnFilter"/>
				<AgGridColumn field="amount" headerName={t("AMOUNT")}
					filter="agTextColumnFilter" />
					<AgGridColumn field="created_at" headerName={t("DATE_TIME")}
					filter="agDateColumnFilter" 
					valueFormatter={(data:any) =>
						data?.data.created_at && moment.utc(data?.data.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
					/>
					<AgGridColumn field="expiry_date" headerName={t("EXPIRE_DATE_TIME")}
					filter="agDateColumnFilter" 
					valueFormatter={(data:any) =>
						data?.data.expiry_date && moment.utc(data?.data.expiry_date).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
					/>
			</AgGridWrapper>
		</>
	);
});

export default GeneratedOtpList;
