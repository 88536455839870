import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button, Col, Form, Modal, Row } from "antd";
import { GoPlus } from "react-icons/go";
import { GrDocumentExcel } from "react-icons/gr";
import { AiOutlineDelete } from "react-icons/ai";
import { useHistory } from "react-router";
import { observer } from "mobx-react";
import AllPermissionList from "../../../config/AllPermissionList";
import { AgGridColumn } from "ag-grid-react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Cash_Module, displayFormats } from "../../../config/Global";
import DateComparator from "../../../utils/DateComparator";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import amount from "../../../assets/images/amount-icon.svg"
import Calendar from "../../../assets/images/Calendar.svg"
import edit_one from "../../../assets/images/edit-one.svg"
import OTP from "../../../assets/images/OTP-Icon.svg"
import { generateOTP } from "../../../requests/GroupRequest";
import profile_icon from "../../../assets/images/profile-icon.svg";
import RoleList from "../../../config/RoleList";

const ViewGroupFines: React.FC = observer(() => {
	const history = useHistory()
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [deleteFineModal, setDeleteFineModal] = useState(false);
	const [deleteFineId, setDeleteFineId] = useState(null);
	const [generateOtpModal, setGenerateOtpModal] = useState(false);
	const [fineData, setFineData] = useState<any>(null);

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);
	const [width, setWidth] = useState(window.innerWidth);
	const [displayOtp, setDisplayOtp] = useState(false)

	const {
		AUTH,
		AUTH: { permissions, userRoleInThisGroup },
		FINE: { setupGroupFineListGrid, deleteGroupFine, exportGroupFineList, groupFineListGrid, createPayCash },
		GENERATE_OTP: { createGeneratedOtp },
		ROOT: { AssignErrorToInput },
	} = useStore();

	const openMemberDetailsModal = (fineId: any) => {
		setDeleteFineId(fineId);
		setDeleteFineModal(true);
	}

	const openGenerateOtpModal = (data: any) => {
		setFineData(data)
		setGenerateOtpModal(true)
		form.setFieldsValue({
			user_name: data?.user_name,
			fine_type: data?.fine_type_name,
			description: data?.description,
			amount: data?.fine_amount,
			due_date: moment(data?.fine_due_date)

		})
	}
	const ActionRenderer = (param: any) => {
		return (
			(param.data?.paid_status == 0) ? <Button
				hidden={AUTH.permissions && AUTH.permissions.indexOf(AllPermissionList.DELETE_FINE) < 0}
				className="edit-button" title={t('DELETE')} type="text" onClick={() => openMemberDetailsModal(param.data?.id)}><AiOutlineDelete
				/></Button> : <></>
		)
	}
	const PayCashRenderer = (param: any) => {
		return (
			(param.data?.paid_status == 0) ? <div className="action-column">
				<Button style={{ color: "green" }} type="default" onClick={() => openGenerateOtpModal(param.data)}>{t("PAY_CASH")}</Button></div> : <></>
		)
	}

	const goToAddFines = () => {
		history.push('/add-fines')
	}

	const deleteFine = () => {
		deleteFineId && deleteGroupFine(deleteFineId).then(() => {
			setDeleteFineId(null);
			setDeleteFineModal(false);
		});
	}

	const exportGroupFine = (type: string) => {
		setExportSaving(true);
		exportGroupFineList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const handleGenerateOtp = () => {
		const payload = { id: fineData?.id, type: 1 }
		createGeneratedOtp(payload).then(() => {
			setDisplayOtp(true)
		}).catch(() => {
			setDisplayOtp(false)
		})
	}

	const handleSubmit = (data: any) => {
		createPayCash(fineData?.id, data).then(() => {
			setGenerateOtpModal(false)
			form.resetFields();
			setDisplayOtp(false)
		}).catch((e: any) => {
			const errors = e?.errors || e?.data.errors;
			AssignErrorToInput(form, errors);
		})
	};

	// const inputStyle = {
	// 	width: '3rem',
	// 	height: '3rem',
	// 	margin: '0 0.7rem',
	// 	fontSize: '2rem',
	// 	borderRadius: 4,
	// 	border: '1px solid rgba(0,0,0,0.3)',
	// 	textAlign: 'center',
	// };

	// const focusStyle = {
	// 	border: '1px solid #blue',
	// 	outline: 'none',
	// };



	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("GROUP_FINES")}</h3>
						<div className="heading-btn-wrap">
							<div className="text-center btn btn-primary btn-yellow">
								<Link to={"/my-fines"} type="primary">
									{t("BACK")}
								</Link>
							</div>
							{groupFineListGrid && groupFineListGrid.api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={() => showExportPopup(true)}>
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>}

							<ExportPopupComponent
								callbackExportFunction={exportGroupFine}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>
							<Button
								hidden={permissions && permissions.indexOf(AllPermissionList.ADD_FINE) < 0}
								className="btn-secondary"
								type="primary"
								onClick={goToAddFines}
							>
								{t("ADD_FINES")} <GoPlus />
							</Button>
						</div>
					</div>
					<div className="custom-tabs">
						<AgGridWrapper
							type="serverSide"
							onGridReady={setupGroupFineListGrid}
							rowSelection={"multiple"}
							className="member-table"
							frameworkComponents={{ ActionRenderer, PayCashRenderer }}
							immutableData={true}
							overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						>
							<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter" />
							<AgGridColumn field="description" headerName={t('DESCRIPTION')} />
							<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} />
							<AgGridColumn field="user_name" headerName={t('USER_NAME')} />
							<AgGridColumn field="fine_type_name" headerName={t('FINE_TYPE')} />
							<AgGridColumn field="fine_amount" headerName={t('AMOUNT')} filter={"agNumberColumnFilter"} />
							<AgGridColumn
								field="fine_due_date"
								headerName={t('DUE_DATE')}
								filter={"agDateColumnFilter"}
								valueFormatter={(data: any) =>
									data?.data.fine_due_date && moment.utc(data?.data.fine_due_date).local().format(displayFormats.DATE_FORMAT)
								}
								filterParams={{
									comparator: DateComparator,
								}}
							/>
							<AgGridColumn field="fine_payment_mode" headerName={t("PAYMENT_MODE")} />
							<AgGridColumn field="display_fine_paid_status" headerName={t('STATUS')} />
							{Cash_Module && <AgGridColumn field="fine_payment_mode" headerName={t('PAYMENT_MODE')} />}
							{userRoleInThisGroup && userRoleInThisGroup == RoleList.PRESIDENT && Cash_Module &&
								<AgGridColumn
									field="id"
									headerName={t('PAY_CASH')}
									cellRenderer="PayCashRenderer"
									width={140}
									pinned={width > 769 ? "right" : ""}
									sortable={false}
									filter={false}
								/>}
							<AgGridColumn
								field="id"
								headerName={t('DELETE')}
								cellRenderer="ActionRenderer"
								width={120}
								pinned={width > 769 ? "right" : ""}
								sortable={false}
								filter={false}
							/>
						</AgGridWrapper>
					</div>
				</div>
			</div>
			<Modal
				centered
				visible={deleteFineModal}
				onOk={() => setDeleteFineModal(false)}
				onCancel={() => setDeleteFineModal(false)}
				className="custom-popup info-popup"
				footer={null}
			>
				<div className="info-text">
					<p>
						{t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_FINE')}
					</p>
				</div>
				<div className="btn">
					<Button className="btn-danger" onClick={deleteFine}>{t("DELETE")}</Button>
					<Button className="btn-grey" onClick={() => setDeleteFineModal(false)}>{t("CANCEL")} </Button>
				</div>
			</Modal>
			<Modal
				centered
				visible={generateOtpModal}
				onOk={() => setGenerateOtpModal(false)}
				onCancel={() => {
					setGenerateOtpModal(false);
					form.resetFields();
					setDisplayOtp(false)
				}}
				className="custom-popup info-popup generate-otp"
				title={`${t("GENERATE_OTP")}`}
				footer={null}
				width={10}
			>

				<div className="register-section section-wrap">
					<div className="main">
						<FormBox form={form} onFinish={handleSubmit}>
							<Row>
								<Col span={12}>
									<div className="field">
										<i className="iconAlignment">
											<img src={profile_icon} alt="Profile" />
										</i>
										<InputBox.Text label={t("MEMBER_NAME")} name={"user_name"} disabled />
									</div>
									<div className="field">
										<i className="iconAlignment">
											<img src={edit_one} alt="Profile" />
										</i>
										<InputBox.Text label={t("FINE_TYPE")} name={"fine_type"} disabled />
									</div>
									<div className="field">
										<i className="iconAlignment">
											<img src={Calendar} alt="Profile" />
										</i>
										<InputBox.TextArea label={t("DESCRIPTION")} name={"description"} disabled />
									</div>
								</Col>
								<Col span={12}>
									<div className="field">
										<i className="iconAlignment">
											<img src={amount} alt="Amount" />
										</i>
										<InputBox.Text label={t("AMOUNT")} name={"amount"} disabled />
									</div>
									<div className="field">
										<i className="iconAlignment">
											<img src={Calendar} alt="Profile" />
										</i>
										<InputBox.DatePicker label={t("DUE_DATE")}
											name={"due_date"}
											value={fineData?.fine_due_date}
											format={displayFormats.DATE_FORMAT}
											disabled />
									</div>
								</Col>
							</Row>
							<Row>
							</Row>
							<Row style={{ justifyContent: "center" }}>
								{
									<Col span={12} style={{ width: "100%" }}>
										<Button onClick={handleGenerateOtp}
											className="btn btn-primary otpBtn" hidden={displayOtp}
										>{t("GENERATE_OTP")}</Button>
									</Col>
								}
							</Row>
							<Row style={{ justifyContent: "center" }}>
								<Col span={12} style={{ width: "100%" }}>
									<div className="field">
										<i className="iconAlignment">
											<img src={OTP} alt="Amount" />
										</i>
										<InputBox.Password
											name="otp"
											placeholder={t("ENTER_OTP")}
											label={t("ENTER_OTP")}
											rules={generateOTP(t).otp}
										/>
									</div>
								</Col>
							</Row><br />
							<Row>
								<Col span={24}>
									<div className="btn btn-wrap">
										<Button
											type="primary"
											htmlType="submit"
											className="btn btn-primary"
										>
											{t("SAVE")}
										</Button>
										<Button
											type="primary"
											className="btn btn-danger"
											onClick={() => {
												setGenerateOtpModal(false)
												form.resetFields();
												setDisplayOtp(false)
											}}
										>
											{t("CANCEL")}
										</Button>
									</div>
								</Col>
							</Row>
						</FormBox>
					</div>
				</div>
			</Modal>
		</>
	);
});

export default ViewGroupFines;
