import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import PageSpinner from "./components/PageSpinner/PageSpinner";
import useStore from "./store";
import AppRouter from "./utils/AppRouter";
import ScrollToTop from "./utils/ScrollToTop";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

const App: React.FC = observer(() => {
	const {
		COMMON: {
			redirectNotification,
			getWebCommonData,
		},
		AUTH: { initiateApp, appLoading, user, fetchAuthUser },
		USER: { updateMyDeviceToken },
	} = useStore();
	const [messaging, setMessaging] = useState<any>();

	async function setFirebaseApp() {
		try {
			const firebaseConfig = {
				apiKey: process.env.REACT_APP_FIREBASE_MESSAGING_API_KEY,
				authDomain: process.env.REACT_APP_FIREBASE_MESSAGING_AUTH_DOMAIN,
				projectId: process.env.REACT_APP_FIREBASE_MESSAGING_PROJECT_ID,
				storageBucket: process.env.REACT_APP_FIREBASE_MESSAGING_STORAGE_BUCKET,
				messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
				appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
				measurementId: process.env.REACT_APP_FIREBASE_MESSAGING_MEASUREMENT_ID,
			};

			initializeApp(firebaseConfig);
			setMessaging(getMessaging());

			if (window.isSecureContext) {
				if ('serviceWorker' in navigator) {
					navigator.serviceWorker
						.register('firebase-messaging-sw.js')
						.then(() => {
							// console.log('test Registration successful, scope is:', registration.scope);
						})
						.catch(() => {
							// console.log('test Service worker registration failed, error:', err);
						});
				}
			}
		}
		catch(err) {
			console.error('err', err);
		}
	}

	function startFCM() {
		getToken(messaging, {
			vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY,
		})
		.then((currentToken) => {
			if (currentToken) {
				user && user.device_token != currentToken && updateMyDeviceToken(currentToken).then(() => {
					fetchAuthUser();
				});
			}
		}).catch(() => {
			//
		});
	}

	messaging && onMessage(messaging, function (payload: any) {
		// console.log('Received foreground message ', JSON.stringify(payload));
		const title = payload.notification.title;
		const options = {
			body: payload.notification.body,
			icon: payload.notification.icon,
		};
		new Notification(title, options).addEventListener('click', (event: any) => {
			event.preventDefault(); // prevent the browser from focusing the Notification's tab
			//window.open(payload.data.click_action, '_blank');
			redirectNotification(payload.data.notification_id)
		});
	});

	useEffect(() => {
		const initFunction = async () => {
			await initiateApp();
			await getWebCommonData();
		}
		initFunction();
	}, [initiateApp]);

	useEffect(() => {
		setFirebaseApp();
		user && messaging && startFCM();
	}, [user, messaging]);
	console.log('Check');
	

	return (
		<BrowserRouter>
			<ScrollToTop />
			{appLoading ? <PageSpinner /> : <AppRouter />}
		</BrowserRouter>
	);
});

export default App;
