import { RcFile } from "antd/lib/upload";
import CryptoJS from "crypto-js";

export const UserStatus = (t: any) => [
	{
		id: 0,
		status: t("IN_ACTIVE"),
	},
	{
		id: 1,
		status: t("ACTIVE"),
	},
	{
		id: 2,
		status: t("BLOCKED"),
	},
];

export const UserLoanStatus = (t: any) => [
	{
		id: 0,
		status: t("PENDING"),
	},
	{
		id: 1,
		status: t("APPROVED"),
	},
	{
		id: 2,
		status: t("REJECTED"),
	},
];

export const TransactionType = {
	GROUP_EXPANSE: "1",
	FINE: "2",
	GROUP_JOIN_FEES: "3",
	PURCHASE_SHARE: "4",
	LOAN_PAYMENT: "5",
	LOAN_RE_PAYMENT: "6",
	GROUP_CYCLE_END_RETURN: "7",
	WELFARE_COLLECTION: "8",
	LOAN: "9",
};

export const paymentType = (t: any) => [
	{
		id: 1,
		payment_type: t("CREDIT"),
	},
	{
		id: 2,
		payment_type: t("DEBIT"),
	},
];

export const paymentStatus = (t: any) => [
	{
		id: 0,
		payment_status: t("PENDING"),
	},
	{
		id: 1,
		payment_status: t("SUCCESS"),
	},
	{
		id: 2,
		payment_status: t("PROCESSING"),
	},
	{
		id: 3,
		payment_status: t("FAILED"),
	},
	{
		id: 4,
		payment_status: t("REFUND"),
	},
];

export const bannerType = {
	ABOUT_US: 1,
	TERMS_AND_CONDITION: 2,
	PRIVACY_POLICY: 3,
	CONTACT_US: 4,
	SAVING_GROUP: 5,
	GROUP_EXPANSE: 6,
	FINES: 7,
	LOAN: 8,
	WELFARE_CONTRIBUTION_FUND: 9,
	GROUP_MEMBER: 10,
	WALLET: 11,
	PASSBOOK: 12,
	MEETINGS: 13,
	ELECTION: 14,
	REPORTS: 15,
	NEWS: 16,
	NOTIFICATION: 17,
	USER_MANAGEMENT: 18,
	FEEDBACK: 19,
	WELFARE_REQUEST: 20,
	PROFILE: 21,
};

export const displayHeader = {
	SLIDER: {
		DISPLAY: "slider",
		TYPE: {
			HOME: 1,
			DONATION: 2,
		},
	},
	BANNER: {
		DISPLAY: "banner",
		TYPE: bannerType,
	},
};

export const donationPaymentFrom = (t: any) => [
	{ id: 1, value: t("WALLET") },
	// { id: 2, value: t("") },   // AIRTEL_MONEY add in language
	{ id: 3, value: t("STRIPE") }, // STRIPE add in language
];

export const donatingToConfig = (t: any) => [
	{ id: 1, value: t("VSLA") },
	{ id: 2, value: t("GROUP") },
];

export const genderList = (t: any) => [
	{ id: 1, value: t("MALE") },
	{ id: 2, value: t("FEMALE") },
	{ id: 3, value: t("OTHERS") },
];

export const loginWithList = (t: any) => [
	{ id: 1, value: t("EMAIL") },
	{ id: 2, value: t("PHONE_NUMBER") },
]

export const displayFormats = {
	DATE_FORMAT: "MM-DD-YYYY",
	TIME_FORMAT: "HH:mm",
	DATE_TIME_FORMAT: "MM-DD-YYYY HH:mm",
};

export const uploadedFileOnPreview = async (file: any) => {
	let src = file.url;
	if (!src) {
		src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj);
			reader.onload = () => resolve(reader.result);
		});
	}

	const image = new Image();
	image.src = src;
	const imgWindow: any = window.open(src);
	imgWindow.document.write(image.outerHTML);
};

export const aesEncryptionPassword = async (password: any) => {
	const passwordInput = password;
	const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_JS_IV ?? "");
	const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_JS_KEY ?? "");
	const options = {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	};
	const encryptedCP = CryptoJS.AES.encrypt(passwordInput, key, options);
	const encryptedBase64 = encryptedCP.toString();
	const timestamp = Date.now().toString().substring(0, 10);
	const newPassword = encryptedBase64 + '#' + timestamp;
	return newPassword;
};
export const Cash_Module = !(process.env.React_APP_API_URL as string).includes("https://earzikionline.com")

export const IMAGE_TYPE = {
	jpeg: "image/jpeg",
	jpg: "image/jpg",
	png: "image/png",
	pdf: "application/pdf",
	docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	doc: "application/msword"
};

export const dataToFormDataConverter = (data: any, appendName?: any, appendFormData?: any) => {
	let formData: any;
	if (!appendFormData) {
		formData = new FormData();
	} else {
		formData = appendFormData;
	}
	for (const name in data) {
		let value = data[name];
		if (value !== undefined && value !== null) {
			if (Array.isArray(value)) {
				value.forEach((item, index) => {
					if (item?.originFileObj) {
						item = item?.originFileObj;
					}
					const fieldName = appendName ? `${appendName}[${name}][${index}]` : `${name}[${index}]`;
					formData.append(fieldName, item);
				});
			} else if (!value?.originFileObj && typeof value === "object" && value !== null) {
				if (appendName) {
					dataToFormDataConverter(value, `${appendName}[${name}]`, formData);
				} else {
					dataToFormDataConverter(value, `${name}`, formData);
				}
			} else {
				if (value.originFileObj) {
					value = value?.originFileObj;
				}
				if (appendName) {
					formData.append(`${appendName}[${name}]`, value);
				} else {
					formData.append(`${name}`, value);
				}
			}
		}
	}
	return formData;
};

export const onPreview = async (file: any) => {
	let src = file.url as string;
	if (!src) {
		src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj as RcFile);
			reader.onload = () => resolve(reader.result as string);
		});
	}
	if (file.type === 'application/pdf' || src.includes(".pdf") || src.includes(".PDF")) {
		const url = file.url ? file.url : URL.createObjectURL(file?.originFileObj)
		window.open(url)
	} else if (src.includes(".doc")) {
		window.open(src);
	} else {
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	}
};
