
import React, { useState } from "react";
import { Form } from "antd";
import useStore from "../../../store";
import moment from "moment";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import MeetingForm from "./MeetingForm";
import { observer } from "mobx-react";

const AddMeetings: React.FC = observer(() => {
	const [form] = Form.useForm();
	const {
		ROOT: {AssignErrorToInput},
		MEETING: {createMeeting},
	} = useStore();
	const history = useHistory();
	const { t } = useTranslation();
	const [saving, setSaving] = useState<boolean>(false);

	const listRoute = () =>{
		history.push('/meetings');
	}

	const handleSubmit = (data: any) => {
		setSaving(true);

		data.meeting_start_date_time && (data.meeting_start_date_time = moment(data.meeting_start_date_time).utc().format("YYYY-MM-DD HH:mm"));
		data.meeting_end_date_time && (data.meeting_end_date_time = moment(data.meeting_end_date_time).utc().format("YYYY-MM-DD HH:mm"));

		createMeeting(data).then(() => {
			listRoute();
		}).catch((e:any) => {
			setSaving(false);
			AssignErrorToInput(form, e?.data?.errors);
		});
	};

	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t('NEW_MEETING')}</h3>
					<MeetingForm
						form={form}
						handleSubmit={handleSubmit}
						isEdit={false}
						listRoute={listRoute}
						meetingDetails={{}}
						saving={saving}
					/>
				</div>
			</div>
		</>
	);
});

export default AddMeetings;
