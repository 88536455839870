import { Modal, Form, Row, Col, Button } from "antd"
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { InputBox } from "../../../components/AntdAddons";
import useStore from "../../../store";
import amount from "../../../../src/assets/images/amount-icon.svg";
import shareCount from "../../../../src/assets/images/number_of_share.svg";
import { BuyShareRequest, CreateSavingGroupRequest } from "../../../requests/GroupRequest";
import PaymentIcon from "../../../assets/images/money.svg";

interface BuyShareProps {
	showBuyShare: boolean;
	showBuyShareModel: (show: boolean) => void;
	form: any;
	groupDetails: any;
}
const BuyShare: React.FC<BuyShareProps> = observer(({
	showBuyShare,
	showBuyShareModel,
	form,
	groupDetails,
}) => {
	const [error, setError] = useState<boolean>(false);
	const { t } = useTranslation();
	const history = useHistory();

	const {
		SAVING_GROUP: { groupPurchaseShare },
		AUTH: { user },
		COMMON: { paymentListData }
	} = useStore();

	const onChangeOfShares = (shareCount: any) => {
		const sharePrice = groupDetails?.share_price || 0;
		form.setFieldsValue({
			share_price: (sharePrice * shareCount),
		});
		if (
			form.getFieldValue("wallet_balance") >= form.getFieldValue("share_price") && user?.wallet_balance > 0
		) {
			setError(true);
		} else {
			setError(false);
		}
	}

	const handleSubmit = (data: any) => {
		groupPurchaseShare(data).then(() => {
			setTimeout(() => {
				history.go(0);
				form.resetFields();
			}, 1000);
		});
	};

	useEffect(() => {
		onChangeOfShares(form.getFieldValue("share_count"));
	}, [showBuyShare]);

	const handleCancel = () => {
		showBuyShareModel(false);
		form.resetFields();
	}
	return (<Modal
		title={t("BUY_SHARES")}
		centered
		visible={showBuyShare}
		onOk={() => showBuyShareModel(false)}
		onCancel={handleCancel}
		className="custom-popup"
		footer={null}
	>
		<Form form={form} onFinish={handleSubmit}>
			<Row gutter={[20, 20]}>
				<Col span={12}>
					<div className="field">
						<small>{t("NUMBER_OF_SHARES")}</small>
						<i>
							<img src={shareCount} alt={t("NUMBER_OF_SHARES")} />
						</i>
						<InputBox.Text
							name="share_count"
							onChange={(e: any) => onChangeOfShares(e.target.value)}
							initialValue={1}
							rules={BuyShareRequest(t, groupDetails?.max_share_purchase_limit).share_count}
						/>
					</div>
				</Col>
				<Col span={12}>
					<div className="field">
						<small>{t("SHARE_PRICE")} + {t("GROUP_WELFARE_RATE")}</small>
						<i>
							<img src={amount} alt={t("SHARE_PRICE")} />
						</i>
						<InputBox.Text
							name="share_price" initialValue={groupDetails?.share_price}
							disabled
						/>
					</div>
				</Col>
				<Col span={12}>
					<div className="field">
						<small>{t("WALLET_BALANCE")}</small>
						<i>
							<img src={amount} alt={t("WALLET_BALANCE")} />
						</i>
						<InputBox.Text
							name="wallet_balance"
							initialValue={user.wallet_balance}
							disabled
						/>
					</div>
				</Col>
				{error && <Col span={12}>
					<div className="field">
						<small>{t("WHAT_IS_YOUR_PREFERENCE")}</small>
						<i>
							<img src={PaymentIcon} alt={t("PREFERENCE")} />
						</i>
						<InputBox.Select
							name={"preference"}
							placeholder={t("PREFERENCE")}
							options={{
								list: paymentListData,
								valueKey: "id",
								textKey: "name",
							}}
							rules={CreateSavingGroupRequest(t).preference}
						/>
					</div>
				</Col>}
			</Row>
				<div className="info-msg">
					<p>
						{t(
							error ? "YOU_HAVE_SUFFICIENT_MONEY_IN_YOUR_DIGITAL_WALLET_BALANCE" : "YOU_NEED_MORE_BALANCE_IN_YOUR_DIGITAL_WALLET_TO_BUY_SHARES"
						)}
					</p>
				</div>
			<div className="btn">
				<Button className="btn-secondary" htmlType="submit">{t("BUY")}</Button>
				<Button className="btn-danger" onClick={handleCancel}>{t("CANCEL")}</Button>
			</div>
		</Form>
	</Modal>)
})
export default BuyShare;
