import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Row, Col, List } from "antd";
import { FiSearch } from "react-icons/fi";
import User_thumb from "../../../assets/images/user-img.svg";
import useStore from "../../../store";
import { Link } from "react-router-dom";
import JoinGroup from "./JoinGroup";

const GroupList: React.FC = observer(() => {
	const [form] = Form.useForm();
	const {
		AUTH: { user },
		SAVING_GROUP: { searchJoinGroupList, getSearchJoinGroupList, setSearchJoinGroupListValue, searchJoinGroupListValue },
	} = useStore();
	const [showJoinGroup, showJoinGroupModel] = useState<boolean>(false);
	const [groupId, setGroupId]= useState<any>(null);
	const { t } = useTranslation();

	const handleSubmit = (data: any) => {
		data && (typeof data==='string') && handleSearch(data);
		data && (typeof data==='string') && setSearchJoinGroupListValue(data);
		return data;
	};

	const openJoinGroupModel = (id:any) => {
		setGroupId(id);
		showJoinGroupModel(true);
	}

	const handleSearch = (searchString? : any ) => {
		const searchObject = searchString ? { search: searchString } : { search: null };
		getSearchJoinGroupList(searchObject).then((data: any) => {
			const groupListData: any = [];
			setSearchJoinGroupListValue(searchString);
			data.forEach((element: any) => {
				groupListData.push({ value: element?.name, label: element?.name });
			});
		});
	};

	const handleChange = (event? : any ) => {
		!event.target.value && handleSearch();
	}

	useEffect(() => {
		user && !searchJoinGroupList && handleSearch(searchJoinGroupListValue);
	}, [user, getSearchJoinGroupList]);

	return (
		<>
			<div className="group-list-section">
				<div className="main">
					<h3>{t("GROUP_NAME")}</h3>
					<div className="section-wrap">
						<Row>
							<Col span={14}>
								<Form
									className="global-search"
									form={form}
									onFinish={handleSubmit}
								>
									<Input.Search
										size="large"
										placeholder={t('SEARCH')}
										onSearch={handleSubmit}
										onChange={handleChange}
										defaultValue={searchJoinGroupListValue}
										enterButton={<FiSearch />}
									/>
								</Form>
							</Col>
							<Col span={10} className="text-right">
								<Link to={"/create-saving-group"} className="btn-yellow" title={t("CREATE_SAVING_GROUP")} type="primary">{t("CREATE_SAVING_GROUP")}</Link>
							</Col>
						</Row>
					</div>

					<div className="group-listing">
						<List
							dataSource={searchJoinGroupList}
							renderItem={(group:any) => (
								<div className="group-item">
									<div className="group-header">
										<Row>
											<Col xs={24} sm={8} md={6} lg={6} xl={4}>
												<h3>{t('GROUP_NAME')}</h3>
											</Col>
											<Col>
												{<Link to={"/group-list/"+group?.id+"/group-details"} title={t('VIEW_GROUP')}><h3>{group?.name}</h3></Link>}
											</Col>
										</Row>
									</div>
									<div className="group-info">
										<Row>
											<Col span={4}>
												<div className="user-img">
													<img src={group?.group_image ? group?.group_image : User_thumb} alt="User" />
												</div>
											</Col>
											<Col span={8}>
												<p>
													{group?.group_description}
												</p>
											</Col>
											<Col span={6}>
												<div className="price">
													<span>{t('SHARE_PRICE')}</span>
													<span>{group?.share_price} {group?.currency_symbol}</span>
												</div>
											</Col>
											<Col span={6} className="text-right">
												<Button
													disabled={group && (group.is_joined == 1 || group.existing_member_count >= group.numbers_of_member)}
													className="btn-primary" type="primary" onClick={() => { setGroupId(group.id); openJoinGroupModel(group.id)}}>
													{group && group.is_joined == 1 ? t("JOINED") : (group && group.existing_member_count >= group.numbers_of_member ? t("LIMIT_EXCEEDED") : t("JOIN"))}
												</Button>
											</Col>
										</Row>
									</div>
								</div>
							)}
						/>
						{searchJoinGroupList == 0 && (
							<div className="profile-section">
								<div className="main">
									<div className="profile-wrap">
										<h3>
											{t("NO_GROUP_FOUND")}
										</h3>
									</div>
								</div>
							</div>
						)}
						<JoinGroup setGroupId={setGroupId} groupId={groupId} showJoinGroupModel={showJoinGroupModel} showJoinGroup={showJoinGroup} />
					</div>
				</div>
			</div>
		</>
	);
});

export default GroupList;
