import { Button, Col, Form, message, Row } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router";
import { InputBox } from "../../../components/AntdAddons";
import RoleList from "../../../config/RoleList";
import { ElectionAddCandidatesRequest } from "../../../requests/ElectionRequest";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";

const ElectionAddCandidates: React.FC = observer(() => {
	const [form] = Form.useForm();
	const history = useHistory();
	const { t } = useTranslation();
	const { id }: any = useParams();
	const {
		ROOT: { AssignErrorToInput },
		AUTH: { user, permissions },
		ELECTION: {
			getElectionRefereeElectionDetail,
			electionRefereeElectionDetail, addElectionCandidates
		},
		COMMON : { getGroupUser, groupUserList },
	} = useStore();
	const [saving, setSaving] = useState<boolean>(false);

	const handleSubmit = (data: any) => {
		const candidateList = [];

		for (const key in data.election) {
			for (let i = 0; i < data.election[key].length; i++) {
				candidateList.push(data.election[key][i]);
			}
		}
		const uniqueCandidate: any = candidateList.filter(
			(v: any, i: any, a: any) => a.indexOf(v) === i
		);

		if (candidateList.length > uniqueCandidate.length) {
			message.error(t("SAME_USER_CANNOT_APPLY_FOR_MULTIPLE_DESIGNATION"));
			return false;
		}

		setSaving(true);
		data.election_id = id;
		addElectionCandidates(data).then(() => {
			history.replace("/");
		}).catch((e: ErrorProps) => {
			const errors = e?.errors || e?.data?.errors;
			errors && AssignErrorToInput(form, errors);
			setSaving(false);
		});
	};

	useEffect(() => {
		id && user && !electionRefereeElectionDetail && getElectionRefereeElectionDetail(id);
		user && electionRefereeElectionDetail && !groupUserList && getGroupUser(electionRefereeElectionDetail.group_id);
	}, [user, permissions, groupUserList, electionRefereeElectionDetail]);
	
	return (
		<>
			{electionRefereeElectionDetail && groupUserList && <section className="group-list-table-section">
				<div className="main">
				<div className="heading-title mb-50">
									<Row>
										<Col span={24}>
											<h4>{t("ELECTION_DETAIL")}</h4>
										</Col>
									</Row>
								</div>
					<h3>{t("GROUP_NAME")} : {electionRefereeElectionDetail?.group.name}</h3>
					<Form form={form} onFinish={handleSubmit}>
					<div className="responsive-table">
						<table>
							<thead>
								<tr>
									<th style={{width: '9%'}} scope="col">{t("SR_NO")}</th>
									<th style={{width: '15%'}} scope="col">{t("DESIGNATIONS")}</th>
									<th scope="col">{t("CANDIDATES")}</th>
								</tr>
							</thead>
							<tbody>
								{electionRefereeElectionDetail.electionCandidates &&
									electionRefereeElectionDetail.electionCandidates.map(
										(itemRole: any, index: any) => {
											const selectedUserIds = (itemRole.cadidates) ? itemRole.cadidates.map((row:any) => row.user_id) : [];
											return (
												<tr key={"election-candidates-" + index}>
													<td data-label={t("SR_NO")}>{index+1}</td>
													<td data-label={t("DESIGNATIONS")}>{(itemRole.role_name == RoleList.PRESIDENT) ? t("PRESIDENT") : (itemRole.role_name == RoleList.SECRETARY) ? t("SECRETARY") : (itemRole.role_name == RoleList.ACCOUNTANT)  ? t("ACCOUNTANT") : ""}</td>
													<td data-label={t("CANDIDATES")}>
														<InputBox.Select
															mode="multiple"
															showSearch
															style={{ width: "100%" }}
															name={["election",itemRole.election_role_id+""]}
															options={{
																list: groupUserList.users,
																valueKey: "id",
																textKey: "user_name",
															}}
															placeholder={t('CANDIDATES')}
															label={t("CANDIDATES")}
															initialValue={selectedUserIds}
															showArrow={true}
															className="candidates_name"
															rules={ElectionAddCandidatesRequest(t).election}
														/>
													</td>
												</tr>
											);
										}
									)}
							</tbody>
						</table>
						<caption></caption>
					</div>

					<div className="btn small-btn text-center">
						<Button
							hidden={!(moment(electionRefereeElectionDetail.election_date) > moment())}
							htmlType="submit"
							className="btn-primary"
							loading={saving}>{t("SAVE")}</Button>
						<Button className="btn-danger" onClick={history.goBack} >{t("CANCEL")}</Button>
					</div>
					</Form>
				</div>
			</section>}
		</>
	);
});

export default ElectionAddCandidates;
