import { Button, Col, Form, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import { InputBox } from "../../../components/AntdAddons";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import useStore from "../../../store";
import RoleList from "../../../config/RoleList";
import moment from "moment";
import { ElectionFeedbackRequest } from "../../../requests/ElectionRequest";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { TrophyOutlined } from '@ant-design/icons';

const ElectionRefereeResultFeedback: React.FC = observer(() => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const { id }: any = useParams();
	const history = useHistory();
	const [saving, setSaving] = useState<boolean>(false);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
	const [updateRoleId, setUpdateRoleId] = useState(null);
	const [updateStatus, setUpdateStatus] = useState(null);

	const {
		ROOT: { AssignErrorToInput },
		AUTH: { user, permissions },
		ELECTION: {
			getElectionRefereeElectionDetail,
			electionRefereeElectionDetail, addElectionFeedback, declareElectionRoleResult
		},
	} = useStore();

	const closeConfirmationPopup = () => {
		setUpdateRoleId(null);
		setUpdateStatus(null);
		setConfirmationModal(false);
	};

	const openConfirmationPopup = (role_id: any, status: any) => {
		setUpdateRoleId(role_id);
		setUpdateStatus(status);
		setConfirmationModal(true);
	};

	const goToBackHome = () => {
		history.push("/");
	};

	const handleSubmit = (data: any) => {
		data.election_id = id;
		addElectionFeedback(data).then(() => {
			setEditMode(false);
		})
		.catch((e: ErrorProps) => {
			const errors = e?.errors || e?.data?.errors;
			errors && AssignErrorToInput(form, errors);
			setSaving(false);
		});
	};

	const handleSubmitElectionResult = () => {
		declareElectionRoleResult({election_id : id, election_role_id: updateRoleId, status: updateStatus})
		.then(() => {
			getElectionRefereeElectionDetail(id);
		}).finally(() => {
			closeConfirmationPopup();
		});
	};

	useEffect(() => {
		id && user && !electionRefereeElectionDetail && getElectionRefereeElectionDetail(id);

		user && electionRefereeElectionDetail && form.setFieldsValue({
			feedback: electionRefereeElectionDetail.feedback,
		});
	}, [user, permissions, electionRefereeElectionDetail]);

	return (
		<>
			<section className="group-list-table-section padding-100">
				<Form form={form} onFinish={handleSubmit}>
					<div className="main">
					<div className="heading-title">
						<Row>
							<Col span={24}>
								<h4>{t("ELECTION_RESULT")}</h4>
							</Col>
						</Row>
					</div>
						{/* <div className="title-overlap title-with-border">
							<h3>{t("ELECTION_RESULT")}</h3>
						</div> */}
						<h3>{t("GROUP_NAME")} : {electionRefereeElectionDetail && electionRefereeElectionDetail?.group.name}</h3>
						<div className="responsive-table">
							<table>
								<thead>
									<tr>
										<th scope="col">{t("DESIGNATIONS")}</th>
										<th scope="col">{t("CANDIDATES")}</th>
										<th scope="col">{t("WINNER")}</th>
									</tr>
								</thead>
								<tbody>
									{electionRefereeElectionDetail &&
										electionRefereeElectionDetail.electionCandidates &&
										electionRefereeElectionDetail.electionCandidates.map(
											(itemRole: any, index: any) => {
												const selectedUserName = itemRole.cadidates ? itemRole.cadidates.map((row: any) => row.user_name) : [];

												return (
													<tr key={"election-candidates-" + index}>
														<td data-label={t("DESIGNATIONS")}>{(itemRole.role_name == RoleList.PRESIDENT) ? t("PRESIDENT") : (itemRole.role_name == RoleList.SECRETARY) ? t("SECRETARY") : (itemRole.role_name == RoleList.ACCOUNTANT)  ? t("ACCOUNTANT") : ""}</td>
														<td data-label={t("CANDIDATES")}>
															{selectedUserName.join(', ')}
														</td>
														<td data-label={t("WINNER")}>
															{(itemRole?.status == 1) ? <>{itemRole?.winner_candidates?.user_name}  <TrophyOutlined /></> : (itemRole.status == 2) ? <Button
																	type="default"
																	icon={<CloseOutlined />}
																	style={{ color: "red" }}
																/> : <div>
																<Button
																	type="default"
																	icon={<CheckOutlined />}
																	style={{ color: "green" }}
																	onClick={()=> openConfirmationPopup(itemRole.election_role_id, 1)}
																/>
																<Button
																	type="default"
																	icon={<CloseOutlined />}
																	style={{ color: "red" }}
																	onClick={()=> openConfirmationPopup(itemRole.election_role_id, 2)}
																/>
															</div>}
														</td>
													</tr>
												);
											}
										)}
								</tbody>
							</table>
							<caption></caption>
						</div>

						<div className="feedback-section">
							<div className="field">
								<label>{t("FEEDBACK")}</label>
								<div className="edit-icon">
									<Button className="edit-button" onClick={() => setEditMode(true)} title={t("EDIT")} type="text">
										<AiOutlineEdit />
									</Button>
								</div>
								<InputBox.TextArea disabled={!editMode} name="feedback" placeholder={t("FEEDBACK")}
										rules={ElectionFeedbackRequest(t).feedback} />
							</div>
						</div>
						<div className="btn text-center">
							<Button
								loading={saving}
								hidden={!editMode}
								type="primary"
								className="btn btn-secondary"
								htmlType="submit"
							>
								{t("SAVE")}
							</Button>
						</div>

						<div className="progress-end">
							{electionRefereeElectionDetail && <p>{moment(electionRefereeElectionDetail.election_date)==moment(moment().format("YYYY-MM-DD")) ? t("ELECTION_IN_PROGRESS") : (moment(electionRefereeElectionDetail.election_date)<=moment(moment().format("YYYY-MM-DD"))) ? t("ELECTION_ENDED") : ""}</p>}
						</div>

						<div className="btn text-center">
							<Button
								type="primary"
								className="btn btn-yellow"
								onClick={goToBackHome}
							>
								{t("BACK_TO_HOME")}
							</Button>
						</div>
					</div>
				</Form>
			</section>

			<Modal
				centered
				visible={confirmationModal}
				onOk={closeConfirmationPopup}
				onCancel={closeConfirmationPopup}
				className="custom-popup info-popup"
				footer={null}
			>
				<div className="info-text">
					<p>
						{(updateStatus == 1) ? t('ACCEPT_THE__ELECTION_REQUEST') : t('DECLINE_ELECTION_REQUEST')}
					</p>
				</div>
				<div className="btn">
					<Button className={(updateStatus == 1) ? "btn-secondary" : "btn-danger"} onClick={handleSubmitElectionResult}>{(updateStatus == 1) ? t("ACCEPT") : t("REJECT")}</Button>
					<Button className="btn-grey" onClick={closeConfirmationPopup}>{t("CANCEL")} </Button>
				</div>
			</Modal>
		</>
	);
});

export default ElectionRefereeResultFeedback;
