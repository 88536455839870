import React, { useEffect } from "react";
import { Button } from "antd";
import useStore from "../../../store";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { observer } from "mobx-react";
import moment from "moment";
import AllPermissionList from "../../../config/AllPermissionList";
import { displayFormats } from "../../../config/Global";

const MeetingDetails: React.FC = observer(() => {
	const history = useHistory()
	const { t } = useTranslation();
	const { id }: any = useParams();

	const {
		AUTH: { selectedGroup, permissions },
		MEETING: { groupMeetingDetails, getGroupMeetingDetails },
	} = useStore();

	const goToRemarkMeeting = () => {
		history.push("/meetings/" + id + "/remark");
	};

	const goToAttendance = () => {
		history.push("/meetings/" + id + "/attendance");
	};

	const goBackToMeetingList = () => {
		history.replace('/meetings');
	};

	useEffect(() => {
		selectedGroup && getGroupMeetingDetails(id);
	}, [selectedGroup, getGroupMeetingDetails]);
	

	return (
		<>
			{groupMeetingDetails && (
				<div className="register-section section-wrap">
					<div className="main">
						<h3>{t("MEETING_DETAILS")}</h3>
						<div className="meeting-table">
							<table>
								<thead>
									<tr>
										<th>{t("MEETING_TITLE")}</th>
										<th>{groupMeetingDetails.title}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t("START_DATE_TIME")}</td>
										<td>
											{moment.utc(groupMeetingDetails.meeting_start_date_time).local().format(displayFormats.DATE_TIME_FORMAT)}
										</td>
									</tr>
									<tr>
										<td>{t("END_DATE_TIME")}</td>
										<td>
											{moment.utc(groupMeetingDetails.meeting_end_date_time).local().format(displayFormats.DATE_TIME_FORMAT)}
										</td>
									</tr>
									<tr>
										<td>{t("MEETING_LOCATION_LINK")}</td>
										<td>{groupMeetingDetails.location}</td>
									</tr>
									<tr>
										<td>{t("MEETING_DESCRIPTION")}</td>
										<td>{groupMeetingDetails.description}</td>
									</tr>
									<tr>
										<td>{t("MEMBERS_JOINED")}</td>
										<td>{groupMeetingDetails?.present_user_count}</td>
									</tr>
									<tr>
										<td>{t("SUMMARY")}</td>
										<td>
											<p>{groupMeetingDetails?.summery ? groupMeetingDetails.summery : "N/A"}</p>
										</td>
									</tr>
								</tbody>
							</table>
							<div className="btn btn-wrap btn-inline">
								{moment() > moment.utc(groupMeetingDetails.meeting_start_date_time).local() && permissions && permissions.indexOf(AllPermissionList.TAKE_MEETING_ATTENDANCE) >= 0 && (
									<Button type="primary" onClick={goToAttendance}>
										{t("ATTENDANCE")}
									</Button>
								)}

								<Button type="primary" onClick={goBackToMeetingList}>
									{t("BACK_TO_MEETING_LIST")}
								</Button>

								{moment() > moment.utc(groupMeetingDetails.meeting_start_date_time).local() && (
									<Button
										hidden={permissions && (permissions.indexOf(AllPermissionList.END_MEETING) < 0)}
										className="btn-danger"
										type="primary"
										onClick={goToRemarkMeeting}
									>
										{(groupMeetingDetails.status == 1) ? t("END") : t('EDIT_SUMMARY')}
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
});

export default MeetingDetails;
