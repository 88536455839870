import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { createLoan } from "./LoanInterface";

export default class LoanStore {
	private rootStore: RootStore;
	public myLoanRequestListGrid?: any;
	public groupUserLoanRequestListGrid?: any;
	public myLoanRequestList?: any;
	public userLoanDisbursementListGrid?: any;
	public userLoanRequestDetails?: any;
	public myLoanRequestDetails?: any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setMyLoanRequestListGrid = (value: any) => {
		this.myLoanRequestListGrid = value;
	};
	private setMyLoanRequestList = (value: any) => {
		this.myLoanRequestList = value;
	};
	private setGroupUserLoanRequestListGrid = (value: any) => {
		this.groupUserLoanRequestListGrid = value;
	};

	private setUserLoanDisbursementListGrid = (value: any) => {
		this.userLoanDisbursementListGrid = value;
	};

	private setUserLoanRequestDetails = (value: any) => {
		this.userLoanRequestDetails = value;
	};

	private setMyUserLoanRequestDetails = (value: any) => {
		this.myLoanRequestDetails = value;
	};

	// Grid Setup Functions
	public setupMyLoanRequestListGrid = (params: GridReadyEvent) => {
		this.setMyLoanRequestListGrid(params);
		const datasource = this.getMyLoanRequestListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private getMyLoanRequestListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getMyLoanRequestList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setMyLoanRequestListGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	///Group user loan request list
	public setupGroupUserLoanRequestListGrid = (params: GridReadyEvent) => {
		this.setGroupUserLoanRequestListGrid(params);
		const datasource = this.getGroupUserLoanRequestListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private getGroupUserLoanRequestListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getGroupUserLoanRequestList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setGroupUserLoanRequestListGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	///User Loan Disbursement List
	public setupUserLoanDisbursementListGrid = (params: GridReadyEvent) => {
		this.setUserLoanDisbursementListGrid(params);
		const datasource = this.getUserLoanDisbursementListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private getUserLoanDisbursementListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getUserLoanDisbursementList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setUserLoanDisbursementListGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public createMyLoan = (payload: createLoan): Promise<any> => {
		return axios
			.post(API_URL.LOAN.MY_LOAN_CREATE, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public getMyLoanRequestList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.LOAN.MY_LOAN_LIST, payload)
			.then(({ data }) => {				
				this.setMyLoanRequestList(data.data);
				return data.data;
			});
	};

	public getGroupUserLoanRequestList = (payload: any): Promise<any> => {
		payload.approve_status_type = 1;
		return axios
			.post(API_URL.LOAN.USER_LOAN_REQUEST_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public getUserLoanDisbursementList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.LOAN.USER_LOAN_DISBURSEMENT_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public approveUserLoanRequest = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.LOAN.APPROVE_USER_LOAN_REQUEST, payload)
			.then(({ data }) => {
				this.setupGroupUserLoanRequestListGrid(this.groupUserLoanRequestListGrid);
				return data.data;
			});
	};

	public getUserLoanRequestDetails = (userLoanId: number): Promise<any> => {
		return axios
			.get(API_URL.LOAN.USER_LOAN_REQUEST_DETAILS(userLoanId))
			.then(({ data }) => {
				this.setUserLoanRequestDetails(data.data);
				return data.data;
			});
	};

	public myUserLoanRequestDetails = (userLoanId: number): Promise<any> => {
		return axios
			.get(API_URL.LOAN.MY_USER_LOAN_REQUEST_DETAILS(userLoanId))
			.then(({ data }) => {
				this.setMyUserLoanRequestDetails(data.data);
				return data.data;
			});
	};

	public payMyLoanInstallment = (userLoanId: number): Promise<any> => {
		return axios
			.get(API_URL.LOAN.PAY_MY_LOAN_INSTALLMENT(userLoanId))
			.then(({ data }) => {
				this.setMyUserLoanRequestDetails(data.data);
				return data.data;
			});
	};

	public exportMyLoanRequestList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.myLoanRequestListGrid);
		payLoad.export_type = exportType;
		return this.getMyLoanRequestList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public exportGroupUserLoanRequestList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.groupUserLoanRequestListGrid);
		payLoad.export_type = exportType;
		return this.getGroupUserLoanRequestList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public exportUserLoanDisbursementList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.userLoanDisbursementListGrid);
		payLoad.export_type = exportType;
		return this.getUserLoanDisbursementList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public changePaymentMode = (id:number,payload: any): Promise<any> => {
		return axios
			.post(API_URL.LOAN.CHANGE_PAYMENT_MODE(id), payload)
			.then(({ data }) => {
				this.setupGroupUserLoanRequestListGrid(this.groupUserLoanRequestListGrid)
				return data.data;
			});
	};

	public payRequestedLoan = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.LOAN.PAY_REQUESTED_LOAN, payload)
			.then(({ data }) => {
				this.setupGroupUserLoanRequestListGrid(this.groupUserLoanRequestListGrid)
				return data.data;
			});
	};
	public payCashInstallment = (id:any,payload: any): Promise<any> => {
		return axios
			.post(API_URL.LOAN.PAY_CASH_INSTALLMENT(id), payload)
			.then(({ data }) => {
				return data.data;
			});
	};
}
