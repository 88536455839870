import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const TransferFundRequest = (t: any): RequestProps => ({
	description: [
		{ required: true, message: Message(t).required(t("DESCRIPTION")) },
		{ min: 3, message: Message(t).min(3, t('DESCRIPTION')) },
		{ max: 5000, message: Message(t).max(5000, t('DESCRIPTION')) },
		({ getFieldValue }) => ({
			validator() {
				const description = getFieldValue("description");
				if (description && !description.trim()) {
					return Promise.reject(Message(t).required(t('DESCRIPTION')));
				}
				return Promise.resolve();
			},
		}),
	],
	transfer_from:[
		{ required: true, message: Message(t).required(t("TRANSFER")) },
	],
	fund_type:[
		{ required: true, message: Message(t).required(t("FUND_TYPE")) },
	],
	actual_amount:[
		{ required: true, message: Message(t).required(t("ACTUAL_AMOUNT")) },
		{ pattern: /^\d*(\.\d{0,2})?$/, message: Message(t).pattern.decimal(2) },
		({ getFieldValue }) => ({
			validator() {
				const amount = getFieldValue("actual_amount");
				if (amount<=0) {
					return Promise.reject(Message(t).minIntFloat(1, t('ACTUAL_AMOUNT')));
				}
				return Promise.resolve();
			},
		}),
	],
	commission_fees:[
		{ required: true, message: Message(t).required(t("COMMISSION_FEES")) },
		// { pattern: /^([0-9]+)$/, message: Message(t).onlyDigits() },
		{ pattern: /^\d*(\.\d{0,2})?$/, message: Message(t).pattern.decimal(2) },
		({ getFieldValue }) => ({
			validator() {
				const amount = getFieldValue("commission_fees");
				if (amount<0) {
					return Promise.reject(Message(t).minIntFloat(0, t('COMMISSION_FEES')));
				}
				return Promise.resolve();
			},
		}),
	],
	attachment:[
		{ required: true, message: Message(t).required(t("ATTACHMENTS")) },
	],
	final_amount:[
		{ required: true, message: Message(t).required(t("FINAL_AMOUNT")) },
	]
});
