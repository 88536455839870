import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button, Form } from "antd";
import { useHistory } from "react-router";
import { EyeOutlined } from "@ant-design/icons";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import moment from "moment";
import DateComparator from "../../../utils/DateComparator";
import Config from "../../../config/Config";
import { observer } from "mobx-react";
import DesignationsToBeElectedForElection from "../Election/DesignationsToBeElectedForElection";
import { displayFormats } from "../../../config/Global";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import CancelElectionReason from "./CancelElectionReason";

const AssociatedGroups: React.FC = observer(() => {
	const [reasonForm] = Form.useForm();
	const { t } = useTranslation();
	const history = useHistory();
	const [electionDetailModal, setElectionDetailModal] = useState(false);
	const [electionId, setElectionId] = useState<any>();
	const [cancelElectionModel, setCancelElectionModal] = useState(false);
	const [cancelElectionId, setCancelElectionId] = useState(null);
	const [saving, setSaving] = useState<boolean>(false);

	const {
		ROOT: { AssignErrorToInput },
		AUTH: { user, permissions },
		ELECTION : { setupElectionRefereeElectionListGrid, setElectionRefereeElectionDetail, cancelElection },
	} = useStore();

	const openElectionCancelModal = (meetingId: any) => {
		reasonForm.resetFields();
		setCancelElectionId(meetingId);
		setCancelElectionModal(true);
	}

	const closeCancelElectionModal = () => {
		setCancelElectionId(null);
		setCancelElectionModal(false);
	}

	const handleModalSubmit = (data: any) => {
		setSaving(true);
		cancelElection(cancelElectionId, data.end_election_reason)
		.then(() => {
			reasonForm.resetFields();
			closeCancelElectionModal();
		})
		.catch((e: ErrorProps) => {
			AssignErrorToInput(reasonForm, e?.data?.errors);
		}).finally(()=>{
			setCancelElectionId(null);
			setCancelElectionModal(false);
			setSaving(false);
		});
	};

	const openElectionDetailModal = (id: any) => {
		setElectionId(id);
		setElectionDetailModal(true);
	};

	const hideElectionDetailModal = () => {
		setElectionId(null);
		setElectionDetailModal(false);
	};

	const switchRenderer = (params: any) => {
		return (
			<div>
				{(params.data?.election_status != 2) && <Button
					type="text"
					style={{color:"green"}}
					onClick={() => {goToElectionResult(params.data?.id, params.data?.election_date)}}
				><EyeOutlined /></Button>}

				{(params.data?.election_status == 0) && <Button
					title={t("CANCEL")} type="default" style={{ color: "red" }} onClick={() => openElectionCancelModal(params.data?.id)}><span>{t("CANCEL")}</span></Button>}
			</div>
		);
	};

	const goToElectionResult = (id: any, electionDate: any) => {
		if (moment(electionDate) > moment()) {
			openElectionDetailModal(id);
		} else {
			history.push("/election/" + id + "/voting-result");
		}
	};

	useEffect(() => {
		user && setElectionRefereeElectionDetail();
	}, [user, permissions]);

	return (
		<>
			{user && permissions && <div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("ASSOCIATED_GROUPS")}</h3>
					</div>
					<AgGridWrapper
						type="serverSide"
						onGridReady={setupElectionRefereeElectionListGrid}
						rowSelection={"multiple"}
						className="member-table"
						immutableData={true}
						frameworkComponents={{ switchRenderer }}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
					>
						<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} pinned="left" filter="agNumberColumnFilter"/>
						<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} width={150}/>
						<AgGridColumn field="election_title" headerName={t('TITLE')} width={150}/>
						<AgGridColumn field="election_for_roles" headerName={t('ELECTION_FOR_ROLES')}/>
						<AgGridColumn field="election_date" headerName={t('ELECTION_DATE')}
							valueGetter={(data:any) =>
								data?.data?.election_date &&
								moment(data?.data.election_date).format(Config.dateFormat)
							}
							filter={"agDateColumnFilter"}
							valueFormatter={(data:any) =>
								data?.data.election_date && moment.utc(data?.data.election_date).local().format(displayFormats.DATE_FORMAT)
							}
							filterParams={{
								comparator: DateComparator,
							}} />
						<AgGridColumn field="display_status" headerName={t('STATUS')}/>
						<AgGridColumn
							field="id"
							width={180}
							wrapText={true}
							autoHeight={true}
							headerName={t("ACTION")}
							cellRenderer="switchRenderer"
							pinned={"right"}
							sortable={false}
							filter={false}
						/>
					</AgGridWrapper>

				</div>
				{electionId && <DesignationsToBeElectedForElection
					showDesignationsToBeElectedForElection={electionDetailModal}
					electionId={electionId}
					hideElectionDetailModal={hideElectionDetailModal}
				/>}

				<CancelElectionReason
					closeCancelElectionModal={closeCancelElectionModal}
					handleModalSubmit={handleModalSubmit}
					cancelElectionModel={cancelElectionModel}
					reasonForm={reasonForm}
					saving={saving}
				/>
			</div>}
		</>
	);
});

export default AssociatedGroups;
