import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "antd";
import FormComponent from "./FormComponent";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useStore from "../../../../../store";
import { ErrorProps } from "../../../../../store/RootStore/RootInterface";
interface EditProps {
	EditID: number;
	EditData: (data: any) => Promise<any>;
	closeEditDonationSubjectPopup: () => void;
	visible?: boolean;
	ReadData: (id: number) => Promise<any>;
}
const EditComponent: React.FC<EditProps> = ({
	EditID,
	EditData,
	visible,
	closeEditDonationSubjectPopup,
	ReadData,
}) => {
	const {
		ROOT : { AssignErrorToInput },
	} = useStore();
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const { t } = useTranslation();
	useEffect(() => {
		if (EditID) {
			ReadData(EditID).then(({ data }) => {
				form.setFieldsValue({
					id: data?.id,
					name: data?.name,
				});
			});
		}
	}, [EditID]);

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		data.id = EditID;
		const formData = new FormData();
		data.name && formData.append('name', data.name);
		data.group_id && (data.donation_type == "2") && formData.append('group_id', data.group_id);
		data.donation_subject && formData.append('donation_subject', data.donation_subject);
		data.start_date_time && formData.append('start_date_time', moment(data.start_date_time).utc().format('YYYY-MM-DD HH:mm:ss'));
		data.end_date_time && formData.append('end_date_time', moment(data.end_date_time).utc().format('YYYY-MM-DD HH:mm:ss'));
		EditData(data)
			.then(() => {
				close();
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
			})
			.finally(() => setSaving(false));
	};
	const close = () => {
		form.resetFields();
		closeEditDonationSubjectPopup();
	};
	return (
		<Modal
			centered
			title={t('EDIT_DONATION_SUBJECT')}
			visible={visible}
			onCancel={close}
			className="custom-popup info-popup info-popup__min popup-wrap"
			destroyOnClose={true}
			footer={[
				<Button
					key="1"
					loading={saving}
					form="editDonationSubjecteForm"
					type="primary"
					htmlType="submit"
					className="btn-primary"
				>
					{t("SAVE")}
				</Button>,
				<Button key="2" className="btn-danger" onClick={close}>
					{t("CANCEL")}
				</Button>,
			]}
		>
			<FormComponent
				formId="editDonationSubjecteForm"
				form={form}
				handleSubmit={handleSubmit}
				isEdit={true}
			/>
		</Modal>
	);
};
export default EditComponent;
