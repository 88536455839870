import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class GroupExpenseStore {
	private rootStore: RootStore;
	public myGroupExpenseListGrid?: any;
	public groupExpenseListGrid?: any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setMyGroupExpenseGrid = (value: any) => {
		this.myGroupExpenseListGrid = value;
	};

	private getGroupExpenseGrid = (value: any) => {
		this.groupExpenseListGrid = value;
	};

	// Grid Setup Functions
	public setupMyGroupExpenseListGrid = (params: GridReadyEvent) => {
		this.setMyGroupExpenseGrid(params);
		const datasource = this.getMyGroupExpenseListDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setupGroupExpenseListGrid = (params: GridReadyEvent) => {
		this.getGroupExpenseGrid(params);
		const datasource = this.getGroupExpenseListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};


	private getMyGroupExpenseListDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getMyGroupExpenseList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setMyGroupExpenseGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	private getGroupExpenseListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getGroupExpenseList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.getGroupExpenseGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public createGroupExpense = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.GROUP_EXPENSE.CREATE_GROUP_EXPENSE, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public createMyGroupExpense = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.GROUP_EXPENSE.CREATE_MY_GROUP_EXPENSE, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public getMyGroupExpenseList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.GROUP_EXPENSE.MY_GROUP_EXPENSE_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public getGroupExpenseList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.GROUP_EXPENSE.GROUP_EXPENSE_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public changeStatus = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.GROUP_EXPENSE.CHANGE_STATUS, payload)
			.then(({ data }) => {
				this.setupGroupExpenseListGrid(this.groupExpenseListGrid);
				return data.data;
			});
	};

	public exportMyGroupExpenseList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.myGroupExpenseListGrid);
		payLoad.export_type = exportType;
		return this.getMyGroupExpenseList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public exportGroupExpenseList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.groupExpenseListGrid);
		payLoad.export_type = exportType;
		return this.getGroupExpenseList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};
	public changePaymentMode = (id:number,payload: any): Promise<any> => {
		return axios
			.post(API_URL.GROUP_EXPENSE.CHANGE_PAYMENT_MODE(id), payload)
			.then(({ data }) => {
				this.setupGroupExpenseListGrid(this.groupExpenseListGrid)
				return data.data;
			});
	};
	public payCash = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.GROUP_EXPENSE.PAY_CASH, payload)
			.then(({ data }) => {
				this.setupGroupExpenseListGrid(this.groupExpenseListGrid)
				return data.data;
			});
	};

}
