import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import ListComponent from "./components/ListComponent";
import DeleteComponent from "./components/DeleteComponent";
import useStore from "../../../../store";
import { Button } from "antd";
import { GoPlus } from "react-icons/go";
import { Col, Row } from "antd";
import AllPermissionList from "../../../../config/AllPermissionList";
const DonationCampaignView: React.FC = observer(() => {
	const { t } = useTranslation();
	const {
		AUTH : { user, permissions },
		DONATION_SUBJECT : {
			listData,
			create,
			update,
			details,
			remove,
			fetchList,
			addDonationSubjectPopup,
			openAddDonationSubjectPopup,
			closeAddDonationSubjectPopup,
			editDonationSubjectID,
			editDonationSubjectPopup,
			openEditDonationSubjectPopup,
			closeEditDonationSubjectPopup,
			deleteDonationSubjectID,
			deleteDonationSubjectPopup,
			openDeleteDonationSubjectPopup,
			closeDeleteDonationSubjectPopup,
		}
	} = useStore();
	const openAddDonationSubjectePopup = () => {
		openAddDonationSubjectPopup();
	}

	useEffect(() => {
		//
	}, [user, permissions]);
	
	return (
		<>
			<Row align="middle">
				<Col span={8}>
					<div className="heading-title-wrap">
						<h3>{t("DONATION_SUBJECT")}</h3>
					</div>
				</Col>
				<Col span={16}>
					<div className="heading-btn-wrap">
						<Button
							hidden={permissions && permissions.indexOf(AllPermissionList.ADD_DONATION_SUBJECT) < 0}
							className="btn-secondary" type="primary" onClick={openAddDonationSubjectePopup}>
							{t("ADD_DONATION_SUBJECT")} <GoPlus />
						</Button>
					</div>
				</Col>
			</Row>
			<ContentBox>
				{permissions && permissions.indexOf(AllPermissionList.LIST_DONATION_SUBJECT) >= 0 &&<ListComponent
					rowData={listData}
					getData={fetchList}
					openDeleteDonationSubjectPopup={openDeleteDonationSubjectPopup}
					openEditDonationSubjectPopup={openEditDonationSubjectPopup}
					permissions={permissions}
				/>}
				<AddComponent
					createData={create}
					visible={addDonationSubjectPopup}
					closeAddDonationSubjectePopup={closeAddDonationSubjectPopup}
				/>
				<EditComponent
					EditID={editDonationSubjectID}
					EditData={update}
					ReadData={details}
					visible={editDonationSubjectPopup}
					closeEditDonationSubjectPopup={closeEditDonationSubjectPopup}
				/>
				<DeleteComponent
					DeleteID={deleteDonationSubjectID}
					DeleteData={remove}
					visible={deleteDonationSubjectPopup}
					closeDeleteDonationSubjectPopup={closeDeleteDonationSubjectPopup}
				/>
			</ContentBox>
		</>
	);
});
export default DonationCampaignView;