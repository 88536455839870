import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button } from "antd";
import { GoPlus } from "react-icons/go";
import { GrDocumentExcel } from "react-icons/gr";
import { useHistory } from "react-router";
import AllPermissionList from "../../../config/AllPermissionList";
import { AgGridColumn } from "ag-grid-react";
import { observer } from "mobx-react";
import { EyeOutlined } from "@ant-design/icons";
import UserLoanDetails from "./UserLoanDetails";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";
import { Cash_Module, displayFormats } from "../../../config/Global";
import moment from "moment";
import DateComparator from "../../../utils/DateComparator";

const LoansList: React.FC = observer(() => {
	const history = useHistory();
	const { t } = useTranslation();
	const [showUserLoanDetails, showUserLoanDetailsModel] = useState(false);
	const [userLoanRequestDetails, setUserLoanRequestDetails] = useState<any>();

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);
	const [width, setWidth] = useState(window.innerWidth);

	const {
		AUTH: { permissions },
		LOAN: {
			setupMyLoanRequestListGrid,
			myUserLoanRequestDetails,
			exportMyLoanRequestList,
			myLoanRequestListGrid,
		},
	} = useStore();

	const goToAddLoan = () => {
		history.push("/add-loan");
	};

	const goViewGroupLoans = () => {
		history.push("/view-group-loans");
	};

	const detailUserLoanRequestRenderer = (params: any) => {
		return (
			<div>
				<Button
					type="default"
					icon={<EyeOutlined />}
					style={{ color: "green" }}
					onClick={() => displayUserLoanDetails(params.data?.id)}
				/>
			</div>
		);
	};

	const displayUserLoanDetails = (id: number) => {
		myUserLoanRequestDetails(id).then((data: any) => {
			setUserLoanRequestDetails(data);
			showUserLoanDetailsModel(true);
		});
	};

	const exportMyLoanRequest = (type: string) => {
		setExportSaving(true);
		exportMyLoanRequestList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	};

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("MY_LOAN_REQUEST")}</h3>
						<div className="btn-wrap">
							{myLoanRequestListGrid &&
								myLoanRequestListGrid.api.getDisplayedRowCount() > 0 && (
									<Button
										className="btn-yellow"
										type="primary"
										onClick={() => showExportPopup(true)}
									>
										{t("GENERATE_REPORT")} <GrDocumentExcel />
									</Button>
								)}

							<ExportPopupComponent
								callbackExportFunction={exportMyLoanRequest}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>
							<Button
								hidden={
									permissions &&
									permissions.indexOf(
										AllPermissionList.LIST_USER_LOAN_REQUEST
									) < 0
								}
								className="btn-yellow"
								type="primary"
								onClick={goViewGroupLoans}
							>
								{t("VIEW_GROUP_LOAN")}
							</Button>
							<Button
								className="btn-secondary"
								type="primary"
								onClick={goToAddLoan}
							>
								{t("ADD_LOANS")} <GoPlus />
							</Button>
						</div>
					</div>
					<div className="sub-custom-tabs">
						<AgGridWrapper
							type="serverSide"
							onGridReady={setupMyLoanRequestListGrid}
							rowSelection={"multiple"}
							className="member-table"
							immutableData={true}
							overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
							frameworkComponents={{ detailUserLoanRequestRenderer }}
						>
							<AgGridColumn
								field="id"
								headerName={"# " + t("ID")}
								width={100}
								pinned={width > 769 ? "left" : ""}
								filter="agNumberColumnFilter"
							/>
							<AgGridColumn field="description" headerName={t("DESCRIPTION")} />
							<AgGridColumn field="group_name" headerName={t("GROUP_NAME")} />
							<AgGridColumn
								field="loan_amount"
								headerName={t("AMOUNT")}
								filter="agNumberColumnFilter"
							/>
							<AgGridColumn
								field="number_of_installments"
								headerName={t("NUMBER_OF_INSTALLMENTS")}
								filter="agNumberColumnFilter"
							/>
							<AgGridColumn
								field="sanctioned_date"
								headerName={t("DISBURSED_DATE")}
								filter={"agDateColumnFilter"}
								valueFormatter={(data: any) =>
									data?.data.sanctioned_date &&
									moment
										.utc(data?.data.sanctioned_date)
										.local()
										.format(displayFormats.DATE_FORMAT)
								}
								filterParams={{
									comparator: DateComparator,
								}}
							/>
							<AgGridColumn
								field="display_approved_status"
								headerName={t("STATUS")}
							/>
							{Cash_Module && (
								<AgGridColumn
									field="loan_payment_mode"
									headerName={t("PAYMENT_MODE")}
								/>
							)}
							<AgGridColumn
								field="id"
								headerName={t("ACTION")}
								cellRenderer="detailUserLoanRequestRenderer"
								width={120}
								pinned={width > 769 ? "right" : ""}
								sortable={false}
								filter={false}
							/>
						</AgGridWrapper>
					</div>
				</div>
			</div>

			<UserLoanDetails
				showUserLoanDetails={showUserLoanDetails}
				showUserLoanDetailsModel={showUserLoanDetailsModel}
				userLoanRequestDetails={userLoanRequestDetails}
				setUserLoanRequestDetails={setUserLoanRequestDetails}
				userLoanRequestType={"my-loan-detail"}
			/>
		</>
	);
});

export default LoansList;
