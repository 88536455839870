import { Modal, Row, Col, Button } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { ElectionCancelReasonRequest } from "../../../requests/ElectionRequest";

interface CancelElectionReasonProps {
	closeCancelElectionModal: () => void;
	handleModalSubmit: (data: any) => void;
	cancelElectionModel: boolean;
	reasonForm: any;
	saving: boolean;
}
const CancelElectionReason: React.FC<CancelElectionReasonProps> = observer(
	({
		closeCancelElectionModal,
		handleModalSubmit,
		cancelElectionModel,
		reasonForm,
		saving,
	}) => {
		const { t } = useTranslation();

		return (
			<Modal
				centered
				visible={cancelElectionModel}
				onOk={closeCancelElectionModal}
				onCancel={closeCancelElectionModal}
				className="custom-popup info-popup"
				footer={null}
			>
				<FormBox form={reasonForm} onFinish={handleModalSubmit}>
					<Row gutter={[20, 20]}>
						<Col span={24}>
							<div className="field">
								<small>{t("REASON")}</small>
								<InputBox.TextArea
									name="end_election_reason"
									placeholder={t("REASON")}
									rules={ElectionCancelReasonRequest(t).end_election_reason}
								/>
							</div>
						</Col>
					</Row>
					<div className="btn">
						<Button
							loading={saving}
							className="btn-secondary"
							htmlType="submit"
						>
							{t("SAVE")}
						</Button>
						<Button className="btn-danger" onClick={closeCancelElectionModal}>
							{t("CANCEL")}
						</Button>
					</div>
				</FormBox>
			</Modal>
		);
	}
);
export default CancelElectionReason;
