import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./assets/styles/main.less";
import App from "./App";
import PageSpinner from "./components/PageSpinner/PageSpinner";
ReactDOM.render(
<I18nextProvider i18n={i18n}>
    <Suspense fallback={<PageSpinner />}>
        <App />
    </Suspense>
</I18nextProvider>, document.getElementById("root"));
