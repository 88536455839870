import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import useStore from "../../../../store";
import { useHistory, useParams } from "react-router";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "../../PaymentGateway/StripeCheckoutForm";
import { Constant } from "../../../../utils/Constant";

const PayDonationComponent: React.FC = observer(() => {
	const {
		DONATION: { getDonationDetails },
	} = useStore();
	const pathHistory = useHistory();
	const { id }: any = useParams();

	const [clientSecret, setClientSecret] = useState("");
	const [donationDetails, setDonationDetails] = useState<any>();
	useEffect(() => {
		id && getDonationDetails(id, {get_client_secret_key: 1})
			.then((data: any) => {
				setDonationDetails(data.donation)
				data.clientSecretKey && setClientSecret(data.clientSecretKey)
				data.donation.response?.client_secret && setClientSecret(data.donation.response?.client_secret);
			})
			.catch(() => {
				pathHistory.push("/donation");
			});
	}, []);

	const stripePromise = loadStripe(Constant.STRIPE_KEY);

	const options: StripeElementsOptions = {
		clientSecret,
		appearance: {
			theme: "stripe",
		},
	};
	return (
		<>
			{clientSecret && donationDetails && (
				<div className="thankyou-vote-section">
					<Elements options={options} stripe={stripePromise}>
						<StripeCheckoutForm type="donation" details={donationDetails} />
					</Elements>
				</div>
			)}
		</>
	);
});
export default PayDonationComponent;
