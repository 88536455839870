import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class DonationCampaignStore {
	private rootStore: RootStore;
	public donationCampaignListGrid?: any;
	public deleteDonationCampaignPopup?: boolean;
	public deleteDonationCampaignID?: number;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setDonationCampaignListGrid = (value: any) => {
		this.donationCampaignListGrid = value;
	};

	public openDeleteDonationCampaignPopup = (id: number): void => {
		this.deleteDonationCampaignPopup = true;
		this.setDeleteDonationCampaignID(id);
	};

	public closeDeleteDonationCampaignPopup = (): void => {
		this.deleteDonationCampaignPopup = false;
		this.setDeleteDonationCampaignID();
	};

	private setDeleteDonationCampaignID = (id?: number): void => {
		this.deleteDonationCampaignID = id;
	};

	// Grid Setup Functions
	public setupDonationCampaignListGrid = (params: GridReadyEvent) => {
		this.setDonationCampaignListGrid(params);
		const datasource = this.getDonationCampaignListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	// API Functions
	public getDonationCampaignList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION_CAMPAIGN.LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION_CAMPAIGN.CREATE, payload)
			.then(({ data }) => {
				return data.data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (id:number,payload: FormData): Promise<any> => {
		return axios.post(API_URL.DONATION_CAMPAIGN.UPDATE(id), payload).then(({ data }) => {
			return data.data;
		});
	};	
	
	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.DONATION_CAMPAIGN.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.DONATION_CAMPAIGN.DELETE(id)).then(({ data }) => {
			this.setupDonationCampaignListGrid(this.donationCampaignListGrid);
			return data;
		});
	};

	private getDonationCampaignListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getDonationCampaignList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setDonationCampaignListGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	public endDonationCampaign = (id: number, end_donation_campaign_reason: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION_CAMPAIGN.END(id), {end_donation_campaign_reason: end_donation_campaign_reason})
			.then(({ data }) => {
				this.setupDonationCampaignListGrid(this.donationCampaignListGrid);
				return data.data;
			});
	};

	public exportDonationCampaignList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.donationCampaignListGrid);
		payLoad.export_type = exportType;
		return this.getDonationCampaignList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

}
