import { Modal, Upload } from "antd"
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { displayFormats, onPreview } from "../../../config/Global";
import moment from "moment";

interface TransferFundDetailsProps {
	showTransferFundDetailsModel: boolean;
	setShowTransferFundDetailsModel: (show: boolean) => void;
	displayTransferFundDetail: any;
	setDisplayTransferFundDetail: any;
	fileList: any;
}
const TransferFundDetails: React.FC<TransferFundDetailsProps> = observer(({
	showTransferFundDetailsModel,
	setShowTransferFundDetailsModel,
	displayTransferFundDetail,
	setDisplayTransferFundDetail,
	fileList
}) => {

	const { t } = useTranslation();

	const closeModel = () => {
		setDisplayTransferFundDetail(null);
		setShowTransferFundDetailsModel(false);
	}
	return (
		<Modal
			centered
			visible={showTransferFundDetailsModel}
			onOk={() => closeModel()}
			onCancel={() => closeModel()}
			className="custom-popup info-popup details-popup"
			footer={null}
		>
			{displayTransferFundDetail && (
				<div className="details-info">
					<div className="info-header">
						<div className="info-thumb">
							<div className="title">
								<h3>{t("TRANSFER_FUND_DETAILS")}</h3>
								<span>
									{displayTransferFundDetail &&
										displayTransferFundDetail?.user_name}
								</span>
							</div>
						</div>
					</div>
					<div className="info-body">
						<div className="item">
							<ul>
								<li>
									<strong>{t("NAME")}: </strong>
									{displayTransferFundDetail &&
										displayTransferFundDetail?.user_name}
								</li>
								<li>
									<strong>{t("DESCRIPTION")}: </strong>
									{displayTransferFundDetail.description}
								</li>
								<li>
									<strong>{t("ACTUAL_AMOUNT")}: </strong>
									{displayTransferFundDetail?.actual_amount}
								</li>
								<li>
									<strong>{t("COMMISSION_FEES")}: </strong>
									{displayTransferFundDetail?.commission_fees}
								</li>
								<li>
									<strong>{t("FINAL_AMOUNT")}: </strong>
									{displayTransferFundDetail.final_amount}
								</li>
								<li>
									<strong>{t("FUND_TYPE")}: </strong>
									{displayTransferFundDetail?.fund_type_name}
								</li>
								<li>
									<strong>{t("TRANSFER_FUND_TYPE")}: </strong>
									{displayTransferFundDetail?.transfer_fund_type_name}
								</li>
							</ul>
						</div>

						<div className="item">
							<ul>
								<li>
									<strong>{t("DATE")}: </strong>
									{moment.utc(displayTransferFundDetail?.date).local().format(displayFormats.DATE_FORMAT)}
								</li>
								<li>
									<strong>{t("ATTACHMENT")}: </strong>
									<Upload
										disabled={true}
										name="attachment"
										accept=".png,.jpeg,.jpg,.pdf,.doc,.docx"
										className="hideeyeicon"
										listType="picture-card"
										multiple={true}
										maxCount={5}
										showUploadList={true}
										onPreview={onPreview}
										fileList={fileList && fileList}
									>
									</Upload>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}
		</Modal>
	);
})
export default TransferFundDetails;
