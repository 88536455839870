import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { CreateWelfareRequest } from "./WelfareRequestInterface";

export default class WelfareRequestStore {
	private rootStore: RootStore;
	public myWelfareRequestListGrid?: any;
	public welfareRequestListGrid?: any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setMyWelfareRequestGrid = (value: any) => {
		this.myWelfareRequestListGrid = value;
	};

	private getWelfareRequestGrid = (value: any) => {
		this.welfareRequestListGrid = value;
	};

	// Grid Setup Functions
	public setupMyWelfareRequestListGrid = (params: GridReadyEvent) => {
		this.setMyWelfareRequestGrid(params);
		const datasource = this.getMyWelfareRequestListDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setupWelfareRequestListGrid = (params: GridReadyEvent) => {
		this.getWelfareRequestGrid(params);
		const datasource = this.getWelfareRequestListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};


	private getMyWelfareRequestListDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getMyWelfareRequestList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setMyWelfareRequestGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	private getWelfareRequestListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getWelfareRequestList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.getWelfareRequestGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public createWelfareRequest = (payload: CreateWelfareRequest): Promise<any> => {
		return axios
			.post(API_URL.WELFARE_REQUEST.CREATE_WELFARE_REQUEST, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public createMyWelfareRequest = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.WELFARE_REQUEST.CREATE_MY_WELFARE_REQUEST, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public getMyWelfareRequestList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.WELFARE_REQUEST.MY_WELFARE_REQUEST_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public getWelfareRequestList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.WELFARE_REQUEST.WELFARE_REQUEST_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public changeStatus = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.WELFARE_REQUEST.CHANGE_STATUS, payload)
			.then(({ data }) => {
				this.setupWelfareRequestListGrid(this.welfareRequestListGrid);
				return data.data;
			});
	};

	public exportMyWelfareRequestList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.myWelfareRequestListGrid);
		payLoad.export_type = exportType;
		return this.getMyWelfareRequestList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public exportWelfareRequestList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.welfareRequestListGrid);
		payLoad.export_type = exportType;
		return this.getWelfareRequestList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public changePaymentMode = (id:number,payload: any): Promise<any> => {
		return axios
			.post(API_URL.WELFARE_REQUEST.CHANGE_PAYMENT_MODE(id), payload)
			.then(({ data }) => {
				this.setupWelfareRequestListGrid(this.welfareRequestListGrid)
				return data.data;
			});
	};

	public payCash = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.WELFARE_REQUEST.PAY_CASH, payload)
			.then(({ data }) => {
				this.setupWelfareRequestListGrid(this.welfareRequestListGrid)
				return data.data;
			});
	};

}
