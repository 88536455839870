import React, { useEffect } from "react";
import { InputBox } from "../../../components/AntdAddons";
import useStore from "../../../store";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";

const GroupSelect: React.FC = observer(() => {
	const {
		AUTH: { user, selectedGroup, setSelectedGroup },
	} = useStore();
	const history = useHistory();

	const changeGroup = (value: any) => {
		setSelectedGroup(value);
		history.go(0);
	}

	useEffect(() => {
		if (user && user.groups && user.groups.length > 0 && selectedGroup) {
			user.groups.filter((item: any) => item.id == selectedGroup).length == 0 && changeGroup(user.groups[0].id);
		}
	}, [selectedGroup])
	

	return (
		<>
			{user && user.groups && selectedGroup && (
				<div className="header-group-select">
					<div className="field">
						<InputBox.Select
							name="groupDropdown"
							defaultValue={selectedGroup}
							options={{
								list: user.groups,
								valueKey: "id",
								textKey: "name",
							}}
							placeholder={"Group A"}
							onChange={changeGroup}
						/>
					</div>
				</div>
			)}
		</>
	);
});

export default GroupSelect;
