import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Button } from "antd";
import Lock from "../../../assets/images/Lock.svg";
import amount from "../../../assets/images/amount-icon.svg";
import user_light from "../../../assets/images/user-icon-light.svg";
import useStore from "../../../store";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { LoanRequest } from "../../../requests/LoanRequest";
import { observer } from "mobx-react";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import PaymentIcon from "../../../assets/images/money.svg";
import { Cash_Module } from "../../../config/Global";

const AddLoans: React.FC = observer(() => {
	const [form] = Form.useForm();
	const {
		ROOT: { AssignErrorToInput },
		AUTH: { user, selectedGroupDetails },
		LOAN: { createMyLoan },
		COMMON: { paymentListData },
	} = useStore();
	const history = useHistory();
	const { t } = useTranslation();
	const [saving, setSaving] = useState<boolean>(false);

	const handleSubmit = (data: any) => {
		setSaving(true);
		createMyLoan(data).then(() => {
			history.replace("/my-loans");
		}).catch((e: ErrorProps) => {
			const errors = e?.errors || e?.data?.errors;
			errors && AssignErrorToInput(form, errors);
			setSaving(false);
		});
	};

	useEffect(() => {
		selectedGroupDetails && form.setFieldsValue({ max_borrowing_limit: selectedGroupDetails.borrowing_limit });
	}, [selectedGroupDetails]);

	const listRoute = () => {
		history.push('/my-loans');
	}

	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t("LOAN")}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<InputBox.Text name="max_borrowing_limit" hidden={true} />
						<Row>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={user_light} alt="Profile" />
									</i>
									{user && (
										<InputBox.Text
											disabled={true}
											placeholder={user.firstname + " " + user.lastname}
											label={t('USER_NAME')}
										/>
									)}
								</div>
								<div className="field">
									<i>
										<img src={Lock} alt="Profile" />
									</i>
									<InputBox.TextArea
										name="description"
										rules={LoanRequest(t).description}
										placeholder={t("DESCRIPTION")}
										label={t('DESCRIPTION')}
									/>
								</div>
							</Col>
							<Col span={12}>
								{selectedGroupDetails && <div className="field">
									<i>
										<img src={amount} alt="Amount" />
									</i>
									<InputBox.Text
										name="loan_amount"
										placeholder={t("AMOUNT")}
										label={t("AMOUNT") + " (" + t("MAXIMUM_AMOUNT_OF_LOAN_AS_AMOUNT_CURRENCY", { amount: selectedGroupDetails.borrowing_limit, currency: selectedGroupDetails.currency.name }) + ")"}
										rules={LoanRequest(t).loan_amount}
									/>
								</div>}
								<div className="field">
									<i>
										<img src={user_light} alt="Profile" />
									</i>
									<InputBox.Text
										name="number_of_installments"
										placeholder={t("DURATION")}
										label={t("DURATION")}
										rules={LoanRequest(t).number_of_installments}
									/>
								</div>
							</Col>
						</Row>
						{Cash_Module && <Row>
							<Col span={12} style={{ width: "100%" }}>
								<div className="field">
									<i className="iconAlignment">
										<img src={PaymentIcon} alt="Profile" />
									</i>
									<InputBox.Select label={t("WHAT_IS_YOUR_PREFERENCE")}
										name={"preference"}
										placeholder={t("PREFERENCE")}
										options={{
											list: paymentListData,
											valueKey: "id",
											textKey: "name",
										}}
										rules={LoanRequest(t).preference}
									/>
								</div>
							</Col>
						</Row>}

						<Row>
							<Col span={24}>
								<div className="btn btn-wrap">
									<Button
										type="primary"
										htmlType="submit"
										className="btn btn-primary"
										loading={saving}
									>
										{t("SAVE")}
									</Button>
									<Button
										type="primary"
										className="btn btn-danger"
										onClick={listRoute}
									>
										{t("CANCEL")}
									</Button>
								</div>
							</Col>
						</Row>
					</FormBox>
				</div>
			</div>
		</>
	);
});

export default AddLoans;
