import React, { useEffect } from "react";
import { observer } from "mobx-react";
import GeneratedOtpList from "./GeneratedOtpList";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import useStore from "../../../store";

const GeneratedOtp: React.FC = observer(() => {
	const { t } = useTranslation();
	const {
		GENERATE_OTP: {getGenerateOtpList},
	} = useStore();

	useEffect(()=>{
		getGenerateOtpList()
	},[])

	const refreshGrid=()=>{
		getGenerateOtpList()
	}
	return (
		<>
			<div className="member-details-section text">
				<div className="main grid-class">

					<div className="heading-title-wrap">
						<h3>{t("GENERATED_OTP")}</h3>
						<div className="heading-btn-wrap">
							<Button className="btn-yellow" type="primary" onClick={refreshGrid} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
								<SyncOutlined className="refreshIcon"/>Refresh</Button>
						</div>

					</div>

					<div className="custom-tabs walletsCustomTabs">

						<GeneratedOtpList/>
					</div>
				</div>
			</div>
		</>
	);
});

export default GeneratedOtp;
