import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class AllTransactionStore {
	public grid?: any;
	private rootStore: RootStore;
	public AddCashList?: any[];

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setAddCashListGrid = (value: any) => {
		this.AddCashList = value;
	};

	// Grid Setup Functions


	public setupAddCashListGrid = (params: GridReadyEvent) => {
		this.setAddCashListGrid(params);
		const datasource = this.getAddCashListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private getAddCashListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setAddCashListGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	}

	// API Functions
	public fetchList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.ADD_CASH.LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};
	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.ADD_CASH.CREATE, payload)
			.then(({ data }) => {
				this.fetchList(data.data.rows);
				return data.data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public exportCashTransactionList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.AddCashList);
		payLoad.export_type = exportType;
		return this.fetchList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};
}
