import React, { useState } from "react";
import { Button, Form, Col, Row, Space } from "antd";
import { ForgotPasswordRequest } from "../../../requests/AuthRequest";
import { useHistory, Link } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import calling from "../../../assets/images/calling-header.svg";
import Login_Img from "../../../assets/images/login-img.svg";
import { useTranslation } from "react-i18next";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import Email_Icon from "../../../assets/images/Email-Icon.svg";
import { loginWithList } from "../../../config/Global";

const ForgotPassword: React.FC = () => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [loginWith, setLoginWith] = useState(1)
	const [phoneNo, setPhoneNo] = useState<any>()
	const history = useHistory();
	const { AUTH, ROOT } = useStore();
	const { forgotPassword } = AUTH;
	const { AssignErrorToInput } = ROOT;
	const { t } = useTranslation();

	const handleSubmit = (data: any) => {
		setSaving(true);
		delete data.login_with
		delete data.phone_number
		if (loginWith == 2) {
			data.username = phoneNo.nationalNumber
			data.country_code = phoneNo?.country
			data.countryCallingCode = "+" + phoneNo?.countryCallingCode
		}

		forgotPassword(data).then(() => {
			localStorage.setItem('forgotUserData', data.username);
			history.push("/verify-otp");
		}).catch((e: ErrorProps) => {
			const errors = e?.errors || e?.data?.errors;
			errors && AssignErrorToInput(form, errors);
			setSaving(false);
		});
	};

	const setPhone = (val: any) => {
		const phoneDetails: any = val ? parsePhoneNumber(val) : "";
		setPhoneNo(phoneDetails);
	};

	return (
		<section className="login__section blue-bg">
			<Row>
				<Col span={12}>
					<div className="section">
						<div className="content-wrap">
							<h1>{t('WELCOME_BACK')}!</h1>
							<div className="screen-img">
								<img src={Login_Img} alt="Profile" />
							</div>
							<p>
								Lorem Ipsum has been the industrys standard dummy text ever
								since the 1500s, when an unknown printer took a galley of type
								and scrambled it to make a type specimen book.
							</p>
						</div>
					</div>
				</Col>
				<Col span={12} className="section white-bg">
					<div className="form-title">
						<h3>VSLA / {t('RESET_PASSWORD')}</h3>
					</div>
					<FormBox form={form} onFinish={handleSubmit}>
						<div className="field">
							<InputBox.Radio
								options={{
									list: loginWithList(t),
									valueKey: "id",
									textKey: "value"
								}}
								name="login_with"
								label={t('RESET_WITH')}
								initialValue={1}
								onChange={(e: any) => {
									if (e.target.value == 1) {
										setLoginWith(1)
									} else {
										setLoginWith(2)
									}
								}}
							/>
						</div>
						<div className="field">
							{
								loginWith == 1 ? <><i>
									<img src={loginWith == 1 ? Email_Icon : calling} alt="Profile" />
								</i>
									<InputBox.Text
										name="username"
										placeholder={t('EMAIL_ID')}
										size="large"
										rules={ForgotPasswordRequest(t).username}
										label={t('EMAIL_ID')}
									/></> : <>
									<Form.Item
										name="phone_number"
										rules={ForgotPasswordRequest(t).phone_number}
										label={t("PHONE_NUMBER")}
										className="phoneInputContent"
									>
										<PhoneInput
											countrySelectProps={{ unicodeFlags: true }}
											placeholder={t('PHONE_NUMBER')}
											name="phone_number"
											value={phoneNo}
											international
											defaultCountry="IN"
											countryCallingCodeEditable={false}
											onChange={setPhone}
										/>
									</Form.Item></>
							}

						</div>
						<div className="info-text" style={{ margin: '30px 0' }}>
							<p style={{ fontSize: '20px', padding: '0 40px' }}>
								{t('PLEASE_ENTER_YOUR_PHONE_NUMBER_EMAIL_ADDRESS_WE_WILL_SEND_THE_OTP_SENT_TO_CHANGE_YOUR_PASSWORD')}
							</p>
						</div>
						<div className="field">
							<Button
								loading={saving}
								type="primary"
								size="large"
								htmlType="submit"
							>
								{t('SEND')}
							</Button>
						</div>
						<div className="sign-in-text text-center">
							<Space>
								<Link to="/login">{t('BACK_TO_LOGIN')}</Link>
							</Space>
						</div>
					</FormBox>
				</Col>
			</Row>
		</section>
	);
};

export default ForgotPassword;
