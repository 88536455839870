import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import { Button, Modal } from "antd";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import { GridReadyEvent } from "ag-grid-community";
import { AiOutlineEye } from "react-icons/ai"
import { displayFormats } from "../../../../../config/Global";
import moment from "moment";
import { useHistory } from "react-router-dom";
interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	donationDetails: any;
	getDonationDetails: (params: GridReadyEvent) => void;
}
const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	donationDetails,
	getDonationDetails
}) => {
	const { t } = useTranslation();
	const [donationDetailsModal, setdonationDetailsModal] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);

	const pathHistory = useHistory();
	const opendonationDetailsModal = (params: any) => {
		setdonationDetailsModal(true);
		getDonationDetails(params.data?.id);
	}
	const ActionRenderer = (params: any) => {
		return (
			<>
				<Button className="edit-button" title={t("DETAIL")} type="text" onClick={() => opendonationDetailsModal(params)}><AiOutlineEye /></Button>

				{(params.data?.paid_status == 0) &&
					<Button
						type="default"
						style={{ color: "green" }}
						onClick={() => pathHistory.push('/donation/add/' + btoa(params.data?.id))}
					>
						{t("PAY")}
					</Button>}
			</>
		)
	}

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	return (
		<>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupGrid}
				rowSelection={"multiple"}
				className="member-table"
				immutableData={true}
				frameworkComponents={{ ActionRenderer }}
				overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
			>
				<AgGridColumn field="id" headerName={"# " + t('ID')} width={90} pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter" />
				<AgGridColumn field="name" headerName={t('NAME')} width={200} />
				<AgGridColumn field="donation_campaign_name" headerName={t('CAMPAIGN_NAME')} width={200} />
				<AgGridColumn
					field="amount"
					headerName={t('AMOUNT')}
					filter={'agNumberColumnFilter'}
					width={150}
					valueFormatter={(data:any) =>
						data?.data.amount + " " + data?.data.payment_currency
					}
				/>
				<AgGridColumn field="phone_number" headerName={t('PHONE_NUMBER')} width={200} />
				<AgGridColumn field="subjectname" headerName={t('DONATION_SUBJECT')} width={200} />
				<AgGridColumn field="group_name" headerName={t('DONATING_TO')} />
				<AgGridColumn field="organisation" headerName={t('ORGANIZATION')} width={200} />
				<AgGridColumn
					width={180}
					wrapText={true}
					autoHeight={true}
					field="action"
					headerName={t('ACTION')}
					cellRenderer="ActionRenderer"
					pinned={width > 769 ? "right" : ""}
					sortable={false}
					filter={false}
				/>
			</AgGridWrapper>

			<Modal
				centered
				visible={donationDetailsModal}
				onOk={() => setdonationDetailsModal(false)}
				onCancel={() => setdonationDetailsModal(false)}
				className="custom-popup info-popup details-popup"
				footer={null}
			>
				{donationDetails && (
					<div className="details-info">
						<div className="info-header">
							<div className="info-thumb">
								<div className="title">
									<h3>{t("DONATION_DETAILS")}</h3>
								</div>
							</div>
						</div>
						<div className="info-body">
							<div className="item" style={{ width: "100%", border: "0" }}>
								<ul>
									<li>
										<strong>{t("CAMPAIGN_NAME")}: </strong>
										{donationDetails.donation?.donation_campaign.name ?? "N/A"}
									</li>
									<li>
										<strong>{t("NAME")}: </strong>
										{donationDetails.donation?.name}
									</li>
									<li>
										<strong>{t("PHONE_NUMBER")}: </strong>
										{donationDetails.donation?.country_calling_code+" "+donationDetails.donation?.phone_number}
									</li>
									<li>
										<strong>{t("EMAIL")}: </strong>
										{donationDetails.donation?.email}
									</li>
									<li>
										<strong>{t("AMOUNT")}: </strong>
										{donationDetails.donation?.amount + " " + donationDetails.donation?.payment_currency}
									</li>
									<li>
										<strong>{t("DONATION_SUBJECT")}: </strong>
										{donationDetails.donation?.subject?.name}
									</li>
									<li>
										<strong>{t("DONATING_TO")}: </strong>
										{donationDetails.donation.group?.name ? donationDetails.donation.group?.name : t("VSLA")}
									</li>
									<li>
										<strong>{t("ORGANIZATION")}: </strong>
										{donationDetails.donation?.organisation}
									</li>
									<li>
										<strong>{t("ADDRESS")}: </strong>
										{donationDetails.donation?.address}
									</li>
									<li>
										<strong>{t("COUNTRY_NAME")}: </strong>
										{donationDetails.donation?.country?.name}
									</li>
									<li>
										<strong>{t("ZIP_CODE")}: </strong>
										{donationDetails.donation?.zipcode}
									</li>
									<li>
										<strong>{t("CREATED_AT")}: </strong>
										{moment.utc(donationDetails.donation?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}
									</li>
								</ul>
							</div>


						</div>
					</div>
				)}
			</Modal>
		</>
	);
};

export default ListComponent;