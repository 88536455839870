import { Button } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const VoteTermsConditions: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const goViewVote = () => {
		history.push('/vote')
	}
	return (
		<>
		<section className="common-page-section">
			<div className="main">
			<div className="heading-title"> 
					<h4>
						{t('TERMS_AND_CONDITIONS')} <span></span>
					</h4>
				</div>
				<p>Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
				<p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
				<p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
				<h3>Neque porro quisquam est qui </h3>
				<p>Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
				<p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
				<p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

				<div className="custom-check">
				<Checkbox value="I agree to the terms and conditions">{t('I_AGREE_TO_THE_TERMS_AND_CONDITIONS')}</Checkbox>
				</div>
				<div className="btn">
					<Button type="primary" htmlType="submit" className="btn btn-secondary" onClick={goViewVote}> {t("START_VOTING")} </Button>
				</div>
			</div>
		</section>
		</>		
	);
};

export default VoteTermsConditions;
