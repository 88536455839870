import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { createMeeting, updateMeeting } from "./MeetingInterface";

export default class MeetingStore {
	private rootStore: RootStore;
	public meetingType = 1;
	public groupMeetingsListGrid: any = {};
	public groupMeetingDetails?: any;
	public zoomMeetingDetails?: any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setMeetingType = (meetingType:number):void => {
		this.meetingType = meetingType as number;
	}

	private setGroupMeetingsListGrid = (value: any, index: any) => {
		this.groupMeetingsListGrid[index] = value;
	};

	private setGroupMeetingDetails = (value: any) => {
		this.groupMeetingDetails = value;
	};

	private setZoomMeetingDetails = (value: any) => {
		this.zoomMeetingDetails = value;
	};

	// Grid Setup Functions

	///Group meeting list
	public setupGroupMeetingsListGrid = (params: GridReadyEvent) => {
		this.setGroupMeetingsListGrid(params, this.meetingType);
		const datasource = this.getGroupMeetingsListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private getGroupMeetingsListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getGroupMeetingsList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setGroupMeetingsListGrid(params, this.meetingType);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public createMeeting = (payload: createMeeting): Promise<any> => {
		return axios
			.post(API_URL.MEETING.CREATE, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public updateMeeting = (meetingId: any, payload: updateMeeting): Promise<any> => {
		return axios
			.post(API_URL.MEETING.UPDATE(meetingId), payload)
			.then(({ data }) => {
				return data;
			});
	};

	public getGroupMeetingsList = (payload: any): Promise<any> => {
		payload.meeting_type = this.meetingType;
		return axios
			.post(API_URL.MEETING.MEETING_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public getGroupMeetingDetails = (meetingId: number): Promise<any> => {
		return axios
			.get(API_URL.MEETING.MEETING_DETAILS(meetingId))
			.then(({ data }) => {
				this.setGroupMeetingDetails(data.data);
				return data.data;
			});
	};

	public deleteMeeting = (meetingId: number): Promise<any> => {
		return axios
			.delete(API_URL.MEETING.DELETE(meetingId))
			.then(({ data }) => {
				this.setupGroupMeetingsListGrid(this.groupMeetingsListGrid[1]);
				return data.data;
			});
	};

	public cancelMeeting = (meetingId: number): Promise<any> => {
		return axios
			.get(API_URL.MEETING.CANCEL(meetingId))
			.then(({ data }) => {
				this.setupGroupMeetingsListGrid(this.groupMeetingsListGrid[1]);
				return data.data;
			});
	};

	public endMeeting = (meetingId: number, payload: any): Promise<any> => {
		return axios
			.post(API_URL.MEETING.END(meetingId), payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public getZoomMeetingDetails = (): Promise<any> => {
		return axios
			.get(API_URL.MEETING.ZOOM_DETAILS)
			.then(({ data }) => {
				this.setZoomMeetingDetails(data.data);
				return data.data;
			});
	};

	public saveZoomMeetingDetails = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.MEETING.ZOOM_DETAILS, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public saveUserMeetingAttendance = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.MEETING.TAKE_ATTENDANCE, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public saveUserMeetingRemark = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.MEETING.REMARK, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public joinMyMeeting = (meetingId: any): Promise<any> => {
		return axios
			.post(API_URL.MEETING.JOIN_MY_MEETING, {meeting_id: meetingId})
			.then(({ data }) => {
				this.setupGroupMeetingsListGrid(this.groupMeetingsListGrid[1]);
				return data;
			});
	};

	// Other
	public changeMeetingType = (meetingType : number): void => {
		this.setMeetingType(meetingType);
		this.groupMeetingsListGrid[meetingType] && this.setupGroupMeetingsListGrid(this.groupMeetingsListGrid[meetingType]);
	}

	public exportGroupMeetingsList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.groupMeetingsListGrid[this.meetingType]);
		payLoad.export_type = exportType;
		return this.getGroupMeetingsList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};
}
