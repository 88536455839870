import React, { useEffect } from "react";
import { Col, Row } from "antd";
import business_people from "../../../assets/images/business-people.png";
import useStore from "../../../store";
import { observer } from "mobx-react";

const AboutUs: React.FC = observer(() => {
	const {
		CMS: {getCMSDetails,myCms},
		COMMON: {selectedLanguage},
	} = useStore();
	useEffect(() => {
		const obj = {
			slug : "about-us",
			language_id: selectedLanguage ? selectedLanguage.id : null
		}
		selectedLanguage && getCMSDetails(obj);
	}, [selectedLanguage, getCMSDetails]);
	
	return (
		<>
		<section className="about-us-section">
			<div className="main">
			<div className="heading-title">
					<h4>{myCms && myCms.page_title}
						{/* <span></span> */}
					</h4>
				</div>
				<div className="about-item">
				<Row>
					<Col span={13}>
						<div className="content-wr ap">
						<h3>{myCms && myCms.meta_title}</h3>
						<div dangerouslySetInnerHTML={{ __html: myCms && myCms.page_description }} />	
						</div>
					</Col>
					<Col span={11}>
						<div className="about-thumb">
							<img src={business_people} alt="About VSLA" />
						</div>
					</Col>
				</Row>
				</div>
			</div>
		</section>
		</>		
	);
});

export default AboutUs;
