import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { acceptAddUserToGroupInvitationType, acceptJoinGroupRequestType, addUserToGroupType, assignSecretaryAccountantToGroupType, createSavingGroupType, groupPurchaseShareType } from "./SavingGroupInterface";

export default class SavingGroupStore {
	public myJoinGroupRequestList?: any;
	public groupDetails?: any;
	public joinGroupRequestList?: any;
	public userListToAddMemberToGroupList?: any;
	public joinGroupGrid?: any;
	public myGroupList?: any;
	public searchJoinGroupListValue?: string;
	public searchJoinGroupList?: string;
	private rootStore: RootStore;
	public calculateAmount?:any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setJoinGroupRequestGrid = (value: any) => {
		this.joinGroupGrid = value;
	};
	private setCalculateAmount = (value: any) => {
		this.calculateAmount = value;
	};
	// Grid Setup Functions
	public setupJoinGroupRequestGrid = (params: GridReadyEvent) => {
		this.setJoinGroupRequestGrid(params);
		const datasource = this.joinGroupRequestCreateDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private joinGroupRequestCreateDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getJoinGroupRequestList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setJoinGroupRequestGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	private setGroupDetails = (value?: any): void => {
		this.groupDetails = value;
	};
	private setMyJoinGroupRequestList = (value?: any): void => {
		this.myJoinGroupRequestList = value;
	};

	private setJoinGroupRequestList = (value?: any): void => {
		this.joinGroupRequestList = value;
	};

	private setSearchJoinGroupListValue = (value: any): void => {
		this.searchJoinGroupListValue = value;
	};

	private setSearchJoinGroupList = (value: any): void => {
		this.searchJoinGroupList = value;
	};

	private setUserListToAddMemberToGroup = (value: any): void => {
		this.userListToAddMemberToGroupList = value;
	};

	private setMyGroupList = (value: any): void => {
		this.myGroupList = value;
	};

	// API Functions
	public createSavingGroup = (payload: createSavingGroupType): Promise<any> => {
		return axios
			.post(API_URL.GROUP.CREATE_SAVING_GROUP, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public getMyJoinGroupRequestList = (): Promise<any> => {
		return axios
			.post(API_URL.GROUP.MY_JOIN_GROUP_REQUEST_LIST)
			.then(({ data }) => {
				return this.setMyJoinGroupRequestList(data.data.rows);
			});
	};

	public getGroupDetails = (groupId: string): Promise<any> => {
		return axios.get(API_URL.COMMON.GROUP_DETAILS(groupId)).then(({ data }) => {
			this.setGroupDetails(data.data);
			return data.data;
		});
	};
	public updateGroupDetails = (groupId: any, payload: any): Promise<any> => {
		return axios
			.post(API_URL.COMMON.GROUP_DETAILS_UPDATE(groupId), payload).then(({ data }) => {
				return data.data;
			});
	};

	public getJoinGroupRequestList = (payload: any): Promise<any> => {
		payload.status = [0, 2];
		return axios
			.post(API_URL.GROUP.JOIN_GROUP_REQUEST_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public approveJoinGroupRequest = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.GROUP.APPROVE_JOIN_GROUP_REQUEST, payload)
			.then(({ data }) => {
				this.setupJoinGroupRequestGrid(this.joinGroupGrid);
				return data.data;
			});
	};

	public joinGroup = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.GROUP.JOIN_GROUP, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public groupPurchaseShare = (payload: groupPurchaseShareType): Promise<any> => {
		return axios
			.post(API_URL.GROUP.PURCHASE_SHARE, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public acceptJoinGroupRequest = (groupId: number, payload: acceptJoinGroupRequestType): Promise<any> => {
		return axios
			.post(API_URL.GROUP.ACCEPT_JOIN_GROUP_REQUEST(groupId), payload)
			.then(({ data }) => {
				return data;
			});
	};

	public addUserToGroup = (payload: addUserToGroupType): Promise<any> => {
		return axios
			.post(API_URL.GROUP.ADD_USER_TO_GROUP, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public assignSecretaryAccountantToGroup = (payload: assignSecretaryAccountantToGroupType): Promise<any> => {
		return axios
			.post(API_URL.GROUP.ASSIGN_SECRETARY_ACCOUNTANT_TO_GROUP, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public acceptAddUserToGroupInvitation = (payload: acceptAddUserToGroupInvitationType): Promise<any> => {
		return axios.post(API_URL.GROUP.ACCEPT_ADD_USER_TO_GROUP_INVITATION, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public myLeaveGroup = (): Promise<any> => {
		return axios.post(API_URL.GROUP.LEAVE_GROUP).then(({ data }) => {
			return data.data;
		});
	};

	public getSearchJoinGroupList = (data:any): Promise<any> => {
		return axios.get(API_URL.GROUP.SEARCH_JOIN_GROUP_LIST, {params: data}).then(({ data }) => {
			this.setSearchJoinGroupList(data.data);
			return data.data;
		});
	};

	public userListToAddMemberToGroup = (payload: any): Promise<any> => {
		return axios.post(API_URL.GROUP.USER_LIST_TO_ADD_MEMBER_TO_GROUP, payload).then(({ data }) => {
			this.setUserListToAddMemberToGroup(data.data);
			return data.data;
		});
	};

	public groupTerminate = (): Promise<any> => {
		return axios.delete(API_URL.GROUP.TERMINATE).then(({ data }) => {
			return data.data;
		});
	};

	public getMyGroupList = (): Promise<any> => {
		return axios.post(API_URL.GROUP.MY_GROUP_LIST, {})
			.then(({ data }) => {
				this.setMyGroupList(data.data.myGroupList);
				return data.data.myGroupList;
			});
	};

	public getCalculateAmount = (payload:any): Promise<any> => {
		return axios.post(API_URL.GROUP.CALCULATE_AMOUNT, payload)
			.then(({ data }) => {
				this.setCalculateAmount(data.data)
				return data.data;
			});
	};
	public getPurchaseShareOfUsers = (payload:any): Promise<any> => {
		return axios.post(API_URL.GROUP.PURCHASE_SHARE_OF_USERS, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

}
