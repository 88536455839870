import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const AddMoneyToWalletRequest = (t: any): RequestProps => ({
	payment_from: [
		{ required: true, message: Message(t).selectRequired(t("PAYMENT_FROM")) },
	],
	amount: [
		{ required: true, message: Message(t).required(t("AMOUNT")) },
		({ getFieldValue }) => ({
			validator() {
				const amount = getFieldValue("amount");
				if (amount < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('AMOUNT')));
				}
				return Promise.resolve();
			},
		}),
		{
			pattern: /^\d*(\.\d{0,2})?$/,
			message: Message(t).pattern.decimal(2),
		},
	],
});

export const WithdrawMoneyFromWalletRequest = (t: any): RequestProps => ({
	payment_from: [
		{ required: true, message: Message(t).selectRequired(t("PAYMENT_FROM")) },
	],
	amount: [
		{ required: true, message: Message(t).required(t("AMOUNT")) },
		({ getFieldValue }) => ({
			validator() {
				const amount = getFieldValue("amount");
				if (amount < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('AMOUNT')));
				}
				return Promise.resolve();
			},
		}),
		{
			pattern: /^\d*(\.\d{0,2})?$/,
			message: Message(t).pattern.decimal(2),
		},
	],
});

export const CreateMyAlIzzaAccountRequest = (t: any): RequestProps => ({
	payment_from: [
		{ required: true, message: Message(t).selectRequired(t("PAYMENT_FROM")) },
	],
});
