import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";
import { transferFund } from "./TransferFundInterface";
import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import Config from "../../config/Config";

export default class TransferFundStore {
	private rootStore: RootStore;
    public generatedOtpListGrid?: any;
	public rowData?:any;
    public transferFundListGrid?:any

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

    //Setter Function
	private setRowData = (value: any) => {
		this.rowData = value;
	};
    private setTransferFundListGrid = (value: any) => {
		this.transferFundListGrid = value;
	};

    public setupTransferFundListGrid = (params: GridReadyEvent) => {
		this.setTransferFundListGrid(params);
		const datasource = this.getTransferFundListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};
    private getTransferFundListDataSource = (gridOptions?: GridOptions) => {
        console.log("call")
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getTransferFundList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setTransferFundListGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};
	
    public getTransferFundList = (payload:any): Promise<any> => {
		return axios
			.post(API_URL.TRANSFER_FUND.LIST,payload)
			.then(({ data }) => {
				return data.data;
			});
	};

   

	public createTransferFund = (payload: transferFund): Promise<any> => {
		return axios
			.post(API_URL.TRANSFER_FUND.CREATE, payload)
			.then(({ data }) => {
                this.setTransferFundListGrid(this.transferFundListGrid);
				return data;
			});
	};

}
