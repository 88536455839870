import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { AgGridColumn } from "ag-grid-react";
import { GridReadyEvent } from "ag-grid-community";
import { Cash_Module, displayFormats } from "../../../config/Global";
import moment from "moment";
import DateComparator from "../../../utils/DateComparator";
import Config from "../../../config/Config";
import { Button, Col, Form, Modal, Row } from "antd";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import profile_icon from "../../../assets/images/profile-icon.svg";
import amount from "../../../assets/images/amount-icon.svg"
import Calendar from "../../../assets/images/Calendar.svg"
import OTP from "../../../assets/images/OTP-Icon.svg"
import { generateOTP } from "../../../requests/GroupRequest";
import useStore from "../../../store";

interface GroupWalletViewListProps {
	setupGrid: (params: GridReadyEvent) => void;
	key?: any;
	type?: any;
}

const GroupWalletViewList: React.FC<GroupWalletViewListProps> = ({
	setupGrid, key, type
}) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [width, setWidth] = useState(window.innerWidth);
	const [generateOtpModal, setGenerateOtpModal] = useState(false);
	const [displayOtp, setDisplayOtp] = useState(false)
	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	const {
		GENERATE_OTP: { createGeneratedOtp },
		ROOT: { AssignErrorToInput },
		WALLET: { payCash }
	} = useStore();
	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const payCashRenderer = (params: any) => {
		return (
			(params.data.payment_status_id == "2" && params.data.transaction_type == "8") &&
			<div className="action-column">
				<Button
					type="default"
					style={{ color: "green" }}
					onClick={() => displayPayCashDetails(params.data)}
				>{t("PAY_CASH")}</Button>
			</div>
		);
	};
	const displayPayCashDetails = (data: any) => {
		setGenerateOtpModal(true);
		form.setFieldsValue({
			user_name: data.user_name,
			description: data.transaction_details,
			amount: data.amount,
			user_id: data.id
		})

	};
	const handleGenerateOtp = () => {
		const payload = { id: form.getFieldValue("user_id"), type: 7 }
		createGeneratedOtp(payload).then(() => {
			setDisplayOtp(true)
		}).catch((e: any) => {
			setDisplayOtp(false)
			const errors = e?.errors || e?.data?.errors;
			AssignErrorToInput(form, errors);
		})
	}
	const handlePayCash = (data: any) => {
		const payload = { transaction_id: form.getFieldValue("user_id"), otp: data?.otp }
		payCash(payload).then(() => {
			setGenerateOtpModal(false)
			form.resetFields()
			setDisplayOtp(false)
		}).catch((e: any) => {
			const errors = e?.errors || e?.data?.errors;
			AssignErrorToInput(form, errors);
		})
	}
	return (
		<>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupGrid}
				rowSelection={"multiple"}
				className="member-table"
				immutableData={true}
				overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
				frameworkComponents={{ payCashRenderer }}
			>
				<AgGridColumn
					field="id"
					headerName={"# " + t("ID")}
					width={100}
					pinned={width > 769 ? "left" : ""}
					filter="agNumberColumnFilter"
				/>
				<AgGridColumn field="user_name" headerName={t("USER_NAME")} />
				<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} />
				<AgGridColumn field="amount" headerName={t("AMOUNT")}
					filter="agNumberColumnFilter" />
				<AgGridColumn
					field="transaction_details"
					headerName={t("DESCRIPTION")}
				/>
				<AgGridColumn
					field="created_at"
					headerName={t("DATE")}
					valueGetter={(data: any) =>
						data?.data?.created_at &&
						moment(data?.data.created_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data: any) =>
						data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
				/>
				<AgGridColumn field="payment_type" headerName={t("TRANSACTION_TYPE")} />
				{Cash_Module && <AgGridColumn field="transaction_payment_mode" headerName={t("PAYMENT_MODE")} />}
				<AgGridColumn field="display_payment_status" headerName={t("STATUS")} />
				{Cash_Module && key !== type && <AgGridColumn
					headerName={t('PAY_CASH')}
					cellRenderer="payCashRenderer"
					width={130}
					pinned={width > 769 ? "right" : ""}
					sortable={false}
					filter={false}

				/>}
			</AgGridWrapper>
			<Modal
				centered
				visible={generateOtpModal}
				onOk={() => setGenerateOtpModal(false)}
				onCancel={() => {
					setGenerateOtpModal(false);
					form.resetFields();
					setDisplayOtp(false)
				}}
				className="custom-popup info-popup generate-otp"
				title={`${t("GENERATE_OTP")}`}
				footer={null}
				width={10}
			>

				<div className="register-section section-wrap">
					<div className="main">
						<FormBox form={form} onFinish={handlePayCash}>
							<Row>
								<InputBox.Text label={t("user_id")} name={"user_name"} disabled hidden />
								<Col span={12}>
									<div className="field">
										<i className="iconAlignment">
											<img src={profile_icon} alt="Profile" />
										</i>
										<InputBox.Text label={t("USER_NAME")} name={"user_name"} disabled />
									</div>
									<div className="field">
										<i className="iconAlignment">
											<img src={amount} alt="Amount" />
										</i>
										<InputBox.Text label={t("AMOUNT")} name={"amount"} disabled />
									</div>
								</Col>
								<Col span={12}>
									<div className="field">
										<i className="iconAlignment">
											<img src={Calendar} alt="Profile" />
										</i>
										<InputBox.TextArea label={t("DESCRIPTION")} name={"description"} disabled />
									</div>
								</Col>
							</Row>
							<Row>
							</Row>
							<Row style={{ justifyContent: "center" }}>
								{displayOtp ? <Col span={12} style={{ width: "100%" }}>
									<div className="field">
										<i className="iconAlignment">
											<img src={OTP} alt="Amount" />
										</i>
										<InputBox.Password
											name="otp"
											placeholder={t("ENTER_OTP")}
											label={t("ENTER_OTP")}
											rules={generateOTP(t).otp}
										/>
									</div></Col> :
									<Col span={12} style={{ width: "100%" }}>
										<Button
											onClick={handleGenerateOtp}
											className="btn btn-primary otpBtn"
										>{t("GENERATE_OTP")}</Button>
									</Col>}
							</Row>
							<Row>
								<Col span={24}>
									<div className="btn btn-wrap">
										<Button
											type="primary"
											htmlType="submit"
											className="btn btn-primary"
										>
											{t("SAVE")}
										</Button>
										<Button
											type="primary"
											className="btn btn-danger"
											onClick={() => {
												setGenerateOtpModal(false)
												form.resetFields();
												setDisplayOtp(false)
											}}
										>
											{t("CANCEL")}
										</Button>
									</div>
								</Col>
							</Row>
						</FormBox>
					</div>
				</div>
			</Modal>
		</>

	);
};

export default GroupWalletViewList;
