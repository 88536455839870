import React, { useEffect, useState } from "react";
import { Button, Form, Space, Col, Row, message } from "antd";
import { LoginRequest } from "../../../requests/AuthRequest";
import { Link, useHistory } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import Lock_icon from "../../../assets/images/lock-icon.svg";
import calling from "../../../assets/images/calling-header.svg";
import google from "../../../assets/images/google.svg";
import facebook from "../../../assets/images/facebook.svg";
import apple from "../../../assets/images/apple.svg";
import Login_Img from "../../../assets/images/login-img.svg";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import SocialLoginButton from "../../../components/SocialLoginButton/SocialLoginButton";
import GoogleLogin from 'react-google-login';
import { Constant } from "../../../utils/Constant";
import AppleLogin from '@jungsoft/react-apple-login';
import { loginWithList } from "../../../config/Global";
import Email_Icon from "../../../assets/images/Email-Icon.svg";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { aesEncryptionPassword } from "../../../config/Global";
import 'react-phone-number-input/style.css';

const Login: React.FC = observer(() => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [loginWith, setLoginWith] = useState(1)
	const [phoneNo, setPhoneNo] = useState<any>()
	const history = useHistory();
	const {
		AUTH: { login, user, setSocialLoginData, verifySocialLogin, setTempToken, setupHeaders },
		ROOT: { AssignErrorToInput },
	} = useStore();
	const { t } = useTranslation();
	const error: any = new URLSearchParams(window.location.search).get("error");
	const token: any = new URLSearchParams(window.location.search).get("token");

	useEffect(() => {
		user && history.replace("/")

		if (error) {
			message.error(error);
			setTimeout(() => {
				history.replace("/login");
			}, 1000);
		}
		if (token) {
			setupHeaders(token);
			history.replace("/");
		}
	}, [user, history]);

	const handleSubmit = async (data: any) => {
		setSaving(true);
		delete data.login_with
		delete data.phone_number
		if (loginWith == 2) {
			data.username = phoneNo.nationalNumber
			data.country_code = phoneNo?.country
			data.countryCallingCode = "+" + phoneNo?.countryCallingCode
		}
		data.password = await aesEncryptionPassword(data?.password);
		login(data)
			.then((data: any) => {
				if (data.user.status == 1) {
					window.location.href = "/";
				} else {
					setTempToken(data.token);
					history.replace("/verify-account");
				}
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data.errors;
				setSaving(false);
				AssignErrorToInput(form, errors);
			});
	};

	const handleSocialLogin = (user: any) => {
		verifySocialLogin({ email: user._profile.email, social_id: user._profile.id, social_login_type: user._provider }).then(() => {
			window.location.href = "/";
		}).catch((e: ErrorProps) => {
			if (e.status == 203) {
				setSocialLoginData({ userData: user._profile, provider: user._provider });
				history.push("/register");
			}
		});
	};

	const handleSocialLoginFailure = (err: any) => {
		console.error(err);
	};

	const responseGoogle = (response: any) => {
		verifySocialLogin({ email: response.profileObj.email, social_id: response.googleId, social_login_type: 'google' }).then(() => {
			window.location.href = "/";
		}).catch((e: ErrorProps) => {
			if (e.status == 203) {
				setSocialLoginData({
					userData: {
						firstName: response.profileObj.givenName,
						lastName: response.profileObj.familyName,
						email: response.profileObj.email,
						id: response.googleId,
					}, provider: 'google'
				});
				history.push("/register");
			}
		});
	}

	const setPhone = (val: any) => {
		const phoneDetails: any = val ? parsePhoneNumber(val) : "";
		setPhoneNo(phoneDetails);
	};
	return (
		<section className="login__section blue-bg">
			<Row>
				<Col span={12}>
					<div className="section">
						<div className="content-wrap">
							<h1>{t('WELCOME_BACK')}!</h1>
							<div className="screen-img">
								<img src={Login_Img} alt="Profile" />
							</div>
							<p>
								Hi - we are eArziki an app build CARE&apos;s savings groups (&apos;Village savings and loans Associations&apos; or VSLA).
							</p>
						</div>

					</div>
				</Col>
				<Col span={12} className="section white-bg">
					<h3>{t('LOG_IN')}</h3>
					<div className="sign-in-text">
						<Space>
							{t('NEED_A_E_ARZIKI_ACCOUNT')}
							<Link to="/register">{t('CREATE_AN_ACCOUNT')}</Link>
						</Space>
					</div>
					<FormBox form={form} onFinish={handleSubmit}>
						<div className="field">
							<InputBox.Radio
								options={{
									list: loginWithList(t),
									valueKey: "id",
									textKey: "value"
								}}
								name="login_with"
								label={t('SIGN_IN_WITH')}
								initialValue={1}
								onChange={(e: any) => {
									if (e.target.value == 1) {
										setLoginWith(1)
									} else {
										setLoginWith(2)
									}
								}}
							/>
						</div>
						<div className="field">
							{
								loginWith == 1 ? <><i>
									<img src={loginWith == 1 ? Email_Icon : calling} alt="Profile" />
								</i>
									<InputBox.Text
										name="username"
										placeholder={t('EMAIL_ID')}
										size="large"
										rules={LoginRequest(t).username}
										label={t('EMAIL_ID')}
									/></> : <>
									<Form.Item
										name="phone_number"
										rules={LoginRequest(t).phone_number}
										label={t("PHONE_NUMBER")}
										className="phoneInputContent"
									>
										<PhoneInput
											countrySelectProps={{ unicodeFlags: true }}
											placeholder={t('PHONE_NUMBER')}
											name="phone_number"
											value={phoneNo}
											international
											defaultCountry="IN"
											countryCallingCodeEditable={false}
											onChange={setPhone}
										/>
									</Form.Item></>
							}

						</div>
						<div className="field">
							<i>
								<img src={Lock_icon} alt="Profile" />
							</i>
							<InputBox.Password
								name="password"
								placeholder={t("PASSWORD")}
								rules={LoginRequest(t).password}
								label={t("PASSWORD")}
							/>

						</div>
						<div className="field">
							<Button
								loading={saving}
								type="primary"
								size="large"
								htmlType="submit"
							>
								{t('LOG_IN')}
							</Button>
						</div>
						<div className="login-form-forgot">
							<Link to="/forgot-password">{t('FORGOT_PASSWORD')}</Link>
						</div>
						<div className="field sharing-list">
							<ul>
								<li>
									<GoogleLogin
										clientId={Constant.GOOGLE_LOGIN_CLIENT_ID}
										render={(renderProps: any) => (
											<a href="#" onClick={renderProps.onClick} >
												<img src={google} alt="" title="Google" />
											</a>
										)}
										buttonText="Login"
										onSuccess={responseGoogle}
										cookiePolicy={'single_host_origin'}
									/>
								</li>
								<li>
									<SocialLoginButton
										provider="facebook"
										appId={Constant.FACEBOOK_LOGIN_APP_ID}
										onLoginSuccess={handleSocialLogin}
										onLoginFailure={handleSocialLoginFailure}
									>
										<a href="#">
											<img src={facebook} alt="" title="Facebook" />
										</a>
									</SocialLoginButton>
								</li>
								<li>
									{/* <Link to={{pathname: ""}}>
										<img src={apple} alt="" title="apple" />
									</Link> */}

									<AppleLogin
										clientId={`${process.env.REACT_APP_APPLE_LOGIN_CLIENT_ID}`}
										// redirectURI={`${'http://vsla-api.silicontechnolabs.com:8003/'}apple-login`}
										redirectURI={`${process.env.React_APP_API_URL}apple-login`}
										scope={'name email'}
										responseType={'code id_token'}
										responseMode={'form_post'}
										render={(renderProps: any) => (
											<a href="#" onClick={renderProps.onClick}>
												<img src={apple} alt="" title="apple" />
											</a>
										)}
									/>
								</li>
							</ul>
						</div>
					</FormBox>
				</Col>
			</Row>
		</section>
	);
});

export default Login;
