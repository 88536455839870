import { observer } from "mobx-react";
import React from "react";
import profile_icon from "../../../../../assets/images/profile-icon.svg";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { Col, Row } from "antd";
import { DonationSubjectRequest } from "../../../../../requests/DonationSubjectRequest";
interface FormDataProps {
	formId: string;
	form: any;
	handleSubmit: (data: any) => void | any;
	isEdit: boolean;
}
const FormComponent: React.FC<FormDataProps> = observer(({
	formId,
	form,
	handleSubmit,
}) => {
	const { t } = useTranslation();
	return (
		<>
			<FormBox id={formId}
				form={form}
				encType="multipart/formdata"
				onFinish={handleSubmit} key="formbasic">
				<Row>
					<Col span={24}>
						<div className="field field_w_label add-donation">
							<i>
								<img src={profile_icon} alt={t('NAME')} />
							</i>
							<InputBox.Text
								name="name"
								label={t('DONATION_SUBJECT')}
								placeholder={t('NAME')}
								rules={DonationSubjectRequest(t).name}
							/>
						</div>
					</Col>
				</Row>
				{/* <Row>
					<Col span={24}>
						<div className="btn btn-wrap text-left">
							<Button type="primary" htmlType="submit" loading={saving} key="submit1" className="btn-primary">{t("SAVE")}</Button>
							<Button type="primary" htmlType="submit" key="resetCancel" className="btn-danger" onClick={close} >{t("CANCEL")} </Button>
						</div>
					</Col>
				</Row> */}

			</FormBox>
		</>
	);
});
export default FormComponent;