export interface MessageProps {
	username: () => string;
	phone_number: () => string;
	onlyDigits: () => string;
	onlySixDigits:()=>string;
	required: (field?: string) => string;
	selectRequired: (field?: string) => string;
	max: (maxLimit: number, field?: string) => string;
	min: (minLimit: number, field?: string) => string;
	minSelect: (minLimit: number, field?: string) => string;
	maxSelect: (minLimit: number, field?: string) => string;
	minIntFloat: (minLimit: number, field?: string) => string;
	maxIntFloat: (minLimit: number, field?: string) => string;
	type: {
		email: (field?: string) => string;
		pattern: (field?: string) => string;
	};
	pattern: {
		password: (field?: string) => string;
		decimal: (number: number) => string;
	};
	global: {
		networkIssue: string;
	};
	afterDateTime: (field: string, dateTime: string) => string;
	rangeBetween: (field: string, from: any, to: any) => string;
	maxImageSize: (maxLimit: number) => string;
}

export const Message = (t: any): MessageProps => ({
	username: () => `${t('PLEASE_ENTER_EMAIL_OR_PHONE_NUMBER_TO_LOGIN')}`,
	phone_number:()=>`${t('PLEASE_ENTER_EMAIL_OR_PHONE_NUMBER_TO_LOGIN')}`,
	onlyDigits: () => `${t('PLEASE_ENTER_DIGITS_ONLY')}`,
	onlySixDigits:()=>`${t("EXACTLY_SIX_DIGITS_ARE_REQUIRED")}`,
	required: (field = t('THE_FIELD')) => `${t('FIELD_NAME_IS_REQUIRED', {fieldName: field})}`,
	selectRequired: (field = t('THE_FIELD')) => `${t('PLEASE_SELECT_FIELD_NAME', {fieldName: field})}`,
	max: (maxLimit, field = t('THE_FIELD')) =>
		`${t('FIELD_NAME_CANNOT_BE_LONGER_THAN_MAX_CHARACTERS', {fieldName: field, max: maxLimit})}`,
	min: (minLimit, field = t('THE_FIELD')) =>
		`${t('FIELD_NAME_SHOULD_CONTAIN_AT_LEAST_MIN_CHARACTERS', {fieldName: field, min: minLimit})}`,
	minSelect: (minLimit, field = t('THE_FIELD')) =>
		`${t('PLEASE_SELECT_MINIMUM_MIN_FIELD_NAME', {fieldName: field, min: minLimit})}`,
	maxSelect: (maxLimit, field = t('THE_FIELD')) =>
		`${t('PLEASE_SELECT_MAXIMUM_MAX_FIELD_NAME', {fieldName: field, max: maxLimit})}`,
	minIntFloat: (minLimit, field = t('THE_FIELD')) =>
		`${t('FIELD_NAME_SHOULD_BE_MINIMUM_MIN', {fieldName: field, min: minLimit})}`,
	maxIntFloat: (maxLimit, field = t('THE_FIELD')) =>
		`${t('FIELD_NAME_SHOULD_BE_MAXIMUM_MAX', {fieldName: field, max: maxLimit})}`,
	type: {
		email: (field = t('THE_FIELD')) => `${t('PLEASE_ENTER_VALID_FIELD_NAME', {fieldName: field})}`,
		pattern: (field = t('THE_FIELD')) => `${t('PLEASE_ENTER_VALID_FIELD_NAME', {fieldName: field})}`,
	},
	pattern: {
		password: (field = t('THE_FIELD')) =>
			`${t('FIELD_NAME_SHOULD_CONTAIN_AT_LEAST_AN_UPPERCASE_LETTER_A_LOWERCASE_LETTER_A_NUMBER_AND_A_SPECIAL_CHARACTER', {fieldName: field})}`,
		decimal: (number) => `${t("PLEASE_ENTER_DIGITS_OR_DECIMAL_DIGITS_UPTO_NUMBER_DECIMAL_ONLY", {number: number}) + "."}`,
	},
	global: {
		networkIssue: `${t('NETWORK_ISSUE')}!`,
	},
	afterDateTime: (field = t('THE_FIELD'), dateTime = '') =>
		`${t('PLEASE_SELECT_FIELD_NAME_AFTER_DATE_TIME', {fieldName: field, dateTime: dateTime})}`,
	rangeBetween: (field = t('THE_FIELD'), from = '', to = '') =>
		`${t('FIELD_NAME_SHOULD_BE_BETWEEN_FROM_TO', {fieldName: field, from: from, to: to})}`,
	maxImageSize: (maxLimit) =>
		`${t('THE_FILE_IS_TOO_LARGE_TO_UPLOAD_FILE_SIZE_SHOULD_BE_LESS_THAN_OR_EQUAL_TO_MAX_KILOBYTES', {max: maxLimit})}`,
});

export default Message;
