import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import { Button, Col, Form, message, Modal, Row } from "antd";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import moment from "moment";
import DateComparator from "../../../../../utils/DateComparator";
import { GridReadyEvent } from "ag-grid-community";
import Config from "../../../../../config/Config";
import { displayFormats } from "../../../../../config/Global";
import AllPermissionList from "../../../../../config/AllPermissionList";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { DonationCampaignEndReasonRequest } from "../../../../../requests/DonationCampaignRequest";
import { ErrorProps } from "../../../../../store/RootStore/RootInterface";
import useStore from "../../../../../store";

interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	editRedirect: (id: number) => void;
	openDeletePopup: (id: number) => void | any;
	permissions: any;
}
const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	editRedirect,
	openDeletePopup,
	permissions,
}) => {
	const [reasonForm] = Form.useForm();
	const { t } = useTranslation();
	const [endDonationCampaignModel, setEndDonationCampaignModal] = useState(false);
	const [endDonationCampaignId, setEndDonationCampaignId] = useState(null);
	const [saving, setSaving] = useState<boolean>(false);
	const [width, setWidth] = useState(window.innerWidth);

	const {
		ROOT: { AssignErrorToInput },
		DONATION_CAMPAIGN : { endDonationCampaign },
	} = useStore();

	const openEndDonationCampaignModal = (donationCampaignIdId: any) => {
		setEndDonationCampaignId(donationCampaignIdId);
		setEndDonationCampaignModal(true);
	}

	const closeEndDonationCampaignModal = () => {
		setEndDonationCampaignId(null);
		setEndDonationCampaignModal(false);
	}

	const handleModalSubmit = (data: any) => {
		setSaving(true);
		endDonationCampaign(endDonationCampaignId, data.end_donation_campaign_reason)
		.then(() => {
			reasonForm.resetFields();
			closeEndDonationCampaignModal();
		})
		.catch((e: ErrorProps) => {
			AssignErrorToInput(reasonForm, e?.data.errors);
			setSaving(false);
		}).finally(()=>{
			setSaving(false);
			setEndDonationCampaignId(null);
			setEndDonationCampaignModal(false);
		});
	};

	function copyTextToClipboard(textToCopy: any) {
		if (navigator.clipboard && window.isSecureContext) {
			// navigator clipboard api method'
			return navigator.clipboard.writeText(textToCopy);
		} else {
			// text area method
			const textArea = document.createElement("textarea");
			textArea.value = textToCopy;
			// make the textarea out of viewport
			textArea.style.position = "fixed";
			textArea.style.left = "-999999px";
			textArea.style.top = "-999999px";
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			return new Promise<void>((res, rej) => {
				// here the magic happens
				document.execCommand('copy') ? res() : rej();
				textArea.remove();
			});
		}
	}

	const ActionRenderer = (params: any) => {
		return (
			<>
				{params.data?.canEdit == 1 && <Button
				hidden={permissions && (permissions.indexOf(AllPermissionList.EDIT_DONATION_CAMPAIGN) < 0 || permissions.indexOf(AllPermissionList.DONATION_CAMPAIGN_DETAILS) < 0)}
				className="edit-button" title={t('EDIT_DONATION_CAMPAIGN')} type="text" onClick={() => editRedirect(params.data?.id)}><AiOutlineEdit /></Button>}
				
				{params.data?.canDelete == 1 && <Button
				hidden={permissions && permissions.indexOf(AllPermissionList.DELETE_DONATION_CAMPAIGN) < 0}
				className="edit-button" title={t('DELETE_DONATION_CAMPAIGN')} type="text" onClick={() => openDeletePopup(params.data?.id)}><AiOutlineDelete /></Button>}

				{params.data?.canEnd == 1 && <Button
				hidden={permissions && permissions.indexOf(AllPermissionList.EDIT_DONATION_CAMPAIGN) < 0}
					title={t("END")} type="default" style={{ color: "red" }} onClick={() => openEndDonationCampaignModal(params.data?.id)}><span>{t("END")}</span></Button>}

				{params.data?.link != '' && <Button
					title={t("COPY")} type="default" style={{ color: "green" }} onClick={() => copyTextToClipboard(params.data?.link)
						.then(() => message.success(t("LINK_COPIED")))}><span>{t("COPY")}</span></Button>}
			</>
		)
	}

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);
	return (
		<>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupGrid}
				rowSelection={"multiple"}
				className="member-table"
				immutableData={true}
				frameworkComponents={{ ActionRenderer }}
				overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
			>
				<AgGridColumn field="id" headerName={"# " + t('ID')} width={90} pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter" />
				<AgGridColumn field="name" headerName={t('NAME')} width={200} />
				<AgGridColumn field="subjectname" headerName={t('DONATION_SUBJECT')} width={200} />
				<AgGridColumn field="group_name" headerName={t('DONATING_TO')} />
				<AgGridColumn
					field="start_date_time"
					headerName={t('START_DATE_TIME')}
					valueGetter={(data: any) =>
						data?.data?.start_date_time &&
						moment(data?.data.start_date_time).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data.start_date_time && moment.utc(data?.data.start_date_time).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
				/>
				<AgGridColumn
					field="end_date_time"
					headerName={t('END_DATE_TIME')}
					valueGetter={(data: any) =>
						data?.data?.end_date_time &&
						moment(data?.data.end_date_time).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data.end_date_time && moment.utc(data?.data.end_date_time).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
				/>
				<AgGridColumn
					width={160}
					wrapText={true}
					autoHeight={true}
					field="action"
					headerName={t('ACTION')}
					cellRenderer="ActionRenderer"
					pinned={width > 769 ? "right" : ""}
				/>
			</AgGridWrapper>

			<Modal
				centered
				visible={endDonationCampaignModel}
				onOk={closeEndDonationCampaignModal}
				onCancel={closeEndDonationCampaignModal}
				className="custom-popup info-popup"
				footer={null}
			>
				<FormBox form={reasonForm} onFinish={handleModalSubmit}>
					<Row gutter={[20, 20]}>
						<Col span={24}>
							<div className="field">
								<small>{t("REASON")}</small>
								<InputBox.TextArea name="end_donation_campaign_reason" placeholder={t("REASON")}
								rules={DonationCampaignEndReasonRequest(t).end_donation_campaign_reason} />
							</div>
						</Col>
					</Row>
					<div className="btn">
						<Button loading={saving} className="btn-secondary" htmlType="submit">
							{t("SAVE")}
						</Button>
						<Button className="btn-danger" onClick={closeEndDonationCampaignModal}>{t("CANCEL")}</Button>
					</div>
				</FormBox>
			</Modal>
		</>
	);
};

export default ListComponent;