import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class DonationCampaignStore {
	private rootStore: RootStore;
	public donationListGrid?: any;
	public donationDetails: any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setDonationListGrid = (value: any) => {
		this.donationListGrid = value;
	};

	private setDonationDetails = (value: any): void => {
		this.donationDetails = value;
	};

	// Grid Setup Functions
	public setupDonationListGrid = (params: GridReadyEvent) => {
		this.setDonationListGrid(params);
		const datasource = this.getDonationListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	// API Functions
	public getDonationList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION.LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION.CREATE, payload)
			.then(({ data }) => {
				return data.data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (id: any, payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION.UPDATE(id), payload)
			.then(({ data }) => {
				return data.data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public getDonationDetails = (id: any, payload: any): Promise<any> => {
		return axios.get(API_URL.DONATION.DETAILS(id), {params: payload}).then(({ data }) => {
			this.setDonationDetails(data.data);
			return data.data;
		});
	};
	
	private getDonationListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getDonationList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setDonationListGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};
	
	private donationPaymentResponse = (id: any, payload: any): Promise<any> => {
		return axios.post(API_URL.DONATION.UPDATE_PAYMENT_RESPONSE(id), payload).then(({ data }) => {
			return data.data;
		});
	};

	public exportDonationList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.donationListGrid);
		payLoad.export_type = exportType;
		return this.getDonationList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

}
