import React, { useEffect } from "react";
import { Modal, Button } from "antd"
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useStore from "../../../store";
import RoleList from "../../../config/RoleList";
import moment from "moment";
import { displayFormats } from "../../../config/Global";

interface DesignationsToBeElectedForElectionProps {
	showDesignationsToBeElectedForElection: boolean;
	hideElectionDetailModal: () => void;
	electionId: any;

}
const DesignationsToBeElectedForElection: React.FC<DesignationsToBeElectedForElectionProps> = observer(({
	showDesignationsToBeElectedForElection,
	hideElectionDetailModal,
	electionId,
}) => {
	const history = useHistory();
	const { t } = useTranslation();
	const {
		ELECTION: {
			getElectionRefereeElectionDetail,
			setElectionRefereeElectionDetail,
			electionRefereeElectionDetail,
		},
	} = useStore();

	const addElectionCandidates = (id: any) => {
		hideModal();
		history.push("/election/"+id+"/add-candidate");
	};

	const hideModal = () => {
		setElectionRefereeElectionDetail();
		hideElectionDetailModal();
	};

	useEffect(() => {
		!electionRefereeElectionDetail && getElectionRefereeElectionDetail(electionId);
	}, [electionId, electionRefereeElectionDetail]);

	return (<Modal
		centered
		visible={showDesignationsToBeElectedForElection}
		onOk={() => hideModal()}
		onCancel={() => hideModal()}
		className="info-popup election-popup"
		footer={null}
	>
		{electionRefereeElectionDetail && <>
			<div className="info-text">
				<div className="date-title">
					<h2>
						{t("DATE_OF_ELECTION")}:{" "}
						<span>
							{electionRefereeElectionDetail && moment.utc(electionRefereeElectionDetail.election_date).local().format(displayFormats.DATE_FORMAT)}
						</span>
					</h2>
				</div>
				<h2>{t("DESIGNATIONS_TO_BE_ELECTED")}:</h2>
			</div>
			<div className="election-list">
				<ul>
					{electionRefereeElectionDetail &&
						electionRefereeElectionDetail.electionCandidates &&
						electionRefereeElectionDetail.electionCandidates.map(
							(itemRole: any, index: any) => {
								return (
									<li key={"DESIGNATIONS" + index}>
										{(itemRole.role_name == RoleList.PRESIDENT) ? t("PRESIDENT") : (itemRole.role_name == RoleList.SECRETARY) ? t("SECRETARY") : (itemRole.role_name == RoleList.ACCOUNTANT)  ? t("ACCOUNTANT") : ""}
									</li>
								);
							}
						)}
				</ul>
			</div>

			<div className="btn small-btn">
				<Button
					hidden={!(moment(electionRefereeElectionDetail.election_date) > moment())}
					onClick={()=>addElectionCandidates(electionRefereeElectionDetail.id)}
					className="btn-secondary">
					{t("FILL_CANDIDATE_DETAILS")}
				</Button>
				<Button
					className="btn-danger"
					onClick={() => hideModal()}
				>{t("CANCEL")}</Button>
			</div>
			</>
		}
	</Modal>)
})
export default DesignationsToBeElectedForElection;
