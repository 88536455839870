import React from "react";
import { Button, Form } from "antd";
import { Col, Row } from "antd";
import moment from "moment";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { CreateSavingGroupRequest } from "../../../requests/GroupRequest";
import { displayFormats } from "../../../config/Global";

const MemberSetting: React.FC = observer(() => {
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const disableStartDate = (current: any) => {
		return current && current <= moment().add(6, "months");
	};

	const handleSubmit = (data: any) => {
		const formData = new FormData();
		data.borrowing_limit && formData.append("borrowing_limit", data.borrowing_limit);
		data.max_borrowing_time_line && formData.append("max_borrowing_time_line", data.max_borrowing_time_line);
		data.operating_cycle_date && formData.append("operating_cycle_date", moment(data.operating_cycle_date).format("YYYY-MM-DD"));
		data.currency_id && formData.append("currency_id", data.currency_id);
		data.share_price && formData.append("share_price", data.share_price);
		data.max_share_purchase_limit && formData.append("max_share_purchase_limit", data.max_share_purchase_limit);
		data.group_welfare_rate && formData.append("group_welfare_rate", data.group_welfare_rate);
		data.interest_rate && formData.append("interest_rate", data.interest_rate);
		data.share_purchase && formData.append("share_purchase", data.share_purchase);
	};

	return (
		<>
			<div className="create-group-section">
				<div className="main">
					<h3>{t("GROUPS_SETTINGS")}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<Row gutter={[100, 100]}>
							<Col span={12}>
							<div className="field without-icon">
									<InputBox.DatePicker
										name="operating_cycle_date"
										placeholder={t("OPERATING_CYCLE_DATE")}
										rules={CreateSavingGroupRequest(t).operating_cycle_date}
										disabledDate={disableStartDate}
										label={t("OPERATING_CYCLE_DATE")}
										format={displayFormats.DATE_FORMAT}

									/>
								</div>
								<div className="field without-icon">
									<InputBox.Text
										name="borrowing_limit"
										placeholder={t("BORROWING_LIMIT")}
										rules={CreateSavingGroupRequest(t).borrowing_limit}
										label={t("BORROWING_LIMIT")}
									/>
								</div>
								<div className="field without-icon">
									<InputBox.Text
										name="max_borrowing_time_line"
										placeholder={t("MAXIMUM_LOAN_DURATION_IN_MONTHS")}
										rules={CreateSavingGroupRequest(t).max_borrowing_time_line}
										label={t("MAXIMUM_LOAN_DURATION_IN_MONTHS")}
									/>
								</div>
							</Col>
							<Col span={12}>
							<div className="field without-icon">
									<InputBox.Text
										name="interest_rate"
										placeholder={t("INTEREST_RATE")}
										rules={CreateSavingGroupRequest(t).interest_rate}
										label={t("INTEREST_RATE")}
									/>
								</div>
							<div className="field without-icon">
									<InputBox.Text
										name="group_welfare_rate"
										placeholder={t("GROUP_WELFARE_RATE")}
										rules={CreateSavingGroupRequest(t).group_welfare_rate}
										label={t("GROUP_WELFARE_RATE")}
									/>
								</div>
								<div className="field without-icon">
									<InputBox.Text
										name="share_price"
										placeholder={t("SHARE_PRICE")}
										rules={CreateSavingGroupRequest(t).share_price}
										label={t("SHARE_PRICE")}
									/>
								</div>

							</Col>
						</Row>
						<Row gutter={[100, 100]} className="field btn-inline text-center margin-top">
							<Col span={12}>
								<Button className="btn-primary" htmlType="submit">
									{t("SUBMIT")}
								</Button>
							</Col>
							<Col span={12}>
								<Button className="btn-danger btn-primary" htmlType="submit">
									{t("CANCEL")}
								</Button>
							</Col>
						</Row>
					</FormBox>
				</div>
			</div>
		</>
	);
});

export default MemberSetting;
