import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Drawer, Button } from "antd";
// import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
// import { FaLinkedinIn } from "react-icons/fa";
// import { FaTwitter } from "react-icons/fa";
// import EmailIcon from "../../../assets/images/email-header-icon.svg";
// import CallingIcon from "../../../assets/images/calling-header.svg";
import HeaderSearch from "../components/HeaderSearch";
import { DownOutlined } from "@ant-design/icons";
import signin from "../../../assets/images/signin-icon.svg";
import AboutUs from "../../../assets/images/About_us.svg";
import OTP from "../../../assets/images/OTP-Icon.svg"
import GroupExpense from "../../../assets/images/Group_Expense.svg";
import Loan from "../../../assets/images/Loan.svg";
import Donation from "../../../assets/images/Donation.svg";
import Welfare from "../../../assets/images/Welfare.svg";
import Passbook from "../../../assets/images/Passbook.svg";
import Election from "../../../assets/images/Election.svg";
import Reports from "../../../assets/images/Reports.svg";
import ContactUs from "../../../assets/images/Contact_us.svg";
import PrivacyPolicy from "../../../assets/images/Privacy_Policy.svg";
import Feedback from "../../../assets/images/Feedback.svg";
import Logout from "../../../assets/images/logout-icon.svg";
import useStore from "../../../store";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import HeaderNotification from "../components/HeaderNotification";
import GroupSelect from "../components/GroupSelect";
import { Link } from "react-router-dom";
import { FaPlayCircle, FaRegFileAlt } from "react-icons/fa";
import { FiHome, FiVideo } from "react-icons/fi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { RiGroupLine } from "react-icons/ri";
import { MdWorkOutline } from "react-icons/md";
import { AiOutlineWallet } from "react-icons/ai";
import { IoNewspaperOutline } from "react-icons/io5";
import { observer } from "mobx-react-lite";
import RoleList from "../../../config/RoleList";
import logo from "../../../assets/images/Earziki_Logo.svg";
import TransferFund from "../../../assets/images/fund_transfer.svg";
import { Cash_Module } from "../../../config/Global";
import AllPermissionList from "../../../config/AllPermissionList";

interface HeaderViewProps {
	visible?: boolean;
}

const HeaderView: React.FC<HeaderViewProps> = observer(({ visible }) => {
	const [visibleDrawer, setDrawerVisible] = useState(false);
	const [getLanguage, setGetLanguage] = useState<boolean>(false);
	const { AUTH, USER, COMMON } = useStore();
	const { updateMyLanguage } = USER;
	const { user, logout, activeKey, setActiveKey, fetchAuthUser, permissions } = AUTH;
	const { languageListData, setSelectedLanguage, siteSettings, selectedLanguage } = COMMON;
	const history = useHistory();
	const { t } = useTranslation();

	const onLanguageChange = (event: any) => {
		const selectedLanguage = languageListData?.filter((element: any) => element.id == event.target.id)
		setLanguage(selectedLanguage[0]);
		user && updateMyLanguage({ language_id: event.target.id }).then(() => {
			fetchAuthUser();
		});
	};

	const handleMenuClick = (key: any) => {
		setActiveKey(key.keyPath);
	}

	const setLanguage = async (selectedLanguage: any) => {
		await i18n.changeLanguage(selectedLanguage?.local_key);
		setSelectedLanguage(selectedLanguage);
		setGetLanguage(selectedLanguage?.name);
	}

	const showDrawer = () => {
		setDrawerVisible(true);
	};

	const signOut = () => {
		logout().then(() => {
			history.replace("/login");
		});
	};

	const onDrawerClose = () => {
		setDrawerVisible(false);
	};

	useEffect(() => {
		// const userLanguage = languageListData ? languageListData.find((element: any) => { return element.name == window.localStorage.i18nextLng }) : [{ id: 1 }];

		const localStorageLanguage = window.localStorage.i18nextLng;

		let userLanguage: any = (languageListData && localStorageLanguage) ? languageListData.find((element: any) => { return element.local_key == localStorageLanguage }) : languageListData && languageListData.find((element: any) => { return element.status == 1 });

		if (user) {
			userLanguage = languageListData && languageListData.find((element: any) => { return element.id == user.language_id });
		}

		(userLanguage) && selectedLanguage?.local_key != userLanguage?.local_key && setLanguage(userLanguage);

		userLanguage && setGetLanguage(userLanguage.name);
	}, [languageListData, siteSettings, user, activeKey, setActiveKey]);

	return !visible ? null : (
		<Layout.Header className="main__page__header">
			<div className="header-main">
				<div className="container">
					<div className="logo__wrapper">
						<a href="/" title={siteSettings?.project_name}>
							<img src={logo} alt={siteSettings?.project_name} />
						</a>
					</div>
					<div className="header-right">
						<Dropdown overlay={
							<Menu>
								{languageListData &&
									languageListData.map((item: any, index: number) => {
										return (
											<Menu.Item key={"headerView" + index}>
												<a href="#" onClick={(e) => onLanguageChange(e)} id={item.id}>
													{item.name}
													<FaPlayCircle />
												</a>
											</Menu.Item>
										);
									})}
							</Menu>
						}
							trigger={["click"]}
						>
							<div className="country-dropdown">

								{languageListData && (
									<a
										className="ant-dropdown-link"
										onClick={(e) => e.preventDefault()}
									>
										{getLanguage} <DownOutlined />
									</a>
								)}
							</div>
						</Dropdown>
						{!user ? (
							<div className="signin-link">
								<a href="/login">{t("LOG_IN")}</a>
							</div>
						) : (
							<Dropdown overlay={(
								<Menu>
									<Menu.Item key="profile">
										<Link to="/my-profile">
											<i>
												<img src={signin} alt={t("PROFILE")} />
											</i>
											{t("PROFILE")}
										</Link>
									</Menu.Item>
									<Menu.Item key="signout">
										<a href="#" onClick={() => signOut()}>
											<i>
												<img src={Logout} alt="Signout " />
											</i>
											{t("SIGN_OUT")}
										</a>
									</Menu.Item>
								</Menu>
							)} trigger={["click"]}>
								<div className="login-dropdown">
									<a
										className="ant-dropdown-link"
										onClick={(e) => e.preventDefault()}
									>
										<i>
											<img src={user.profile_image ?? signin} alt="SignIn" />
										</i>
										<span>{user.firstname + " " + user.lastname}</span><DownOutlined />
									</a>
								</div>
							</Dropdown>
						)}
						{user && user.userRole != RoleList.ELECTION_REFREE && <GroupSelect />}
						<div className="logoWrapper">
							<a href="/" title={siteSettings?.project_name}>
								<img src={logo} alt={siteSettings?.project_name} />
							</a>
						</div>
						{user && (user.userRole != RoleList.ELECTION_REFREE && user.userRole != RoleList.VILLAGE_AGENT) && <HeaderSearch />}
						{user && <HeaderNotification />}
						{user &&
							<> <Button className="menu-box" type="primary" onClick={showDrawer}>
								<span></span>
								<span></span>
								<span></span>
							</Button>
								<Drawer
									placement="right"
									onClose={onDrawerClose}
									visible={visibleDrawer}
									width={"450"}
								>
									{activeKey && <Menu
										onClick={handleMenuClick}
										defaultSelectedKeys={[activeKey[0]]}
										defaultOpenKeys={[activeKey[activeKey.length - 1]]}
										mode="inline"
									>
										<Menu.Item key="HOME_PAGE" icon={<FiHome />}>
											<Link to={"/"}>{t("HOME_PAGE")}</Link>
										</Menu.Item>
										{user && user.isElectionReferee && (
											<Menu.Item key="associated-groups" icon={<HiOutlineUserGroup />}>
												<Link to={"/associated-groups"}>{t("ASSOCIATED_GROUPS")}</Link>
											</Menu.Item>
										)}
										{user && user.isVillageAgent && user.groups.length > 0 && (
											<>
												<Menu.Item key="saving-group/details" icon={<HiOutlineUserGroup />}>
													<Link to={"/saving-group/details"}>{t("SAVING_GROUP")}</Link>
												</Menu.Item>
												<Menu.Item key="saving-group/member-details" icon={<RiGroupLine />} >
													<Link to={"/saving-group/member-details"}>{t("GROUP_MEMBER")}</Link>
												</Menu.Item>
												<Menu.Item key="meetings" icon={<FiVideo />}>
													<Link to={"/meetings"}>{t("MEETINGS")}</Link>
												</Menu.Item>
												<Menu.Item key="election" icon={<MdWorkOutline />}>
													<Link to={"/election"}>{t("ELECTION")}</Link>
												</Menu.Item>
											</>
										)}
										{user && !user.isElectionReferee && !user.isVillageAgent && user.groups.length == 0 && (
											<>
												<Menu.Item key="my-group/list" icon={<HiOutlineUserGroup />}>
													<Link to={"/my-group/list"}>{t("MY_GROUPS")}</Link>
												</Menu.Item>
												<Menu.Item key="my-wallet" icon={<AiOutlineWallet />}>
													<Link to={"/my-wallet"}>{t("WALLET")}</Link>
												</Menu.Item>
											</>
										)}
										{user && !user.isElectionReferee && !user.isVillageAgent && user.groups.length > 0 && (
											<>
												<Menu.Item key="saving-group/details" icon={<HiOutlineUserGroup />}>
													<Link to={"/saving-group/details"}>{t("SAVING_GROUP")}</Link>
												</Menu.Item>
												<Menu.Item key="saving-group/member-details" icon={<RiGroupLine />} >
													<Link to={"/saving-group/member-details"}>{t("GROUP_MEMBER")}</Link>
												</Menu.Item>
												<Menu.Item key="my-group/list" icon={<HiOutlineUserGroup />}>
													<Link to={"/my-group/list"}>{t("MY_GROUPS")}</Link>
												</Menu.Item>
												<Menu.Item key="my-group-expenses">
													<img src={GroupExpense} alt="my-group-expenses" />
													<Link to={"/my-group-expenses"}>{t("GROUP_EXPENSES")}</Link>
												</Menu.Item>
												<Menu.Item key="my-fines" icon={<FaRegFileAlt />}>
													<Link to={"/my-fines"}>{t("FINE")}</Link>
												</Menu.Item>
												<Menu.Item key="my-loans">
													<img src={Loan} alt="my-loans" />
													<Link to={"/my-loans"}>{t("LOAN")}</Link>
												</Menu.Item>
												<Menu.Item key="donation">
													<img src={Donation} alt="donation" />
													<Link to={"/donation"}>{t("DONATION")}</Link>
												</Menu.Item>
												<Menu.Item key="welfare_request">
													<img src={Welfare} alt="welfare_request" />
													<Link to={"/my-welfare-request"}>{t("WELFARE_REQUEST")}</Link>
												</Menu.Item>
												<Menu.Item key="my-wallet" icon={<AiOutlineWallet />}>
													<Link to={"/my-wallet"}>{t("WALLET")}</Link>
												</Menu.Item>
												<Menu.Item key="passbook">
													<img src={Passbook} alt="passbook" />
													<Link to={"/passbook"}>{t("PASSBOOKS")}</Link>
												</Menu.Item>
												<Menu.Item key="meetings" icon={<FiVideo />}>
													<Link to={"/meetings"}>{t("MEETINGS")}</Link>
												</Menu.Item>
												<Menu.Item key="election">
													<img src={Election} alt="election" />
													<Link to={"/election"}>{t("ELECTION")}</Link>
												</Menu.Item>
												<Menu.Item key="all-reports">
													<img src={Reports} alt="all-reports" />
													<Link to={"/all-reports"}>{t("REPORTS")}</Link>
												</Menu.Item>
											</>
										)}

										{Cash_Module && <Menu.Item key="generated-otp">
											<img src={OTP} alt="generated-otp" />
											<Link to={"/generated-otp"}>{t("GENERATED_OTP")}</Link>
										</Menu.Item>}
										{(permissions && permissions.indexOf(AllPermissionList.LIST_FUND_TRANSFER) >= 0) && <Menu.Item key="transfer-funds">
											<img src={TransferFund} alt="generated-otp" />
											<Link to={"/transfer-funds"}>{t("TRANSFER_FUNDS")}</Link>
										</Menu.Item>}
										<Menu.Item key="about-us">
											<img src={AboutUs} alt="about-us" />
											<Link to={"/about-us"}>{t("ABOUT_US")}</Link>
										</Menu.Item>
										<Menu.Item key="contact-us">
											<img src={ContactUs} alt="contact-us" />
											<Link to={"/contact-us"}>{t("CONTACT_US")}</Link>
										</Menu.Item>
										<Menu.Item key="privacy-policy">
											<img src={PrivacyPolicy} alt="privacy-policy" />
											<Link to={"/privacy-policy"}>{t("PRIVACY_POLICY")}</Link>
										</Menu.Item>
										<Menu.Item key="feedback">
											<img src={Feedback} alt="feedback" />
											<Link to={"/feedback"}>{t("FEEDBACK")}</Link>
										</Menu.Item>
										{!user.isElectionReferee && <Menu.Item key="news" icon={<IoNewspaperOutline />}>
											<Link to={"/news"}>{t("NEWS")}</Link>
										</Menu.Item>}
									</Menu>}
								</Drawer>
							</>}
					</div>
				</div>
			</div>
		</Layout.Header>
	);
});

export default HeaderView;
