import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ProfileImg from "../../../assets/images/profile-img.svg";
import useStore from "../../../store";
import { Button, Col, Form, Modal, Row } from "antd";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";

const GroupInvitation: React.FC = observer(() => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const { id }: any = useParams();
	const {
		ROOT: { AssignErrorToInput },
		AUTH: { user },
		SAVING_GROUP: {
			groupDetails,
			getGroupDetails,
			acceptAddUserToGroupInvitation,
		},
	} = useStore();

	const [saving, setSaving] = useState<boolean>(false);
	const [buyShare, setBuyShare] = useState(false);
	const [Error, setError] = useState(false);

	const modalClose = () => {
		setBuyShare(false);
		setSaving(false);
	};

	const handleModalSubmit = (data: any) => {
		const formData = new FormData();
		id && formData.append("group_id", id);
		data.share_count && formData.append("share_count", data.share_count);
		formData.append("status", "1");

		acceptAddUserToGroupInvitation(formData)
			.then(() => {
				window.location.href = "/";
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	const onChangeOfShares = (data: any) => {
		const sharePrice = groupDetails?.share_price || 0;
		const groupWelfareRate = groupDetails?.group_welfare_rate || 0;
		form.setFieldsValue({
			share_price: (sharePrice * parseInt(data.target.value)) + groupWelfareRate,
		});
		if (
			form.getFieldValue("walletbalance") < form.getFieldValue("share_price")
		) {
			setError(true);
		} else {
			setError(false);
		}
	};

	useEffect(() => {
		id && getGroupDetails(id).then((data: any) => {
			form.setFieldsValue({
				share_price: data?.share_price,
				walletbalance: user?.wallet_balance,
			});
		});
	}, [getGroupDetails, id, user]);

	return (
		<>
			{groupDetails && (
				<div className="profile-section">
					<div className="main">
						<div className="profile-wrap">
							<div className="profile-thumb">
								<span>
									<img
										src={groupDetails.group_image ?? ProfileImg}
										alt="Profile"
									/>
								</span>
								<h3>{groupDetails.name}</h3>
							</div>
						</div>
						<div className="Profile-description">
							<h3>{t("DESCRIPTION")}</h3>
							<p>{groupDetails.group_description}</p>

							<table>
								<thead>
									<tr>
										<th>
											<strong>{t("PRESIDENT")}: </strong>
											{groupDetails.president.firstname ?
												groupDetails.president.firstname +
												" " +
												groupDetails.president.lastname +
												" (" +
												groupDetails.president.email +
												")" : "N/A"}
										</th>
										<td>
											<strong>{t("MEMBER")}: </strong>{" "}
											{groupDetails.existing_member_count +
												"/" +
												groupDetails.numbers_of_member}
										</td>
									</tr>
								</thead>

								<tbody>
									<tr>
										<th>
											<strong>{t("SECRETARY")}: </strong>
											{groupDetails.secretary.firstname ?
												groupDetails.secretary.firstname +
												" " +
												groupDetails.secretary.lastname +
												" (" +
												groupDetails.secretary.email +
												")" : "N/A"}
										</th>
										<td>
											<strong>{t("GROUP_WELFARE_RATE")}: </strong>{" "}
											{groupDetails.group_welfare_rate}
										</td>

									</tr>
									<tr>
										<td>
											<strong>{t("ACCOUNTANT")}: </strong>
											{groupDetails.accountant.firstname ?
												groupDetails.accountant.firstname +
												" " +
												groupDetails.accountant.lastname +
												" (" +
												groupDetails.accountant.email +
												")" : "N/A"}
										</td>

										<td>
											<strong>{t("SHARE_PRICE")}: </strong>{" "}
											{groupDetails.share_price}
										</td>
									</tr>
									<tr>
										<td>
											<strong>{t("VILLAGE_AGENT")}: </strong>
											{groupDetails.villageAgent.firstname ?
												groupDetails.villageAgent.firstname +
												" " +
												groupDetails.villageAgent.lastname +
												" (" +
												groupDetails.villageAgent.email +
												")" : "N/A"}
										</td>
										<td>
											<strong>{t("INTEREST_RATE")}: </strong>{" "}
											{groupDetails.interest_rate + " %"}
										</td>
									</tr>
								</tbody>
							</table>

							{groupDetails && groupDetails.is_joined == 0 && (
								<div className="section-wrap">
									<div className="btn-wrap">
										<Button
											type="primary"
											className="btn-secondary"
											onClick={() => setBuyShare(true)}
										>
											{t("ACCEPT")}
										</Button>
									</div>
								</div>
							)}
						</div>
					</div>
					{buyShare && (
						<Modal
							title={t("BUY_SHARES")}
							centered
							visible={buyShare}
							onOk={() => setBuyShare(false)}
							onCancel={() => setBuyShare(false)}
							afterClose={() => modalClose()}
							className="custom-popup"
							footer={null}
						>
							<FormBox form={form} onFinish={handleModalSubmit}>
								<Row gutter={[20, 20]}>
									<Col span={12}>
										<div className="field">
											<small>{t("NUMBER_OF_SHARES")}</small>
											<InputBox.Text
												name="share_count"
												initialValue={1}
												onChange={onChangeOfShares}
											/>
										</div>
									</Col>
									<Col span={12}>
										<div className="field">
											<small>{t("SHARE_PRICE")} + {t("GROUP_WELFARE_RATE")}</small>
											<InputBox.Text
												name="share_price"
												value={form.getFieldValue("share_price")}
												disabled={true}
											/>
										</div>
									</Col>
									<Col span={12}>
										<div className="field">
											<small>{t("WALLET_BALANCE")}</small>
											<InputBox.Text
												name="walletbalance"
												initialValue={user.wallet_balance}
												disabled={true}
											/>
										</div>
									</Col>
								</Row>
								{Error ? (
									<div className="info-msg">
										<p>
											{t(
												"YOU_DO_NOT_HAVE_ENOUGH_WALLET_BALANCE_TO_BUY_A_SHARE_PLEASE_ADD_BALANCE"
											)}
										</p>
									</div>
								) : null}
								<div className="btn">
									<Button
										loading={saving}
										className="btn-secondary"
										htmlType="submit"
									>
										{t("BUY")}
									</Button>
									<Button className="btn-danger" onClick={modalClose}>
										{t("CANCEL")}
									</Button>
								</div>
							</FormBox>
						</Modal>
					)}
				</div>
			)}
		</>
	);
});

export default GroupInvitation;
