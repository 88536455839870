import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import { Button } from "antd";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import { DonationSubjectListDataProps } from "../../../../../store/DonationSubjectStore/DonationSubjectIntrface";
import AllPermissionList from "../../../../../config/AllPermissionList";

interface ListProps {
	rowData: DonationSubjectListDataProps[];
	getData: () => Promise<any>;
	openEditDonationSubjectPopup: (id: number) => void | any;
	openDeleteDonationSubjectPopup: (id: number) => void | any;
	permissions: any;
}
const ListComponent: React.FC<ListProps> = ({
	rowData,
	getData,
	openEditDonationSubjectPopup,
	openDeleteDonationSubjectPopup,
	permissions,
}) => {
	useEffect(() => {
		getData();
	}, [getData]);
	const { t } = useTranslation();
	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				{params.data?.canEditDelete == 1 && (permissions && permissions.indexOf(AllPermissionList.EDIT_DONATION_SUBJECT) >= 0 && permissions.indexOf(AllPermissionList.DONATION_SUBJECT_DETAILS) >= 0) && <Button
				className="edit-button" title={t('EDIT_DONATION_SUBJECT')} type="text" onClick={() => openEditDonationSubjectPopup(params.data?.id)}><AiOutlineEdit /></Button>}
				{params.data?.canEditDelete == 1 && <Button
				hidden={permissions && permissions.indexOf(AllPermissionList.DELETE_DONATION_SUBJECT) < 0}
				className="edit-button" title={t('DELETE_DONATION_SUBJECT')} type="text" onClick={() => openDeleteDonationSubjectPopup(params.data?.id)}><AiOutlineDelete /></Button>}
			</div>
		)
	}
	return (
		<>
			<AgGridWrapper
				rowData={rowData}
				className="member-table"
				frameworkComponents={{ ActionRenderer }}
				overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
				onFilterChanged={({ api }) => {
					if (api.getDisplayedRowCount()) {
						api.hideOverlay();
					} else {
						api.showNoRowsOverlay();
					}
				}}
			>
				<AgGridColumn field="id" headerName={"# " + t('ID')} width={130} pinned="left" filter="agNumberColumnFilter" />
				<AgGridColumn field="name" headerName={t('DONATION_SUBJECT')} width={200} />
				<AgGridColumn
					width={120}
					field="action"
					headerName={t('ACTION')}
					cellRenderer="ActionRenderer"
					pinned="right"
				/>
			</AgGridWrapper>
		</>
	);
};

export default ListComponent;