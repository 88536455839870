import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStore from "../../../store";
import { observer } from "mobx-react";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import moment from "moment";
import DateComparator from "../../../utils/DateComparator";
import Config from "../../../config/Config";
import { displayFormats } from "../../../config/Global";
import { Button } from "antd";
import { AiOutlineEye } from "react-icons/ai";

const NotificationList: React.FC = observer(() => {
	const { t } = useTranslation();
	const [width, setWidth] = useState(window.innerWidth);
	const {
		AUTH : {user},
		COMMON: { notificationList, fetchNotificationList, redirectNotification }
	} = useStore();

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	const ActionRenderer = (params: any) => {
		return (
			<>
				<Button className="edit-button" title={t('DETAIL')} type="text" onClick={() => redirectNotification(params.data.id)}><AiOutlineEye /></Button>
			</>
		)
	}

	useEffect(() => {
		user && fetchNotificationList()
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, [user, fetchNotificationList]);
	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("NOTIFICATION")}</h3>
					</div>
					<AgGridWrapper
						tooltipShowDelay={0}
						enableBrowserTooltips={true}
						rowData={notificationList}
						className="member-table"
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						frameworkComponents={{ ActionRenderer }}
						onFilterChanged={({ api }) => {
							if (api.getDisplayedRowCount()) {
								api.hideOverlay();
							} else {
								api.showNoRowsOverlay();
							}
						}}
					>
						<AgGridColumn field="id" headerName={"# " + t('ID')} width={90} pinned="left" filter="agNumberColumnFilter" />
						<AgGridColumn field="title" headerName={t('TITLE')} maxWidth={350} tooltipField="title"/>
						<AgGridColumn
							field="created_at"
							headerName={t('DATE')}
							valueGetter={(data: any) =>
								data?.data?.created_at &&
								moment(data?.data.created_at).format(Config.dateTimeFormat)
							}
							cellFormatter={(data: any) => {
								return moment(data.value).format(displayFormats.DATE_TIME_FORMAT);
							}}
							filter={"agDateColumnFilter"}
							valueFormatter={(data:any) =>
								data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
							}
							filterParams={{
								comparator: DateComparator,
							}}
							maxWidth={200}
						/>
						<AgGridColumn field="description" headerName={t('DESCRIPTION')} width={120} tooltipField="description"/>
						<AgGridColumn
							field="id"
							width={120}
							pinned={width > 769 ? "right" : ""}
							sortable={false}
							filter={false}
							headerName={t("ACTION")}
							cellRenderer="ActionRenderer"
						/>
					</AgGridWrapper>

				</div>
			</div>
		</>
	);
});

export default NotificationList;
