import React, { useEffect } from "react";
import { Menu, Dropdown, Badge, Empty} from "antd";
import { FaRegBell } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import useStore from "../../../store";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import moment from "moment";
import { displayFormats } from "../../../config/Global";


const HeaderNotification: React.FC = observer(() => {
	const { t } = useTranslation();
	const {
		COMMON: { getHomePageNotificationList, homePageNotificationList, notificationCount, getHomePageNotificationMarkAsRead, redirectNotification },
	} = useStore();

	useEffect(() => {
		//
	}, [homePageNotificationList, notificationCount]);

	const menu = (
		<>
			<div className="notification__list_show">
				{homePageNotificationList && homePageNotificationList.length > 0 && <>
					<Menu className="notification-dropdown">
						{homePageNotificationList?.map((data: any, index: number) => {
							return (
								<Menu.Item key={"notification" + index} onClick={() => {redirectNotification(data.id)}}>
									<a title={data.title}>
										{data.title}
									</a>
									<p>{data.description}</p>
									<div className="latest-update">
										<span>{moment.utc(data.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
									</div>
								</Menu.Item>
							);
						})}
					</Menu>


					<div className="notifi_view_all__btn" key="notification-4">
						<Link to="/notification" title={t('VIEW_ALL')}>{t('VIEW_ALL')}</Link>
					</div>
				</>
				}
				{homePageNotificationList && homePageNotificationList.length == 0 && <Menu className="notification-dropdown-empty notification-dropdown">
					<Empty description={t("NO_RECORD_FOUND")}/>
				</Menu>}
			</div>
		</>
	);

	const notificationMarkAsRead = () => {
		getHomePageNotificationMarkAsRead()
		getHomePageNotificationList()
	};

	return (
		<>

			<div className="header-notification">
				<Dropdown overlay={menu} trigger={["click"]}>
					<a title="Notification" onClick={notificationMarkAsRead}>
						<Badge count={notificationCount} size="small"> <FaRegBell /> </Badge>
					</a>
				</Dropdown>
			</div>
		</>
	);
});

export default HeaderNotification;
