import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button } from "antd";
import { GrDocumentExcel } from "react-icons/gr";
import { useHistory } from "react-router";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import { observer } from "mobx-react";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";

const MemberPassbook: React.FC = observer(() => {
	const history = useHistory();
	const { t } = useTranslation();

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);
	const [width, setWidth] = useState(window.innerWidth);

	const {
		PASSBOOK: { setupGroupPassbookListGrid, exportGroupPassbookList, PassbookListGrid },
	} = useStore();

	const ActionRenderer = (params: any) => {
		return (
			<Button className="edit-button" title={t("PASSBOOK")} type="text" onClick={() => getMembersPassbookDetail(params)}><AiOutlineEye /></Button>
		)
	}

	const getMembersPassbookDetail = (params: any) => {
		history.push("/member-passbook/" + params.data?.id + "/details");
	};

	const exportGroupPassbook = (type: string) => {
		setExportSaving(true);
		exportGroupPassbookList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}
	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("MEMBER_PASSBOOK")}</h3>
						<div className="heading-btn-wrap">
							<div className="text-center btn btn-primary btn-yellow">
								<Link to={"/passbook"} type="primary">
									{t("BACK")}
								</Link>
							</div>
							
							{PassbookListGrid && PassbookListGrid.api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={()=> showExportPopup(true)}>
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>}
							
							<ExportPopupComponent
								callbackExportFunction={exportGroupPassbook}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>
						</div>
					</div>
					<AgGridWrapper
						type="serverSide"
						onGridReady={setupGroupPassbookListGrid}
						rowSelection={"multiple"}
						className="member-table"
						immutableData={true}
						frameworkComponents={{ ActionRenderer }}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
					>
						<AgGridColumn field="id" headerName={"# " + t("ID")} width={120} pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter" />
						<AgGridColumn field="user_name" headerName={t("USER_NAME")} width={230} />
						<AgGridColumn field="group_name" headerName={t("GROUP_NAME")} width={295} />
						<AgGridColumn field="email" headerName={t("EMAIL_ID")} width={230} />
						<AgGridColumn field="phone_number" headerName={t("PHONE_NUMBER")} width={180} />

						<AgGridColumn
							width={120}
							field="action"
							headerName={t('DETAIL')}
							cellRenderer="ActionRenderer"
							pinned={width > 769 ? "right" : ""}
						/>
					</AgGridWrapper>
				</div>
			</div>
		</>
	);
});

export default MemberPassbook;
