import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const DonationSubjectRequest = (t: any): RequestProps => ({
	name: [
		{ required: true, message: Message(t).required(t("DONATION_SUBJECT")) },
		// { pattern: /^([A-Za-z -]+)$/, message: Message(t).type.pattern(t("DONATION_SUBJECT")) },
		{ min: 3, message: Message(t).min(3, t("DONATION_SUBJECT")) },
		{ max: 50, message: Message(t).max(50, t("DONATION_SUBJECT")) },
		({ getFieldValue }) => ({
			validator() {
				const name = getFieldValue("name");
				if (name && !name.trim()) {
					return Promise.reject(Message(t).required(t("DONATION_SUBJECT")));
				}
				return Promise.resolve();
			},
		}),
	],
});
