import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import useStore from "../../../store";
import { Button } from "antd";
import { AiOutlineEye } from "react-icons/ai";
import { EditOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

const MyGroups: React.FC = observer(() => {
	const { t } = useTranslation();
	const history = useHistory();
	const [width, setWidth] = useState(window.innerWidth);
	const {
		AUTH: { user },
		SAVING_GROUP: { myGroupList, getMyGroupList },
	} = useStore();

	const goToGroupDetails = (groupId: any) => {
		history.push("/group-list/" + groupId + "/group-details");
	}

	const ActionRenderer = (params: any) => {
		return (
			<>
				{(params.data.isEditable == true) && <Button className="edit-button" title={t('DETAIL')} type="text" onClick={() => history.push("/group/" + params.data.group_id + "/edit")}><EditOutlined /></Button>}

				<Button className="edit-button" title={t('DETAIL')} type="text" onClick={() => goToGroupDetails(params.data.group_id)}><AiOutlineEye /></Button>
			</>
		)
	}

	useEffect(() => {
		user && getMyGroupList();
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, [user]);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}
	
	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("MY_GROUPS")}</h3>
					</div>
					{user && myGroupList && <AgGridWrapper
						tooltipShowDelay={0}
						enableBrowserTooltips={true}
						className="member-table"
						rowData={myGroupList}
						frameworkComponents={{ ActionRenderer }}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						onFilterChanged={({ api }) => {
							if (api.getDisplayedRowCount()) {
								api.hideOverlay();
							} else {
								api.showNoRowsOverlay();
							}
						}}
					>
						<AgGridColumn field="group_id" headerName="# ID" pinned={width > 769 ? "left" : ""} width={120} />
						<AgGridColumn field="group_name" headerName={t("GROUP_NAME")} minWidth={180} tooltipField="group_name" />
						<AgGridColumn field="group_description" headerName={t("DESCRIPTION")} minWidth={180} tooltipField="group_description" />
						<AgGridColumn field="share_count" headerName={t("SHARE_COUNT")} minWidth={180} />
						<AgGridColumn field="share_price" headerName={t("SHARE_PRICE")} minWidth={180} />
						<AgGridColumn field="group_welfare_rate" headerName={t("WELFARE_RATE")} minWidth={180} />
						<AgGridColumn field="display_status" headerName={t("STATUS")} minWidth={150} tooltipField="display_status" />
						<AgGridColumn
							field="group_id"
							width={120}
							pinned={width > 769 ? "right" : ""}
							sortable={false}
							filter={false}
							headerName={t("ACTION")}
							cellRenderer="ActionRenderer"
						/>
					</AgGridWrapper>}
				</div>
			</div>
		</>
	);
});

export default MyGroups;
