import { ServerSideStoreType } from "ag-grid-community";
import { AgGridReactProps, AgReactUiProps } from "ag-grid-react/lib/interfaces";

interface ConfigProps {
	[key: string]: any;
	grid: {
		local: AgGridReactProps | AgReactUiProps;
		server: AgGridReactProps | AgReactUiProps;
	};
}

export const onFilterChanged = (params: any) => {
	const agGrid = params;
	if (agGrid && agGrid.api.getModel().getRowCount() === 0) {
		agGrid.api.showNoRowsOverlay();
	}
	if (agGrid && agGrid.api.getModel().getRowCount() > 0) {
		agGrid.api.hideOverlay();
	}
};

const Config: ConfigProps = {
	gutter: 24,
	sidebar_width: 260,
	dateFormat: "YYYY-MM-DD",
	dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
	grid: {
		server: {
			rowModelType: "serverSide",
			serverSideStoreType: ServerSideStoreType.Partial,
			gridOptions: {
				animateRows: true,
				pagination: true,
				paginationPageSize: 10,
				rowHeight: 36,
				enableRangeSelection: true,
				cacheBlockSize: 10,
				blockLoadDebounceMillis: 500,
			},
			defaultColDef: {
				resizable: true,
				sortable: true,
				filter: "agTextColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
				},
				floatingFilter: true,
			},
			columnTypes: {
				actionColumn: {
					cellRenderer: "ActionRenderer",
				},
			},
		},
		local: {
			gridOptions: {
				animateRows: true,
				pagination: true,
				paginationPageSize: 500,
				rowHeight: 36,
				enableRangeSelection: true,
			},
			defaultColDef: {
				resizable: true,
				sortable: true,
				filter: "agTextColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
				},
				floatingFilter: true,
				flex: 1,
			},
			columnTypes: {
				actionColumn: {
					cellRenderer: "ActionRenderer",
				},
			},
			onFilterChanged: onFilterChanged,
			onSortChanged: onFilterChanged
		},
	},
};

export default Config;
