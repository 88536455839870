import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import useStore from "../../../store";
import User_2 from "../../../assets/images/User_2.svg";
import passBook from "../../../assets/images/Passbook.svg";
import Meetings from "../../../assets/images/Meetings.svg";
import Election from "../../../assets/images/Elections.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Button, Col, Empty, Row } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import About from "./About";
import VillageAgentHomePage from "./VillageAgentHomePage";
import moment from "moment";
import { displayFormats } from "../../../config/Global";
import { RightOutlined } from "@ant-design/icons";
import UserLoanDetails from "../Loans/UserLoanDetails";

const HomePage: React.FC = observer(() => {
	const {
		AUTH: { user, selectedGroup },
		COMMON: { homePageNotificationList, selectedLanguage, siteSettings, redirectNotification },
		LOAN: { myUserLoanRequestDetails },
		USER: { getUserHomePageDetails, userHomePageDetails },
	} = useStore();
	const { t } = useTranslation();
	const history = useHistory();
	const [showUserLoanDetails, showUserLoanDetailsModel] = useState(false);
	const [userLoanRequestDetails, setUserLoanRequestDetails] = useState<any>();

//loans Setting
	const settings = {
		arrows: false,
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: (
			<Button className="slick-arrow prev slick-prev">
				<IoIosArrowBack />
			</Button>
		),
		nextArrow: (
			<Button className="slick-arrow next slick-next">
				<IoIosArrowForward />
			</Button>
		),
		responsive: [
			{
				breakpoint: 1450,
				settings: {
					arrows: false,
				},
			},
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 639,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
		],
	};

	const upcomingSliderSettings = {
		arrows: false,
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		rows: 4,
		prevArrow: (
			<Button className="slick-arrow prev slick-prev">
				<IoIosArrowBack />
			</Button>
		),
		nextArrow: (
			<Button className="slick-arrow next slick-next">
				<IoIosArrowForward />
			</Button>
		),
		responsive: [
			{
				breakpoint: 1450,
				settings: {
					arrows: false,
				},
			},
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 639,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
		],
	};
//overview Setting
	const groupSliderSettings = {
		arrows: true,
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: (
			<Button className="slick-arrow prev slick-prev">
				<IoIosArrowBack />
			</Button>
		),
		nextArrow: (
			<Button className="slick-arrow next slick-next">
				<IoIosArrowForward />
			</Button>
		),
		responsive: [
			{
				breakpoint: 1450,
				settings: {
					arrows: false,
					dots: true,
				},
			},
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					dots: true,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: true,
					dots: true,
				},
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: true,
					dots: true,
				},
			},
		],
	};

	const goToGroupDetails = (groupId: any) => {
		history.push("/group-list/" + groupId + "/group-details");
	}

	const goToMeetingDetails = (id: any) => {
		history.push("/meetings/" + id + "/details");
	};

	const displayUserLoanDetails = (id: number) => {
		myUserLoanRequestDetails(id).then((data: any) => {
			setUserLoanRequestDetails(data);
			showUserLoanDetailsModel(true);
		});
	};

	useEffect(() => {
		//
	}, [selectedLanguage, siteSettings, homePageNotificationList]);

	useEffect(() => {
		user && selectedGroup && !userHomePageDetails && getUserHomePageDetails();
	}, [user, selectedGroup, userHomePageDetails]);

	return (
		<>
			{(!user || (user && user.isElectionReferee)) ? (
				<About />
			) : (user && user.isVillageAgent) ? (
				<VillageAgentHomePage />
			) : (
				<>
					{/* overview section */}
					<section>
						<div className="upcoming-event-section">
							<div className="main">
								<div className="heading-title">
									<Row>
										<Col span={24}>
											<h4>{t("OVERVIEW")}</h4>
										</Col>
									</Row>
								</div>
								<div className="overviewContentWrap">
									<Row>
										<Col span={8}>
											<div className="overViewBox">
												<div className="myGroupTitle">
													<h6>{t("MY_GROUPS")}</h6>
												</div>
												{userHomePageDetails && userHomePageDetails?.userHomePageMyGroups && <Slider
													className="four-col-slider listing-item-wrap"
													{...groupSliderSettings}
												>
													{userHomePageDetails.userHomePageMyGroups.slice(0, 5).map((item: any, index: number) => {
														return (
															<div className="text-center min-hight"  key={"userHomePageMyGroups" + index}
															>
																<div className="Thumb">
																	{item.group_image ? <img src={item.group_image} alt={item.group_name} /> : <img src={User_2} alt={item.group_name} />}
																	<h4>{item.group_name}</h4>
																	<p>{item.group_description}</p>
																	<div className="status">
																		<span>{t("STATUS")}: <em><span
																			className={
																				item.status == 1
																					? "success"
																					: item.status == 2
																						? "rejected"
																						: "pending"
																			}
																		>
																			{item.status == 1
																				? t("ACCEPTED")
																				: item.status == 2
																					? t("REJECTED")
																					: t("PENDING")}
																		</span></em> </span>
																	</div>
																		<a className="viewGroup" href="javascript:void(0)" onClick={() => goToGroupDetails(item.group_id)}>{t("VIEW_GROUP")}</a>
																</div>
															</div>
														);
													})}
												</Slider>}
												{((userHomePageDetails && userHomePageDetails?.userHomePageMyGroups && userHomePageDetails?.userHomePageMyGroups.length <= 0) || !selectedGroup) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NO_RECORD_FOUND")} />}
											</div>
										</Col>

										<Col span={8}>
											<div className="overViewBox">
												<div className="upcomingMeeting">
													<h6>{t("UPCOMING_MEETINGS")}</h6>
												</div>
												{userHomePageDetails && userHomePageDetails?.myMeetingsList && <div className="upcomingContent ">
													<Slider
														className="three-col-slider"
														{...upcomingSliderSettings}
													>
														{userHomePageDetails.myMeetingsList && userHomePageDetails.myMeetingsList.map((item: any, index: number) => {
															return (
																<div className="listing-item" key={"myMeetingsList" + index}>
																	<a href="javascript:void(0)" className="overviewArrows" onClick={() => goToMeetingDetails(item.id)}>
																		<p>{item.title} <span>{moment.utc(item.meeting_start_date_time).local().format(displayFormats.DATE_TIME_FORMAT)}</span></p>
																		<RightOutlined className="upcomingRightArrow" />
																	</a>
																</div>

															);
														})}
													</Slider>
												</div>}
												{((userHomePageDetails && userHomePageDetails?.myMeetingsList && userHomePageDetails?.myMeetingsList.length <= 0) || !selectedGroup) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NO_RECORD_FOUND")} />}
											</div>
										</Col>
										{homePageNotificationList && (<Col span={8}>
											<div className="overViewBox">
												<div className="latestNotifications">
													<h6>{t("LATEST_NOTIFICATIONS")}</h6>
												</div>
												<div className="upcomingContent">
													<Slider
														className="three-col-slider"
														{...upcomingSliderSettings}
													>
														{homePageNotificationList.map((data: any, index: number) => {
															return (
																<div className="listing-item" key={"myMeetingsList" + index}>
																	<Link to={"#"} onClick={()=>{redirectNotification(data.id)}} className="overviewArrows">
																		<p>{data.title}<span>{moment.utc(data.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span></p>
																		<RightOutlined className="upcomingRightArrow" />
																	</Link>
																</div>
															);
														})}
													</Slider>
												</div>
												{((userHomePageDetails && userHomePageDetails?.homePageNotificationList && homePageNotificationList?.length <= 0) || !selectedGroup) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("NO_RECORD_FOUND")} />}
											</div>
										</Col>)}
									</Row>
								</div>
							</div>
						</div>
					</section>

					{/** My Group Section*/}
					<section>
						<div className="informationSection">
							<div className="title">
								<h5>{t("BE_UPDATE_ON_THE_LAST_EVENTS_ON_YOUR_E_ARZIKI")}</h5>
							</div>
							<div className="informationContent">
								<Row justify="center">
									<Col span={6}>
										<div className="informationDetails">
											<div className="infoDetailImg">
												<img src={passBook} alt="passbook" />
											</div>
											<div className="informationTitle">
												<h6>{t("PASSBOOK")}</h6>
												<div dangerouslySetInnerHTML={{ __html: siteSettings && selectedLanguage && siteSettings.passbook_message[selectedLanguage.local_key]?.description }} />
												<p><Link to="/passbook" className="seeMore">{t("SEE_MORE")} <RightOutlined className="seeMoreRightArrow" /></Link ></p>
											</div>
										</div>
									</Col>
									<Col span={6}>
										<div className="informationDetails">
											<div className="infoDetailImg">
												<img src={Meetings} alt="meetings" />
											</div>
											<div className="informationTitle">
												<h6>{t("MEETINGS")}</h6>
												<div dangerouslySetInnerHTML={{ __html: siteSettings && selectedLanguage && siteSettings.meetings_message[selectedLanguage.local_key]?.description }} />
												<p><Link to="/meetings" className="seeMore">{t("SEE_MORE")} <RightOutlined className="seeMoreRightArrow" /></Link></p>
											</div>
										</div>
									</Col>
									<Col span={6}>
										<div className="informationDetails">
											<div className="infoDetailImg">
												<img src={Election} alt="election" />
											</div>
											<div className="informationTitle">
												<h6>{t("ELECTIONS")}</h6>
												<div dangerouslySetInnerHTML={{ __html: siteSettings && selectedLanguage && siteSettings.election_message[selectedLanguage.local_key]?.description }} />
												<p><Link to="/election" className="seeMore">{t("SEE_MORE")} <RightOutlined className="seeMoreRightArrow" /></Link></p>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</section>

					{/*loans*/}
					<section>
						<div className="upcoming-event-section">
							<div className="main">
								<div className="heading-title">
									<Row>
										<Col span={24}>
											<h4>{t("LOANS")}</h4>
										</Col>
									</Row>
								</div>
								<div className="overviewContentWrap overviewContentWrapLoan">
									<div>
										{userHomePageDetails && userHomePageDetails?.myLoanRequestList && <Slider
											className="group-loan-listing three-col-slider"
											{...settings}
										>
											{userHomePageDetails && userHomePageDetails?.myLoanRequestList && userHomePageDetails.myLoanRequestList.map((item: any, index: number,) => {
												return (<div className="loan-info" key={"myMeetingsList" + index}>
													<div className="overViewBox">
														<div className="loansContentTitle">
															<h6>{item.group_name}</h6>
														</div>
														<div className="upcomingContent">
															<div className="overviewArrows">
																<p>{t("LOAN_REQUEST_DATE")} <span>{moment.utc(item.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span></p>
															</div>
															<div className="overviewArrows">
																<p>{t("LOAN_AMOUNT")} <span>{item.loan_amount}</span></p>
															</div>
															<div className="overviewArrows">
																<p>{t("INTEREST_RATE")} <span>{item.interest_rate}</span></p>
															</div>
															<div className="overviewArrows">
																<p className="status">{t("STATUS")} <span className={item.display_approved_status}>{item.display_approved_status}</span></p>
															</div>
															<p><a href="javascript:void(0)" className="moreDetail" onClick={() => displayUserLoanDetails(item.id)} >More Details <RightOutlined className="rightArrow" /></a></p>
														</div>
													</div>
												</div>);
											})}
										</Slider>}

										{((userHomePageDetails && userHomePageDetails?.myLoanRequestList && userHomePageDetails.myLoanRequestList.length <= 0) || !selectedGroup) && <h4 className="no_record_found mt-50">{t("GROUP_LOANS_FOUND")}</h4>}

										<UserLoanDetails
											showUserLoanDetails={showUserLoanDetails}
											showUserLoanDetailsModel={showUserLoanDetailsModel}
											userLoanRequestDetails={userLoanRequestDetails}
											setUserLoanRequestDetails={setUserLoanRequestDetails}
											userLoanRequestType={"my-loan-detail"}
										/>
									</div>

								</div>
							</div>
						</div>
					</section>

				</>
			)}
		</>
	);
});

export default HomePage;
