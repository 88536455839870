import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../../store";
import { Button } from "antd";
import { GoPlus } from "react-icons/go";
import { GrDocumentExcel } from "react-icons/gr";
import { useHistory } from "react-router";
import { Col, Row } from "antd";
import ExportPopupComponent from "../../../../utils/ExportPopupComponent";
const DonationCampaignView: React.FC = observer(() => {
	const pathHistory = useHistory();
	const { t } = useTranslation();

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const { DONATION } = useStore();
	const { setupDonationListGrid, donationDetails, getDonationDetails, exportDonationList, donationListGrid } = DONATION;
	const addRedirect = () => {
		pathHistory.push('/donation/add');
	}

	const exportDonation = (type: string) => {
		setExportSaving(true);
		exportDonationList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	return (
		<>
			<Row align="middle">
				<Col span={12}>
					<div className="heading-title-wrap">
						<h3>{t("DONATION")}</h3>
					</div>
				</Col>
				<Col span={12}>
					<div className="heading-btn-wrap">
						{donationListGrid && donationListGrid.api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={()=> showExportPopup(true)}>
							{t("GENERATE_REPORT")} <GrDocumentExcel />
						</Button>}
						
						<ExportPopupComponent
							callbackExportFunction={exportDonation}
							setVisible={showExportPopup}
							visible={exportPopup}
							saving={exportSaving}
						/>
						<Button className="btn-secondary" type="primary" onClick={addRedirect}>
							{t("ADD_DONATION")} <GoPlus />
						</Button>
					</div>
				</Col>
			</Row>

			<ContentBox>
				<ListComponent
					setupGrid={setupDonationListGrid}
					donationDetails={donationDetails}
					getDonationDetails={getDonationDetails}
				/>
			</ContentBox>
		</>
	);
});
export default DonationCampaignView;