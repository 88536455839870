import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { createFineType,  } from "./FineInterface";

export default class FineStore {
	private rootStore: RootStore;
	public myFineListGrid?: any;
	public groupFineListGrid?: any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setMyFineListGrid = (value: any) => {
		this.myFineListGrid = value;
	};

	private setGroupFineListGrid = (value: any) => {
		this.groupFineListGrid = value;
	};

	// Grid Setup Functions
	public setupMyFineListGrid = (params: GridReadyEvent) => {
		this.setMyFineListGrid(params);
		const datasource = this.getMyFineListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setupGroupFineListGrid = (params: GridReadyEvent) => {
		this.setGroupFineListGrid(params);
		const datasource = this.getGroupFineListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	// API Functions
	public createFine = (payload: createFineType): Promise<any> => {
		return axios
			.post(API_URL.FINE.CREATE_FINE, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public getMyFineList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.FINE.MY_FINE_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public getGroupFineList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.FINE.FINE_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	private getMyFineListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getMyFineList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setMyFineListGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	private getGroupFineListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getGroupFineList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setGroupFineListGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	public myFinePay = (id: number): Promise<any> => {
		return axios.post(API_URL.FINE.MY_FINE_PAY(id), {}).then(({ data }) => {
			this.setupMyFineListGrid(this.myFineListGrid);
			return data.data;
		});
	};

	public deleteGroupFine = (id: number): Promise<any> => {
		return axios.delete(API_URL.FINE.DELETE(id)).then(({ data }) => {
			this.setupGroupFineListGrid(this.groupFineListGrid);
			return data.data;
		});
	};

	public exportMyFineList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.myFineListGrid);
		payLoad.export_type = exportType;
		return this.getMyFineList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public exportGroupFineList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.groupFineListGrid);
		payLoad.export_type = exportType;
		return this.getGroupFineList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public createPayCash = (id:any,payload: any): Promise<any> => {
		return axios
			.post(API_URL.FINE.PAY_CASH(id), payload)
			.then(({ data }) => {
				this.setupGroupFineListGrid(this.groupFineListGrid);
				return data;
			});
	};
}
