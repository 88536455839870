import { Button, Col, Row } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router";
import RoleList from "../../../config/RoleList";
import useStore from "../../../store";

const ElectionRefereeResult: React.FC = observer(() => {
	const { t } = useTranslation();
	const { id }: any = useParams();
	const history = useHistory();
	const {
		AUTH: { user, permissions },
		ELECTION: {
			getElectionRefereeElectionDetail,
			electionRefereeElectionDetail
		},
	} = useStore();

	const goToElectionFeedback = () => {
		history.push("/election/" + id + "/feedback");
	};

	const goToBackHome = () => {
		history.push("/");
	};

	useEffect(() => {
		id && user && !electionRefereeElectionDetail && getElectionRefereeElectionDetail(id);
	}, [user, permissions, electionRefereeElectionDetail]);

	return (
		<>
			{electionRefereeElectionDetail && (
				<section className="group-list-table-section padding-100">
					<div className="main">
					<div className="heading-title">
						<Row>
							<Col span={24}>
								<h4>{t("ELECTION_DETAILS")}</h4>
							</Col>
						</Row>
					</div>
						{/* <div className="title-overlap title-with-border">
							<h3>{t("ELECTION_DETAILS")}</h3>
						</div> */}
						<h3>{t("GROUP_NAME")} : {electionRefereeElectionDetail && electionRefereeElectionDetail?.group.name}</h3>

						{electionRefereeElectionDetail && electionRefereeElectionDetail.candidateAssigned == 0 && <div className="profile-section">
							<div className="main">
								<div className="profile-wrap">
									<h3>
										{t("NO_CANDIDATE_ASSIGN_ELECTION")}
									</h3>
								</div>
							</div>
						</div>}

						{electionRefereeElectionDetail && electionRefereeElectionDetail.candidateAssigned > 0 && <div className="responsive-table">
							<table>
								<thead>
									<tr>
										{/* <th scope="col">{t("SR_NO")}</th> */}
										<th scope="col">{t("DESIGNATIONS")}</th>
										<th scope="col">{t("CANDIDATES")}</th>
										<th scope="col">{t("VOTES")}</th>
									</tr>
								</thead>
								<tbody>
									{
										electionRefereeElectionDetail.electionCandidates.map(
											(itemRole: any, roleIndex: any) => {
												return(
													itemRole.cadidates && itemRole.cadidates.map(
													(itemCadidates: any, cadidatesIndex: any) => {
															return (
																<tr key={"election-result-" + roleIndex + cadidatesIndex}>
																	{/* <td data-label={t("SR_NO")}>{count}</td> */}
																	<td data-label={t("DESIGNATIONS")}>{(itemRole.role_name == RoleList.PRESIDENT) ? t("PRESIDENT") : (itemRole.role_name == RoleList.SECRETARY) ? t("SECRETARY") : (itemRole.role_name == RoleList.ACCOUNTANT)  ? t("ACCOUNTANT") : ""}</td>
																	<td data-label={t("DESIGNATIONS")}>{itemCadidates.user_name}</td>
																	<td data-label={t("VOTES")}>{itemCadidates.voteCount}</td>
																</tr>
															);
														}
													)
												)
											}
										)
									}
								</tbody>
							</table>
						</div>}

						<div className="btn small-btn text-center">
							<Button
								type="primary"
								className="btn btn-yellow"
								onClick={goToBackHome}
							>
								{t("BACK_TO_HOME")}
							</Button>
							{electionRefereeElectionDetail && electionRefereeElectionDetail.candidateAssigned > 0 && moment(electionRefereeElectionDetail.election_date).startOf('day') < moment().startOf('day') && <Button className="btn-primary" onClick={goToElectionFeedback}>{t("FEEDBACK")}</Button>}
						</div>
					</div>
				</section>

				
			)}
		</>
	);
});

export default ElectionRefereeResult;
