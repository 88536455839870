import { Button } from "antd";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import useStore from "../../../store";
import moment from "moment";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../config/Global";

const NewsDetail: React.FC = observer(() => {
	const history = useHistory();
	const { t } = useTranslation();
	const { id }: any = useParams();
	const {
		NEWS: { myNewDetails, getNewsDetails },
	} = useStore();

	const goViewElection = () => {
		history.push("/news");
	};

	useEffect(() => {
		getNewsDetails(id);
	}, [id, getNewsDetails]);

	return (
		<>
			<section className="common-page-section">
				<div className="main blog-pages">
					<div className="heading-title">
						<h4>{myNewDetails && myNewDetails.title}</h4>
					</div>
					{/* <h2  className="post-title">{myNewDetails && myNewDetails.title}</h2>					 */}
					<i
						className="post-date"
						dangerouslySetInnerHTML={{
							__html:
								myNewDetails &&
								moment
									.utc(myNewDetails.created_at)
									.local()
									.format(displayFormats.DATE_FORMAT),
						}}
					/>
					{myNewDetails?.news_image && (
						<img className="post-img" src={myNewDetails?.news_image} />
					)}
					<div
						dangerouslySetInnerHTML={{
							__html: myNewDetails && myNewDetails.short_description,
						}}
					/>
					<div
						dangerouslySetInnerHTML={{
							__html: myNewDetails && myNewDetails.description,
						}}
					/>

					<div className="btn small-btn text-center margin-50">
						<Button
							className="btn-yellow"
							title={t("BACK_TO_NEWS")}
							onClick={goViewElection}
						>
							{t("BACK_TO_NEWS")}{" "}
						</Button>
					</div>
				</div>
			</section>
		</>
	);
});

export default NewsDetail;
