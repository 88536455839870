import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button, Modal, Tabs } from "antd";
import { useHistory } from "react-router";
import { FiSettings } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { EyeOutlined } from "@ant-design/icons";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { observer } from "mobx-react";
import { AgGridColumn } from "ag-grid-react";
import AllPermissionList from "../../../config/AllPermissionList";
import moment from "moment";
import { displayFormats } from "../../../config/Global";
import DateComparator from "../../../utils/DateComparator";
import { GrDocumentExcel } from "react-icons/gr";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";

const MeetingsList: React.FC = observer(() => {
	const history = useHistory()
	const { t } = useTranslation();
	const { TabPane } = Tabs;
	const [deleteMeetingModel, setDeleteMeetingModal] = useState(false);
	const [deleteMeetingId, setDeleteMeetingId] = useState(null);
	const [cancelMeetingModel, setCancelMeetingModal] = useState(false);
	const [cancelMeetingId, setCancelMeetingId] = useState(null);
	const [isMeeting, setIsMeeting] = useState<boolean>(false);

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);
	const [width, setWidth] = useState(window.innerWidth);

	const {
		AUTH,
		AUTH: { permissions },
		MEETING: { setupGroupMeetingsListGrid, meetingType, changeMeetingType, deleteMeeting, cancelMeeting, joinMyMeeting, exportGroupMeetingsList, groupMeetingsListGrid },
	} = useStore();

	const openMeetingDeleteModal = (meetingId: any) => {
		setDeleteMeetingId(meetingId);
		setDeleteMeetingModal(true);
	}

	const openMeetingCancelModal = (meetingId: any) => {
		setCancelMeetingId(meetingId);
		setCancelMeetingModal(true);
	}

	const goToEditMeeting = (meetingId: any) => {
		history.push("/meetings/" + meetingId + "/edit");
	};

	const goToCreateMeeting = () => {
		history.push("/meeting/add");
	};

	const goToMeetingDetails = (meetingId: any) => {
		history.push("/meetings/" + meetingId + "/details");
	};

	const goToMeetingSettings = () => {
		history.push('/meetings/meetings-settings')
	}

	const upcomingMeetingActionRenderer = (params: any) => {
		return (
			<>
				{AUTH.user && !AUTH.user.isVillageAgent && (params.data?.is_started == 0) && <Button
					hidden={AUTH.permissions && (AUTH.permissions.indexOf(AllPermissionList.EDIT_MEETING) < 0)}
					className="edit-button grey-btn" title={t("EDIT")} type="text" onClick={() => goToEditMeeting(params.data?.id)}><AiOutlineEdit /></Button>}

				<Button className="edit-button grey-btn" title={t("DETAIL")} type="text" onClick={() => goToMeetingDetails(params.data?.id)}><EyeOutlined /></Button>

				{AUTH.user && !AUTH.user.isVillageAgent && (params.data?.show_join==1) && <Button
				title={t("JOIN")} type="default" style={{ color: "green" }} onClick={() => joinMyMeeting(params.data?.id)}><span>{t("JOIN")}</span></Button>}

				{AUTH.user && !AUTH.user.isVillageAgent && (params.data?.status == 1) && <Button
					hidden={AUTH.permissions && (AUTH.permissions.indexOf(AllPermissionList.CANCEL_MEETING) < 0)}
					title={t("CANCEL_MEETING")} type="default" style={{ color: "red" }} onClick={() => openMeetingCancelModal(params.data?.id)}><span>{t("CANCEL")}</span></Button>}
			</>
		)
	}

	const pastMeetingActionRenderer = (params: any) => {
		return (
			<>
				<Button className="edit-button grey-btn" title={t("DETAIL")} type="text" onClick={() => goToMeetingDetails(params.data?.id)}><EyeOutlined /></Button>
			</>
		)
	}

	const cancelMeetingActionRenderer = (params: any) => {
		return (
			<>
				{AUTH.user && !AUTH.user.isVillageAgent && <Button className="edit-button grey-btn" title={t("DELETE")} type="text" onClick={() => openMeetingDeleteModal(params.data?.id)}><AiOutlineDelete /></Button>}
			</>
		)
	}

	const setMeetingType = (key:any) => {
		changeMeetingType(key);
	}

	const closeDeleteMeetingModal = () => {
		setDeleteMeetingId(null);
		setDeleteMeetingModal(false);
	}

	const submitDeleteMeeting = () => {
		deleteMeeting(deleteMeetingId).finally(()=>{
			setDeleteMeetingId(null);
			setDeleteMeetingModal(false);
		});
	}

	const closeCancelMeetingModal = () => {
		setCancelMeetingId(null);
		setCancelMeetingModal(false);
	}

	const submitCancelMeeting = () => {
		cancelMeeting(cancelMeetingId).finally(()=>{
			setCancelMeetingId(null);
			setCancelMeetingModal(false);
		});
	}

	const exportGroupMeetings = (type: string) => {
		setExportSaving(true);
		exportGroupMeetingsList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	useEffect(() => {
		if(!isMeeting) {
			setMeetingType(1);
			setIsMeeting(true);
		}
	}, [isMeeting, setIsMeeting, setMeetingType]);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	return (
		<>
			<div className="member-details-section text">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("MEETINGS")}</h3>
						<div className="heading-btn-wrap heading-with-icon">
							<div className="setting">
								<Button
									hidden={permissions && (permissions.indexOf(AllPermissionList.VIEW_ZOOM_DETAILS) < 0)}
									type="primary" title="Settings" onClick={goToMeetingSettings}>
									<FiSettings />
								</Button>
							</div>
							<Button
								hidden={permissions && (permissions.indexOf(AllPermissionList.ADD_MEETING) < 0)}
								className="btn-secondary" type="primary" onClick={goToCreateMeeting}>
								{t("CREATE_MEETING")} <GoPlus />
							</Button>
							{groupMeetingsListGrid && groupMeetingsListGrid[meetingType] && groupMeetingsListGrid[meetingType].api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={()=> showExportPopup(true)}>
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>}
							
							<ExportPopupComponent
								callbackExportFunction={exportGroupMeetings}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>
						</div>
					</div>
					<div className="custom-tabs">
						<Tabs defaultActiveKey="1" onChange={setMeetingType} style={{ marginBottom: 32 }}>
							<TabPane tab={t('UPCOMING_MEETINGS')} key={1} active={meetingType == 1}>
								<AgGridWrapper
									type="serverSide"
									onGridReady={setupGroupMeetingsListGrid}
									rowSelection={"multiple"}
									className="member-table"
									immutableData={true}
									frameworkComponents={{ upcomingMeetingActionRenderer }}
									overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
								>
									<AgGridColumn
										field="id"
										headerName={"# " + t("ID")}
										width={100}
										pinned={width > 769 ? "left" : ""}
										filter="agNumberColumnFilter"
									/>
									<AgGridColumn field="group_name" headerName={t("GROUP_NAME")} />
									<AgGridColumn field="title" headerName={t("MEETING_TITLE")} />
									<AgGridColumn
										field="meeting_start_date_time"
										headerName={t("DATE_AND_TIME")}
										filter={"agDateColumnFilter"}
										valueFormatter={(data:any) =>
											data?.data.meeting_start_date_time && moment.utc(data?.data.meeting_start_date_time).local().format(displayFormats.DATE_TIME_FORMAT)
										}
										filterParams={{
											comparator: DateComparator,
										}}
									/>
									<AgGridColumn field="location" headerName={t("MEETING_LOCATION_LINK")} />
									<AgGridColumn
										field="id"
										headerName={t('ACTION')}
										cellRenderer="upcomingMeetingActionRenderer"
										width={250}
										wrapText={true}
										autoHeight={true}
										pinned={width > 769 ? "right" : ""}
										sortable={false}
										filter={false}
									/>
								</AgGridWrapper>
							</TabPane>
							<TabPane tab={t("PAST_MEETINGS")} key={2} active={meetingType == 2}>
								<AgGridWrapper
									type="serverSide"
									onGridReady={setupGroupMeetingsListGrid}
									rowSelection={"multiple"}
									className="member-table"
									immutableData={true}
									frameworkComponents={{ pastMeetingActionRenderer }}
									overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
								>
									<AgGridColumn
										field="id"
										headerName={"# " + t("ID")}
										width={100}
										pinned={width > 769 ? "left" : ""}
										filter="agNumberColumnFilter"
									/>
									<AgGridColumn field="group_name" headerName={t("GROUP_NAME")} />
									<AgGridColumn field="title" headerName={t("MEETING_TITLE")} />
									<AgGridColumn
										field="meeting_start_date_time"
										headerName={t("DATE_AND_TIME")}
										filter={"agDateColumnFilter"}
										valueFormatter={(data:any) =>
											data?.data.meeting_start_date_time && moment.utc(data?.data.meeting_start_date_time).local().format(displayFormats.DATE_TIME_FORMAT)
										}
										filterParams={{
											comparator: DateComparator,
										}}
									/>
									<AgGridColumn field="location" headerName={t("MEETING_LOCATION_LINK")} />
									<AgGridColumn
										field="id"
										headerName={t('ACTION')}
										cellRenderer="pastMeetingActionRenderer"
										width={150}
										wrapText={true}
										autoHeight={true}
										pinned={width > 769 ? "right" : ""}
										sortable={false}
										filter={false}
									/>
								</AgGridWrapper>
							</TabPane>
							<TabPane tab={t("CANCELLED_MEETINGS")} key={3} active={meetingType == 3}>
								<AgGridWrapper
									type="serverSide"
									onGridReady={setupGroupMeetingsListGrid}
									rowSelection={"multiple"}
									className="member-table"
									immutableData={true}
									frameworkComponents={{ cancelMeetingActionRenderer }}
									overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
								>
									<AgGridColumn
										field="id"
										headerName={"# " + t("ID")}
										width={100}
										pinned={width > 769 ? "left" : ""}
										filter="agNumberColumnFilter"
									/>
									<AgGridColumn field="group_name" headerName={t("GROUP_NAME")} />
									<AgGridColumn field="title" headerName={t("MEETING_TITLE")} />
									<AgGridColumn
										field="meeting_start_date_time"
										headerName={t("DATE_AND_TIME")}
										filter={"agDateColumnFilter"}
										valueFormatter={(data:any) =>
											data?.data.meeting_start_date_time && moment.utc(data?.data.meeting_start_date_time).local().format(displayFormats.DATE_TIME_FORMAT)
										}
										filterParams={{
											comparator: DateComparator,
										}}
									/>
									<AgGridColumn field="location" headerName={t("MEETING_LOCATION_LINK")} />
									{permissions && (permissions.indexOf(AllPermissionList.DELETE_MEETING) >= 0) && <AgGridColumn
										field="id"
										headerName={t('ACTION')}
										cellRenderer="cancelMeetingActionRenderer"
										width={150}
										wrapText={true}
										autoHeight={true}
										pinned={width > 769 ? "right" : ""}
										sortable={false}
										filter={false}
									/>}
								</AgGridWrapper>
							</TabPane>
						</Tabs>
					</div>
				</div>
			</div>
			<Modal
				centered
				visible={deleteMeetingModel}
				onOk={closeDeleteMeetingModal}
				onCancel={closeDeleteMeetingModal}
				className="custom-popup info-popup"
				footer={null}
			>
				<div className="info-text">
					<p>
						{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_MEETING")}
					</p>
				</div>
				<div className="btn">
					<Button className="btn-danger" onClick={submitDeleteMeeting}>{t("DELETE")}</Button>
					<Button className="btn-grey" onClick={closeDeleteMeetingModal}>{t("CANCEL")} </Button>
				</div>
			</Modal>
			<Modal
				centered
				visible={cancelMeetingModel}
				onOk={closeCancelMeetingModal}
				onCancel={closeCancelMeetingModal}
				className="custom-popup info-popup"
				footer={null}
			>
				<div className="info-text">
					<p>
						{t("CANCEL_MEETING_CONFIRMATION")}
					</p>
				</div>
				<div className="btn">
					<Button className="btn-danger" onClick={submitCancelMeeting}>{t("CANCEL_MEETING")}</Button>
					<Button className="btn-grey" onClick={closeCancelMeetingModal}>{t("CANCEL")} </Button>
				</div>
			</Modal>
		</>
	);
});

export default MeetingsList;
