import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const LoanRequest = (t: any): RequestProps => ({
	loan_amount: [
		{ required: true, message: Message(t).required(t("AMOUNT")) },
		({ getFieldValue }) => ({
			validator() {
				const loan_amount = getFieldValue("loan_amount");
				const max_borrowing_limit = getFieldValue("max_borrowing_limit");
				if (loan_amount < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('AMOUNT')));
				}
				if (loan_amount > max_borrowing_limit) {
					return Promise.reject(Message(t).maxIntFloat(max_borrowing_limit, t('AMOUNT')));
				}
				return Promise.resolve();
			},
		}),
		{ pattern: /^\d*(\.\d{0,2})?$/, message: Message(t).pattern.decimal(2) },
	],
	number_of_installments: [
		{ required: true, message: Message(t).required(t("DURATION")) },
		{ min: 1, message: Message(t).min(1, t("DURATION")) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits()},
	],
	description: [
		{ required: true, message: Message(t).required(t("DESCRIPTION")) },
		{ min: 3, message: Message(t).min(3, t('DESCRIPTION')) },
		{ max: 5000, message: Message(t).max(5000, t('DESCRIPTION')) },
		({ getFieldValue }) => ({
			validator() {
				const description = getFieldValue("description");
				if (description && !description.trim()) {
					return Promise.reject(Message(t).required(t('DESCRIPTION')));
				}
				return Promise.resolve();
			},
		}),
	],
	preference:[
		{ required: true, message: Message(t).required(t("PREFERENCE")) },
	]
});
