import React, { useEffect, useState } from "react";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button } from "antd";
import { useHistory, useParams } from "react-router";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import RoleList from "../../../config/RoleList";
import moment from "moment";
import { displayFormats } from "../../../config/Global";
import { TrophyOutlined } from '@ant-design/icons';

const ElectionDetails: React.FC = observer(() => {
	const history = useHistory();
	const { id }: any = useParams();
	const { t } = useTranslation();
	const [presidentElectionData, setPresidentElectionData] = useState([]);
	const [secretaryElectionData, setSecretaryElectionData] = useState([]);
	const [accountantElectionData, setAccountantElectionData] = useState([]);
	const {
		AUTH: { selectedGroup },
		ELECTION: { electionDetail, getElectionDetail, setElectionDetail },
	} = useStore();

	const gridOptions = {
		suppressRowTransform: true,
	};
	const goToElection = () => {
		setElectionDetail();
		history.push("/election");
	};

	useEffect(() => {
		selectedGroup && getElectionDetail(id);
	}, [id, selectedGroup]);

	useEffect(() => {
		const presidentData: any = [];
		const secretaryData: any = [];
		const accountantData: any = [];
		electionDetail && electionDetail.electionCandidates && electionDetail.electionCandidates.map((element: any)=> {
			if(element.role_name == RoleList.PRESIDENT){
				element.cadidates && element.cadidates.map((presidentElement: any)=> {
					if(presidentData.length<=0){
						presidentData.push({designation: element.role_name, candidate: presidentElement.user_name, votes: presidentElement.voteCount, election_result_status: presidentElement.election_result_status });
					} else {
						presidentData.push({designation: "", candidate: presidentElement.user_name, votes: presidentElement.voteCount, election_result_status: presidentElement.election_result_status });
					}
				});
			}
			if(element.role_name == RoleList.SECRETARY){
				element.cadidates && element.cadidates.map((secretaryElement: any)=> {
					if(secretaryData.length<=0){
						secretaryData.push({designation: element.role_name, candidate: secretaryElement.user_name, votes: secretaryElement.voteCount, election_result_status: secretaryElement.election_result_status });
					} else {
						secretaryData.push({designation: "", candidate: secretaryElement.user_name, votes: secretaryElement.voteCount, election_result_status: secretaryElement.election_result_status });
					}
				});
			}
			if(element.role_name == RoleList.ACCOUNTANT){
				element.cadidates && element.cadidates.map((accountantElement: any)=> {
					if(accountantData.length<=0){
						accountantData.push({designation: element.role_name, candidate: accountantElement.user_name, votes: accountantElement.voteCount, election_result_status: accountantElement.election_result_status });
					} else {
						accountantData.push({designation: "", candidate: accountantElement.user_name, votes: accountantElement.voteCount, election_result_status: accountantElement.election_result_status });
					}
				});
			}
		});
		setPresidentElectionData(presidentData);
		setSecretaryElectionData(secretaryData);
		setAccountantElectionData(accountantData);
	}, [electionDetail]);

	const candidateName = (params: any) => {
		return (
			<div>
				{(params.data?.election_result_status == 1) ? <>{params.data.candidate} <TrophyOutlined /></> : params.data.candidate}
			</div>
		);
	};
	

	const columnElection = [
		{ field: "designation", headerName: t("DESIGNATION"), filter: false, sorting: false,},
		{ field: "candidate", headerName: t("CANDIDATES"), filter: false, sorting: false, cellRenderer: "candidateName"},
		{ field: "votes", headerName: t("VOTES"), filter: false, sorting: false,},
	];

	return (
		<>
			<div className="election-details-section">
				<div className="main">
					<div className="heading-title-wrap">
						<div className="title-wrap">
							<h3>{t("ELECTION_TITLE")}: {electionDetail && electionDetail.title}</h3>
						</div>
						<div className="date">
							<h3>{t("DATE")}: {electionDetail && moment.utc(electionDetail.election_date).local().format(displayFormats.DATE_FORMAT)}</h3>
						</div>
					</div>
					{(presidentElectionData && presidentElectionData.length > 0) && <div className="grid-class">
						<AgGridWrapper
							className="member-table"
							rowData={presidentElectionData}							
							columnDefs={columnElection}
							gridOptions={gridOptions}
							overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
							frameworkComponents={{ candidateName }}
							onFilterChanged={({ api }) => {
								if (api.getDisplayedRowCount()) {
									api.hideOverlay();
								} else {
									api.showNoRowsOverlay();
								}
							}}
						/>
					</div>}
					{(secretaryElectionData && secretaryElectionData.length > 0) && <div className="grid-class">
						<AgGridWrapper
							className="member-table"
							rowData={secretaryElectionData}
							columnDefs={columnElection}
							gridOptions={gridOptions}
							overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
							frameworkComponents={{ candidateName }}
							
							onFilterChanged={({ api }) => {
								if (api.getDisplayedRowCount()) {
									api.hideOverlay();
								} else {
									api.showNoRowsOverlay();
								}
							}}
						/>
					</div>}
					{(accountantElectionData && accountantElectionData.length > 0) && <div className="grid-class">
						<AgGridWrapper
							className="member-table"
							rowData={accountantElectionData}
							columnDefs={columnElection}
							gridOptions={gridOptions}
							overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
							frameworkComponents={{ candidateName }}
							onFilterChanged={({ api }) => {
								if (api.getDisplayedRowCount()) {
									api.hideOverlay();
								} else {
									api.showNoRowsOverlay();
								}
							}}
						/>
					</div>}

					{(electionDetail && electionDetail.feedback && electionDetail.feedback !='' ) && <div className="">
						<h3>{t("FEEDBACK")}</h3>
						<p>{electionDetail.feedback}</p>
					</div>}
					<div className="btn btn-wrap justify-center">
						<Button type="primary" onClick={goToElection}>
							{t("BACK_TO_ELECTION")}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
});

export default ElectionDetails;
