import { Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Button, } from "antd";
import profile_icon from "../../../assets/images/profile-icon.svg";
import Email_Icon from "../../../assets/images/Email-Icon.svg";
//import Calling from "../../../assets/images/Calling.svg";
import Lock_icon from "../../../assets/images/lock-icon.svg";
import Calendar from "../../../assets/images/Calendar.svg";
import Lock from "../../../assets/images/Lock.svg";
import useStore from "../../../store";
import moment from "moment";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { RegisterRequest } from "../../../requests/AuthRequest";
import { Link } from "react-router-dom";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { observer } from "mobx-react-lite";
import { displayFormats, genderList } from "../../../config/Global";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import PaymentIcon from "../../../assets/images/money.svg";
import { aesEncryptionPassword } from "../../../config/Global";

const Registration = observer(() => {
	const [form] = Form.useForm();
	const {
		AUTH: { registration, user, socialLoginData, getSocialLoginData, unsetSocialLoginData },
		COMMON: { getProfessionList, professionListData, selectedLanguage, getCurrencyList, currencyListData },
		ROOT: { AssignErrorToInput },
	} = useStore();

	const history = useHistory();
	const [saving, setSaving] = useState<boolean>();
	const [phoneNo, setPhoneNo] = useState<any>();
	const [confirmationModal, setConfirmationModal] = useState(false)
	const [userData, setUserData] = useState<any>()
	const [currency, setCurrency] = useState<any>()
	const { t } = useTranslation();
	const new_user: any = new URLSearchParams(window.location.search).get("new_user");

	useEffect(() => {
		user && history.replace("/")
		getProfessionList();
		getCurrencyList();
		!socialLoginData && getSocialLoginData();
		if (socialLoginData) {
			form.setFieldsValue({
				firstname: socialLoginData.userData.firstName,
				lastname: socialLoginData.userData.lastName,
				email: socialLoginData.userData.email,
				social_id: socialLoginData.userData.id,
				social_login_type: socialLoginData.provider,
			})
		}

		/// Apple login
		if (new_user) {
			form.setFieldsValue({
				firstname: new_user?.name?.firstName,
				lastname: new_user?.name?.lastName,
				email: new_user?.email,
			})
		}
	}, [getProfessionList, user, history, getCurrencyList]);

	const disableStartDate = (current: any) => {
		return current && (current > moment().endOf('day'));
	}

	const handleSubmit = () => {
		setSaving(false);
		selectedLanguage && selectedLanguage.id && (userData.language_id = selectedLanguage.id);
		if (form.getFieldValue('social_login_type') && form.getFieldValue('social_id')) {
			userData.social_login_type = form.getFieldValue('social_login_type');
			userData.social_id = form.getFieldValue('social_id');
		}
		registration(userData).then(() => {
			setSaving(false);
			if (form.getFieldValue('social_login_type') && form.getFieldValue('social_id')) {
				unsetSocialLoginData();
				history.replace("/login");
			} else {
				const username = userData.email ?? userData.phone_number;
				localStorage.setItem('userData', username);
				history.replace("/verify-account");
			}
		}).catch((e: ErrorProps) => {
			const errors = e.errors || e?.data.errors;
			AssignErrorToInput(form, errors);
			setSaving(false);
		});
	};

	const setPhone = (val: any) => {
		const phoneDetails: any = val ? parsePhoneNumber(val) : "";
		setPhoneNo(phoneDetails);
	};

	const closeConfirmationPopup = () => {
		setConfirmationModal(false);
	};

	const handleFinish = async (data: any) => {
		data.date_of_birth = moment(data?.date_of_birth).format("YYYY-MM-DD");
		data.country_code = phoneNo?.country
		data.phone_number = phoneNo?.nationalNumber,
			data.countryCallingCode = "+" + phoneNo?.countryCallingCode

		const currency_name = currencyListData?.filter((item: any) => item.id == data?.currency_id)
		setCurrency(currency_name[0]?.name)
		
		data.password = await aesEncryptionPassword(data?.password);
		data.password_confirmation = await aesEncryptionPassword(data?.password_confirmation);

		form.validateFields().then(() => {
			setConfirmationModal(true)
		}).catch(() => {
			setConfirmationModal(false)
		})
		setUserData(data)
	}

	return (
		<>
			<div className="register-section p-80">
				<div className="main">
					<h3>{t('REGISTRATIONS')}</h3>
					<FormBox form={form} onFinish={handleFinish}>
						<Row>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={profile_icon} alt="Profile" />
									</i>
									<InputBox.Text
										name="firstname"
										placeholder={t('FIRST_NAME')}
										rules={RegisterRequest(t).firstname}
										label={t("FIRST_NAME")}
									/>

								</div>
								<div className="field">
									<i>
										<img src={profile_icon} alt="Profile" />
									</i>
									<InputBox.Text
										name="lastname"
										placeholder={t('LAST_NAME')}
										rules={RegisterRequest(t).lastname}
										label={t("LAST_NAME")}
									/>

								</div>
								<div className="field">
									<i>
										<img src={Email_Icon} alt="Profile" />
									</i>
									<InputBox.Email
										name="email"
										placeholder={t('EMAIL_ID')}
										rules={RegisterRequest(t).email}
										label={t("EMAIL_ID")}
									/>
								</div>
								<div className="field">
									<Form.Item
										name="phone_number"
										rules={RegisterRequest(t).phone_number}
										label={t("PHONE_NUMBER")}
										className="phoneInputContent"
									>
										<PhoneInput
											countrySelectProps={{ unicodeFlags: true }}
											placeholder={t('PHONE_NUMBER')}
											name="phone_number"
											value={phoneNo}
											international
											defaultCountry="IN"
											countryCallingCodeEditable={false}
											onChange={setPhone}
										/>
									</Form.Item>
								</div>
								<div className="field">
									<i>
										<img src={Lock_icon} alt="Profile" />
									</i>
									<InputBox.Password
										name="password"
										placeholder={t('PASSWORD')}
										rules={RegisterRequest(t).password}
										label={t("PASSWORD")}
									/>
								</div>
								<div className="field">
									<i>
										<img src={Lock_icon} alt="Profile" />
									</i>
									<InputBox.Password
										name="password_confirmation"
										placeholder={t('CONFIRM_PASSWORD')}
										rules={RegisterRequest(t).password_confirmation}
										label={t("CONFIRM_PASSWORD")}
									/>
								</div>
							</Col>
							<Col span={12}>
								<div className="field">
									<InputBox.Radio
										options={{
											list: genderList(t),
											valueKey: "id",
											textKey: "value"
										}}
										name="gender"
										label={t('GENDER')}
										rules={RegisterRequest(t).gender}
									/>
								</div>
								{professionListData && <div className="field">
									<i>
										<img src={Lock_icon} alt="Profile" />
									</i>
									<InputBox.Select
										name="profession_id"
										options={{
											list: professionListData,
											valueKey: "id",
											textKey: "name"
										}}
										placeholder={t('PROFESSION')}
										rules={RegisterRequest(t).profession_id}
										label={t("PROFESSION")}
									/>
								</div>}
								<div className="field">
									<i>
										<img src={Calendar} alt="Profile" />
									</i>
									<InputBox.DatePicker
										name="date_of_birth"
										rules={RegisterRequest(t).date_of_birth}
										placeholder={t('DATE_OF_BIRTH')}
										disabledDate={disableStartDate}
										label={t("DATE_OF_BIRTH")}
										format={displayFormats.DATE_FORMAT}
									/>
								</div>
								<div className="field">
									<i>
										<img src={PaymentIcon} alt="Profile" />
									</i>
									<InputBox.Select
										name="currency_id"
										options={{
											list: currencyListData,
											valueKey: "id",
											textKey: "name"
										}}
										placeholder={t('CURRENCY')}
										rules={RegisterRequest(t).currency_id}
										label={t("CURRENCY")}
									/>
								</div>
								<div className="field">
									<i>
										<img src={Lock} alt="Profile" />
									</i>
									<InputBox.TextArea
										name="address"
										rules={RegisterRequest(t).address}
										placeholder={t('ADDRESS')}
										label={t("ADDRESS")}
									/>
								</div>
								<div className="field field-button">
									<Button
										type="primary"
										htmlType="submit"
										className="login-form-button"
										loading={saving}
									>
										{t('SIGN_UP')}
									</Button>
									<div className="sign-in-text">
										{`${t('ALREADY_HAVE_AN_ACCOUNT')} `}
										<Link to="/login" title={t('SIGN_IN_NOW')}>
											{t('SIGN_IN_NOW')}
										</Link>
									</div>
								</div>
							</Col>
						</Row>
						<Modal
							centered
							visible={confirmationModal}
							onOk={closeConfirmationPopup}
							onCancel={closeConfirmationPopup}
							className="custom-popup info-popup"
							footer={null}
						>
							<div className="info-text">
								<p>
									{`${t('REGISTRATION_ALERT_MESSAGE', { currency: currency })}`}
								</p>
							</div>
							<div className="btn">
								<Button className="btn-secondary" onClick={handleSubmit}>{t("OK")}</Button>
								<Button className="btn-grey" onClick={closeConfirmationPopup}>{t("CANCEL")} </Button>
							</div>
						</Modal>
					</FormBox>

				</div>
			</div>
		</>
	);
});

export default Registration;
