import React from "react";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { Button } from "antd";
import trophy from "../../../assets/images/winning-trophy.svg";
import { GrDocumentExcel } from "react-icons/gr";
import { useTranslation } from "react-i18next";

const ElectionResult: React.FC = () => {
	const { t } = useTranslation();

	const gridOptions = {
		suppressRowTransform: true,
	};
	const ActionRenderer = () => {
		return (
			<Button className="edit-button" title="Edit Journal Groups" type="text">
				<img src={trophy} alt="User" />
			</Button>
		);
	};

	const columnElectionPresident = [
		{ field: "sr_no", headerName: "Sr. No", filter: false, sorting: false,},
		{ field: "candidate_name", headerName: "Candidate Name", filter: false, sorting: false,},
		{ field: "designation", headerName: "Designation", filter: false, sorting: false,},
		{ field: "votes_received", headerName: "Votes Received", filter: false, sorting: false,},

	];
	// specify the data
	const rowElectionPresident = [
		{ sr_no:"1", candidate_name: "Neque porro quisquam est ", designation: "President", votes_received: "15", },
		{ sr_no:"2", candidate_name: "Neque porro quisquam est ", designation: "President", votes_received: "10",},
		{ sr_no:"3", candidate_name: "Neque porro quisquam est ", designation: "President", votes_received: "5", },
		
	];
	return (
		<>
			<div className="election-details-section">
				<div className="main">
					<div className="heading-title-wrap">
						<div className="title-wrap">
							<h3>Election Title</h3>
						</div>
						<div className="heading-btn-wrap">
							<Button className="btn-yellow" type="primary">
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>
						</div>
					</div>
					<div className="grid-class">
						<AgGridWrapper
							className="member-table"
							rowData={rowElectionPresident}
							columnDefs={columnElectionPresident}
							gridOptions={gridOptions}
							frameworkComponents={{ ActionRenderer }}
							overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
							onFilterChanged={({ api }) => {
								if (api.getDisplayedRowCount()) {
									api.hideOverlay();
								} else {
									api.showNoRowsOverlay();
								}
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ElectionResult;
