import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class CMSStore {
    public myCms?: any;

    constructor() {
		makeAutoObservable(this);
	}

    // // Setter Functions
	// private setCmsType = (cmsType:any):void => {
	// 	this.myCms = cmsType;
	// }
    public getCMSDetails = (payload: any): Promise<any> => {
        return axios.post(API_URL.CMS.CMS_DETAILS,payload).then(async({ data }) => {
            this.myCms = data.data;
            return data;
        })
    }
}