import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import DonationCampaignComponent from "./DonationCampaign";
import DonationSubjectComponent from "./DonationSubject/";
import DonationComponent from "./Donation/";
import AllPermissionList from "../../../config/AllPermissionList";
import useStore from "../../../store";
import { observer } from "mobx-react";


const DonationSubject: React.FC = observer(() => {
	const { t } = useTranslation();
	const { TabPane } = Tabs;
	const {
		AUTH : { permissions },
		DONATION,
		DONATION_CAMPAIGN,
		DONATION_SUBJECT,
	} = useStore();
	const { setupDonationListGrid, donationListGrid } = DONATION;
	const { setupDonationCampaignListGrid, donationCampaignListGrid } = DONATION_CAMPAIGN;
	const { fetchList } = DONATION_SUBJECT;

	const setModuleType = (key: any) => {
		if(key == 1){
			donationListGrid && setupDonationListGrid(donationListGrid);
		}
		if(key == 2){
			donationCampaignListGrid && setupDonationCampaignListGrid(donationCampaignListGrid);
		}
		if(key == 3){
			fetchList();
		}
	}

	useEffect(() => {
		//
	}, [permissions]);

	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="custom-tabs" style={{ marginTop: 0 }}>
						<Tabs defaultActiveKey="1" onChange={setModuleType} style={{ marginBottom: 32 }} centered>
							<TabPane tab={t('DONATION')} key="1">
								<DonationComponent />
							</TabPane>
							{permissions && permissions.indexOf(AllPermissionList.LIST_DONATION_CAMPAIGN) >= 0 && <TabPane tab={t('DONATION_CAMPAIGN')} key="2">
								<DonationCampaignComponent />
							</TabPane>}
							{permissions && permissions.indexOf(AllPermissionList.LIST_DONATION_SUBJECT) >= 0 && <TabPane tab={t('DONATION_SUBJECT')} key="3">
								<DonationSubjectComponent />
							</TabPane>}
						</Tabs>
					</div>
				</div>
			</div>
		</>
	);
});

export default DonationSubject;

