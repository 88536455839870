import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const CreateGroupExpenseRequest = (t: any): RequestProps => ({
	user_id: [
		{ required: true, message: Message(t).selectRequired(t('MEMBER_NAME')) },
	],
	description: [
		{ required: true, message: Message(t).required(t('DESCRIPTION')) },
		{ min: 3, message: Message(t).min(3, t('DESCRIPTION')) },
		{ max: 5000, message: Message(t).max(5000, t('DESCRIPTION')) },
		({ getFieldValue }) => ({
			validator() {
				const description = getFieldValue("description");
				if (description && !description.trim()) {
					return Promise.reject(Message(t).required(t('DESCRIPTION')));
				}
				return Promise.resolve();
			},
		}),
	],
	amount: [
		{ required: true, message: Message(t).required(t('AMOUNT')) },
		{ pattern: /^\d*(\.\d{0,2})?$/, message: Message(t).pattern.decimal(2) },
		({ getFieldValue }) => ({
			validator() {
				const amount = getFieldValue("amount");
				if (amount < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('AMOUNT')));
				}
				return Promise.resolve();
			},
		}),
	],
	preference:[
		{ required: true, message: Message(t).required(t("PREFERENCE")) },
	]

});

export const CreateMyGroupExpenseRequest = (t: any): RequestProps => ({
	description: [
		{ required: true, message: Message(t).required(t('DESCRIPTION')) },
		{ min: 3, message: Message(t).min(3, t('DESCRIPTION')) },
		{ max: 5000, message: Message(t).max(5000, t('DESCRIPTION')) },
		({ getFieldValue }) => ({
			validator() {
				const description = getFieldValue("description");
				if (description && !description.trim()) {
					return Promise.reject(Message(t).required(t('DESCRIPTION')));
				}
				return Promise.resolve();
			},
		}),
	],
	amount: [
		{ required: true, message: Message(t).required(t('AMOUNT')) },
		{ pattern: /^\d*(\.\d{0,2})?$/, message: Message(t).pattern.decimal(2) },
		({ getFieldValue }) => ({
			validator() {
				const amount = getFieldValue("amount");
				if (amount < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('AMOUNT')));
				}
				return Promise.resolve();
			},
		}),
	],
	preference:[
		{ required: true, message: Message(t).required(t("PREFERENCE")) },
	]
});
