import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { Button, Tabs } from "antd";
import { GrDocumentCsv, GrDocumentExcel } from "react-icons/gr";
import { observer } from "mobx-react";
import useStore from "../../../store";

const AllReports: React.FC = observer(() => {
	const { t } = useTranslation();
	const { TabPane } = Tabs;
	const {
		AUTH: { user },
		USER: { getUserReportList, exportReportData },
	} = useStore();
	const [myReportRows, setMyReportRows] = useState<any>();
	const [groupReportRows, setGroupReportRows] = useState<any>();

	const SwitchRenderer = (params: any) => {
		return (
			<>
				<div>
					<Button
						className="edit-button grey-btn"
						title="csv"
						type="text"
						onClick={() => exportReport(params.data, 'csv')}
					>
						<GrDocumentCsv />
					</Button>
					<Button
						className="edit-button grey-btn"
						title="xlsx"
						type="text"
						onClick={() => exportReport(params.data, 'xlsx')}
					>
						<GrDocumentExcel />
					</Button>
				</div>
			</>
		);
	};

	const exportReport = (data: any, type: string) => {
		const defaultPost = data.defaultPost;
		defaultPost.export_type = type;
		exportReportData(data.url, defaultPost);
	};

	const myReportColumns = [
		{ field: "title", headerName: t("TITLE")},
		{
			field: "Action",
			headerName: t("ACTION"),
			type: "actionColumn",
			cellRenderer: "SwitchRenderer",
			wrapText: true,
			autoHeight: true,
			maxWidth: 150,
		},
	];

	const groupReportColumns = [
		{ field: "title", headerName: t("TITLE")},
		{
			field: "Action",
			headerName: t("ACTION"),
			type: "actionColumn",
			cellRenderer: "SwitchRenderer",
			wrapText: true,
			autoHeight: true,
			maxWidth: 150,
		},
	];

	useEffect(() => {
		user && getUserReportList().then((result: any) => {
			setMyReportRows(result.myReports);
			setGroupReportRows(result.groupReports);
		});
	}, [user])
	

	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("ALL_REPORTS")}</h3>
					</div>
					<div className="sub-custom-tabs">
						<Tabs defaultActiveKey="1" style={{ marginBottom: 32 }}>
							<TabPane tab={t("MY_REPORTS")} key="1">
								<AgGridWrapper
									className="member-table"
									rowData={myReportRows}
									columnDefs={myReportColumns}
									frameworkComponents={{ SwitchRenderer }}
									overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
								/>
							</TabPane>
							<TabPane tab={t("GROUP_RELATED_REPORTS")} key="2">
								<AgGridWrapper
									className="member-table"
									rowData={groupReportRows}
									columnDefs={groupReportColumns}
									frameworkComponents={{ SwitchRenderer }}
									overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
								/>
							</TabPane>
						</Tabs>
					</div>
				</div>
			</div>
		</>
	);
});

export default AllReports;
