import { Button } from "antd";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useStore from "../../../../store";

interface DonationThankYouProps {
	paymentDetail: any;
	donationDetails: any;
}
const DonationThankYou: React.FC<DonationThankYouProps> = observer(
	({ paymentDetail, donationDetails }) => {
		const { t } = useTranslation();
		const history = useHistory();
		const {
			DONATION: { donationPaymentResponse },
		} = useStore();

		const goViewDonation = () => {
			history.push("/donation");
		};

		useEffect(() => {
			switch (paymentDetail.status) {
				case "succeeded":
					donationPaymentResponse(donationDetails.id, {
						paid_status: 1,
						payment_response: paymentDetail,
						payment_from: 3,
					});
					// donationPaymentResponse("Payment succeeded!");
					break;
				case "processing":
					donationPaymentResponse(donationDetails.id, {
						paid_status: 2,
						payment_response: paymentDetail,
						payment_from: 3,
					});
					// donationPaymentResponse("Your payment is processing.");
					break;
				case "requires_payment_method":
					donationPaymentResponse(donationDetails.id, {
						paid_status: 3,
						payment_response: paymentDetail,
						payment_from: 3,
					});
					// donationPaymentResponse("Your payment was not successful, please try again.");
					break;
				default:
					donationPaymentResponse(donationDetails.id, {
						paid_status: 3,
						payment_response: paymentDetail,
						payment_from: 3,
					});
					// donationPaymentResponse("Something went wrong.");
					break;
			}
		}, []);

		return (
			<>
				{paymentDetail && donationDetails && (
					<div className="main">
						<div className="title-overlap title-with-border">
							{(paymentDetail.status == "succeeded" || paymentDetail.status == "processing") && (
								<h3>{t("THANK_YOU_FOR_DONATION")}</h3>
							)}
							{paymentDetail.status != "succeeded" &&
								paymentDetail.status != "processing" && (
									<h3>{t("TRANSACTION_FAILED")}</h3>
								)}
						</div>
						<div className="vote-info">
							{paymentDetail.status == "succeeded" && (
								<h3>{t("YOU_HAVE_DONATED_TO")} :- {donationDetails.group?.name
									? donationDetails.group.name
									: t("VSLA")}</h3>
							)}
							{paymentDetail.status == "processing" && (
								<h3>{t("YOUR_PAYMENT_IS_PROCESSING")}</h3>
							)}
						</div>
						<div className="common-table">
							<table>
								<tbody>
									<tr>
										<td>{t("DONATION_SUBJECT")}</td>
										<td>{donationDetails?.subject.name}</td>
									</tr>
									<tr>
										<td>{t("AMOUNT")}</td>
										<td>{donationDetails?.amount + " " + donationDetails?.payment_currency}</td>
									</tr>
									<tr>
										<td>{t("DONATING_TO")}</td>
										<td>
											{donationDetails.group?.name ?? t("VSLA")}
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div className="btn small-btn text-center">
							<Button
								className="btn-secondary"
								title={t("BACK_TO_DONATION")}
								onClick={goViewDonation}
							>
								{t("BACK_TO_DONATION")}
							</Button>
						</div>
					</div>
				)}
			</>
		);
	}
);

export default DonationThankYou;
