import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { createMeeting, voteCandidateType } from "./ElectionInterface";

export default class ElectionStore {
	private rootStore: RootStore;
	public electionType = 1;
	public electionListGrid: any = {};
	public electionListData?: any;
	public electionDetail?: any;
	public voteViewDetail?: any;
	public electionRefereeElectionListGrid?: any;
	public electionRefereeElectionList?: any;
	public electionRefereeElectionDetail?: any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setElectionType = (electionType:number):void => {
		this.electionType = electionType;
	}
	private setElectionListGrid = (value: any, index: any) => {
		this.electionListGrid[index] = value;
	};
	private setElectionListData = (value: any) => {
		this.electionListData = value;
	};
	private setElectionDetail = (value: any) => {
		this.electionDetail = value;
	};
	private setVoteView = (value: any) => {
		this.voteViewDetail = value;
	};
	private setElectionRefereeElectionListGrid = (value: any) => {
		this.electionRefereeElectionListGrid = value;
	};
	private setElectionRefereeElectionList = (value: any) => {
		this.electionRefereeElectionList = value;
	};
	private setElectionRefereeElectionDetail = (value: any) => {
		this.electionRefereeElectionDetail = value;
	};

	// Grid Setup Functions
	public setupElectionListGrid = (params: GridReadyEvent) => {
		this.setElectionListGrid(params, this.electionType);
		const dataSource = this.getElectionListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(dataSource);
		params.api.sizeColumnsToFit();
	};

	private getElectionListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getElectionList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setElectionListGrid(params, this.electionType);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// Election referee election list
	public setupElectionRefereeElectionListGrid = (params: GridReadyEvent) => {
		this.setElectionRefereeElectionListGrid(params);
		const dataSource = this.getElectionRefereeElectionListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(dataSource);
		params.api.sizeColumnsToFit();
	};

	private getElectionRefereeElectionListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getElectionRefereeElectionList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setElectionRefereeElectionListGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public createElection = (payload: createMeeting): Promise<any> => {
		return axios
			.post(API_URL.ELECTION.CREATE, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public getElectionList = (payload: any): Promise<any> => {
		payload.election_type = this.electionType;
		return axios
			.post(API_URL.ELECTION.LIST, payload)
			.then(({ data }) => {
				this.setElectionListData(data.data);
				return data.data;
			});
	};

	public getElectionDetail = (id: any): Promise<any> => {
		return axios
			.get(API_URL.ELECTION.DETAILS(id))
			.then(({ data }) => {
				this.setElectionDetail(data.data.electionDetail);
				return data.data.electionDetail;
			});
	};

	public voteCandidate = (payload: voteCandidateType): Promise<any> => {
		return axios
			.post(API_URL.ELECTION.VOTE_CANDIDATE, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public getVoteView = (electionId: number): Promise<any> => {
		return axios
			.get(API_URL.ELECTION.VOTE_VIEW(electionId))
			.then(({ data }) => {
				this.setVoteView(data.data);
				return data.data;
			});
	};

	public getElectionRefereeElectionList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.ELECTION_REFEREE.LIST, payload)
			.then(({ data }) => {
				this.setElectionRefereeElectionList(data.data);
				return data.data;
			});
	};

	public getElectionRefereeElectionDetail = (electionId: any): Promise<any> => {
		return axios
			.get(API_URL.ELECTION_REFEREE.DETAILS(electionId))
			.then(({ data }) => {
				this.setElectionRefereeElectionDetail(data.data);
				return data.data;
			});
	};

	public addElectionCandidates = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.ELECTION_REFEREE.ADD_ELECTION_CANDIDATES, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public addElectionFeedback = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.ELECTION_REFEREE.ELECTION_FEEDBACK, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public declareElectionRoleResult = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.ELECTION_REFEREE.ELECTION_RESULT, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public cancelElection = (meetingId: number, end_election_reason: any): Promise<any> => {
		return axios
			.post(API_URL.ELECTION_REFEREE.CANCEL(meetingId), {end_election_reason: end_election_reason})
			.then(({ data }) => {
				return data.data;
			}).finally(() => {
				this.setupElectionRefereeElectionListGrid(this.electionRefereeElectionListGrid);
			});
	};

	// Other
	public changeElectionType = (electionType : number): void => {
		this.setElectionType(electionType);
		this.electionListGrid[this.electionType] && this.setupElectionListGrid(this.electionListGrid[this.electionType]);
	}

	public exportElectionList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.electionListGrid[this.electionType]);
		payLoad.export_type = exportType;
		return this.getElectionList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};
}
