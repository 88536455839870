import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button } from "antd";
import { GoPlus } from "react-icons/go";
import { GrDocumentExcel } from "react-icons/gr";
import { useHistory } from "react-router";
import { observer } from "mobx-react";
import AllPermissionList from "../../../config/AllPermissionList";
import { AgGridColumn } from "ag-grid-react";
import DateComparator from "../../../utils/DateComparator";
import moment from "moment";
import { displayFormats } from "../../../config/Global";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";
import PayConfirm from "../../../utils/PayConfirm";

const FinesList: React.FC = observer(() => {
	const history = useHistory()
	const { t } = useTranslation();

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);
	const [fineId, setFineId] = useState<number>(0);
	const [width, setWidth] = useState(window.innerWidth);

	const {
		AUTH: { permissions },
		FINE: { setupMyFineListGrid, myFinePay, exportMyFineList, myFineListGrid },
	} = useStore();

	const goToAddFines = () => {
		history.push('/add-fines')
	}
	const goViewGroupFines = () => {
		history.push('/view-group-fines')
	}
	const finePayment = () => {
		fineId !== 0 && myFinePay(fineId).then(() => {
			setFineId(0);
		});
	}

	const ActionRenderer = (params: any) => {
		return (
			(params.data?.paid_status == 0) && <div className="action-column">
				<Button style={{ color: "green" }} title={t('PAY')} type="default" onClick={() => setFineId(params.data.id)}>{t("PAY")}</Button>
			</div>
		)
	}

	const exportMyFine = (type: string) => {
		setExportSaving(true);
		exportMyFineList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("FINE")}</h3>
						<div className="heading-btn-wrap">
							{myFineListGrid && myFineListGrid.api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={()=> showExportPopup(true)}>
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>}
							
							<ExportPopupComponent
								callbackExportFunction={exportMyFine}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>

							<Button
								hidden={permissions && permissions.indexOf(AllPermissionList.LIST_FINE) < 0}
								className="btn-yellow"
								type="primary"
								onClick={goViewGroupFines}
							>
								{t("VIEW_GROUP_FINES")}
							</Button>
							<Button
								hidden={permissions && permissions.indexOf(AllPermissionList.ADD_FINE) < 0}
								className="btn-secondary"
								type="primary"
								onClick={goToAddFines}
							>
								{t("ADD_FINES")} <GoPlus />
							</Button>
						</div>
					</div>
					<div className="custom-tabs">
						<AgGridWrapper
							type="serverSide"
							onGridReady={setupMyFineListGrid}
							rowSelection={"multiple"}
							className="member-table"
							immutableData={true}
							frameworkComponents={{ ActionRenderer }}
							overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						>
							<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter" />
							<AgGridColumn field="description" headerName={t('DESCRIPTION')} />
							<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} />
							<AgGridColumn field="fine_type_name" headerName={t('FINE_TYPE')} />
							<AgGridColumn field="fine_amount" headerName={t('AMOUNT')} filter={"agNumberColumnFilter"} />
							<AgGridColumn
								field="fine_due_date"
								headerName={t('DUE_DATE')}
								filter={"agDateColumnFilter"}
								valueFormatter={(data:any) =>
									data?.data.fine_due_date && moment.utc(data?.data.fine_due_date).local().format(displayFormats.DATE_FORMAT)
								}
								filterParams={{
									comparator: DateComparator,
								}}
							/>
							<AgGridColumn field="fine_payment_mode" headerName={t("PAYMENT_MODE")} />
							<AgGridColumn field="display_fine_paid_status" headerName={t('STATUS')} />
							<AgGridColumn
								width={120}
								wrapText={true}
								autoHeight={true}
								field="action"
								headerName={t('ACTION')}
								cellRenderer="ActionRenderer"
								pinned={width > 769 ? "right" : ""}
							/>
						</AgGridWrapper>
						<PayConfirm setVisible={setFineId} visible={fineId} callbackFunction={finePayment} module={t("FINE")} />
					</div>
				</div>
			</div>
		</>
	);
});

export default FinesList;
