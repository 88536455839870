import React, { useState } from "react";
import { Button, Form, Col, Row } from "antd";
import { ChangePasswordWithOtpRequest } from "../../../requests/AuthRequest";
import { useHistory, Link } from "react-router-dom";
import { useParams } from "react-router";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import Lock_icon from "../../../assets/images/lock-icon.svg";
import New_Password_Img from "../../../assets/images/new-password-img.svg";
import { useTranslation } from "react-i18next";
import { aesEncryptionPassword } from "../../../config/Global";

const ChangePasswordWithLink: React.FC = () => {
	const [form]                 = Form.useForm();
	const { otp }: any           = useParams();
	const [saving, setSaving]    = useState<boolean>(false);
	const history                = useHistory();
	const {AUTH, ROOT}           = useStore();
	const {resetPasswordWithLink} = AUTH;
	const {AssignErrorToInput}   = ROOT;
	const { t } = useTranslation();

	const handleSubmit = async(data: any) => {
		data.password=await aesEncryptionPassword(data?.password)
		data.password_confirmation=await aesEncryptionPassword(data?.password_confirmation)

		setSaving(true);
		resetPasswordWithLink(otp, data)
			.then(() => {
				history.replace("/");
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	return (
		<section className="login__section yellow-bg">
			<Row>
				<Col span={12}>
					<div className="section">
						<div className="content-wrap">
							<h1>{t('WELCOME_BACK')}!</h1>
							<p>{t('PLEASE_CHANGE_PASSWORD')}</p>
						</div>
						<div className="screen-img">
							<img src={New_Password_Img} alt="Profile"/>
						</div>
					</div>
				</Col>
				<Col span={12} className="section white-bg">
					<h3><Link to="/login" title="vsla">
						VSLA
					</Link>/ {t('NEW_PASSWORD')}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<div className="field">
							<i>
								<img src={Lock_icon} alt="Password"/>
							</i>
								<InputBox.Password
									name="password"
									placeholder={t('NEW_PASSWORD')}
									size="large"
									label={t('NEW_PASSWORD')}
									rules={ChangePasswordWithOtpRequest(t).new_password}
								/>
						</div>
						<div className="field">
							<i>
								<img src={Lock_icon} alt="Password"/>
							</i>
								<InputBox.Password
									name="password_confirmation"
									placeholder={t('CONFIRM_PASSWORD')}
									label={t('CONFIRM_PASSWORD')}
									rules={ChangePasswordWithOtpRequest(t).confirm_password}
								/>
							<div className="login-form-forgot text-center">
								<p>{t('PLEASE_ENTER_A_NEW_PASSWORD')}.</p>
							</div>
						</div>
						<div className="field">
							<Button
								className="width-150"
								loading={saving}
								type="primary"
								size="large"
								htmlType="submit"
							>
								{t('CHANGE_PASSWORD')}
							</Button>
						</div>
					</FormBox>
				</Col>
			</Row>
		</section>
	);
};

export default ChangePasswordWithLink;
