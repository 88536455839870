import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";
import { UserListDataProps } from "./UserInterface";

export default class UserStore {
	public listData?: UserListDataProps[];
	public userDetails: any;
	public userHomePageDetails: any;
	public userReportList: any;
	private rootStore: RootStore;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: UserListDataProps[]): void => {
		this.listData = value;
	};
	private setUserDetails = (value: any): void => {
		this.userDetails = value;
	};
	private setUserHomePageDetails = (value: any): void => {
		this.userHomePageDetails = value;
	};
	private setUserReportList = (value: any): void => {
		this.userReportList = value;
	};

	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.USER.LIST).then(({ data }) => {
			this.setListData(data.data);
		});
	};

	public getUserDetails = (id: any): Promise<any> => {
		return axios.get(API_URL.USER.DETAILS(id)).then(({ data }) => {
			this.setUserDetails(data.data);
			return data.data;
		});
	};

	public getUserHomePageDetails = (): Promise<any> => {
		return axios.get(API_URL.USER.HOME_PAGE).then(({ data }) => {
			this.setUserHomePageDetails(data.data);
			return data.data;
		});
	};

	public updateMyLanguage = (payLoad: any): Promise<any> => {
		return axios.post(API_URL.USER.UPDATE_MY_LANGUAGE, payLoad).then(({ data }) => {
			return data.data;
		});
	};

	public getUserReportList = (): Promise<any> => {
		return axios.get(API_URL.USER.REPORT_LIST).then(({ data }) => {
			this.setUserReportList(data.data);
			return data.data;
		});
	};

	public exportReportData = (url: string, payLoad: any): Promise<any> => {
		return axios.post(url, payLoad).then(({ data }) => {
			this.rootStore.downloadUrl(data.data.downloadExportUrl)
			return data.data;
		});
	};

	public updateMyDeviceToken = (deviceToken: any): Promise<any> => {
		return axios.post(API_URL.USER.UPDATE_MY_DEVICE_TOKEN, {device_token: deviceToken}).then(({ data }) => {
			return data.data;
		});
	};

	public createMyAlIzzaAccount = (payLoad: any): Promise<any> => {
		return axios.post(API_URL.USER.CREATE_MY_AL_IZZA_ACCOUNT, payLoad).then(({ data }) => {
			return data.data;
		});
	};

	public verifyAlIzzaAccount = (payLoad: any): Promise<any> => {
		return axios.post(API_URL.USER.VERIFY_AL_IZZA_ACCOUNT, payLoad).then(({ data }) => {
			return data.data;
		});
	};
}
