import { observer } from "mobx-react";
import React, { useState } from "react";
import { Button, Form, } from "antd";
import money_note from "../../../../../assets/images/money-note.svg";
// import calling from "../../../../../assets/images/calling-header.svg";
import profile_icon from "../../../../../assets/images/profile-icon.svg";
import union from "../../../../../assets/images/union.svg";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { Col, Row } from "antd";
import { donatingToConfig } from "../../../../../config/Global";
import { DonationRequest } from "../../../../../requests/DonationRequest";
import Email_Icon from "../../../../../assets/images/Email-Icon.svg";
import Country_Icon from "../../../../../assets/images/country_name_Icon.svg";
import LocationIcon from "../../../../../assets/images/location-icon.svg";
import PaymentIcon from "../../../../../assets/images/money.svg";
import ZipcodeIcon from "../../../../../assets/images/zip_code_icon.svg";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';

interface FormDataProps {
	formId: string;
	form: any;
	handleSubmit: (data: any) => void | any;
	saving: boolean;
	isEdit: boolean;
	listRoute: () => void;
	donationSubjectList: [] | any;
	groupList: [] | any;
	paymentFromList: [] | any;
	countryList: [] | any;
	showGroupList: any;
	setShowGroupList: any;
	isDonationCampaign: boolean;
	phoneNo:any;
	setPhoneNo:any;
	user?:any;
}
const FormComponent: React.FC<FormDataProps> = observer(({
	formId,
	form,
	handleSubmit,
	saving,
	listRoute,
	donationSubjectList,
	groupList,
	paymentFromList,
	countryList,
	showGroupList,
	setShowGroupList,
	isDonationCampaign,
	phoneNo,
	setPhoneNo,
	user
}) => {
	const [currency, setCurrency] = useState("");
	
	
	const { t } = useTranslation();	
	const checkGroup =  () => {
		setShowGroupList(form.getFieldValue("donation_type") === 2)
	}
	
	const setPhone = (val: any) => {
		const phoneDetails: any = val ? parsePhoneNumber(val) : "";
		setPhoneNo(phoneDetails);
	};

	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{!isDonationCampaign ? t('ADD_DONATION') : t('DONATION')}</h3>
					<FormBox id={formId} form={form} onFinish={handleSubmit}>
						<Row>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={profile_icon} alt="Profile" />
									</i>
									<InputBox.Text
										name="name"
										placeholder={t('NAME')}
										label={t('NAME')}
										rules={DonationRequest(t).name}
									/>
								</div>
								<div className="field">
									<i>
										<img src={Email_Icon} alt="Profile" />
									</i>
									<InputBox.Text
										name="email"
										placeholder={t("EMAIL_ADDRESS")}
										label={t("EMAIL_ADDRESS")}
										rules={DonationRequest(t).email}
									/>
								</div>

								<div className="field">
									{/* <i>
										<img src={calling} alt="Profile" />
									</i>
									<InputBox.Text
										name="phone_number"
										placeholder={t('PHONE_NUMBER')}
										label={t('PHONE_NUMBER')}
										rules={DonationRequest(t).phone_number}
									/> */}
									<Form.Item
										name="phone_number"
										rules={DonationRequest(t).phone_number}
										label={t("PHONE_NUMBER")}
										className="phoneInputContent"

									>
										<PhoneInput
											countrySelectProps={{ unicodeFlags: true }}
											placeholder={t('PHONE_NUMBER')}
											name="phone_number"
											value={phoneNo}
											international
											defaultCountry="US"
											countryCallingCodeEditable={false}
											onChange={setPhone}

										/>
									</Form.Item>
								</div>
								<div className="field">
									<i>
										<img src={PaymentIcon} alt="Profile" />
									</i>
									<InputBox.Select
										name="payment_from"
										options={{
											list: paymentFromList,
											valueKey: "id",
											textKey: "name"
										}}
										placeholder={t("PAYMENT_FROM")}
										label={t("PAYMENT_FROM")}
										rules={DonationRequest(t).payment_from}
										onChange={(e: any) => {
											paymentFromList.forEach((item: any) => {
												(e == item.id) && setCurrency(e==1?user.currency.symbol:item.currency);
											});
										}}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money_note} alt="Amount" />
									</i>
									<InputBox.Text
										name="amount"
										placeholder={t('AMOUNT')}
										label={`${t('AMOUNT')} ${currency}`}
										rules={DonationRequest(t).amount}
									/>
								</div>
								<div className="field" hidden={isDonationCampaign}>
									<label>{t('DONATING_TO')}</label>
									<InputBox.Radio
										options={{
											list: donatingToConfig(t),
											valueKey: "id",
											textKey: "value"
										}}
										defaultValue={1}
										name="donation_type"
										onChange={() => {
											checkGroup();
										}}
									/>
								</div>
								{showGroupList && <div className="field" hidden={isDonationCampaign}>
									<i>
										<img src={union} alt="Profile" />
									</i>
									<InputBox.Select
										options={{
											list:groupList,
											valueKey:"id",
											textKey:"name"
										}}										
										name="group_id"
										placeholder={t("GROUP_NAME")}
										label={t("GROUP_NAME")}
										rules={DonationRequest(t).group_id}
										showSearch
										optionFilterProp="children"
										filterOption={(input, option: any) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									/>
								</div>}
							</Col>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={LocationIcon} alt="Profile" />
									</i>
									<InputBox.TextArea
										name="address"
										placeholder={t("ADDRESS")}
										label={t("ADDRESS")}
										rules={DonationRequest(t).address}
									/>
								</div>
								<div className="field">
									<i>
										<img src={ZipcodeIcon} alt="Profile" />
									</i>
									<InputBox.Text
										name="zipcode"
										placeholder={t('ZIP_CODE')}
										label={t('ZIP_CODE')}
										rules={DonationRequest(t).zipcode}
									/>
								</div>
								<div className="field">
									<i>
										<img src={Country_Icon} alt="Profile" />
									</i>
									<InputBox.Select
										name="country_id"
										options={{
											list: countryList,
											valueKey: "id",
											textKey: "name"
										}}
										placeholder={t("COUNTRY_NAME")}
										label={t("COUNTRY_NAME")}
										rules={DonationRequest(t).country_id}
										showSearch
										optionFilterProp="children"
										filterOption={(input, option: any) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									/>
								</div>
								<div className="field">
									<i>
										<img src={union} alt="Profile" />
									</i>
									<InputBox.Text
										name="organisation"
										placeholder={t('ORGANIZATION')}
										label={t('ORGANIZATION')}
										rules={DonationRequest(t).organisation}
									/>
								</div>
								<div className="field" hidden={isDonationCampaign}>
									<i>
										<img src={union} alt="Profile" />
									</i>
									<InputBox.Select
										name="donation_subject"
										options={{
											list: donationSubjectList,
											valueKey: "id",
											textKey: "name"
										}}
										placeholder={t("DONATION_SUBJECT")}
										label={t("DONATION_SUBJECT")}
										rules={DonationRequest(t).donation_subject}
									/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<div className="btn btn-wrap text-left">
									<Button type="primary" htmlType="submit" loading={saving} className="btn btn-primary">{t("DONATE")}</Button>
									<Button type="primary" htmlType="submit" key="resetCancel" className="btn btn-danger" onClick={listRoute}>{t("CANCEL")} </Button>
								</div>
							</Col>
						</Row>
					</FormBox>
				</div>
			</div>
		</>
	);
});
export default FormComponent;