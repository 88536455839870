import { makeAutoObservable } from "mobx";
import axios from "axios";
import {
	loginType,
	forgotPasswordType,
	resetPasswordWithOtpType,
	registrationType,
	userType,
	updateProfileType,
	changePasswordType,
} from "./AuthInterface";
import RootStore from "../RootStore/RootStore";
import API_URL from "../../config/ApiUrl";
import SavingGroupStore from "../SavingsGroup/SavingGroupStore";
import CommonStore from "../CommonStore/CommonStore";
import RoleList from "../../config/RoleList";

export default class AuthStore {
	// Variables
	public user?: userType;
	public token?: any;
	public appLoading: boolean;
	public email?: string;
	public otp?: string = "";
	public selectedGroup?:any = null;
	public selectedGroupDetails?:any=null;
	public permissions?:any = [];
	public hasRole?:boolean =false;
	private rootStore: RootStore;
	private groupStore: SavingGroupStore;
	private commonStore: CommonStore;
	public activeKey? : any = [];
	public socialLoginData?: any;
	public userRoleInThisGroup = "";

	constructor(commonStore: CommonStore) {
		this.appLoading = true;
		this.token = localStorage.getItem("token");
		this.rootStore = new RootStore();
		this.groupStore = new SavingGroupStore();
		this.commonStore = commonStore;
		makeAutoObservable(this);
	}

	// Initiate Application Functions
	public initiateApp = async () => {
		this.rootStore.setAxiosBaseUrl();
		await this.rootStore.setAxiosInterceptor();
		this.rootStore.SetNotificationConfig();
		await this.setupHeaders(localStorage.getItem("token"));
	};

	public setupHeaders = async (token?: any) => {
		this.setAppLoading(true);
		await this.rootStore
			.setAxiosHeaders(token)
			.then((token) => {
				this.setToken(token);
				this.fetchAuthUser();
				this.setTempToken(null);
			})
			.catch(() => {
				this.setToken();
				this.setUser();
				this.setAppLoading(false);
			});
	};

	// Setter Functions
	public setActiveKey = (value?: any): void => {
		this.activeKey = value;
	};
	public setUser = (value?: userType): void => {
		this.user = value;
	};
	public setToken = (value?: string): void => {
		this.token = value;
	};
	public setAppLoading = (value: boolean): void => {
		this.appLoading = value;
	};
	public setEmail = (value: string): void => {
		this.email = value;
	};

	private setOtp = (value: string) => {
		this.otp = value;
	};

	private setUserRoleInThisGroup = (value: any): void => {
		this.userRoleInThisGroup = value;
	};

	private setSocialLoginData = (value: string) => {
		// localStorage.setItem('socialLoginData', btoa(JSON.stringify(value)));
		this.socialLoginData = value;
	};

	private unsetSocialLoginData = () => {
		localStorage.removeItem('socialLoginData');
		this.socialLoginData = null;
	};

	public setSelectedGroup = async (value?:any): Promise<any> => {
		this.selectedGroup = value;
		this.rootStore.setGroupId(value);
		if (value) {
			await this.groupStore.getGroupDetails(value).then((data:any) => {
				this.selectedGroupDetails = data;

				this.user?.groups.forEach((element:any) => {
					if (element?.id == value) {
						this.setUserRoleInThisGroup(element?.role?.name ?? "");
					}
				});
			});
		}

		this.setPermissionForUser(value);

		this.rootStore.setGroupId(value);
	}

	public setPermissionForUser = (groupId?:any): void => {
		this.permissions = [];
		this.hasRole=false;

		this.user?.groups.forEach((element:any) => {
			if (groupId && element?.id == groupId && element.role && element.role.rolePermissions) {
				this.permissions = element.role.rolePermissions.map((permissionList:any)=> {
					return permissionList.permission_name
				});
				this.hasRole=true;
			}
		});

		if(this.hasRole==false){
			this.user?.roles.forEach((element:any) => {
				if(element.name == RoleList.ELECTION_REFREE || element.name == RoleList.VILLAGE_AGENT){
					this.permissions = element.rolePermissions.map((permissionList:any)=> {
						return permissionList.permission_name
					});
					this.hasRole=true;
				}
			});
		}
	}

	private setTempToken = (value: any) => {
		value ? localStorage.setItem('tempToken', btoa(value)) : localStorage.removeItem('tempToken');
	};

	// API Functions
	public login = (payload: loginType): Promise<any> => {
		return axios
			.post(API_URL.LOGIN, payload)
			.then(({ data }) => {
				if(data.data.user.status == 1){
					this.setupHeaders(data.data.token);
				}
				return data.data;
			})
			.catch(({ data }) => {
				this.setToken();
				this.setUser();
				return Promise.reject(data);
			});
	};

	public registration = (payload: registrationType): Promise<any> => {
		return axios.post(API_URL.REGISTRATION, payload).then(({ data }) => {
			return data;
		});
	};

	public fetchAuthUser = (): Promise<any> => {
		return axios
			.get(API_URL.ME)
			.then(async ({ data }) => {
				this.setUser(data.data.user);
				this.commonStore.setHomePageNotificationList(data.data.notificationList.data);
				this.commonStore.setNotificationCount(data.data.notificationList.count);
				const selectedGroup = localStorage.getItem('groupId') ||  null;
				(data.data?.user?.groups && data.data?.user?.groups?.length > 0) ? await this.setSelectedGroup(selectedGroup && parseInt(selectedGroup) ?  parseInt(selectedGroup) :  data.data?.user?.groups[0]?.id) : this.setPermissionForUser();
				return data.data;
			})
			.catch((data:any) => {
				this.setToken();
				this.setUser();
				this.setSelectedGroup();
				return Promise.reject(data);
			})
			.finally(() => this.setAppLoading(false));
	};

	public resetPasswordWithOtp = (
		payload: resetPasswordWithOtpType
	): Promise<any> => {
		return axios
			.post(API_URL.RESET_PASSWORD_WITH_OTP(this.getOtp() || ""), payload)
			.then(({ data }) => {
				return data;
			});
	};

	public verifyOtp = (otp: any): Promise<any> => {
		return axios.get(API_URL.VERIFY_OTP(otp)).then(({ data }) => {
			this.setOtp(otp);
			return data;
		});
	};

	public verifyAccount = (requestData: any): Promise<any> => {
		return axios.post(API_URL.VERIFY_ACCOUNT, requestData).then(({ data }) => {
			return data.data;
		});
	};

	public forgotPassword = (payload: forgotPasswordType): Promise<any> => {
		return axios.post(API_URL.FORGOT_PASSWORD, payload).then(({ data }) => {
			return data;
		});
	};

	public changePassword = (payload: changePasswordType): Promise<any> => {
		return axios.post(API_URL.NEW_PASSWORD, payload).then(({ data }) => {
			return data;
		});
	};

	public logout = (): Promise<any> => {
		return axios.post(API_URL.LOGOUT, {}).then(({ data }) => {
			localStorage.removeItem("token");
			this.setSelectedGroup();
			this.setToken();
			this.setUser();
			return data;
		});
	};

	public updateProfile = (data: updateProfileType): Promise<any> => {
		return axios
			.post(API_URL.ME, data)
			.then(({ data }) => {
				// this.setUser(data.data.user);
				return data;
			})
			.catch(({ data }) => {
				// this.setUser();
				return Promise.reject(data);
			})
			.finally(() => this.setAppLoading(false));
	};

	public getOtp = () => {
		return this.otp?.toString();
	};

	public resetPasswordWithLink = (otp:string, payload: resetPasswordWithOtpType): Promise<any> => {
		return axios
			.post(API_URL.RESET_PASSWORD_WITH_LINK(otp), payload)
			.then(({ data }) => {
				return data;
			});
	};

	public verifySocialLogin = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.VERIFY_SOCIAL_LOGIN, payload)
			.then(({ data }) => {
				this.setupHeaders(data.data.token);
				return data;
			});
	};

	public getSocialLoginData = () => {
		// const socialLoginData = localStorage.getItem('socialLoginData') && localStorage.getItem('socialLoginData') != null && localStorage.getItem('socialLoginData') != 'null' ? JSON.parse(atob(localStorage.getItem('socialLoginData') + "")) : null;
		// this.setSocialLoginData(socialLoginData);
		return this.socialLoginData;
	};

	public getTempToken = () => {
		return localStorage.getItem('tempToken') ? atob(localStorage.getItem('tempToken') + "") : null;
	};

	public resendOtp = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.RESEND_OTP, payload)
			.then(({ data }) => {
				return data;
			});
	};
}
