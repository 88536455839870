import React from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import loader from "../assets/images/loader.gif";
interface PayConfirmProps {
	callbackFunction: () => void;
	setVisible: (type: number) => void;
	visible?: number;
	saving?: boolean;
	module: string;
}

const PayConfirm: React.FC<PayConfirmProps> = ({
	callbackFunction,
	setVisible,
	visible,
	saving,
	module,
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			centered
			visible={visible !== 0}
			onCancel={() => setVisible(0)}
			onOk={() => setVisible(0)}
			className="custom-popup info-popup"
			footer={null}
			destroyOnClose={true}
		>
			<div className="info-text">
				<p>{t("ARE_YOU_SURE_YOU_WANT_TO_PAY")} {module}?</p>
			</div>
			<img src={loader} hidden={!saving} />
			<div className="btn">
				<Button
					className="btn-secondary"
					htmlType="button"
					onClick={callbackFunction}
				>
					{t("PAY")}
				</Button>
				<Button className="btn-danger" onClick={() => setVisible(0)}>
					{t("CANCEL")}
				</Button>
			</div>
		</Modal>
	);
};

export default PayConfirm;
