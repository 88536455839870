import { Button } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { displayFormats } from "../../../config/Global";
import useStore from "../../../store";

const VoteThankYou: React.FC = observer(() => {
	const { t } = useTranslation();
	const history = useHistory();
	const { id }: any = useParams();
	const {
		AUTH: { selectedGroup },
		ELECTION: { electionDetail, getElectionDetail, getVoteView, voteViewDetail },
	} = useStore();
	
	const goViewElection = () => {
		history.push('/election')
	}

	useEffect(() => {
		selectedGroup && !electionDetail && getElectionDetail(id);
		selectedGroup && !voteViewDetail && getVoteView(id);
	}, [id, selectedGroup, electionDetail, voteViewDetail]);
	
	return (
		<>
			<section className="thankyou-vote-section">
				<div className="main">
					<div className="title-overlap title-with-border">
						<h3>{t("THANK_YOU_FOR_VOTING")}</h3>
					</div>
					<div className="vote-info">
						<h3>{t("YOU_HAVE_VOTED_FOR")}:</h3>
					</div>
					<div className="common-table">
						<table>
							<tbody>
								{voteViewDetail &&
									voteViewDetail.map((item: any) => {
										return (
											<>
												<tr>
													<td className="role">{item.role_name}</td>
													<td>{item.candidate_name}</td>
												</tr>
											</>
										);
									})}
							</tbody>
						</table>
						<p>
							{t("THE_VOTING_PERIOD_WILL_END_ON")}
							<span> {electionDetail && moment.utc(electionDetail.election_date).local().format(displayFormats.DATE_FORMAT)}</span>
						</p>
						<p>
							{t("PLEASE_CONTACT_THE_ELECTION_REFEREE_FOR_MORE_INFORMATION")}
						</p>
					</div>

					<div className="btn small-btn text-center">
						<Button
							className="btn-primary"
							title={t("BACK_TO_ELECTION")}
							onClick={goViewElection}
						>
							{t("BACK_TO_ELECTION")}
						</Button>
					</div>
				</div>
			</section>
		</>
	);
});

export default VoteThankYou;
