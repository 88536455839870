import React, { useEffect, useState } from "react";
import { Form, Button, Upload, Modal } from "antd";
import { Col, Row } from "antd";
import profile_icon from "../../../assets/images/profile-icon.svg";
import user_light from "../../../assets/images/user-icon-light.svg";
import money from "../../../assets/images/money.svg";
import description from "../../../assets/images/description.svg";
import Calendar from "../../../assets/images/Calendar.svg";
import moment from "moment";
import useStore from "../../../store";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { CreateSavingGroupRequest } from "../../../requests/GroupRequest";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { displayFormats, uploadedFileOnPreview } from "../../../config/Global";
import amount from "../../../../src/assets/images/amount-icon.svg";
import shareCount from "../../../../src/assets/images/number_of_share.svg";
import { useHistory } from "react-router-dom";
import PaymentIcon from "../../../assets/images/money.svg";

const CreateGroupRequest: React.FC = observer(() => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [buyShare, setBuyShare] = useState(false);
	const [Error, setError] = useState(false);
	const [groupImage, setGroupImage] = useState<any>(null);

	const {
		ROOT: { AssignErrorToInput },
		AUTH: { user },
		COMMON: { currencyListData, getCurrencyList, confederationList, fetchConfederationList, setCurrencyListData, paymentListData },
		SAVING_GROUP: { createSavingGroup },
	} = useStore();
	const { t } = useTranslation();
	const history = useHistory();

	const disableDate = (current: any) => {
		return current && current <= moment().add(6, "months");
	};

	const handleSubmit = () => {
		setBuyShare(true);
		form.setFieldsValue({
			share_purchase: form.getFieldValue('max_share_purchase_limit')
		});
		onChangeOfShares(form.getFieldValue('max_share_purchase_limit'));
	};

	useEffect(() => {
		setCurrencyListData([user.currency])
		form.setFieldsValue({ currency_id: user.currency_id })
	}, [form])

	const handleModalSubmit = (data: any) => {
		if(!Error){
			data.preference="0"
		}else{
			data.preference = data?.preference?.toString();
		}
		const formData = new FormData();
		groupImage && formData.append("group_image", groupImage);
		data.name && formData.append("name", data.name);
		data.group_description && formData.append("group_description", data.group_description);
		data.numbers_of_member && formData.append("numbers_of_member", data.numbers_of_member);
		data.borrowing_limit && formData.append("borrowing_limit", data.borrowing_limit);
		data.max_borrowing_time_line && formData.append("max_borrowing_time_line", data.max_borrowing_time_line);
		data.operating_cycle_date && formData.append("operating_cycle_date", moment(data.operating_cycle_date).format("YYYY-MM-DD"));
		data.currency_id && formData.append("currency_id", data.currency_id);
		data.share_price && formData.append("share_price", data.share_price);
		data.max_share_purchase_limit && formData.append("max_share_purchase_limit", data.max_share_purchase_limit);
		data.group_welfare_rate && formData.append("group_welfare_rate", data.group_welfare_rate);
		data.interest_rate && formData.append("interest_rate", data.interest_rate);
		data.share_purchase && formData.append("share_purchase", data.share_purchase);
		data.confederation_id && formData.append("confederation_id", data.confederation_id);
		data.preference && formData.append("preference", data?.preference);

		createSavingGroup(formData)
			.then(() => {
				history.push("/")
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
				setBuyShare(false)
			});
	};

	const onChangeOfShares = (data: any) => {
		form.setFieldsValue({
			shareprice: (parseInt(form.getFieldValue("share_price")) * parseInt(data)) + parseInt(form.getFieldValue("group_welfare_rate")),
		});
		if (form.getFieldValue("walletbalance") >= form.getFieldValue("shareprice") && user?.wallet_balance > 0) {
			setError(true);
		} else {
			setError(false);
		}
	}

	const onFileChange = (info: any) => {
		setGroupImage(info);
		return false;
	}

	const modalClose = () => {
		setBuyShare(false);
		setSaving(false);
		form.setFieldsValue({preference:""})
	}

	useEffect(() => {
		getCurrencyList();
		fetchConfederationList()
	}, [form, user, getCurrencyList]);

	const handleCancel = () => {
		setBuyShare(false)
		form.setFieldsValue({preference:""})
	}
	return (
		<>
			<div className="create-group-section">
				<div className="main">
					<h3>{t("CREATE_SAVING_GROUP")}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<InputBox.Text name="minMembers" initialValue={5} hidden={true} />
						<Row gutter={[50, 50]}>
							<Col span={12}>
								<div className="field">
									<label className="title-lable">{t("GROUP_IMAGE")}</label>
									<div className="upload-field">
										<i>
											<img src={user_light} alt="Profile" />
										</i>
										<span className="title-lable">{t("GROUP_IMAGE")}</span>
										<Form.Item
											name="group_image"
											rules={CreateSavingGroupRequest(t).group_image}
										>
											<Upload
												name="group_image"
												accept="image/png, image/jpeg"
												className="hideeyeicon"
												listType="picture-card"
												multiple={false}
												showUploadList={true}
												maxCount={1}
												beforeUpload={onFileChange}
												onPreview={uploadedFileOnPreview}
											>
												<Button className="browse-btn">{t("BROWSE")}</Button>
											</Upload>
										</Form.Item>
									</div>
								</div>
								<div className="field">
									<i>
										<img src={profile_icon} alt="Profile" />
									</i>
									<InputBox.Text
										name="name"
										placeholder={t("GROUP_NAME")}
										label={t("GROUP_NAME")}
										rules={CreateSavingGroupRequest(t).name}
									/>
								</div>
								<div className="field">
									<i>
										<img src={description} alt="Profile" />
									</i>
									<InputBox.TextArea
										placeholder={t("DESCRIPTION")}
										label={t("DESCRIPTION")}
										name="group_description"
										rules={CreateSavingGroupRequest(t).group_description}
									/>
								</div>
								<div className="field">
									<i>
										<img src={user_light} alt="Profile" />
									</i>
									<InputBox.Text
										name="numbers_of_member"
										placeholder={t("TOTAL_MEMBERS")}
										label={t("TOTAL_MEMBERS")}
										rules={CreateSavingGroupRequest(t).numbers_of_member}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="borrowing_limit"
										placeholder={t("BORROWING_LIMIT")}
										label={t("BORROWING_LIMIT")}
										rules={CreateSavingGroupRequest(t).borrowing_limit}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="max_borrowing_time_line"
										placeholder={t("MAXIMUM_LOAN_DURATION_IN_MONTHS")}
										label={t("MAXIMUM_LOAN_DURATION_IN_MONTHS")}
										rules={CreateSavingGroupRequest(t).max_borrowing_time_line}
									/>
								</div>
							</Col>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={Calendar} alt="Profile" />
									</i>
									<InputBox.DatePicker
										name="operating_cycle_date"
										initialValue={moment().add(6, 'months').add(1, 'day')}
										placeholder={t("OPERATING_CYCLE_DATE")}
										label={t("OPERATING_CYCLE_DATE") + " (" + t("DEFAULT_6_MONTHS_AS_GROUP_CYCLE") + ")"}
										rules={CreateSavingGroupRequest(t).operating_cycle_date}
										disabledDate={disableDate}
										format={displayFormats.DATE_FORMAT}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>

									<InputBox.Select
										disabled
										name="currency_id"
										options={{
											list: currencyListData,
											valueKey: "id",
											textKey: "name",
										}}
										placeholder={t("CURRENCY")}
										label={t("CURRENCY")}
										rules={CreateSavingGroupRequest(t).currency_id}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="share_price"
										placeholder={t("SHARE_PRICE")}
										label={t("SHARE_PRICE")}
										rules={CreateSavingGroupRequest(t).share_price}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="group_welfare_rate"
										placeholder={t("GROUP_WELFARE_RATE")}
										label={t("GROUP_WELFARE_RATE")}
										rules={CreateSavingGroupRequest(t).group_welfare_rate}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="max_share_purchase_limit"
										placeholder={t("MAXIMUM_SHARES")}
										label={t("MAXIMUM_SHARES")}
										rules={CreateSavingGroupRequest(t).max_share_purchase_limit}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="interest_rate"
										placeholder={t("INTEREST_RATE")}
										label={t("INTEREST_RATE")}
										rules={CreateSavingGroupRequest(t).interest_rate}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Select
										options={{
											list: confederationList,
											valueKey: "id",
											textKey: "name"
										}}
										label={t("CONFEDERATION")}
										name="confederation_id"
										placeholder={t("CONFEDERATION")}
										rules={CreateSavingGroupRequest(t).confederation_id}
									/>
								</div>
								<div className="field text-right">
									<Button className="btn btn-primary" htmlType="submit">
										{t("SUBMIT")}
									</Button>
								</div>
							</Col>
						</Row>
					</FormBox>
					{buyShare && (
						<Modal
							title={t("BUY_SHARES")}
							centered
							visible={buyShare}
							onOk={() => setBuyShare(false)}
							onCancel={handleCancel}
							afterClose={() => modalClose()}
							className="custom-popup"
							footer={null}
						>
							<FormBox form={form} onFinish={handleModalSubmit}>
								<Row gutter={[20, 20]}>
									<Col span={12}>
										<div className="field buyShares">
											<small>{t("NUMBER_OF_SHARES")}</small>
											<i>
												<img src={shareCount} alt={t("NUMBER_OF_SHARES")} />
											</i>
											<InputBox.Text
												name="share_purchase"
												initialValue={1}
												onChange={(e: any) => {
													onChangeOfShares(e.target.value);
												}}
												rules={CreateSavingGroupRequest(t).share_purchase}
											/>
										</div>
									</Col>
									<Col span={12}>
										<div className="field buyShares">
											<small>{t("SHARE_PRICE")} + {t("GROUP_WELFARE_RATE")}</small>
											<i>
												<img src={amount} alt={t("SHARE_PRICE")} />
											</i>
											<InputBox.Text
												name="shareprice"
												value={form.getFieldValue("shareprice")}
												disabled={true}
											/>
										</div>
									</Col>
									<Col span={12}>
										<div className="field buyShares">
											<small>{t("WALLET_BALANCE")}</small>
											<i>
												<img src={amount} alt={t("WALLET_BALANCE")} />
											</i>
											<InputBox.Text
												name="walletbalance"
												initialValue={user.wallet_balance}
												disabled={true}
											/>
										</div>
									</Col>
									{Error && <Col span={12}>
										<div className="field">
											<small>{t("WHAT_IS_YOUR_PREFERENCE")}</small>
											<i>
												<img src={PaymentIcon} alt={t("PREFERENCE")} />
											</i>
											<InputBox.Select
												name={"preference"}
												placeholder={t("PREFERENCE")}
												options={{
													list: paymentListData,
													valueKey: "id",
													textKey: "name",
												}}
												rules={CreateSavingGroupRequest(t).preference}
											/>
										</div>
									</Col>}
								</Row>
								<div className="info-msg">
									<p>
										{t(
											Error ? "YOU_HAVE_SUFFICIENT_MONEY_IN_YOUR_DIGITAL_WALLET_BALANCE" : "YOU_NEED_MORE_BALANCE_IN_YOUR_DIGITAL_WALLET_TO_BUY_SHARES"
										)}
									</p>
								</div>
								<div className="btn">
									<Button
										loading={saving}
										className="btn-secondary"
										htmlType="submit"
									>
										{t("BUY")}
									</Button>
									<Button className="btn-danger" onClick={modalClose}>
										{t("CANCEL")}
									</Button>
								</div>
							</FormBox>
						</Modal>
					)}
				</div>
			</div>
		</>
	);
});

export default CreateGroupRequest;
