import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import DeleteComponent from "./components/DeleteComponent";
import useStore from "../../../../store";
import { Button } from "antd";
import { GoPlus } from "react-icons/go";
import { GrDocumentExcel } from "react-icons/gr";
import { useHistory } from "react-router";
import { Col, Row } from "antd";
import AllPermissionList from "../../../../config/AllPermissionList";
import ExportPopupComponent from "../../../../utils/ExportPopupComponent";
const DonationCampaignView: React.FC = observer(() => {
	const pathHistory = useHistory();
	const { t } = useTranslation();

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const {
		AUTH : { user, permissions },
		DONATION_CAMPAIGN : { setupDonationCampaignListGrid,
			remove,
			deleteDonationCampaignID,
			deleteDonationCampaignPopup,
			openDeleteDonationCampaignPopup,
			closeDeleteDonationCampaignPopup,
			exportDonationCampaignList,
			donationCampaignListGrid,
		}
	} = useStore();
	const addRedirect = () => {
		pathHistory.push('/donation-campaign/create');
	}
	const editRedirect = (id: number) => {
		pathHistory.push('/donation-campaign/' + id + '/edit');
	}

	const exportDonationCampaign = (type: string) => {
		setExportSaving(true);
		exportDonationCampaignList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	useEffect(() => {
		//
	}, [user, permissions]);

	return (
		<>
			<Row align="middle">
				<Col span={8}>
					<div className="heading-title-wrap">
						<h3>{t("DONATION_CAMPAIGN")}</h3>
					</div>
				</Col>
				<Col span={16}>
					<div className="heading-btn-wrap">
						{donationCampaignListGrid && donationCampaignListGrid.api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={()=> showExportPopup(true)}>
							{t("GENERATE_REPORT")} <GrDocumentExcel />
						</Button>}
						
						<ExportPopupComponent
							callbackExportFunction={exportDonationCampaign}
							setVisible={showExportPopup}
							visible={exportPopup}
							saving={exportSaving}
						/>
						<Button
							hidden={permissions && permissions.indexOf(AllPermissionList.ADD_DONATION_CAMPAIGN) < 0}
							className="btn-secondary" type="primary" onClick={addRedirect}>
							{t("ADD_DONATION_CAMPAIGN")} <GoPlus />
						</Button>
					</div>
				</Col>
			</Row>

			<ContentBox>
			{permissions && permissions.indexOf(AllPermissionList.LIST_DONATION_CAMPAIGN) >= 0 &&<ListComponent
					setupGrid={setupDonationCampaignListGrid}
					editRedirect={editRedirect}
					openDeletePopup={openDeleteDonationCampaignPopup}
					permissions={permissions}
				/>}
				<DeleteComponent
					DeleteID={deleteDonationCampaignID}
					DeleteData={remove}
					visible={deleteDonationCampaignPopup}
					closeDeleteFinePopup={closeDeleteDonationCampaignPopup}
				/>
			</ContentBox>
		</>
	);
});
export default DonationCampaignView;