import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStore from "../../../store";
import moment from "moment";
import { observer } from "mobx-react";
import { displayFormats } from "../../../config/Global";

const NewsDetail: React.FC = observer(() => {
	const { id }: any = useParams();
	const {
		NEWS: { myNewDetails, getNewsDetails },
	} = useStore();

	useEffect(() => {
		getNewsDetails(id);
	}, [id, getNewsDetails]);

	return (
		<>
			<section className="common-page-section">
				<div className="main blog-pages">
					<div className="heading-title">
						<h4>{myNewDetails && myNewDetails.title}</h4>
					</div>
					<i
						className="post-date"
						dangerouslySetInnerHTML={{
							__html:
								myNewDetails &&
								moment
									.utc(myNewDetails.created_at)
									.local()
									.format(displayFormats.DATE_FORMAT),
						}}
					/>
					{myNewDetails?.news_image && (
						<img className="post-img" src={myNewDetails?.news_image} />
					)}
					<div
						dangerouslySetInnerHTML={{
							__html: myNewDetails && myNewDetails.short_description,
						}}
					/>
					<div
						dangerouslySetInnerHTML={{
							__html: myNewDetails && myNewDetails.description,
						}}
					/>
				</div>
			</section>
		</>
	);
});

export default NewsDetail;
