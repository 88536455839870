import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button } from "antd";
import { GoPlus } from "react-icons/go";
import { GrDocumentExcel } from "react-icons/gr";
import { useHistory } from "react-router";
import AllPermissionList from "../../../config/AllPermissionList";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import moment from "moment";
import DateComparator from "../../../utils/DateComparator";
import Config from "../../../config/Config";
import { observer } from "mobx-react-lite";
import { Cash_Module, displayFormats } from "../../../config/Global";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";


const MyGroupExpenseList: React.FC = observer(() => {
	const { t } = useTranslation();
	const history = useHistory()
	const [width, setWidth] = useState(window.innerWidth);
	const {
		AUTH: { permissions },
		GROUP_EXPENSE: { setupMyGroupExpenseListGrid, exportMyGroupExpenseList, myGroupExpenseListGrid },
	} = useStore();

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const goToAddMyExpense = () => {
		history.push('/my-group-expenses/add-my-expenses')
	}

	const exportMyGroupExpense = (type: string) => {
		setExportSaving(true);
		exportMyGroupExpenseList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("MY_GROUP_EXPENSES")}</h3>
						<div className="btn-wrap">
							{myGroupExpenseListGrid && myGroupExpenseListGrid.api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={() => showExportPopup(true)}>
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>}

							<ExportPopupComponent
								callbackExportFunction={exportMyGroupExpense}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>

							<Button
								hidden={permissions && permissions.indexOf(AllPermissionList.LIST_GROUP_EXPENSE) < 0}
								className="btn-yellow"
								type="primary"
								title={t("VIEW_GROUP_EXPENSE")}
								onClick={() => { history.replace('/group-expenses') }}
							>{t("VIEW_GROUP_EXPENSE")}</Button>
							<Button className="btn-secondary" type="primary" onClick={goToAddMyExpense}>
								{t("ADD_MY_GROUP_EXPENSE")} <GoPlus />
							</Button>
						</div>
					</div>
					<AgGridWrapper
						type="serverSide"
						onGridReady={setupMyGroupExpenseListGrid}
						rowSelection={"multiple"}
						className="member-table"
						immutableData={true}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
					>
						<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter" />
						<AgGridColumn field="description" headerName={t('DESCRIPTION')} width={300} />
						<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} width={150} />
						<AgGridColumn field="amount" headerName={t('AMOUNT')} filter={'agNumberColumnFilter'} />
						<AgGridColumn field="display_approved_status" headerName={t('STATUS')} />
						{Cash_Module && <AgGridColumn field="group_expense_payment_mode" headerName={t('PAYMENT_MODE')} filter={'agTextColumnFilter'} />}
						<AgGridColumn
							field="created_at"
							headerName="Date"
							valueGetter={(data: any) =>
								data?.data?.created_at &&
								moment(data?.data.created_at).format(Config.dateTimeFormat)
							}
							filter={"agDateColumnFilter"}
							valueFormatter={(data: any) =>
								data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
							}
							filterParams={{
								comparator: DateComparator,
							}}
						/>
					</AgGridWrapper>
				</div>
			</div>
		</>
	);
});

export default MyGroupExpenseList;
