import React, { useEffect } from "react";
import { Row, Col, Layout } from "antd";
// import { RightOutlined } from "@ant-design/icons";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
// import CallIcon from "../../../assets/images/call-icon.svg";
// import LocationIcon from "../../../assets/images/location-icon.svg";
// import MessageIcon from "../../../assets/images/message-icon.svg";
import FooterLogo from "../../../assets/images/care_logo.svg";
import { useTranslation } from "react-i18next";
import useStore from "../../../store";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import logo from "../../../assets/images/Earziki_Logo.svg";

interface FooterViewProps {
	visible?: boolean;
}

const FooterView: React.FC<FooterViewProps> = observer(({ visible }) => {
	const { t } = useTranslation();
	const {
		AUTH: { user },
		COMMON: { siteSettings, redirectLinks, cmsList, setCmsList, webCommonData, selectedLanguage }
	} = useStore();

	useEffect(() => {
		webCommonData && selectedLanguage && setCmsList(webCommonData.cmsList[selectedLanguage.id]);
	}, [siteSettings, selectedLanguage, redirectLinks, webCommonData]);

	useEffect(() => {
		//
	}, [cmsList]);

	return !visible ? null : (
		<Layout.Footer className="main__page__header">
			<div>
				{/* <div className="donate-now-section">
					<div className="donate-now-wrap">
						<span>{t('TOGETHER_TO_HELP_THE_WORLD_BETTER')}</span>
						<Link to={'/donation/add'} title={t('DONATE_NOW')}>
							{t('DONATE_NOW')}
						</Link>
					</div>
				</div> */}
				<div className="footer-main">
					<div className="main">
						<Row>
							<Col span={user ? 6 : 8}>
								<div className="item">
									<h4>{t('CONTACT_US')}</h4>
									<ul className="contact-listing">
										<li>
											{/* <i>
												<img src={LocationIcon} alt="Call " />
											</i> */}
											{siteSettings?.address}<br />
										</li>
										<li>
											<a href={"tel: " + siteSettings?.phone_number} title="Call us">
												{/* <i>
													<img src={CallIcon} alt="Call " />
												</i> */}
												{t('PHONE_NUMBER')}: {siteSettings?.phone_number}
											</a>
										</li>
										<li>
											<a href={"mailto:" + siteSettings?.email} title="Main us">
												{/* <i>
													<img src={MessageIcon} alt="Call " />
												</i> */}
												{t('EMAIL_ID')}: {siteSettings?.email}
											</a>
										</li>
									</ul>
									<ul className="social-list">
										{siteSettings?.facebook_url && <li className="facebook">
											<Link to={{ pathname: siteSettings?.facebook_url }} target="_blank" title="Facebook">
												<FaFacebookF />
											</Link>
										</li>}
										{siteSettings?.whatsapp_url && <li className="whatsapp">
											<Link to={{ pathname: siteSettings?.whatsapp_url }} target="_blank" title="WhatsApp">
												<FaWhatsapp />
											</Link>
										</li>}
										{siteSettings?.instagram_url && <li className="instagram">
											<Link to={{ pathname: siteSettings?.instagram_url }} target="_blank" title="Instagram">
												<FaInstagram />
											</Link>
										</li>}
										{siteSettings?.twitter_url && <li className="twitter">
											<Link to={{ pathname: siteSettings?.twitter_url }} target="_blank" title="Twitter">
												<FaTwitter />
											</Link>
										</li>}
										{siteSettings?.linkedin_url && <li className="linkedin">
											<Link to={{ pathname: siteSettings?.linkedin_url }} target="_blank" title="LinkedIn">
												<FaLinkedinIn />
											</Link>
										</li>}
									</ul>
								</div>
							</Col>

							{user && <Col span={6}>
								<div className="item">
									<h4>{t('USEFUL_LINKS')}</h4>
									<ul>
										{redirectLinks?.map((element: any) =>
											<li key={element.link_caption + "redirect"}>
												<a href={element.link_url} title={element.link_caption} >
													{element.link_caption}
												</a>
											</li>
										)
										}
									</ul>
								</div>
							</Col>}
							<Col span={user ? 6 : 8}>
								<div className="item">
									<h4>{t('HELP')}</h4>
									<ul>
										{cmsList && cmsList?.map((element: any, index: number) =>
											<li key={index + "cms"}>
												<Link to={"/" + element.slug} title={element.page_title} >
													{element.page_title}
												</Link>
											</li>
										)
										}
									</ul>
								</div>
							</Col>
							<Col span={user ? 6 : 8}>
								<div className="item-full">
									{siteSettings && selectedLanguage && siteSettings.footer_message[selectedLanguage.local_key]?.title && <div className="logo">
										<Link to="/" title={siteSettings?.project_name}>
											<img src={FooterLogo} alt={siteSettings?.project_name} />
										</Link>
										<div className="footer-logo">
											<Link to="/" title={siteSettings?.project_name}>
												<img src={logo} alt={siteSettings?.project_name} />
											</Link>
										</div>

									</div>}
									<div dangerouslySetInnerHTML={{ __html: siteSettings && selectedLanguage && siteSettings.footer_message[selectedLanguage.local_key]?.description }} />

									{/* <h4>{t('SOCIAL_MEDIA')}</h4> */}
								</div>
							</Col>
						</Row>
					</div>
				</div>
				{/* <div className="copyright-section">
					<div className="main">
						<p>Copyright © {(new Date().getFullYear())} VSLA Pvt. Ltd.</p>
					</div>
				</div> */}
			</div>
		</Layout.Footer>
	);
});

export default FooterView;
