import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";
import { DonationSubjectListDataProps } from "./DonationSubjectIntrface";

export default class DonationSubjectStore {
	public listData?:DonationSubjectListDataProps[];
	public DonationSubject?:DonationSubjectListDataProps;
	private rootStore: RootStore;
	public addDonationSubjectPopup?: boolean;
	public editDonationSubjectPopup?: boolean;
	public editDonationSubjectID?: number;
	public deleteDonationSubjectPopup?: boolean;
	public deleteDonationSubjectID?: number;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	public openAddDonationSubjectPopup = (): void => {
		this.addDonationSubjectPopup = true;
	};
	public closeAddDonationSubjectPopup = (): void => {
		this.addDonationSubjectPopup = false;
	};
	public openEditDonationSubjectPopup = (id: number): void => {
		this.editDonationSubjectPopup = true;
		this.setEditDonationSubjectID(id);
	};
	public closeEditDonationSubjectPopup = (): void => {
		this.editDonationSubjectPopup = false;
		this.setEditDonationSubjectID();
	};
	public openDeleteDonationSubjectPopup = (id: number): void => {
		this.deleteDonationSubjectPopup = true;
		this.setDeleteDonationSubjectID(id);
	};

	public closeDeleteDonationSubjectPopup = (): void => {
		this.deleteDonationSubjectPopup = false;
		this.setDeleteDonationSubjectID();
	};

	private setEditDonationSubjectID = (id?: number): void => {
		this.editDonationSubjectID = id;
	};
	private setDeleteDonationSubjectID = (id?: number): void => {
		this.deleteDonationSubjectID = id;
	};

	private setListData = (value?: DonationSubjectListDataProps[]): void => {
		this.listData = value;
	};

	public setDonationSubjectType = (value?: DonationSubjectListDataProps): void => {
		this.DonationSubject = value;
	};

	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.DONATION_SUBJECT.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION_SUBJECT.CREATE, payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION_SUBJECT.UPDATE(payload.id), payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.DONATION_SUBJECT.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.DONATION_SUBJECT.DELETE(id)).then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

}
