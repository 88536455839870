import { Collapse, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import useStore from "../../../store";
import { useTranslation } from "react-i18next";
import AddMoneyToWallet from "./AddMoneyToWallet";
import WithdrawMoneyFromWallet from "./WithdrawMoneyFromWallet";
import CreateMyAlizzaAccount from "./CreateMyAlizzaAccount";

const WalletSettings = () => {
	const { AUTH } = useStore();
	const { user } = AUTH;
	const { t } = useTranslation();
	const [width, setWidth] = useState(window.innerWidth);
	const { TabPane } = Tabs;
	const { Panel } = Collapse;

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, [user]);

	return (
		<>
			<div className="main mainWalletTitle">
				<h2>{t("WALLET_SETTINGS")}</h2>
			</div>
			<div className="main tabCollapse">
				{width > 769 ? (
					<Tabs tabPosition={"left"} className="tabbingWrap">
						<TabPane tab={t("ADD_MONEY_TO_WALLET")} key="1">
							<div className="two-col-form section-wrap ">
								<div className="main tabContentTitle">
									<AddMoneyToWallet />
								</div>
							</div>
						</TabPane>
						<TabPane tab={t("WITHDRAW_MONEY_FROM_WALLET")} key="2">
							<div className="two-col-form section-wrap">
								<div className="main tabContentTitle">
									<WithdrawMoneyFromWallet />
								</div>
							</div>
						</TabPane>
						{user && (user.has_alizza_account == 0) && <TabPane tab={t("CREATE_AL_IZZA_ACCOUNT")} key="3">
							<div className="two-col-form section-wrap">
								<div className="main tabContentTitle">
									<CreateMyAlizzaAccount />
								</div>
							</div>
						</TabPane>}
					</Tabs>
				) : (
					<Collapse
						defaultActiveKey={["1"]}
						className="walletAccordion"
						expandIconPosition={"right"}
					>
						<Panel header={t("ADD_MONEY_TO_WALLET")} key="1">
							<div className="main tabContentTitle">
								<AddMoneyToWallet />
							</div>
						</Panel>
						<Panel header={t("WITHDRAW_MONEY_FROM_WALLET")} key="2">
							<div className="main tabContentTitle">
								<WithdrawMoneyFromWallet />
							</div>
						</Panel>
						{user && (user.has_alizza_account == 0) &&  <Panel header={t("CREATE_AL_IZZA_ACCOUNT")} key="3">
							<div className="main tabContentTitle">
								<CreateMyAlizzaAccount />
							</div>
						</Panel>}
					</Collapse>
				)}
			</div>
		</>
	);
};

export default WalletSettings;
