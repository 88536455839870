import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button, Form } from "antd";
import { useParams } from "react-router";
import { AgGridColumn } from "ag-grid-react";
import { observer } from "mobx-react";
import moment from "moment";
import { displayFormats } from "../../../config/Global";
import DateComparator from "../../../utils/DateComparator";
import { Link, useLocation } from "react-router-dom";
import PayRequestedLoan from "./PayRequestedLoan";
import PayConfirm from "../../../utils/PayConfirm";

const LoanInstallmentsList: React.FC = observer(() => {
	const { t } = useTranslation();
	const { id }: any = useParams();
	const location = useLocation();
	const [form] = Form.useForm();
	const [rowsUserLoanInstallments, setRowsUserLoanInstallments] = useState<any>([]);
	const [openPayCashModal, setOpenPayCashModal] = useState(false)
	const [installmentData, setInstallmentData] = useState<any>()
	const [displayOtp, setDisplayOtp] = useState(false)
	const [installmentId, setInstallmentId] = useState(0)

	const {
		AUTH: { selectedGroup, fetchAuthUser },
		LOAN: { myLoanRequestDetails, userLoanRequestDetails, myUserLoanRequestDetails, payMyLoanInstallment, payCashInstallment, getUserLoanRequestDetails },
		GENERATE_OTP: { createGeneratedOtp },
		ROOT: { AssignErrorToInput },
	} = useStore();

	const detailUserLoanRequestRenderer = (params: any) => {
		return (
			(params.data?.payment_status == 0) ?
				<Button
					type="default"
					style={{ color: "green" }}
					onClick={() => setInstallmentId(params.data?.id)}
				>
					{t("PAY")}
				</Button> : <div>{t('PAID')}</div>
		);
	};
	const statusRenderer = (params: any) => {
		return (
			(params.data?.payment_status == 0) ?
				<Button
					type="default"
					style={{ color: "green" }}
					onClick={() => {
						setOpenPayCashModal(true);
						setInstallmentData({ ...userLoanRequestDetails, user_name: userLoanRequestDetails.user.firstname + userLoanRequestDetails.user.lastname, installment: params.data, installment_no: params.node.rowIndex + 1 })
					}}
				>
					{t("PAY")}
				</Button> : <div>{t('PAID')}</div>
		);
	};

	const payLoanInstallment = () => {
		installmentId !== 0 && payMyLoanInstallment(installmentId).then(() => {
			setInstallmentId(0);
			getLoanInstallmentDetails();
			fetchAuthUser();
		});
	};

	const getLoanInstallmentDetails = () => {
		location.pathname.includes("view-group-loans") ?
			getUserLoanRequestDetails(id).then((data: any) => {
				setRowsUserLoanInstallments(data?.installments);
			}) :
			myUserLoanRequestDetails(id).then((data: any) => {
				setRowsUserLoanInstallments(data?.installments);
			});
	};

	useEffect(() => {
		selectedGroup && getLoanInstallmentDetails();
	}, [selectedGroup, myUserLoanRequestDetails, getUserLoanRequestDetails]);

	const handleGenerateOtp = () => {
		const payload = { id: installmentData?.installment?.id, type: 3 }
		createGeneratedOtp(payload).then(() => {
			setDisplayOtp(true)
		}).catch((e:any) => {
			setDisplayOtp(false)
			const errors = e?.errors || e?.data.errors;
			AssignErrorToInput(form, errors);
		})
	}

	const handlePayCashInstallment = (data: any) => {
		payCashInstallment(installmentData?.installment?.id, data).then(() => {
			location.pathname.includes("view-group-loans") ?
				getUserLoanRequestDetails(id).then((data: any) => {
					setRowsUserLoanInstallments(data?.installments);
				}) : myUserLoanRequestDetails(id).then((data: any) => {
					setRowsUserLoanInstallments(data?.installments);
				});
			form.resetFields()
			setOpenPayCashModal(false)
			setDisplayOtp(false)
		}).catch((e:any) => {
			const errors = e?.errors || e?.data.errors;
			AssignErrorToInput(form, errors);
		})

	}
	return (
		<>
			{(myLoanRequestDetails || userLoanRequestDetails) && <div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{location.pathname.includes("view-group-loans")?t("LOAN_INSTALLMENTS"):t("MY_LOAN_INSTALLMENTS")}</h3>
						<div className="btn-wrap">
							<div className="text-center btn btn-primary btn-yellow">
								<Link to={"/my-loans"} type="primary">
									{t("BACK")}
								</Link>
							</div>
						</div>

						{
							location.pathname.includes("my-loan") ? <><small>{t('TOTAL_PAID_AMOUNT')} - {myLoanRequestDetails?.paidAmount}</small>
								<small>{t('TOTAL_UNPAID_AMOUNT')} - {myLoanRequestDetails?.unPaidAmount}</small></> : <>
								<small>{t('MEMBER_NAME')} - {userLoanRequestDetails?.user?.firstname + " " + userLoanRequestDetails?.user?.lastname}</small>
								<small>{t('TOTAL_AMOUNT')} - {userLoanRequestDetails?.unPaidAmount}</small>
							</>
						}
					</div>
					<div className="sub-custom-tabs">
						{rowsUserLoanInstallments && <AgGridWrapper
							rowData={rowsUserLoanInstallments}
							className="member-table"
							frameworkComponents={{ detailUserLoanRequestRenderer, statusRenderer }}
							overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
							onFilterChanged={({ api }) => {
								if (api.getDisplayedRowCount()) {
									api.hideOverlay();
								} else {
									api.showNoRowsOverlay();
								}
							}}
						>
							<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} pinned="left" filter="agNumberColumnFilter"
								cellRenderer={(params: any) => {
									const serialNumber = params.node.rowIndex + 1
									return serialNumber
								}}
							/>
							<AgGridColumn
								field="installment_pay_date"
								headerName={t('INSTALLMENT_PAY_DATE')}
								filter={"agDateColumnFilter"}
								valueFormatter={(data: any) =>
									data?.data.installment_pay_date && moment.utc(data?.data.installment_pay_date).local().format(displayFormats.DATE_FORMAT)
								}
								filterParams={{
									comparator: DateComparator,
								}}
							/>
							<AgGridColumn field="installment_amount" headerName={t('INSTALLMENT_AMOUNT')} />
							{
								location.pathname.includes("view-group-loans") &&
								<AgGridColumn
									field="payment_status"
									headerName={t('STATUS')}
									cellRenderer="statusRenderer"
									width={120}
									wrapText={true}
									autoHeight={true}
									pinned={"right"}
									sortable={false}
									filter={false} />
							}
							{location.pathname.includes("my-loan") && <AgGridColumn
								field="payment_status"
								headerName={t('ACTION')}
								cellRenderer="detailUserLoanRequestRenderer"
								width={120}
								wrapText={true}
								autoHeight={true}
								pinned={"right"}
								sortable={false}
								filter={false}
							/>}
						</AgGridWrapper>}
					</div>
					<PayConfirm setVisible={setInstallmentId} visible={installmentId} callbackFunction={payLoanInstallment} module={t("LOAN")} />
					<PayRequestedLoan
						openPayCashModal={openPayCashModal}
						setOpenPayCashModal={setOpenPayCashModal}
						form={form}
						data={installmentData}
						handleGenerateOtp={handleGenerateOtp}
						handleSubmit={handlePayCashInstallment}
						displayOtp={displayOtp}
						setDisplayOtp={setDisplayOtp}
					/>
				</div>
			</div>}
		</>
	);
});

export default LoanInstallmentsList;
