import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { Form, Button } from "antd";
import Calendar from "../../../assets/images/Calendar.svg";
import edit_one from "../../../assets/images/edit-one.svg";
import amount from "../../../assets/images/amount-icon.svg";
import user_light from "../../../assets/images/user-icon-light.svg";
import useStore from "../../../store";
import moment from "moment";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { observer } from "mobx-react";
import { CreateFineRequest } from "../../../requests/GroupRequest";
import { displayFormats } from "../../../config/Global";

const AddFines: React.FC = observer(() => {
	const [form] = Form.useForm();
	const {
		AUTH : { selectedGroup, selectedGroupDetails },
		FINE : { createFine },
		COMMON : { fineTypeList, getFineTypeList, getGroupUser, groupUserList },
	} = useStore();
	const history = useHistory();
	const { t } = useTranslation();
	const pathHistory = useHistory();
	useEffect(() => {
		!fineTypeList && getFineTypeList();
		selectedGroup && !groupUserList && getGroupUser(selectedGroup);
	}, [getFineTypeList, fineTypeList, selectedGroup, groupUserList, getGroupUser]);

	const disableFutureDate = (current: any) => {
		return current && current >= moment().endOf("day");
	};

	const disableDate = (current: any) => {
		return current && current < moment().endOf("day");
	};

	const handleSubmit = (data: any) => {
		data.date = moment(data.date).format("YYYY-MM-DD");
		data.fine_due_date = moment(data.fine_due_date).format("YYYY-MM-DD");
		createFine(data).then(() => {
			history.goBack();
		});
	};
	const listRoute = () => {
		pathHistory.goBack();
	}
	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t('ADD_FINES')}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<Row>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={user_light} alt="Profile" />
									</i>
									{selectedGroupDetails && <InputBox.Text
										disabled={true}
										placeholder={selectedGroupDetails.name}
										label={t('GROUP_NAME')}
									/>}
								</div>
								<div className="field">
									<i>
										<img src={user_light} alt="Profile" />
									</i>
									{groupUserList && groupUserList.users && <InputBox.Select
										name="user_id"
										options={{
											list: groupUserList.users,
											valueKey: "id",
											textKey: "user_name",
										}}
										placeholder={t("MEMBER_NAME")}
										label={t("MEMBER_NAME")}
										rules={CreateFineRequest(t).user_id}
									/>}
								</div>
								<div className="field">
									<i>
										<img src={Calendar} alt="Profile" />
									</i>
									<InputBox.DatePicker
										name="date"
										rules={CreateFineRequest(t).date}
										placeholder={t("DATE")}
										label={t("DATE")}
										disabledDate={disableFutureDate}
										format={displayFormats.DATE_FORMAT}
									/>
								</div>
								<div className="field">
									<i>
										<img src={Calendar} alt="Profile" />
									</i>
									<InputBox.TextArea
										name="description"
										rules={CreateFineRequest(t).description}
										placeholder={t("DESCRIPTION")}
										label={t("DESCRIPTION")}
									/>
								</div>
							</Col>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={edit_one} alt="Profile" />
									</i>

									{fineTypeList && <InputBox.Select
										name="fine_type_id"
										options={{
											list: fineTypeList,
											valueKey: "id",
											textKey: "name",
										}}
										placeholder={t("FINE_TYPE")}
										label={t("FINE_TYPE")}
										rules={CreateFineRequest(t).fine_type_id}
									/>}
								</div>
								<div className="field">
									<i>
										<img src={amount} alt="Amount" />
									</i>
									<InputBox.Text
										name="fine_amount"
										placeholder={t("AMOUNT")}
										label={t("AMOUNT")}
										rules={CreateFineRequest(t).fine_amount}
									/>
								</div>
								<div className="field">
									<i>
										<img src={Calendar} alt="Profile" />
									</i>
									<InputBox.DatePicker
										name="fine_due_date"
										rules={CreateFineRequest(t).fine_due_date}
										placeholder={t("DUE_DATE")}
										label={t("DUE_DATE")}
										disabledDate={disableDate}
										format={displayFormats.DATE_FORMAT}
									/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<div className="btn btn-wrap">
									<Button
										type="primary"
										htmlType="submit"
										className="btn btn-primary"
									>
										{t("SAVE")}
									</Button>
									<Button
										type="primary"
										htmlType="submit"
										className="btn btn-danger"
										onClick={listRoute}
									>
										{t("CANCEL")}
									</Button>
								</div>
							</Col>
						</Row>
					</FormBox>
				</div>
			</div>
		</>
	);
});

export default AddFines;
