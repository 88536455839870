import { Col, Row } from "antd";
import React from "react";
import { Form, Button, } from "antd";
import description from "../../../assets/images/description.svg";
import amount from "../../../assets/images/amount-icon.svg";
import useStore from "../../../store";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { CreateMyGroupExpenseRequest } from "../../../requests/GroupExpenseRequest";
import PaymentIcon from "../../../assets/images/money.svg";
import { Cash_Module } from "../../../config/Global";

const AddMyExpense = () => {
	const [form] = Form.useForm();
	const { GROUP_EXPENSE: { createMyGroupExpense }, COMMON: { paymentListData }, } = useStore();
	const history = useHistory();
	const { t } = useTranslation();

	const handleSubmit = (data: any) => {
		createMyGroupExpense(data).then(() => {
			history.replace("/my-group-expenses");
		});
	};

	const listMyGroupExpense = () => {
		history.replace("/my-group-expenses");
	}

	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t('ADD_MY_GROUP_EXPENSE')}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<Row>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={amount} alt="Amount" />
									</i>
									<InputBox.Text
										name="amount"
										placeholder={t('AMOUNT')}
										label={t('AMOUNT')}
										rules={CreateMyGroupExpenseRequest(t).amount}
									/>
								</div>
								{Cash_Module && <div className="field">
									<i className="iconAlignment">
										<img src={PaymentIcon} alt="Profile" />
									</i>
									<InputBox.Select
										name={"preference"}
										label={t("WHAT_IS_YOUR_PREFERENCE")}
										placeholder={t("PREFERENCE")}
										options={{
											list: paymentListData,
											valueKey: "id",
											textKey: "name",
										}}
										rules={CreateMyGroupExpenseRequest(t).preference}
									/>
								</div>}
							</Col>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={description} alt="Description" />
									</i>
									<InputBox.TextArea
										name="description"
										placeholder={t('DESCRIPTION')}
										label={t('DESCRIPTION')}
										rules={CreateMyGroupExpenseRequest(t).description}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<div className="btn btn-wrap">
									<Button type="primary" htmlType="submit" className="btn btn-primary">{t("SAVE")}</Button>
									<Button type="primary" htmlType="submit" className="btn btn-danger" onClick={listMyGroupExpense}>{t("CANCEL")} </Button>
								</div>
							</Col>
						</Row>

					</FormBox>
				</div>
			</div>
		</>
	);
};

export default AddMyExpense;
