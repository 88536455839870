import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { Button, Modal } from "antd";
import { GrDocumentExcel } from "react-icons/gr";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import moment from "moment";
import DateComparator from "../../../utils/DateComparator";
import Config from "../../../config/Config";
import { observer } from "mobx-react";
import { useHistory } from "react-router";
import { AiOutlineEye } from "react-icons/ai";
import { displayFormats, paymentStatus, paymentType } from "../../../config/Global";
import AllPermissionList from "../../../config/AllPermissionList";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";

const Passbook: React.FC = observer(() => {
	const history = useHistory();

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const {
		AUTH: { permissions },
		WALLET: { setupMyTransactionListGrid, exportMyTransactionList, changeTransactionType, myTransactionListGrid },
		PASSBOOK: { getmyPassbookDetails, myPassbookDetails },
	} = useStore();
	const { t } = useTranslation();
	const [passbookEditModal, setPassbookEditModal,] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);

	const goViewGroupFines = () => {
		history.push('/member-passbook')
	}
	const openPassbookDetailsModal = (params: any) => {
		setPassbookEditModal(true);
		getmyPassbookDetails(params.data?.transaction_id);
	}
	const ActionRenderer = (params: any) => {
		return (
			<Button className="edit-button" title={t("PASSBOOK")} type="text" onClick={() => openPassbookDetailsModal(params)}><AiOutlineEye /></Button>
		)
	}
	const renderPaidStatus = (param: any) => {
		const paidStatus = paymentStatus(t).filter((item: any) => item.id == param)
		return paidStatus[0]?.payment_status
	}

	const renderPaymentType = (param: any) => {
		const payment = paymentType(t).filter((item: any) => item.id == param)
		return payment[0]?.payment_type
	}

	const exportMyTransaction = (type: string) => {
		setExportSaving(true);
		exportMyTransactionList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	useEffect(() => {
		changeTransactionType('my', 0);
	}, []);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("PASSBOOK")}</h3>
						<div className="btn-wrap">
							{myTransactionListGrid && myTransactionListGrid[0] && myTransactionListGrid[0].api.getDisplayedRowCount() > 0 && <Button className="btn-yellow" type="primary" onClick={()=> showExportPopup(true)}>
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>}
							
							<ExportPopupComponent
								callbackExportFunction={exportMyTransaction}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>
							<Button
								hidden={permissions && permissions.indexOf(AllPermissionList.MEMBER_PASSBOOK) < 0}
								className="btn-yellow"
								type="primary"
								onClick={goViewGroupFines}
							>
								{t("MEMBER_PASSBOOK")}
							</Button>
						</div>
					</div>
					<AgGridWrapper
						type="serverSide"
						onGridReady={setupMyTransactionListGrid}
						rowSelection={"multiple"}
						className="member-table"
						immutableData={true}
						frameworkComponents={{ ActionRenderer }}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
					>
						<AgGridColumn field="id" headerName={"# " + t('ID')} width={90} pinned={width > 769 ? "left" : ""} filter="agNumberColumnFilter" />
						<AgGridColumn field="transaction_id" headerName={"# " + t('TRANSACTION_ID')} width={175} />
						<AgGridColumn field="category" headerName={t('CATEGORY')} width={330} />
						<AgGridColumn field="transaction_details" headerName={t('TRANSACTIONS_DETAIL')} width={330} />
						<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} width={200} />
						<AgGridColumn field="amount" headerName={t('AMOUNT')} filter={'agNumberColumnFilter'} width={140} />
						<AgGridColumn field="payment_type" headerName={t("TRANSACTION_TYPE")} />
						<AgGridColumn field="transaction_payment_mode" headerName={t("PAYMENT_MODE")} />
						<AgGridColumn field="display_payment_status" headerName={t('STATUS')} width={120} />
						<AgGridColumn
							field="created_at"
							headerName={t('CREATED_AT')}
							valueGetter={(data: any) =>
								data?.data?.created_at &&
								moment(data?.data.created_at).format(Config.dateTimeFormat)
							}
							filter={"agDateColumnFilter"}
							valueFormatter={(data:any) =>
								data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
							}
							filterParams={{
								comparator: DateComparator,
							}}
						/>
					</AgGridWrapper>
				</div>
			</div>
			<Modal
				centered
				visible={passbookEditModal}
				onOk={() => setPassbookEditModal(false)}
				onCancel={() => setPassbookEditModal(false)}
				className="custom-popup custom-info-modal details-popup"
				footer={null}
			>
				{myPassbookDetails && (
					<div className="details-info ">
						<div className="info-header">
							<div className="info-thumb">
								<div className="title">
									<h3>{t('TRANSACTIONS_DETAIL')}</h3>
								</div>
							</div>
						</div>
						<div className="info-body">
							<div className="col-wrap">
								<div className="title">
									<p><strong>{t('Transaction ID')}</strong>
										{myPassbookDetails[0]?.transaction_id}</p>
								</div>
								<div className="title">
									<p><strong>{t('USER_NAME')}:</strong>
										{myPassbookDetails[0]?.user?.firstname + " " + myPassbookDetails[0]?.user?.lastname}</p>
								</div>
							</div>
							<div className="body-wrap permission__table table__resp">
								<table key="table" width={"100%"}>
									<thead key="thead">
										<tr key="headtr">
											<th key="tr_id">
												{t('TRANSACTION_ID')}
											</th>
											<th key="User_Name">
												{t('USER')}
											</th>
											<th key="Group_Name">
												{t('GROUP')}
											</th>
											<th key="Payment_Status">
												{t('STATUS')}
											</th>
											<th key="CR_DR">
												Cr/Dr
											</th>
											<th key="Amount_details">
												{t('AMOUNT')}
											</th>
										</tr>
									</thead>
									<tbody key={"module"}>
										{myPassbookDetails?.map((item: any) => {
											return (
												<tr key={"tr" + item.id}>
													<td>{item.transaction_id}</td>
													<td>{item.user.firstname + " " + item.user.lastname}</td>
													<td>{item.group?.name}</td>
													<td>{renderPaidStatus(item.payment_status)}</td>
													<td>{renderPaymentType(item.payment_type)}</td>
													<td>{item.amount}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
						<div className="info-footer">

						</div>
					</div>
				)}
			</Modal>
		</>
	);
});

export default Passbook;
