import React, { useEffect, useState } from "react";
import { Form, Button, Upload, Modal } from "antd";
import { Col, Row } from "antd";
import profile_icon from "../../../assets/images/profile-icon.svg";
import user_light from "../../../assets/images/user-icon-light.svg";
import money from "../../../assets/images/money.svg";
import description from "../../../assets/images/description.svg";
import Calendar from "../../../assets/images/Calendar.svg";
import PaymentIcon from "../../../assets/images/money.svg";
import moment from "moment";
import useStore from "../../../store";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { CreateSavingGroupRequest } from "../../../requests/GroupRequest";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { useParams, useHistory } from "react-router"
import { displayFormats, uploadedFileOnPreview } from "../../../config/Global";
//import { toJS } from "mobx";

const EditGroup: React.FC = observer(() => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [buyShare, setBuyShare] = useState(false);
	const [Error, setError] = useState(false);
	const [groupImage, setGroupImage] = useState<any>(null);
	const [groupDetailsInfo, setGroupDetailsInfo] = useState<any>();
	const [editGroupId, setEditGroupId] = useState<any>();
	const {
		ROOT: { AssignErrorToInput },
		AUTH: { user, selectedGroup, },
		COMMON: { currencyListData, getCurrencyList, confederationList, fetchConfederationList,
			setConfederationList,paymentListData },
		SAVING_GROUP: { getGroupDetails, updateGroupDetails },
	} = useStore();
	const { t } = useTranslation();
	const { id }: any = useParams();
	const history = useHistory();
	const [fileList, setFileList] = useState<any>([])

	const handleSubmit = (data: any) => {
		if (groupDetailsInfo.publish_status == 3) {
			setBuyShare(true);
			form.setFieldsValue({
				share_purchase: form.getFieldValue('max_share_purchase_limit')
			});
			onChangeOfShares(form.getFieldValue('max_share_purchase_limit'));
		} else {
			submitData(data);
		}
	};

	const handleModalSubmit = (data: any) => {
		submitData(data);
	};


	const submitData = (data: any) => {
		if(!Error){
			data.preference="0"
		}else{
			data.preference = data?.preference?.toString();
		}
		const formData = new FormData();
		groupImage && formData.append("group_image", groupImage);
		data.name && formData.append("name", data.name);
		data?.interest_rate && formData.append("interest_rate", data?.interest_rate);
		data.group_description && formData.append("group_description", data.group_description);
		data.numbers_of_member && formData.append("numbers_of_member", data.numbers_of_member);
		data.borrowing_limit && formData.append("borrowing_limit", data.borrowing_limit);
		data.max_borrowing_time_line && formData.append("max_borrowing_time_line", data.max_borrowing_time_line);
		data.operating_cycle_date && formData.append("operating_cycle_date", moment(data.operating_cycle_date).format("YYYY-MM-DD"));
		data.currency_id && formData.append("currency_id", data.currency_id);
		data.share_price && formData.append("share_price", data.share_price);
		data.max_share_purchase_limit && formData.append("max_share_purchase_limit", data.max_share_purchase_limit);
		data.group_welfare_rate && formData.append("group_welfare_rate", data.group_welfare_rate);
		data.share_purchase && formData.append("share_purchase", data.share_purchase);
		data.confederation_id && formData.append("confederation_id", data.confederation_id)
		data.preference && formData.append("preference", data?.preference);

		updateGroupDetails(groupDetailsInfo.id, formData)
			.then(() => {
				id ? history.push("/my-group/list") : history.push("/saving-group/details");
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
			});
	};

	const onFileChange = (info: any) => {
		setGroupImage(info);
		return false;
	}

	const disableDate = (current: any) => {
		return current && current <= moment().add(6, "months");
	};

	const onChangeOfShares = (data: any) => {
		form.setFieldsValue({
			shareprice: (parseInt(form.getFieldValue("share_price")) * parseInt(data)) + parseInt(form.getFieldValue("group_welfare_rate")),
		});
		if (form.getFieldValue("walletbalance") >= form.getFieldValue("shareprice") && user?.wallet_balance > 0) {
			setError(true);
		} else {
			setError(false);
		}
	}

	const modalClose = () => {
		setBuyShare(false);
		setSaving(false);
		form.setFieldsValue({preference:""})
	}

	useEffect(() => {
		id ? setEditGroupId(id) : setEditGroupId(selectedGroup);
		!currencyListData && getCurrencyList();
	}, [user, getCurrencyList, id, selectedGroup]);

	useEffect(() => {
		editGroupId && getGroupDetails(editGroupId).then((data: any) => {
			setGroupDetailsInfo(data);
			getCurrencyList()
			//setCurrencyListData([data?.currency])
			data?.confederation && setConfederationList([data?.confederation])
			form.resetFields();
			form.setFieldsValue({
				name: data?.name,
				group_description: data?.group_description,
				numbers_of_member: data?.numbers_of_member,
				minMembers: data?.publish_status == 0 ? 5 : data?.numbers_of_member,
				borrowing_limit: data?.borrowing_limit,
				max_borrowing_time_line: data?.max_borrowing_time_line,
				operating_cycle_date: moment(data?.operating_cycle_date),
				currency_id: data?.currency_id,
				max_share_purchase_limit: data?.max_share_purchase_limit,
				share_price: data?.share_price,
				group_welfare_rate: data?.group_welfare_rate?.toString(),
				interest_rate: data?.interest_rate?.toString(),
				share_purchase: data?.share_purchase,
				confederation_id: data?.confederation_id,
			})
			let list: any = []
			list = [data?.group_image]?.map(
				(image: any) => {
					return {
						uid: "-1",
						name: "",
						status: "done",
						url: image,
						thumbUrl: image,
					};
				}
			),
				data?.group_image && setFileList(list)
		});
	}, [form, editGroupId]);

	return (
		<>
			{groupDetailsInfo && <div className="create-group-section">
				<div className="main">
					<h3>{t("EDIT_GROUP")}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<InputBox.Text name="minMembers" hidden={true} />
						<Row gutter={[50, 50]}>
							<Col span={12}>
								<div className="field">
									<label className="title-lable">{t("GROUP_IMAGE")}</label>
									<div className="upload-field">
										<i>
											<img src={user_light} alt="Profile" />
										</i>
										<span className="title-lable">{t("GROUP_IMAGE")}</span>
										<Upload
											name="group_image"
											accept="image/png, image/jpeg"
											className="hideeyeicon"
											listType="picture-card"
											multiple={false}
											showUploadList={true}
											fileList={fileList && fileList}
											maxCount={1}
											beforeUpload={onFileChange}
											onPreview={uploadedFileOnPreview}
											onChange={(e: any) => {
												setFileList(e.fileList)
											}}
										>
											<Button className="browse-btn">{t("BROWSE")}</Button>
										</Upload>
									</div>
								</div>
								<div className="field">
									<i>
										<img src={profile_icon} alt="Profile" />
									</i>
									<InputBox.Text
										name="name"
										placeholder={t("GROUP_NAME")}
										label={t("GROUP_NAME")}
										rules={CreateSavingGroupRequest(t).name}
									/>
								</div>
								<div className="field">
									<i>
										<img src={description} alt="Profile" />
									</i>
									<InputBox.TextArea
										placeholder={t("DESCRIPTION")}
										label={t("DESCRIPTION")}
										name="group_description"
										rules={CreateSavingGroupRequest(t).group_description}
									/>
								</div>
								<div className="field">
									<i>
										<img src={user_light} alt="Profile" />
									</i>
									<InputBox.Text
										name="numbers_of_member"
										placeholder={t("TOTAL_MEMBERS")}
										label={t("TOTAL_MEMBERS")}
										rules={CreateSavingGroupRequest(t).numbers_of_member}
										disabled={groupDetailsInfo.publish_status != 0 && groupDetailsInfo.publish_status != 3}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="borrowing_limit"
										placeholder={t("BORROWING_LIMIT")}
										label={t("BORROWING_LIMIT")}
										rules={CreateSavingGroupRequest(t).borrowing_limit}
										disabled={groupDetailsInfo.publish_status != 0 && groupDetailsInfo.publish_status != 3}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="max_borrowing_time_line"
										placeholder={t("MAXIMUM_LOAN_DURATION_IN_MONTHS")}
										label={t("MAXIMUM_LOAN_DURATION_IN_MONTHS")}
										rules={groupDetailsInfo.publish_status == 0 || groupDetailsInfo.publish_status == 3 ? CreateSavingGroupRequest(t).max_borrowing_time_line : []}
										disabled={groupDetailsInfo.publish_status != 0 && groupDetailsInfo.publish_status != 3}
									/>
								</div>
							</Col>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={Calendar} alt="Profile" />
									</i>
									<InputBox.DatePicker
										name="operating_cycle_date"
										initialValue={moment().add(6, 'months').add(1, 'day')}
										placeholder={t("OPERATING_CYCLE_DATE")}
										label={t("OPERATING_CYCLE_DATE") + " (" + t("DEFAULT_6_MONTHS_AS_GROUP_CYCLE") + ")"}
										rules={(groupDetailsInfo.publish_status == 0 || groupDetailsInfo.publish_status == 3) ? CreateSavingGroupRequest(t).operating_cycle_date : []}
										disabledDate={disableDate}
										disabled={groupDetailsInfo.publish_status != 0 && groupDetailsInfo.publish_status != 3}
										format={displayFormats.DATE_FORMAT}
									/>
								</div>
								<div className="field">
									<i>
										<img src={PaymentIcon} alt="Profile" />
									</i>

									<InputBox.Select
										name="currency_id"
										options={{
											list: currencyListData,
											valueKey: "id",
											textKey: "name",
										}}
										placeholder={t("CURRENCY")}
										label={t("CURRENCY")}
										rules={CreateSavingGroupRequest(t).currency_id}
										disabled
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="share_price"
										placeholder={t("SHARE_PRICE")}
										label={t("SHARE_PRICE")}
										rules={CreateSavingGroupRequest(t).share_price}
										disabled={groupDetailsInfo.publish_status != 3}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="group_welfare_rate"
										placeholder={t("GROUP_WELFARE_RATE")}
										label={t("GROUP_WELFARE_RATE")}
										rules={CreateSavingGroupRequest(t).group_welfare_rate}
										disabled={groupDetailsInfo.publish_status != 3}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="max_share_purchase_limit"
										placeholder={t("MAXIMUM_SHARES")}
										label={t("MAXIMUM_SHARES")}
										rules={CreateSavingGroupRequest(t).max_share_purchase_limit}
										disabled={groupDetailsInfo.publish_status != 3}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Text
										name="interest_rate"
										placeholder={t("INTEREST_RATE")}
										label={t("INTEREST_RATE")}
										rules={CreateSavingGroupRequest(t).interest_rate}
										disabled={groupDetailsInfo.publish_status != 0 && groupDetailsInfo.publish_status != 3}
									/>
								</div>
								<div className="field">
									<i>
										<img src={money} alt="Profile" />
									</i>
									<InputBox.Select
										options={{
											list: confederationList ?? [],
											valueKey: "id",
											textKey: "name"
										}}
										onFocus={() => fetchConfederationList()}
										label={t("CONFEDERATION")}
										name="confederation_id"
										placeholder={t("CONFEDERATION")}
										rules={CreateSavingGroupRequest(t).confederation_id}
									/>
								</div>
								<div className="field text-right">
									<Button className="btn btn-primary" htmlType="submit">
										{t("SUBMIT")}
									</Button>
								</div>
							</Col>
						</Row>
					</FormBox>
					{buyShare && (
						<Modal
							title={t("BUY_SHARES")}
							centered
							visible={buyShare}
							onOk={() => setBuyShare(false)}
							onCancel={() => {setBuyShare(false);form.setFieldsValue({preference:""})}}
							afterClose={() => modalClose()}
							className="custom-popup"
							footer={null}
						>
							<FormBox form={form} onFinish={handleModalSubmit}>
								<Row gutter={[20, 20]}>
									<Col span={12}>
										<div className="field">
											<small>{t("NUMBER_OF_SHARES")}</small>
											<InputBox.Text
												name="share_purchase"
												initialValue={1}
												onChange={(e: any) => { onChangeOfShares(e.target.value) }}
												rules={CreateSavingGroupRequest(t).share_purchase}
											/>
										</div>
									</Col>
									<Col span={12}>
										<div className="field">
											<small>{t("SHARE_PRICE")} + {t("GROUP_WELFARE_RATE")}</small>
											<InputBox.Text name="shareprice" value={form.getFieldValue("shareprice")} disabled={true} />
										</div>
									</Col>
									<Col span={12}>
										<div className="field">
											<small>{t("WALLET_BALANCE")}</small>
											<InputBox.Text name="walletbalance" initialValue={user.wallet_balance} disabled={true} />
										</div>
									</Col>
									{Error && <Col span={12}>
										<div className="field">
											<small>{t("WHAT_IS_YOUR_PREFERENCE")}</small>
											<i>
												<img src={PaymentIcon} alt={t("PREFERENCE")} />
											</i>
											<InputBox.Select
												name={"preference"}
												placeholder={t("PREFERENCE")}
												options={{
													list: paymentListData,
													valueKey: "id",
													textKey: "name",
												}}
												rules={CreateSavingGroupRequest(t).preference}
											/>
										</div>
									</Col>}
								</Row>
									<div className="info-msg">
										<p>
											{t(
												Error ? "YOU_HAVE_SUFFICIENT_MONEY_IN_YOUR_DIGITAL_WALLET_BALANCE" : "YOU_NEED_MORE_BALANCE_IN_YOUR_DIGITAL_WALLET_TO_BUY_SHARES"
											)}
										</p>
									</div>
								<div className="btn">
									<Button loading={saving} className="btn-secondary" htmlType="submit">
										{t("BUY")}
									</Button>
									<Button className="btn-danger" onClick={modalClose}>{t("CANCEL")}</Button>
								</div>
							</FormBox>
						</Modal>
					)}
				</div>
			</div>}
		</>
	);
});

export default EditGroup;
