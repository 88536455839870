import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const MeetingRequest = (t: any): RequestProps => ({
	title: [
		{ required: true, message: Message(t).required(t("MEETING_TITLE")) },
		{ min: 3, message: Message(t).min(3, t('MEETING_TITLE')) },
		{ max: 255, message: Message(t).max(5000, t('MEETING_TITLE')) },
		({ getFieldValue }) => ({
			validator() {
				const title = getFieldValue("title");
				if (title && !title.trim()) {
					return Promise.reject(Message(t).required(t('MEETING_TITLE')));
				}
				return Promise.resolve();
			},
		}),
	],
	description: [
		{ required: true, message: Message(t).required(t("DESCRIPTION")) },
		{ min: 3, message: Message(t).min(3, t('DESCRIPTION')) },
		{ max: 5000, message: Message(t).max(5000, t('DESCRIPTION')) },
		({ getFieldValue }) => ({
			validator() {
				const description = getFieldValue("description");
				if (description && !description.trim()) {
					return Promise.reject(Message(t).required(t('DESCRIPTION')));
				}
				return Promise.resolve();
			},
		}),
	],
	meeting_start_date_time : [{ required: true, message: Message(t).required(t("START_DATE_TIME")) }],
	meeting_end_date_time : [{ required: true, message: Message(t).required(t("END_DATE_TIME")) }],
	type: [{ required: true, message: Message(t).selectRequired(t("MEETING_TYPE")) }],
	location: [
		{ required: true, message: Message(t).required(t("MEETING_LOCATION_LINK")) },
		({ getFieldValue }) => ({
			validator() {
				const location = getFieldValue("location");
				if (location && !location.trim()) {
					return Promise.reject(Message(t).required(t('MEETING_LOCATION_LINK')));
				}
				return Promise.resolve();
			},
		}),
	]
});

export const MeetingSummaryRequest = (t: any): RequestProps => ({
	summary: [
		{ required: true, message: Message(t).required(t("MEETING_SUMMARY")) },
		{ min: 3, message: Message(t).min(3, t('MEETING_SUMMARY')) },
		{ max: 5000, message: Message(t).max(5000, t('MEETING_SUMMARY')) },
		({ getFieldValue }) => ({
			validator() {
				const summary = getFieldValue("summary");
				if (summary && !summary.trim()) {
					return Promise.reject(Message(t).required(t('MEETING_SUMMARY')));
				}
				return Promise.resolve();
			},
		}),
	],
});

export const ZoomMeetingDetailsRequest = (t: any): RequestProps => ({
	zoom_key: [{ required: true, message: Message(t).required(t("CLIENT_ID")) }],
	zoom_secrete: [{ required: true, message: Message(t).required(t("CLIENT_SECRET_KEY")) }],
});

export const MeetingRemarkRequest = (t: any): RequestProps => ({
	election: [
		{ required: true, message: Message(t).required(t("CANDIDATES")) },
	],
});
