import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import useStore from "../../../store";
import RoleList from "../../../config/RoleList";
import { AgGridColumn } from "ag-grid-react";

const Vote: React.FC = observer(() => {
	const { t } = useTranslation();
	const history = useHistory();
	const { id }: any = useParams();
	const [presidentElectionData, setPresidentElectionData] = useState([]);
	const [secretaryElectionData, setSecretaryElectionData] = useState([]);
	const [accountantElectionData, setAccountantElectionData] = useState([]);
	const [disableFinishBtn, setDisableFinishBtn] = useState(false);
	const {
		AUTH: { selectedGroup },
		ELECTION: { electionDetail, getElectionDetail, voteCandidate },
	} = useStore();

	const goViewVoteThankyou = () => {
		history.push("/election/"+id+"/vote-thank-you");
	};

	const selectPresidentVote = (params: any) => {
		voteCandidate({election_id: params.data.election_id, election_role_id: params.data.election_role_id, election_candidate_id: params.data.election_candidate_id}).then(() => {
			getElectionDetail(id).then(() => {
				checkVotingDone();
			});
		});
	};
	const selectSecretaryVote = (params: any) => {
		voteCandidate({election_id: params.data.election_id, election_role_id: params.data.election_role_id, election_candidate_id: params.data.election_candidate_id}).then(() => {
			getElectionDetail(id).then(() => {
				checkVotingDone();
			});
		});
	};
	const selectAccountantVote = (params: any) => {
		voteCandidate({election_id: params.data.election_id, election_role_id: params.data.election_role_id, election_candidate_id: params.data.election_candidate_id}).then(() => {
			getElectionDetail(id).then(() => {
				checkVotingDone();
			});
		});
	};

	const actionPresidentRenderer = (props: any) => {
		return (
			<div>
				<div className="ag-grid-action-btn">
					<Button
						className="btn-primary"
						disabled={props.data.isDisabled}
						title={t("VOTE")}
						type="text"
						onClick={() => selectPresidentVote(props)}
					>
						{t("VOTE")}
					</Button>
				</div>
			</div>
		);
	};

	const actionSecretaryRenderer = (props: any) => {
		return (
			<div>
				<div className="ag-grid-action-btn">
					<Button
						className="btn-primary"
						disabled={props.data.isDisabled}
						title={t("VOTE")}
						type="text"
						onClick={() => selectSecretaryVote(props)}
					>
						{t("VOTE")}
					</Button>
				</div>
			</div>
		);
	};

	const actionAccountantRenderer = (props: any) => {
		return (
			<div className="ag-grid-action-btn">
				<Button
					className="btn-primary"
					disabled={props.data.isDisabled}
					title={t("VOTE")}
					type="text"
					onClick={() => selectAccountantVote(props)}
				>
					{t("VOTE")}
				</Button>
			</div>
		);
	};

	const checkVotingDone = () => {
		if(electionDetail && electionDetail.electionCandidates){
			const totalElectionVotingCandidates = presidentElectionData.concat(secretaryElectionData, accountantElectionData).filter((element: any) => element.isDisabled==true);
			const totalElectionCandidates = presidentElectionData.concat(secretaryElectionData, accountantElectionData);
			if(totalElectionVotingCandidates.length == totalElectionCandidates.length){
				setDisableFinishBtn(false);
			} else {
				setDisableFinishBtn(true);
			}
		}
	};

	useEffect(() => {
		setDisableFinishBtn(true);

		const presidentData: any = [];
		const secretaryData: any = [];
		const accountantData: any = [];
		electionDetail && electionDetail.electionCandidates && electionDetail.electionCandidates.map((element: any)=> {
			if(element.role_name == RoleList.PRESIDENT){
				element.cadidates && element.cadidates.map((presidentElement: any)=> {
					presidentData.push({user_id: presidentElement.user_id, election_candidate_id: presidentElement.election_candidate_id, election_role_id: element.election_role_id, election_id: electionDetail.id, name: presidentElement.user_name, isDisabled: element.isVotedByCurrentUser });
				});
			}
			if(element.role_name == RoleList.SECRETARY){
				element.cadidates && element.cadidates.map((secretaryElement: any)=> {
					secretaryData.push({user_id: secretaryElement.user_id, election_candidate_id: secretaryElement.election_candidate_id, election_role_id: element.election_role_id, election_id: electionDetail.id, name: secretaryElement.user_name, isDisabled: element.isVotedByCurrentUser });
				});
			}
			if(element.role_name == RoleList.ACCOUNTANT){
				element.cadidates && element.cadidates.map((accountantElement: any)=> {
					accountantData.push({user_id: accountantElement.user_id, election_candidate_id: accountantElement.election_candidate_id, election_role_id: element.election_role_id, election_id: electionDetail.id, name: accountantElement.user_name, isDisabled: element.isVotedByCurrentUser });
				});
			}
		});

		setPresidentElectionData(presidentData);
		setSecretaryElectionData(secretaryData);
		setAccountantElectionData(accountantData);
	}, [electionDetail]);	

	useEffect(() => {
		checkVotingDone();
	}, [presidentElectionData, secretaryElectionData, accountantElectionData]);

	useEffect(() => {
		selectedGroup && getElectionDetail(id);
	}, [id, selectedGroup]);
	
	
	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">

					{/* President */}
					{(presidentElectionData && presidentElectionData.length > 0) && <><div className="heading-title-wrap">
						<h3>{t("PRESIDENT")}</h3>
					</div>
					
					<AgGridWrapper
						className="member-table"
						rowData={presidentElectionData}
						frameworkComponents={{actionPresidentRenderer}}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						onFilterChanged={({ api }) => {
							if (api.getDisplayedRowCount()) {
								api.hideOverlay();
							} else {
								api.showNoRowsOverlay();
							}
						}}
					>
						<AgGridColumn field="user_id" headerName={"# " + t("ID")}
							pinned="left" filter="agNumberColumnFilter"
						/>
						<AgGridColumn field="name" headerName={t("NAME")} />
						<AgGridColumn
							width={150}
							field="action"
							wrapText={true}
							autoHeight={true}
							headerName={t("VOTE")}
							cellRenderer="actionPresidentRenderer"
							pinned="right"
							filter={false}
						/>
					</AgGridWrapper></>}

					{/* Secretary */}
					{(secretaryElectionData && secretaryElectionData.length > 0) && <><div className="heading-title-wrap">
						<h3>{t("SECRETARY")}</h3>
					</div>

					<AgGridWrapper
						className="member-table"
						rowData={secretaryElectionData}
						frameworkComponents={{actionSecretaryRenderer}}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						onFilterChanged={({ api }) => {
							if (api.getDisplayedRowCount()) {
								api.hideOverlay();
							} else {
								api.showNoRowsOverlay();
							}
						}}
					>
						<AgGridColumn field="user_id" headerName={"# " + t("ID")}
							pinned="left" filter="agNumberColumnFilter"
						/>
						<AgGridColumn field="name" headerName={t("NAME")} />
						<AgGridColumn
							width={150}
							wrapText={true}
							autoHeight={true}
							field="action"
							headerName={t("VOTE")}
							cellRenderer="actionSecretaryRenderer"
							pinned="right"
							filter={false}
						/>
					</AgGridWrapper></>}

					{/* Accountant */}
					{(accountantElectionData && accountantElectionData.length > 0) && <><div className="heading-title-wrap">
						<h3>{t("ACCOUNTANT")}</h3>
					</div>

					<AgGridWrapper
						className="member-table"
						rowData={accountantElectionData}
						frameworkComponents={{actionAccountantRenderer}}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						onFilterChanged={({ api }) => {
							if (api.getDisplayedRowCount()) {
								api.hideOverlay();
							} else {
								api.showNoRowsOverlay();
							}
						}}
					>
						<AgGridColumn field="user_id" headerName={"# " + t("ID")}
							pinned="left" filter="agNumberColumnFilter"
						/>
						<AgGridColumn field="name" headerName={t("NAME")} />
						<AgGridColumn
							width={150}
							wrapText={true}
							autoHeight={true}
							field="action"
							headerName={t("VOTE")}
							cellRenderer="actionAccountantRenderer"
							pinned="right"
							filter={false}
						/>
					</AgGridWrapper></>}

					<div className="action-btn text-right">
						<Button
							disabled={disableFinishBtn}
							className="btn-secondary"
							title={t("FINISH")}
							type="text"
							onClick={goViewVoteThankyou}
						>
							{t("FINISH")}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
});

export default Vote;
