import React, { useState } from "react";
import { Form, Button } from "antd";
import description from "../../../assets/images/description.svg";
import profile_icon from "../../../assets/images/profile-icon.svg";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import useStore from "../../../store";
import { useHistory } from "react-router";
import Email_Icon from "../../../assets/images/Email-Icon.svg";
import Calling from "../../../assets/images/Calling.svg";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { ContactUsRequest } from "../../../requests/ContactUsRequest";

const Feedback: React.FC = () => {
	const [form] = Form.useForm();
	const { COMMON, ROOT } = useStore();
	const { getFeedBackData } = COMMON;
	const { AssignErrorToInput } = ROOT;
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { t } = useTranslation();

	const HandleSubmit = (data: any) => {
		setSaving(true);
		data.type = 0;
		getFeedBackData(data)
			.then(() => {
				setTimeout(() => {
					history.go(0);
				}, 1000);
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	return (
		<>
			<section className="common-page-section">
				<div className="main">
					<div className="heading-title">
						<h4>
							{t("FEEDBACK")} 
							{/* <span></span> */}
						</h4>
					</div>
					<p>
						Lorem Ipsum has been the industrys standard dummy text ever since
						the 1500s, when an unknown printer took a galley of type and
						scrambled it to make a type specimen book. It has survived not only
						five centuries, but also the leap into electronic Ipsum passages,
						and more recently with desktop publishing software like Aldus
						PageMaker including Ipsum.
					</p>
					<div className="form-wrap">
						<FormBox form={form} onFinish={HandleSubmit}>
							<div className="field">
								<i>
									<img src={profile_icon} alt="Profile" />
								</i>
								<InputBox.Text
									name="name"
									placeholder={t("NAME")}
									label={t("NAME")}
									required
									rules={ContactUsRequest(t).name}
								/>
							</div>
							<div className="field">
								<i>
									<img src={Email_Icon} alt="Profile" />
								</i>
								<InputBox.Text
									name="email"
									placeholder={t("EMAIL")}
									label={t("EMAIL")}
									required
									rules={ContactUsRequest(t).email}
								/>
							</div>
							<div className="field">
								<i>
									<img src={Calling} alt="Profile" />
								</i>
								<InputBox.Text
									name="phone_number"
									placeholder={t("PHONE_NUMBER")}
									label={t("PHONE_NUMBER")}
									required
									rules={ContactUsRequest(t).phone_number}
								/>
							</div>
							<div className="field">
								<i>
									<img src={description} alt="Profile" />
								</i>
								<InputBox.TextArea
									name="description"
									placeholder={t("FEEDBACK_DESCRIPTION")}
									label={t("FEEDBACK_DESCRIPTION")}
									required
									rules={ContactUsRequest(t).description}
								/>
							</div>
							<div className="btn">
								<Button type="primary" htmlType="submit" loading={saving} className="btn btn-yellow">{t("SUBMIT")}</Button>
							</div>
						</FormBox>
					</div>
				</div>
			</section>
		</>
	);
};

export default Feedback;
