import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { observer } from "mobx-react";
import useStore from "../../../../../store";
import moment from "moment";
import { useHistory } from "react-router";
import FormComponent from "./FormComponent";
import { ErrorProps } from "../../../../../store/RootStore/RootInterface";

const AddDonationCampaignComponent: React.FC = observer(() => {
    const { DONATION_CAMPAIGN, COMMON, ROOT } = useStore();
    const { create } = DONATION_CAMPAIGN;
	const { donationFormData, getDonationFormData,  selectedLanguage  } = COMMON;
	const { AssignErrorToInput  } = ROOT;
    const [form] = Form.useForm();
    const [saving, setSaving] = useState<boolean>(false);
	const [showGroupList, setShowGroupList] =  useState<boolean>(false);
    const [donationSubjectList, setDonationSubjectList] =  useState<boolean>();
    const [groupList, setGroupList] =  useState<boolean>();
    const pathHistory = useHistory();
    const listRoute = () => {
        pathHistory.push('/donation');
    }
    useEffect(()=>{
        selectedLanguage && !donationFormData && getDonationFormData();
		selectedLanguage && donationFormData && setDonationSubjectList(donationFormData.donationSubjectList);
        selectedLanguage && donationFormData && setGroupList(donationFormData.groupList);
		form.setFieldsValue({donation_type: 1});
	},[selectedLanguage, donationFormData]);
    // Handle submit and call function to save new record
    const handleSubmit = (data: any) => {
        setSaving(true);
        const formData = new FormData();
        data.name && formData.append('name', data.name);
        data.group_id && formData.append('group_id', data.group_id);
        data.donation_subject && formData.append('donation_subject', data.donation_subject);
        data.donation_type && formData.append('donation_type', data.donation_type);
        data.start_date_time && formData.append('start_date_time', moment(data.start_date_time).utc().format('YYYY-MM-DD HH:mm:ss'));
        data.end_date_time && formData.append('end_date_time', moment(data.end_date_time).utc().format('YYYY-MM-DD HH:mm:ss'));

        create(formData)
            .then(() => {
                setSaving(false);
                listRoute();
                form.resetFields();
            })
            .catch((e: ErrorProps) => {
                const errors = e?.errors || e?.data?.errors;
                setSaving(false);
                AssignErrorToInput(form, errors);
            });
    };
    return (
        <>
            <FormComponent
                formId="addDonationCampaignForm"
                form={form}
                handleSubmit={handleSubmit}
                saving={saving}
                isEdit={false}
                listRoute={listRoute}
				donationSubjectList={donationSubjectList}
				groupList={groupList}
				showGroupList={showGroupList}
				setShowGroupList={setShowGroupList}
            />
        </>
    );
});
export default AddDonationCampaignComponent;
