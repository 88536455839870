import { Col, Row } from "antd";
import React from "react";
import { Form, Button, } from "antd";
import description from "../../../assets/images/description.svg";
import amount from "../../../assets/images/amount-icon.svg";
import useStore from "../../../store";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { CreateMyWelfareRequestRequest } from "../../../requests/WelfareRequestRequest";
import PaymentIcon from "../../../assets/images/money.svg";
import { Cash_Module } from "../../../config/Global";

const AddMyWelfareRequest = () => {
	const [form] = Form.useForm();
	const {
		WELFARE_REQUEST: { createMyWelfareRequest },
		COMMON: { paymentListData },
	} = useStore();
	const history = useHistory();
	const { t } = useTranslation();

	const handleSubmit = (data: any) => {
		createMyWelfareRequest(data).then(() => {
			history.replace("/my-welfare-request");
		});
	};

	const listMyWelfareRequest = () => {
		history.replace("/my-welfare-request");
	}

	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t('ADD_MY_WELFARE_REQUEST')}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<Row>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={amount} alt="Amount" />
									</i>
									<InputBox.Text
										name="amount"
										placeholder={t('AMOUNT')}
										label={t('AMOUNT')}
										rules={CreateMyWelfareRequestRequest(t).amount}
									/>
								</div>
								{Cash_Module && <div className="field">
									<i className="iconAlignment">
										<img src={PaymentIcon} alt="Profile" />
									</i>
									<InputBox.Select label={t("WHAT_IS_YOUR_PREFERENCE")}
										name={"preference"}
										placeholder={t("PREFERENCE")}
										options={{
											list: paymentListData,
											valueKey: "id",
											textKey: "name",
										}}
										rules={CreateMyWelfareRequestRequest(t).preference}
									/>
								</div>}
							</Col>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={description} alt="Description" />
									</i>
									<InputBox.TextArea
										name="description"
										placeholder={t('DESCRIPTION')}
										label={t('DESCRIPTION')}
										rules={CreateMyWelfareRequestRequest(t).description}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<div className="btn btn-wrap">
									<Button type="primary" htmlType="submit" className="btn btn-primary">{t("SAVE")}</Button>
									<Button type="primary" htmlType="submit" className="btn btn-danger" onClick={listMyWelfareRequest}>{t("CANCEL")} </Button>
								</div>
							</Col>
						</Row>

					</FormBox>
				</div>
			</div>
		</>
	);
};

export default AddMyWelfareRequest;
