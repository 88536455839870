import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import useStore from "../../../store";
import { Modal, Button, Form, Row, Col } from "antd";
import { GoPlus } from "react-icons/go";
import { GrDocumentExcel } from "react-icons/gr";
import { AiOutlineEye } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import User_thumb from "../../../assets/images/user-img.svg";
import { observer } from "mobx-react";
import { displayFormats, UserStatus } from "../../../config/Global";
import moment from "moment";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { addUserToGroupRequest, assignSecretaryAccountantToGroupRequest } from "../../../requests/GroupRequest";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import RoleList from "../../../config/RoleList";
import AllPermissionList from "../../../config/AllPermissionList";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";

const GroupMemberDetails: React.FC = observer(() => {
	const [form] = Form.useForm();
	const [formAssignRole] = Form.useForm();
	const { t } = useTranslation();
	const [showAddMember, showAddMemberModel] = useState(false);
	const [assignRole, showAssignRoleModel] = useState(false);
	const [memberEditModal, setMemberEditModal] = useState(false);
	const [userList, setUserList] = useState<any>([]);
	const [roleListData, setRoleListData] = useState<any>([]);
	const [memberListData, setMemberListData] = useState<any>([]);
	const [saving, setSaving] = useState<boolean>(false);

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const history = useHistory();
	const {
		ROOT: { AssignErrorToInput },
		COMMON: { getGroupUser, groupUserList, exportGroupUserList },
		AUTH: { permissions, selectedGroup, selectedGroupDetails },
		USER: { getUserDetails, userDetails },
		SAVING_GROUP: { addUserToGroup, assignSecretaryAccountantToGroup, userListToAddMemberToGroup },
	} = useStore();

	const handleSearch = (data: any) => {
		if (data.length >= 3) {
			userListToAddMemberToGroup({ search: data }).then((data: any) => {
				setUserList(data.userList);
			});
		}
	};

	const openMemberDetailsModal = (params: any) => {
		setMemberEditModal(true);
		getUserDetails(params.data?.id);
	}

	const ActionRenderer = (params: any) => {
		return (
			<Button className="edit-button" title={t('DETAIL')} type="text" onClick={() => openMemberDetailsModal(params)}><AiOutlineEye /></Button>
		)
	}

	const displayStatus = (param: any) => {
		const status = UserStatus(t).filter((item: any) => item.id == param);
		return status[0]?.status;
	};

	const displayMyRoleInGroup = () => {
		const myRole = userDetails &&
			userDetails.groups.filter((item: any) => item.id == selectedGroup);
		return (myRole.length > 0 && myRole[0].role && myRole[0].role.name) ? myRole[0].role.name : t("MEMBER");
	};

	const displaySharePurchasedCount = () => {
		const group = userDetails &&
			userDetails.groups.filter((item: any) => item.id == selectedGroup);
		return group[0]?.share_purchased;
	};

	const displayJoinDate = () => {
		const group = userDetails &&
			userDetails.groups.filter((item: any) => item.id == selectedGroup);
		return group[0].join_date ?? null;
	};

	const handleSubmit = (data: any) => {
		setSaving(true);
		addUserToGroup(data).then(() => {
			form.resetFields();
			showAddMemberModel(false);
		}).catch((e: ErrorProps) => {
			const errors = e?.errors || e?.data?.errors;
			errors && AssignErrorToInput(form, errors);
		}).finally(() => { setSaving(false) });
	}

	const handleAssignRoleSubmit = (data: any) => {
		setSaving(true);
		assignSecretaryAccountantToGroup(data).then(() => {
			showAssignRoleModel(false);
			setTimeout(() => {
				history.go(0);
			}, 1000);
		}).catch((e: ErrorProps) => {
			AssignErrorToInput(formAssignRole, e?.data.errors);
		}).finally(() => { setSaving(false) });
	}


	useEffect(() => {
		if (selectedGroup && selectedGroupDetails) {
			const roleList: any = [];
			!selectedGroupDetails.secretary.firstname && roleList.push({ id: RoleList.SECRETARY, name: RoleList.SECRETARY });
			!selectedGroupDetails.accountant.firstname && roleList.push({ id: RoleList.ACCOUNTANT, name: RoleList.ACCOUNTANT });
			setRoleListData(roleList);
		}
	}, [selectedGroup, selectedGroupDetails, groupUserList, getGroupUser, setMemberListData, setRoleListData]);

	useEffect(() => {
		selectedGroup && getGroupUser(selectedGroup).then((data: any) => {
			const userList: any = [];
			data.users && data.users.map((element: any) => {
				!element.role_name && userList.push({ id: element.id, name: element.user_name });
			});
			setMemberListData(userList);
		});
	}, [selectedGroup]);

	const exportGroupUser = (type: string) => {
		setExportSaving(true);
		exportGroupUserList(selectedGroup, type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("MEMBER_DETAILS")}</h3>
						<div className="heading-btn-wrap">
							<div className="text-center btn btn-primary btn-yellow">
								<Link to={"/saving-group/details"} type="primary">
									{t("BACK")}
								</Link>
							</div>

							{groupUserList && groupUserList.users && groupUserList.users.length > 0 && <Button className="btn-yellow" type="primary" onClick={()=> showExportPopup(true)}>
								{t("GENERATE_REPORT")} <GrDocumentExcel />
							</Button>}

							<ExportPopupComponent
								callbackExportFunction={exportGroupUser}
								setVisible={showExportPopup}
								visible={exportPopup}
								saving={exportSaving}
							/>

							{selectedGroupDetails && groupUserList && groupUserList.users && groupUserList.users.length < selectedGroupDetails.numbers_of_member && <Button
								hidden={permissions && permissions.indexOf(AllPermissionList.ADD_USER_TO_GROUP) < 0}
								className="btn-secondary"
								type="primary"
								onClick={() => showAddMemberModel(true)}
							>
								{t("ADD_MEMBER")} <GoPlus />
							</Button>}
							{selectedGroupDetails && (!selectedGroupDetails.secretary.firstname || !selectedGroupDetails.accountant.firstname) && <Button
								hidden={permissions && permissions.indexOf(AllPermissionList.ASSIGN_SECRETARY_OR_ACCOUNTANT_TO_GROUP) < 0}
								className="btn-secondary"
								type="primary"
								onClick={() => showAssignRoleModel(true)}
							>
								{t("ASSIGN_ROLE")}
							</Button>}
						</div>
					</div>
					{groupUserList && groupUserList.users && <AgGridWrapper
						className="member-table"
						immutableData={true}
						rowData={groupUserList.users}
						frameworkComponents={{ ActionRenderer }}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						onFilterChanged={({ api }) => {
							if (api.getDisplayedRowCount()) {
								api.hideOverlay();
							} else {
								api.showNoRowsOverlay();
							}
						}}
					>
						<AgGridColumn field="id" headerName="# ID" width={100} />
						<AgGridColumn field="user_name" headerName={t("USER_NAME")} />
						<AgGridColumn field="role_name" headerName={t("ROLE")} cellRenderer={(data:any)=>{
							return data?.value==null?"Member":data?.value
						}}/>
						<AgGridColumn field="email" headerName={t("EMAIL")} />
						<AgGridColumn field="phone_number" headerName={t("PHONE_NUMBER")} />
						<AgGridColumn
							field="action"
							headerName={t("MEMBER_DETAILS")}
							cellRenderer="ActionRenderer"
						/>
					</AgGridWrapper>}
				</div>

				<Modal
					centered
					visible={showAddMember}
					onOk={() => showAddMemberModel(false)}
					onCancel={() => showAddMemberModel(false)}
					className="custom-popup info-popup popup-wrap"
					footer={null}
				>
					<FormBox
						id="addUserToGroupRequest"
						encType="multipart/formdata"
						key="addUserToGroupRequestForm"
						form={form}
						onFinish={handleSubmit}>
						<div className="multi-select-input">
							<InputBox.Select
								placeholder={t("SEARCH_USER")}
								name="users"
								filterOption={false}
								options={{
									list: userList,
									valueKey: "id",
									textKey: ["user_name", " (", "phone_number", ")"]
								}}
								required
								onSearch={handleSearch}
								mode="multiple"
								rules={addUserToGroupRequest(t).users}
							/>
						</div>
						<div className="btn">
							<Button
								className="btn-danger"
								loading={saving}
								htmlType="submit"
							>
								{t("SUBMIT")}
							</Button>
							<Button
								className="btn-grey"
								onClick={() => showAddMemberModel(false)}
							>
								{t("CANCEL")}
							</Button>
						</div>
					</FormBox>
				</Modal>

				<Modal
					centered
					visible={memberEditModal}
					onOk={() => setMemberEditModal(false)}
					onCancel={() => setMemberEditModal(false)}
					className="custom-popup info-popup details-popup"
					footer={null}
				>
					{userDetails && (
						<div className="details-info">
							<div className="info-header">
								<div className="info-thumb">
									<div className="Thumb">
										<img src={User_thumb} alt="User" />
									</div>
									<div className="title">
										<h3>
											{userDetails.firstname + " " + userDetails.lastname}
										</h3>
										<span>({displayMyRoleInGroup()})</span>
									</div>
								</div>
								<div className="info-content">
									<Link to={"tel:" + userDetails.phone_number} title="Call us">
										{userDetails.phone_number}
									</Link>
									<Link to={"mailto:" + userDetails.email} title="Mail us">
										{userDetails.email}
									</Link>
								</div>
							</div>
							<div className="info-body">
								<div className="item">
									<h4>{t("GROUPS")}</h4>
									<ul className="list">
										{userDetails.groups.map((item: any, index: number) => {
											return (
												<>
													<li
														key={"groupMemberDetailsGroup" + index}
														className={item.id == selectedGroup ? "active" : ""}
													>
														{item.name}
													</li>
												</>
											);
										})}
									</ul>
								</div>
								<div className="item">
									<h4></h4>
									<h4>{t("DETAILS")}</h4>
									<ul>
										<li>
											{t("SHARES_PURCHASED")}: <span>{displaySharePurchasedCount()}</span>
										</li>
										{displayJoinDate() && <li>
											{t("DATE_JOINED")}: <span>{moment(displayJoinDate()).format(displayFormats.DATE_FORMAT)}</span>
										</li>}
									</ul>
								</div>
							</div>
							<div className="info-footer">
								<p>
									{t("STATUS")}: <span>{displayStatus(userDetails.status)}</span>
								</p>
							</div>
						</div>
					)}
				</Modal>

				{/* Assign Role Model */}
				<Modal
					title={t("ASSIGN_ROLE")}
					centered
					visible={assignRole}
					onOk={() => showAssignRoleModel(false)}
					onCancel={() => showAssignRoleModel(false)}
					className="custom-popup"
					footer={null}
				>
					<Form form={formAssignRole} onFinish={handleAssignRoleSubmit}>
						<Row gutter={[20, 20]}>
							<Col span={12}>
								<div className="field">
									<InputBox.Select
										name="role"
										options={{
											list: roleListData,
											valueKey: "id",
											textKey: "name"
										}}
										placeholder={t('ROLE')}
										label={t("ASSIGN_ROLE")}
										rules={assignSecretaryAccountantToGroupRequest(t).role}
									/>
								</div>
							</Col>
							<Col span={12}>
								<div className="field">
									<InputBox.Select
										name="user_id"
										options={{
											list: memberListData,
											valueKey: "id",
											textKey: "name"
										}}
										placeholder={t('USER')}
										label={t('USER')}
										rules={assignSecretaryAccountantToGroupRequest(t).user_id}
									/>
								</div>
							</Col>
						</Row>
						<div className="btn">
							<Button className="btn-secondary"
								loading={saving} htmlType="submit">{t("ASSIGN")}</Button>
							<Button className="btn-danger" onClick={() => showAssignRoleModel(false)}>{t("CANCEL")}</Button>
						</div>
					</Form>
				</Modal>
			</div>
		</>
	);
});

export default GroupMemberDetails;
