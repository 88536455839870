import React, { useEffect, useState } from "react";
import useStore from "../../../store";
import { Button, Form, Modal } from "antd";
import { Col, Row } from "antd";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { useHistory } from "react-router-dom";
import { ElectionRequest } from "../../../requests/ElectionRequest";
import { displayFormats } from "../../../config/Global";
import Calendar from "../../../assets/images/Calendar.svg";
import electionicon from "../../../assets/images/election-icon.svg";

const AddElection: React.FC = observer(() => {
	const [form] = Form.useForm();
	const history = useHistory();
	const {
		ROOT: { AssignErrorToInput },
		ELECTION: { createElection, getElectionList, electionListData }
	} = useStore();
	const { t } = useTranslation();
	const [saving, setSaving] = useState<boolean>(false);
	const [electionSubmit, showElectionSubmitModal] = useState(false);
	const [electionRoleList, setElectionRoleList] = useState<any>();

	const openElectionSubmitModal = () => {
		showElectionSubmitModal(true);
	};

	const disableDate = (current: any) => {
		return current && current <= moment().add(7, "days").startOf("day");
	};

	const handleSubmit = (data: any) => {
		setSaving(true);
		data.election_date = moment(data.election_date).format("YYYY-MM-DD");

		createElection(data).then(() => {
			openElectionSubmitModal();
		}).catch((e: ErrorProps) => {
			const errors = e?.errors || e?.data?.errors;
			errors && AssignErrorToInput(form, errors);
			setSaving(false);
		});
	};

	const goToElection = () => {
		history.push('/election');
	}

	useEffect(() => {
		!electionListData && getElectionList({});
		electionListData && setElectionRoleList(electionListData.electionRoleList);
	}, [electionRoleList, electionListData]);

	return (
		<>
			<div className="create-group-section">
				<div className="main">
					<h3>{t("PLEASE_FILL_THE_FOLLOWING_DETAILS_DESIGNATION")}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<Row gutter={[100, 100]}>
							<Col md={24} lg={12} xl={12} xxl={12}>
								<div className="field radio-square">
									<InputBox.Checkbox
										options={{
											list: electionRoleList,
											valueKey: "id",
											textKey: "name",
										}}
										name="election_role_id"
										rules={ElectionRequest(t).election_role_id}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={12} lg={12} xl={12} xxl={12}>
								<div className="field mb-20">
								<i>
										<img src={electionicon} alt="Profile" />
									</i>
									<InputBox.Text
										name="title"
										label={t('ELECTION_TITLE')}
										placeholder={t('ELECTION_TITLE')}
										rules={ElectionRequest(t).title}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={12} lg={12} xl={12} xxl={12}>
								<div className="field">
									<i>
										<img src={Calendar} alt="Profile" />
									</i>
									<InputBox.DatePicker
										label={t('DATE')}
										name="election_date"
										placeholder={t('DATE')}
										disabledDate={disableDate}
										rules={ElectionRequest(t).election_date}
										format={displayFormats.DATE_FORMAT}
									/>
								</div>
								<div className="error-msg">
									<p>*{t('PLEASE_SELECT_A_DATE_AT_LEAST_7_DAYS_FROM_TODAY')}</p>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={24} lg={24} xl={24} xxl={24}>
								<div className="field btn-wrap small-btn-inline">
									<Button
										className="btn-primary"
										htmlType="submit"
										loading={saving}
									>
										{t("SAVE")}
									</Button>
									<Button className="btn-danger btn-primary" htmlType="button" onClick={goToElection}>
										{t("CANCEL")}
									</Button>
								</div>
							</Col>
						</Row>
					</FormBox>
				</div>
			</div>

			<Modal
				centered
				visible={electionSubmit}
				onOk={() => showElectionSubmitModal(false)}
				onCancel={() => goToElection()}
				className="custom-popup info-popup"
				footer={null}
			>
				<div className="info-text">
					<p>
						{t("REQUEST_SUBMITTED_ADMIN") + " " + moment().format(displayFormats.DATE_FORMAT)}
					</p>
				</div>
				<div className="btn">
					<Button className="btn-secondary" onClick={goToElection}>{t("OK")}</Button>
				</div>
			</Modal>
		</>
	);
});

export default AddElection;
