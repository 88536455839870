import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import moment from "moment";
import { displayFormats } from "../config/Global";

export const CreateSavingGroupRequest = (t: any): RequestProps => ({
	group_image: [
		({ getFieldValue }) => ({
			validator() {
				const group_image = getFieldValue("group_image");
				if (group_image && group_image.file.size >= 5120000) {
					return Promise.reject(Message(t).maxImageSize(5120));
				}
				return Promise.resolve();
			},
		}),
	],
	name: [
		{ required: true, message: Message(t).required(t('GROUP_NAME')) },
		// { pattern: /^([A-Za-z -]+)$/, message: Message(t).type.pattern(t("GROUP_NAME")) },
		{ min: 3, message: Message(t).min(3, t('GROUP_NAME')) },
		{ max: 50, message: Message(t).max(50, t('GROUP_NAME')) },
		({ getFieldValue }) => ({
			validator() {
				const name = getFieldValue("name");
				if (name && !name.trim()) {
					return Promise.reject(Message(t).required(t('GROUP_NAME')));
				}
				return Promise.resolve();
			},
		}),
	],
	group_description: [
		{ required: true, message: Message(t).required(t('DESCRIPTION')) },
		{ min: 3, message: Message(t).min(3, t('DESCRIPTION')) },
		{ max: 5000, message: Message(t).max(5000, t('DESCRIPTION')) },
		({ getFieldValue }) => ({
			validator() {
				const group_description = getFieldValue("group_description");
				if (group_description && !group_description.trim()) {
					return Promise.reject(Message(t).required(t('DESCRIPTION')));
				}
				return Promise.resolve();
			},
		}),
	],
	numbers_of_member: [
		{ required: true, message: Message(t).required(t('TOTAL_MEMBERS')) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits() },
		({ getFieldValue }) => ({
			validator() {
				const minMembers = getFieldValue("minMembers");
				const numbersOfMember = getFieldValue("numbers_of_member");
				if (numbersOfMember < minMembers) {
					return Promise.reject(Message(t).minIntFloat(minMembers, t('TOTAL_MEMBERS')));
				}
				return Promise.resolve();
			},
		}),
	],
	borrowing_limit: [
		{ required: true, message: Message(t).required(t('BORROWING_LIMIT')) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits() },
		({ getFieldValue }) => ({
			validator() {
				const borrowingLimit = getFieldValue("borrowing_limit");
				if (borrowingLimit < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('BORROWING_LIMIT')));
				}
				return Promise.resolve();
			},
		}),
	],
	max_borrowing_time_line: [
		{ required: true, message: Message(t).required(t('MAXIMUM_LOAN_DURATION_IN_MONTHS')) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits() },
		({ getFieldValue }) => ({
			validator() {
				if (!getFieldValue("operating_cycle_date")) {
					return Promise.reject(Message(t).required(t('OPERATING_CYCLE_DATE')));
				}
				const maxBorrowingTimeLine = getFieldValue("max_borrowing_time_line");

				const operatingCycleDate = moment(getFieldValue("operating_cycle_date"));
				const currentDate = moment();
				const monthsDifference = (operatingCycleDate.diff(currentDate, 'months') - 1);
				if (maxBorrowingTimeLine < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('MAXIMUM_LOAN_DURATION_IN_MONTHS')));
				}
				if (maxBorrowingTimeLine > monthsDifference) {
					return Promise.reject(Message(t).maxIntFloat(monthsDifference, t('MAXIMUM_LOAN_DURATION_IN_MONTHS')));
				}
				return Promise.resolve();
			},
		}),
	],
	operating_cycle_date: [
		{ required: true, message: Message(t).required(t('OPERATING_CYCLE_DATE')) },
		({ getFieldValue }) => ({
			validator() {
				const operatingCycleDate = moment(getFieldValue("operating_cycle_date"));
				const currentDate = moment();
				const monthsDifference = operatingCycleDate.diff(currentDate, 'months');
				if (operatingCycleDate && monthsDifference < 6) {
					return Promise.reject(Message(t).afterDateTime(t('OPERATING_CYCLE_DATE'), moment().add(6, "months").endOf('day').format(displayFormats.DATE_FORMAT)));
				}
				return Promise.resolve();
			},
		}),
	],
	currency_id: [{ required: true, message: Message(t).selectRequired(t('CURRENCY')) }],
	confederation_id: [
		{ required: true, message: Message(t).selectRequired(t("CONFEDERATION")) },
	],
	share_price: [
		{ required: true, message: Message(t).required(t('SHARE_PRICE')) },
		{ pattern: /^\d*(\.\d{0,2})?$/, message: Message(t).pattern.decimal(2) },
		({ getFieldValue }) => ({
			validator() {
				const sharePrice = getFieldValue("share_price");
				if (sharePrice < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('SHARE_PRICE')));
				}
				return Promise.resolve();
			},
		}),
	],
	group_welfare_rate: [
		{ required: true, message: Message(t).required(t('GROUP_WELFARE_RATE')) },
		{ pattern: /^\d*(\.\d{0,2})?$/, message: Message(t).pattern.decimal(2) },
		({ getFieldValue }) => ({
			validator() {
				const groupWelfareRate = getFieldValue("group_welfare_rate");
				if (groupWelfareRate < 0) {
					return Promise.reject(Message(t).minIntFloat(0, t('GROUP_WELFARE_RATE')));
				}
				return Promise.resolve();
			},
		}),
	],
	max_share_purchase_limit: [
		{ required: true, message: Message(t).required(t('MAXIMUM_SHARES')) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits() },
		({ getFieldValue }) => ({
			validator() {
				const maxSharePurchaseLimit = getFieldValue("max_share_purchase_limit");
				if (maxSharePurchaseLimit < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('MAXIMUM_SHARES')));
				}
				if (maxSharePurchaseLimit > 5) {
					return Promise.reject(Message(t).maxIntFloat(5, t('MAXIMUM_SHARES')));
				}
				return Promise.resolve();
			},
		}),
	],
	interest_rate: [
		{ required: true, message: Message(t).required(t('INTEREST_RATE')) },
		{ pattern: /^\d*(\.\d{0,2})?$/, message: Message(t).pattern.decimal(2) },
		({ getFieldValue }) => ({
			validator() {
				const interestRate = getFieldValue("interest_rate");
				if (interestRate < 0 || interestRate > 100) {
					return Promise.reject(Message(t).rangeBetween(t('INTEREST_RATE'), 0, 100));
				}
				return Promise.resolve();
			},
		}),
	],
	share_purchase: [
		{ required: true, message: Message(t).required(t('NUMBER_OF_SHARES')) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits() },
		({ getFieldValue }) => ({
			validator() {
				const sharePurchase = getFieldValue("share_purchase");
				const maxSharePurchaseLimit = getFieldValue("max_share_purchase_limit");
				if (sharePurchase > maxSharePurchaseLimit) {
					return Promise.reject(Message(t).maxIntFloat(maxSharePurchaseLimit, t('NUMBER_OF_SHARES')));
				}
				if (sharePurchase < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('NUMBER_OF_SHARES')));
				}
				return Promise.resolve();
			},
		}),
	],
	preference:[
		{ required: true, message: Message(t).selectRequired(t("PREFERENCE")) },
	],
});

export const CreateFineRequest = (t: any): RequestProps => ({
	user_id: [{ required: true, message: Message(t).selectRequired(t('MEMBER_NAME')) }],
	fine_type_id: [{ required: true, message: Message(t).selectRequired(t('FINE_TYPE')) }],
	fine_amount: [
		{ required: true, message: Message(t).required(t('AMOUNT')) },
		{ pattern: /^\d*(\.\d{0,2})?$/, message: Message(t).pattern.decimal(2) },
		({ getFieldValue }) => ({
			validator() {
				const groupWelfareRate = getFieldValue("fine_amount");
				if (groupWelfareRate < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('AMOUNT')));
				}
				return Promise.resolve();
			},
		}),
	],
	date: [{ required: true, message: Message(t).selectRequired(t('DATE')) }],
	description: [
		{ required: true, message: Message(t).required(t('DESCRIPTION')) },
		{ min: 3, message: Message(t).min(3, t('DESCRIPTION')) },
		{ max: 5000, message: Message(t).max(5000, t('DESCRIPTION')) },
		({ getFieldValue }) => ({
			validator() {
				const description = getFieldValue("description");
				if (description && !description.trim()) {
					return Promise.reject(Message(t).required(t('DESCRIPTION')));
				}
				return Promise.resolve();
			},
		}),
	],
	fine_due_date: [
		{ required: true, message: Message(t).selectRequired(t('DUE_DATE')) },
	],
});

export const addUserToGroupRequest = (t: any): RequestProps => ({
	users: [
		{ required: true, message: Message(t).required(t('SEARCH_USER')) },
	],
});

export const assignSecretaryAccountantToGroupRequest = (t: any): RequestProps => ({
	user_id: [
		{ required: true, message: Message(t).selectRequired(t('MEMBER_NAME')) },
	],
	role: [
		{ required: true, message: Message(t).selectRequired(t('ROLE')) },
	],
});

export const BuyShareRequest = (t: any, maxSharePurchaseLimit: number): RequestProps => ({
	share_count: [
		{ required: true, message: Message(t).required(t('NUMBER_OF_SHARES')) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits() },
		({ getFieldValue }) => ({
			validator() {
				const sharePurchase = getFieldValue("share_count");
				if (sharePurchase > maxSharePurchaseLimit) {
					return Promise.reject(Message(t).maxIntFloat(maxSharePurchaseLimit, t('NUMBER_OF_SHARES')));
				}
				if (sharePurchase < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('NUMBER_OF_SHARES')));
				}
				return Promise.resolve();
			},
		}),
	]
});

export const JoinGroupRequest = (t: any, maxSharePurchaseLimit: number): RequestProps => ({
	share_purchase: [
		{ required: true, message: Message(t).required(t('NUMBER_OF_SHARES')) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits() },
		({ getFieldValue }) => ({
			validator() {
				const sharePurchase = getFieldValue("share_purchase");
				if (sharePurchase > maxSharePurchaseLimit) {
					return Promise.reject(Message(t).maxIntFloat(maxSharePurchaseLimit, t('NUMBER_OF_SHARES')));
				}
				if (sharePurchase < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('NUMBER_OF_SHARES')));
				}
				return Promise.resolve();
			},
		}),
	]
});

export const generateOTP = (t: any): RequestProps => ({
	otp: [
		{ required: true, message: Message(t).required(t('OTP')) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits() },
		{pattern:/^\d{6}$/,message:Message(t).onlySixDigits() }
	],
});

export const buyShareOfUsers=(t: any): RequestProps => ({
	user: [
		{ required: true, message: Message(t).required(t('USER_NAME')) },
	],
	share:[
		{ required: true, message: Message(t).required(t('NUMBER_OF_SHARES')) },
		{ pattern: /^([0-9]+)$/, message: Message(t).onlyDigits() },
		({ getFieldValue }) => ({
			validator() {
				const maxSharePurchaseLimit = getFieldValue("number_of_shares");
				if (maxSharePurchaseLimit < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('NUMBER_OF_SHARES')));
				}
				if (maxSharePurchaseLimit > 5) {
					return Promise.reject(Message(t).maxIntFloat(5, t('NUMBER_OF_SHARES')));
				}
				return Promise.resolve();
			},
		}),
	],
});