import React, { useEffect, useState } from "react";
import { Form } from "antd";
import useStore from "../../../store";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import MeetingForm from "./MeetingForm";
import { observer } from "mobx-react";

const EditMeetings: React.FC = observer(() => {
	const [form] = Form.useForm();
	const {
		ROOT: {AssignErrorToInput},
		AUTH: { selectedGroup },
		MEETING: {updateMeeting, getGroupMeetingDetails},
	} = useStore();
	const history = useHistory();
	const { t } = useTranslation();
	const { id }: any = useParams();
	const [saving, setSaving] = useState<boolean>(false);
	const [meeting, setMeeting] = useState<any>([]);

	const listRoute = () =>{
		history.push('/meetings');
	}

	const handleSubmit = (data: any) => {
		setSaving(true);

		data.meeting_start_date_time && (data.meeting_start_date_time = moment(data.meeting_start_date_time).utc().format("YYYY-MM-DD HH:mm"));
		data.meeting_end_date_time && (data.meeting_end_date_time = moment(data.meeting_end_date_time).utc().format("YYYY-MM-DD HH:mm"));

		updateMeeting(id, data).then(() => {
			listRoute();
		}).catch((e:any) => {
			setSaving(false);
			AssignErrorToInput(form, e?.data?.errors);
		});
	};

	useEffect(() => {
		getGroupMeetingDetails(id).then((data: any) => {
			setMeeting(data);
			form.resetFields();
			form.setFieldsValue({
				group_id: data.group_id,
				type: data.type,
				title: data.title,
				location: data.location,
				description: data.description,
				meeting_start_date_time: moment.utc(data.meeting_start_date_time).local(),
				meeting_end_date_time: moment.utc(data.meeting_end_date_time).local(),
			});
		});
	}, [selectedGroup, getGroupMeetingDetails]);

	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t('MEETING_EDIT')}</h3>
					<MeetingForm
						form={form}
						handleSubmit={handleSubmit}
						isEdit={true}
						listRoute={listRoute}
						meetingDetails={meeting}
						saving={saving}
					/>
				</div>
			</div>
		</>
	);
});

export default EditMeetings;
