import React, { useEffect, useState } from "react";
import { Col, Row, Carousel, Button } from "antd";
import { Link } from "react-router-dom";
import useStore from "../../store";
import { observer } from "mobx-react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
interface HeaderSectionProps {
	displayCategory?: string;
	displayType?: number | string;
}
const HeaderSection: React.FC<HeaderSectionProps> = observer(({ displayCategory, displayType }) => {
	const {
		AUTH: { user },
		COMMON: { webCommonData },
	} = useStore();
	const [banner, setBanner] = useState<any>();
	const [slider, setSlider] = useState<any>([]);

	const returnBtnView = (title: string, link: string) => {
		if(user) {
			return (<a href={link?.startsWith("http")?link:link?.startsWith("/")?link:"/"+link} title={title}>
				{title}
			</a>)
		} else {
			return (<Link to={"/login"} title={title} >
				{title}
			</Link>)
		}
	}

	useEffect(() => {
		(displayCategory == 'banner') && webCommonData && webCommonData.bannerList.find((item: any) => {
			if(item.banner_type == displayType) {
				setBanner(item);
				return item;
			}
		});
		if (displayCategory == 'slider') {
			webCommonData && setSlider(webCommonData.sliderList.filter((item: any) => item.slider_type == displayType));
		}
	}, [webCommonData]);


	const upcomingSliderSettings = {
		arrows: true,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: (
			<Button className="slick-arrow prev slick-prev">
				<AiOutlineArrowLeft />
			</Button>
		),
		nextArrow: (
			<Button className="slick-arrow next slick-next">
				<AiOutlineArrowRight />
			</Button>
		),
	};
	return (
		<>
			{(displayCategory == 'banner') && <section
				className="banner-section"
				// style={{ backgroundImage: `url(${banner?.banner_image})` }}
			>
				<div className="main">
					<Row justify="space-between" >
						<Col span={12}>
							<h1>{banner?.title}</h1>
							<span>{banner?.description}</span>
							{banner?.link && banner?.link_title && <div className="btn">
								{ returnBtnView(banner?.link_title, banner?.link) }
							</div>}
						</Col>
						{banner?.banner_image && <Col span={12} className="slider__img__section_img">
							<img src={banner?.banner_image} alt={banner?.banner_image} />
						</Col>}
					</Row>
				</div>
			</section>}
			{(displayCategory == 'slider') && <section className="home__slider">
				<Carousel autoplay {...upcomingSliderSettings}>
					{slider && slider.map((item: any) => {
						return (<div key={"Sliders" + item.id}>
							<div className="banner-section slider__img__section">
								<div className="main">
									<Row justify="space-between">
										<Col span={12}>
											<h1>{item.title}</h1>
											<span>{item.description}</span>
											{ item?.link && item?.link_title && <div className="btn">
												{ returnBtnView(item?.link_title, item?.link) }
											</div> }
										</Col>
										{item.slider_image && <Col span={12} className="slider__img__section_img">
											<img src={item?.slider_image} alt={item.title} />
										</Col>}
									</Row>
								</div>
							</div>
						</div>);
					})}
				</Carousel>
			</section>}
		</>
	);
});

export default HeaderSection;
