import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import FormComponent from "./FormComponent";
import moment from "moment";
import { ErrorProps } from "../../../../../store/RootStore/RootInterface";

const EditDonationCampaignComponent: React.FC = observer(() => {
	const {
		DONATION_CAMPAIGN: { update, details },
		ROOT: { AssignErrorToInput },
		COMMON: { donationFormData, getDonationFormData, selectedLanguage }
	} = useStore();
	
	const [donationCampaign, setDonationCampaign] = useState<any>([])
	const [showGroupList, setShowGroupList] =  useState<boolean>(false);
	const [donationSubjectList, setDonationSubjectList] =  useState<boolean>();
    const [groupList, setGroupList] =  useState<boolean>();
	const { id }: any = useParams();
	
	useEffect(() => {
		selectedLanguage && !donationFormData && getDonationFormData();
		selectedLanguage && donationFormData && setDonationSubjectList(donationFormData.donationSubjectList);
        selectedLanguage && donationFormData && setGroupList(donationFormData.groupList);
		details(id).then((data: any) => {
			setDonationCampaign(data);
			form.resetFields();
			form.setFieldsValue({
				name: data?.name,
				group_id: data?.group_id,
				donation_subject: data?.subject.id,
				start_date_time: moment.utc(data?.start_date_time).local(),
				end_date_time: moment.utc(data?.end_date_time).local(),
				donation_type: data?.donation_type,
			});
			setShowGroupList(form.getFieldValue("donation_type") === 2)
		});
	}, [selectedLanguage, donationFormData, details,])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const listRoute = () => {
		history.push('/donation');
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.name && formData.append('name', data.name);
		//data.group_id && (data.donation_type == "2") && formData.append('group_id', data.group_id);
		data.group_id && formData.append('group_id', data.group_id);
		data.donation_subject && formData.append('donation_subject', data.donation_subject);
		data.donation_type && formData.append('donation_type', data.donation_type);
		data.start_date_time && formData.append('start_date_time', moment(data.start_date_time).utc().format('YYYY-MM-DD HH:mm:ss'));
		data.end_date_time && formData.append('end_date_time', moment(data.end_date_time).utc().format('YYYY-MM-DD HH:mm:ss'));
		update(donationCampaign.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				setSaving(false);
				AssignErrorToInput(form, errors);
			});
	};
	return (
		donationCampaign && <FormComponent
			formId="editDonationCampaignForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			isEdit={true}
			listRoute={listRoute}
			donationSubjectList={donationSubjectList}
			groupList={groupList}
			showGroupList={showGroupList}
			setShowGroupList={setShowGroupList}
		/>
	);
});
export default EditDonationCampaignComponent;
