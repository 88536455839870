import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";
// import * as fs from 'fs';

export default class CommonStore {
	public languageListData?: [];
	public professionListData?: [];
	public currencyListData?: [];
	public groupUserList?: [];
	public groupList?: [];
	public userList?: [];
	public fineTypeList?: [];
	public siteSettings? : any;
	public searchValue?: string;
	public redirectLinks : any;
	public cmsList : any;
	public selectedLanguage: any;
	public donationSubjectList?: any[];
	public homePageNotificationList?: any[];
	public WithoutLoginHomeContentList?: any[];
	public notificationList?: any[];
	public notificationDetail?: any[];
	public notificationCount?: any[];
	public BannerList?: any[];
	public BannerID?: number;
	public sliderList?: any[];
	public sliderID?: number;
	public translationData?: any;
	public electionRoleList?: any[];
	public donationFormData?: any;
	public addMoneyToWalletFormData?: any;
	public webCommonData?: any;
	private rootStore: RootStore;
	public confederationList?: any[];
	public paymentListData?:any[];
	public transferFundType?:any[];
	public fundType?:any[];

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setLanguageListData = (value: any): void => {
		this.languageListData = value;
	};
	public setPaymentListData = (value: any): void => {
		this.paymentListData = value;
	};

	private setProfessionListData = (value: any): void => {
		this.professionListData = value;
	};
	private setCurrencyListData = (value: any): void => {
		this.currencyListData = value;
	};

	private setGroupUserListData = (value: any): void => {
		this.groupUserList = value;
	};

	private setGroupListData = (value: any): void => {
		this.groupList = value;
	};

	private setUserListData = (value: any): void => {
		this.userList = value;
	};

	private setFineTypeListData = (value: any): void => {
		this.fineTypeList = value;
	};

	private setSearchValue = (value: any): void => {
		this.searchValue = value;
	};

	private setSiteSettings = (value: any): void => {
		this.siteSettings = value;
	}

	private setRedirectLinks =(value:any) => {
		this.redirectLinks = value;
	}

	public setCmsList =(value:any) => {
		this.cmsList = value;
	}
	private setDonationSubjectList = (value?: any[]): void => {
		this.donationSubjectList = value;
	};
	public setHomePageNotificationList = (data:any[]): void => {
		this.homePageNotificationList = data;
	};
	private setNotificationList = (data: any[]): void => {
		this.notificationList = data;
	};
	private setNotificationDetail = (data: any[]): void => {
		this.notificationDetail = data;
	};
	public setNotificationCount = (data: any): void => {
		this.notificationCount = data;
	};
	private setBannerListData = (value: any): void => {
		this.BannerList = value;
		this.setBannerID();
	};
	private setBannerID = (id?: number): void => {
		this.BannerID = id;
	};
	private setSliderListData = (data: any[]): void => {
		this.sliderList = data;
		this.setSliderID();
	};
	private setSliderID = (id?: number): void => {
		this.sliderID = id;

	};
	public setWithoutLoginHomeContentListData = (data:any[]): void => {
		this.WithoutLoginHomeContentList = data;
	};

	public setTranslationData = (value?: any): void => {
		this.translationData = value;
	};
	public setElectionRoleList = (value?: any): void => {
		this.electionRoleList = value;
	};
	public setDonationFormData = (value?: any): void => {
		this.donationFormData = value;
	};
	public setAddMoneyToWalletFormData = (value?: any): void => {
		this.addMoneyToWalletFormData = value;
	};
	public setWebCommonData = (value?: any): void => {
		this.webCommonData = value;
	};
	private setConfederationList = (value?: any[]): void => {
		this.confederationList = value;
	};
	private setTransferFundType = (value?: any[]): void => {
		this.transferFundType = value;
	};
	private setFundType = (value?: any[]): void => {
		this.fundType = value;
	};
	// API Functions
	public languageList = (): Promise<any> => {
		return axios.get(API_URL.COMMON.LANGUAGE_LIST).then(({ data }) => {
			this.setLanguageListData(data.data);
		});
	};

	public getProfessionList = (): Promise<any> => {
		return axios.get(API_URL.COMMON.PROFESSION_LIST).then(({ data }) => {
			this.setProfessionListData(data.data);
		});
	};

	public getCurrencyList = (): Promise<any> => {
		return axios.get(API_URL.COMMON.CURRENCY_LIST).then(({ data }) => {
			this.setCurrencyListData(data.data);
		});
	};

	public fetchConfederationList = () => {
		return axios.get(API_URL.COMMON.CONFEDERATION_LIST).then(({ data }) => {
			this.setConfederationList(data.data);
			return data.data;
		});
	}
	public getGroupUser = (groupId: any, payLoad: any): Promise<any> => {
		this.setGroupUserListData([]);
		return axios.post(API_URL.COMMON.GROUP_USER_LIST(groupId), payLoad).then(({ data }) => {
			this.setGroupUserListData(data.data);
			return data.data;
		});
	};

	public getGroup = (data:any): Promise<any> => {
		return axios.get(API_URL.COMMON.GROUP_LIST, {params: data}).then(({ data }) => {
			this.setGroupListData(data.data);
			return data.data;
		});
	};

	public getUser = (data:any): Promise<any> => {
		return axios.post(API_URL.COMMON.USER_LIST, data).then(({ data }) => {
			this.setUserListData(data.data.rows);
			return data.data.rows;
		});
	};

	public getSiteSettings = () : Promise<any> => {
		return axios.get(API_URL.COMMON.SITE_SETTING).then(({ data }) => {
			this.setSiteSettings(data.data);
			return data.data;
		});
	}

	public getRedirectLinks = () : Promise<any> => {
		return axios.get(API_URL.COMMON.REDIRECT_LINKS).then(({ data }) => {
			this.setRedirectLinks(data.data);
			return data.data;
		});
	}

	public getCmsList = () : Promise<any> => {
		const queryString = (this.selectedLanguage && this.selectedLanguage.id) ? "?language_id="+this.selectedLanguage.id : "?language_id=1";
		return axios.post(API_URL.COMMON.CMS_LIST+queryString).then(({ data }) => {
			this.setCmsList(data.data);
			return data.data;
		});
	}

	public setSelectedLanguage = (selectedLanguage: any) => {
		this.selectedLanguage = selectedLanguage;
	}

	public getFineTypeList = (): Promise<any> => {
		this.setFineTypeListData([]);
		return axios.get(API_URL.COMMON.FINE_TYPE_LIST).then(({ data }) => {
			this.setFineTypeListData(data.data);
		});
	};

	public fetchDonationSubjectList = () => {
		return axios.get(API_URL.DONATION_SUBJECT_LIST).then(({ data }) => {
			this.setDonationSubjectList(data.data);
			return data.data;
		});
	}

	public getHomePageNotificationList = (): Promise<any> => {
		return axios.get(API_URL.NOTIFICATION.HOMEPAGE_LIST).then(({ data }) => {
			this.setHomePageNotificationList(data.data.lists);
			this.setNotificationCount(data.data.count);
			return data.data;
		});
	}
	public fetchNotificationList = (): Promise<any> => {
		return axios.post(API_URL.NOTIFICATION.LIST).then(({ data }) => {
			this.setNotificationList(data.data.rows);
			return data.data;
		});
	}
	public fetchNotificationDetail = (id: number): Promise<any> => {
		return axios.get(API_URL.NOTIFICATION.DETAILS(id)).then(({ data }) => {
			this.setNotificationDetail(data.data.rows);
			return data.data;
		});
	}
	public getHomePageNotificationMarkAsRead = (): Promise<any> => {
		return axios.get(API_URL.NOTIFICATION.MARK_AS_READ).then(({ data }) => {
			this.setNotificationCount(0);
			return data.data;
		});
	}
	public getBannerList = (displayType:any): Promise<any> => {
		return axios.get(API_URL.BANNER.LIST(displayType)).then(({ data }) => {
			this.setBannerListData(data.data);
			return data.data;
		});
	};
	public getSliderList = (displayType:any): Promise<any> => {
		return axios.get(API_URL.SLIDER.LIST(displayType)).then(({ data }) => {
			this.setSliderListData(data.data);
			return data.data;
		});
	};
	public getElectionRoleList = (): Promise<any> => {
		return axios.get(API_URL.COMMON.ELECTION_ROLE_LIST).then(({ data }) => {
			this.setElectionRoleList(data.data);
			return data.data;
		});
	};
	public getFeedBackData = (data:any): Promise<any> => {
		return axios.post(API_URL.COMMON.FEEDBACK, data).then(({ data }) => {
			return data.data;
		});
	};

	public loadTranslationData = async (locale: string) => {
		return await axios.get(API_URL.LANGUAGE_DATA(locale))
			.then((response: any) => {
				this.setTranslationData(response.data);
				return response.data;
			});
	};

	public getDonationFormData = (): Promise<any> => {
		return axios.get(API_URL.COMMON.DONATION_FORM_DATA).then(({ data }) => {
			this.setDonationFormData(data.data);
			return data.data;
		});
	};

	public exportGroupUserList = (groupId: any, exportType: any): Promise<any> => {
		const payLoad: any = {};
		payLoad.export_type = exportType;
		return axios.post(API_URL.COMMON.GROUP_USER_LIST(groupId), payLoad).then(({ data }) => {
			this.rootStore.downloadUrl(data.data.downloadExportUrl);
			return data.data;
		});
	};

	public getAddMoneyToWalletFormData = (): Promise<any> => {
		return axios.get(API_URL.COMMON.ADD_MONEY_TO_WALLET_FORM_DATA).then(({ data }) => {
			this.setAddMoneyToWalletFormData(data.data);
			return data.data;
		});
	};

	public getWithoutLoginHomeContentList = (): Promise<any> => {
		return axios.get(API_URL.HOME_PAGES_LIST).then(({ data }) => {
			this.setWithoutLoginHomeContentListData(data.data);
			return data.data;
		});
	};

	public redirectNotification = (id: number) => {
		this.fetchNotificationDetail(id)
				.then((data: any) => {
					(data.isActiveMemberOfGroup === true) && localStorage.setItem('groupId', data.group_id);
					window.location.href = data.main_redirect_url;
				});
	};

	public getWebCommonData = (): Promise<any> => {
		return axios.get(API_URL.COMMON.WEB_COMMON_DATA).then(({ data }) => {
			this.setWebCommonData(data.data);
			this.setSiteSettings(data.data.footerSectionDetails);
			this.setRedirectLinks(data.data.redirectLinks);
			this.setLanguageListData(data.data.languageList);
			this.setPaymentListData(data.data.paymentMode)
			this.setTransferFundType(data.data.transferFundType)
			this.setFundType(data.data.fundType)
			// this.setBannerListData(data.data.bannerList);
			// this.setSliderListData(data.data.sliderList);
			return data.data;
		});
	};
}
