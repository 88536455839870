
const AllPermissionList =  {
	'ADD_USER'                               : "Add User",
	'LIST_USER'                              : "List User",
	'EDIT_USER'                              : "Edit User",
	'DELETE_USER'                            : "Delete User",
	'USER_DETAILS'                           : "User Details",
	'ADD_GROUP'                              : "Add Group",
	'LIST_GROUP'                             : "List Group",
	'EDIT_GROUP'                             : "Edit Group",
	'DELETE_GROUP'                           : "Delete Group",
	'GROUP_DETAILS'                          : "Group Details",
	'ADD_ROLE'                               : "Add Role",
	'LIST_ROLE'                              : "List Role",
	'EDIT_ROLE'                              : "Edit Role",
	'DELETE_ROLE'                            : "Delete Role",
	'ROLE_DETAILS'                           : "Role Details",
	'ASSIGN_ROLE'                            : "Assign Role",
	'ADD_PROFESSION'                         : "Add Profession",
	'LIST_PROFESSION'                        : "List Profession",
	'EDIT_PROFESSION'                        : "Edit Profession",
	'DELETE_PROFESSION'                      : "Delete Profession",
	'PROFESSION_DETAILS'                     : "Profession Details",
	'ADD_LANGUAGE'                           : "Add Language",
	'LIST_LANGUAGE'                          : "List Language",
	'EDIT_LANGUAGE'                          : "Edit Language",
	'DELETE_LANGUAGE'                        : "Delete Language",
	'LANGUAGE_DETAILS'                       : "Language Details",
	'LIST_CMS'                               : "List CMS",
	'ADD_CMS'                                : "Add CMS",
	'EDIT_CMS'                               : "Edit CMS",
	'CMS_DETAILS'                            : "CMS Details",
	'DELETE_CMS'                             : "Delete CMS",
	'ADD_FINE_TYPE'                          : "Add Fine Type",
	'LIST_FINE_TYPE'                         : "List Fine Type",
	'EDIT_FINE_TYPE'                         : "Edit Fine Type",
	'DELETE_FINE_TYPE'                       : "Delete Fine Type",
	'FINE_TYPE_DETAILS'                      : "Fine Type Details",
	'ADD_FINE'                               : "Add Fine",
	'LIST_FINE'                              : "List Fine",
	'EDIT_FINE'                              : "Edit Fine",
	'DELETE_FINE'                            : "Delete Fine",
	'FINE_DETAILS'                           : "Fine Details",
	'LIST_FEEDBACK'                          : "List Feedback",
	'EDIT_FEEDBACK'                          : "Edit Feedback",
	'FEEDBACK_DETAILS'                       : "Feedback Details",
	'ADD_FAQ_CATEGORY'                       : "Add Faq Category",
	'LIST_FAQ_CATEGORY'                      : "List Faq Category",
	'EDIT_FAQ_CATEGORY'                      : "Edit Faq Category",
	'DELETE_FAQ_CATEGORY'                    : "Delete Faq Category",
	'FAQ_CATEGORY_DETAILS'                   : "Faq Category Details",
	'ADD_FAQ'                                : "Add Faq",
	'LIST_FAQ'                               : "List Faq",
	'EDIT_FAQ'                               : "Edit Faq",
	'DELETE_FAQ'                             : "Delete Faq",
	'FAQ_DETAILS'                            : "Faq Details",
	'ADD_SETTING'                            : "Add Setting",
	'LIST_SETTING'                           : "List Setting",
	'LIST_TEMPLATE'                          : "List Template",
	'EDIT_TEMPLATE'                          : "Edit Template",
	'TEMPLATE_DETAILS'                       : "Template Details",
	'LIST_GROUP_EXPENSE'                     : "List Group Expense",
	'ADD_GROUP_EXPENSE'                      : "Add Group Expense",
	'GROUP_EXPENSE_DETAILS'                  : "Group Expense Details",
	'ADD_DONATION_SUBJECT'                   : "Add Donation Subject",
	'LIST_DONATION_SUBJECT'                  : "List Donation Subject",
	'EDIT_DONATION_SUBJECT'                  : "Edit Donation Subject",
	'DELETE_DONATION_SUBJECT'                : "Delete Donation Subject",
	'DONATION_SUBJECT_DETAILS'               : "Donation Subject Details",
	'LIST_TRANSACTION'                       : "List Transaction",
	'TRANSACTION_DETAILS'                    : "Transaction Details",
	'ADD_NEWS'                               : "Add News",
	'LIST_NEWS'                              : "List News",
	'EDIT_NEWS'                              : "Edit News",
	'DELETE_NEWS'                            : "Delete News",
	'NEWS_DETAILS'                           : "News Details",
	'ADD_ELECTION'                           : "Add Election",
	'LIST_ELECTION'                          : "List Election",
	'EDIT_ELECTION'                          : "Edit Election",
	'APPROVE_ELECTION'                       : "Approve Election",
	'ELECTION_DETAILS'                       : "Election Details",
	'NEW_ELECTION_LIST'                      : "New Election List",
	'ADD_REDIRECT_LINK'                      : "Add Redirect Link",
	'LIST_REDIRECT_LINK'                     : "List Redirect Link",
	'EDIT_REDIRECT_LINK'                     : "Edit Redirect Link",
	'REDIRECT_LINK_DETAILS'                  : "Redirect Link Details",
	'DELETE_REDIRECT_LINK'                   : "Delete Redirect Link",
	'FOOTER_SECTION_DETAILS'                 : "Footer Section Details",
	'SAVE_FOOTER_SECTION'                    : "Save Footer Section",
	'ADD_SLIDER'                             : "Add Slider",
	'LIST_SLIDER'                            : "List Slider",
	'EDIT_SLIDER'                            : "Edit Slider",
	'SLIDER_DETAILS'                         : "Slider Details",
	'DELETE_SLIDER'                          : "Delete Slider",
	'LIST_BANNER'                            : "List Banner",
	'EDIT_BANNER'                            : "Edit Banner",
	'BANNER_DETAILS'                         : "Banner Details",
	'DELETE_BANNER'                          : "Delete Banner",
	'ADD_DONATION'                           : "Add Donation",
	'LIST_DONATION'                          : "List Donation",
	'EDIT_DONATION'                          : "Edit Donation",
	'DELETE_DONATION'                        : "Delete Donation",
	'DONATION_DETAILS'                       : "Donation Details",
	'ADD_DONATION_CAMPAIGN'                  : "Add Donation Campaign",
	'LIST_DONATION_CAMPAIGN'                 : "List Donation Campaign",
	'EDIT_DONATION_CAMPAIGN'                 : "Edit Donation Campaign",
	'DONATION_CAMPAIGN_DETAILS'              : "Donation Campaign Details",
	'DELETE_DONATION_CAMPAIGN'               : "Delete Donation Campaign",
	'ADD_USER_TO_GROUP'                      : "Add User To Group",
	'APPROVE_GROUP_JOIN_REQUEST'             : "Approve Group Join Request",
	'LIST_GROUP_JOIN_REQUEST'                : "List Group Join Request",
	'LIST_USER_ROLE_REQUEST'                 : "List User Role Request",
	'EDIT_USER_ROLE_REQUEST'                 : "Edit User Role Request",
	'GROUP_EXPENSE_APPROVE'                  : "Approve Group Expense",
	'LIST_USER_LOAN_REQUEST'                 : "List User Loan Request",
	'ADD_USER_LOAN_REQUEST'                  : "Add User Loan Request",
	'USER_LOAN_REQUEST_DETAILS'              : "User Loan Request Details",
	'EDIT_USER_LOAN_REQUEST'                 : "Edit User Loan Request",
	'DELETE_USER_LOAN_REQUEST'               : "Delete User Loan Request",
	'USER_LOAN_DISBURSEMENT_LIST'            : "User Loan Disbursement List",
	'USER_LOAN_DISBURSEMENT_DETAILS'         : "User Loan Disbursement Details",
	'ADD_MEETING'                            : "Add Meeting",
	'LIST_MEETING'                           : "List Meeting",
	'EDIT_MEETING'                           : "Edit Meeting",
	'DELETE_MEETING'                         : "Delete Meeting",
	'MEETING_DETAILS'                        : "Meeting Details",
	'CANCEL_MEETING'                         : "Cancel Meeting",
	'END_MEETING'                            : "End Meeting",
	'TAKE_MEETING_ATTENDANCE'                : "Take Meeting Attendance",
	'WALLET_LIST'                            : "List Wallet",
	'VIEW_ZOOM_DETAILS'                      : "View Zoom Details",
	'LIST_ELECTION_REFEREE'                  : "List Election Referee",
	'ELECTION_REFEREE_DETAILS'               : "Election Referee Details",
	'ASSIGN_ELECTION_REFEREE_TO_ELECTION'    : "Assign Election Referee To Election",
	'LIST_VILLAGE_AGENT'                     : "List Village Agent",
	'VILLAGE_AGENT_DETAILS'                  : "Village Agent Details",
	'ASSIGN_VILLAGE_AGENT_TO_GROUP'          : "Assign Village Agent To Group",
	'ADD_CONFEDERATION'                      : "Add Confederation",
	'LIST_CONFEDERATION'                     : "List Confederation",
	'EDIT_CONFEDERATION'                     : "Edit Confederation",
	'DELETE_CONFEDERATION'                   : "Delete Confederation",
	'CONFEDERATION_DETAILS'                  : "Confederation Details",
	'GROUP_FINANCE_MANAGEMENT'			     : "Group Finance Management",
	'REMOVE_VILLAGE_AGENT_FROM_GROUP'        : "Remove Village Agent From Group",
	'CHANGE_DEFAULT_LANGUAGE'                : "Change Default Language",
    'MEMBER_PASSBOOK'                        : "Member Passbook",
    'ASSIGN_SECRETARY_OR_ACCOUNTANT_TO_GROUP': "Assign Secretary Or Accountant To Group",
	'LIST_TRANSLATION'                       : "List Translation",
	'ADD_TRANSLATION'                        : "Add Translation",
	'TRANSLATION_DETAILS'                    : "Translation Details",
	'EDIT_TRANSLATION'                       : "Edit Translation",
	'DELETE_TRANSLATION'                     : "Delete Translation",
	'ADD_CASH'                               : "Add Cash",
	'LIST_PRESIDENT'                         : "List President",
	'LIST_SECRETARY'                         : "List Secretary",
	'GROUP_TERMINATE'                        : "Group Terminate",
	'LIST_WELFARE_REQUEST'                   : "List Welfare Request",
	'ADD_WELFARE_REQUEST'                    : "Add Welfare Request",
	'WELFARE_REQUEST_DETAILS'                : "Welfare Request Details",
	'WELFARE_REQUEST_APPROVE'                : "Approve Welfare Request",
	'CANCEL_ELECTION'                        : "Cancel Election",
	'ALL_REPORTS'                            : "All Reports",
	'ADD_HOME_PAGE_CONTENT'                  : "Add Home Page Content",
	'LIST_HOME_PAGE_CONTENT'                 : "List Home Page Content",
	'EDIT_HOME_PAGE_CONTENT'                 : "Edit Home Page Content",
	'DELETE_HOME_PAGE_CONTENT'               : "Delete Home Page Content",
	'HOME_PAGE_CONTENT_DETAILS'              : "Home Page Content Details",
	'ADD_FUND_TRANSFER'               		 : "Add Fund Transfer",
	'LIST_FUND_TRANSFER'              		 : "List Fund Transfer",
}

export default AllPermissionList;
