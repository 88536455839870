import { createContext, useContext } from "react";
import { Context } from "vm";
import AllTransactionStore from "./AllTransactionStore/AllTransactionStore";
import AuthStore from "./AuthStore/AuthStore";
import RootStore from "./RootStore/RootStore";
import UserStore from "./UserStore/UserStore";
import CommonStore from "./CommonStore/CommonStore";
import CreateSavingGroup from "./SavingsGroup/SavingGroupStore";
import FineStore from "./FineStore/FineStore";
import GroupExpenseStore from "./GroupExpenseStore/GroupExpenseStore";
import LoanStore from "./Loans/LoanStore";
import WalletStore from "./WalletStore/WalletStore";
import MeetingStore from "./Meeting/MeetingStore";
import NewsStore from "./News/NewsStore";
import PassbookStore from "./PassbookStore/PassbookStore";
import DonationCampaignStore from "./DonationCampaignStore/DonationCampaignStore";
import DonationSubjectStore from "./DonationSubjectStore/DonationSubjectStore";
import DonationStore from "./DonationStore/DonationStore";
import ElectionStore from "./Election/ElectionStore";
import CMSStore from "./CMS/CMSStore";
import AddCashStore from "./AddCashStore/AddCashStore";
import WelfareRequestStore from "./WelfareRequest/WelfareRequestStore";
import GenerateOTPStore from "./GenerateOtpStore/GenerateOtpStore";
import TransferFundStore from "./TransferFundStore/TransferFundStore";

const common = new CommonStore();
const AppContext = createContext({
	ROOT: new RootStore(),
	AUTH: new AuthStore(common),
	USER: new UserStore(),
	ALL_TRANSACTIONS: new AllTransactionStore(),
	COMMON: common,
	SAVING_GROUP: new CreateSavingGroup(),
	LOAN: new LoanStore(),
	FINE: new FineStore(),
	GROUP_EXPENSE: new GroupExpenseStore(),
	WALLET: new WalletStore(),
	MEETING: new MeetingStore(),
	NEWS: new NewsStore(),
	PASSBOOK: new PassbookStore(),
	DONATION_CAMPAIGN : new DonationCampaignStore(),
	DONATION_SUBJECT : new DonationSubjectStore(),
	DONATION : new DonationStore(),
	ELECTION : new ElectionStore(),
	CMS : new CMSStore(),
	ADD_CASH : new AddCashStore(),
	WELFARE_REQUEST: new WelfareRequestStore(),
	GENERATE_OTP:new GenerateOTPStore(),
	TRANSFER_FUND:new TransferFundStore()
});

const useStore = (): Context => useContext(AppContext);

export default useStore;