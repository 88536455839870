import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import useStore from "../../../store";
import { useHistory } from "react-router";
import moment from "moment";
import { displayFormats } from "../../../config/Global";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import DateComparator from "../../../utils/DateComparator";

const News: React.FC = () => {
	const history = useHistory();
	const { t } = useTranslation();

	const {
		NEWS: { getNewsList },
	} = useStore();
	const [newList, setMyNewsList] = useState();

	const getSelectedRowData = (newsId: any) => {
		history.push("/news/" + newsId + "/detail");
	};

	const actionRenderer = (params: any) => {
		return (
			<>
				<Button
					className="edit-button grey-btn"
					title={t("DETAIL")}
					type="text"
					onClick={() => getSelectedRowData(params.data?.id)}
				>
					<EyeOutlined />
				</Button>
			</>
		);
	};

	const columnDefs = [
		{ field: "id", headerName: "#" + t("ID") },
		{ field: "title", headerName: t("NEWS_TITLE") },
		{
			field: "created_at",
			headerName: t("PUBLISHED_DATE"),
			valueFormatter: (data: any) =>
				data?.data.created_at &&
				moment
					.utc(data?.data.created_at)
					.local()
					.format(displayFormats.DATE_FORMAT),
			filter: "agDateColumnFilter",
			filterParams: {
				comparator: DateComparator,
			},
		},
		{ field: "id", headerName: t("ACTION"), cellRenderer: "actionRenderer" },
	];

	useEffect(() => {
		getNewsList().then((data: any) => {
			setMyNewsList(data.rows);
		});
	}, [getNewsList]);

	return (
		<>
			<div className="member-details-section">
				<div className="main grid-class">
					<div className="heading-title-wrap">
						<h3>{t("NEWS")}</h3>
					</div>
					<AgGridWrapper
						frameworkComponents={{ actionRenderer }}
						className="member-table"
						rowData={newList}
						columnDefs={columnDefs}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						onFilterChanged={({ api }) => {
							if (api.getDisplayedRowCount()) {
								api.hideOverlay();
							} else {
								api.showNoRowsOverlay();
							}
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default News;
