import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "antd";
import { observer } from "mobx-react";
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import { ErrorProps } from "../../../../../store/RootStore/RootInterface";
import FormComponent from "../../Donation/components/FormComponent";
import { useTranslation } from "react-i18next";
import moment from "moment";

const PayDonationCampaignComponent: React.FC = observer(() => {
	const {
		ROOT: { AssignErrorToInput },
		DONATION: { create },
		DONATION_CAMPAIGN: { details },
		COMMON: { donationFormData, getDonationFormData, selectedLanguage },
	} = useStore();
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [showGroupList, setShowGroupList] = useState<boolean>(false);
	const [donationSubjectList, setDonationSubjectList] = useState<boolean>();
	const [groupList, setGroupList] = useState<boolean>();
	const [paymentFromList, setPaymentFromList] = useState<boolean>();
	const [countryList, setCountryList] = useState<boolean>();
	const [donationCampaignDetail, setDonationCampaignDetail] = useState<any>();
	const [phoneNo, setPhoneNo] = useState<any>();
	const pathHistory = useHistory();
	const { donationCampaignId }: any = useParams();
	const { t } = useTranslation();
	const listRoute = () => {
		pathHistory.push('/donation');
	}
	useEffect(() => {
		selectedLanguage && !donationFormData && getDonationFormData();
		selectedLanguage && donationFormData && setDonationSubjectList(donationFormData.donationSubjectList);
		selectedLanguage && donationFormData && setGroupList(donationFormData.groupList);
		selectedLanguage && donationFormData && setCountryList(donationFormData.countryList);
		selectedLanguage && donationFormData && setPaymentFromList(donationFormData.paymentFrom);

		form.setFieldsValue({ donation_type: 1 });
		donationCampaignId && atob(donationCampaignId) && details(atob(donationCampaignId), {}).then((data: any) => {
			form.setFieldsValue({
				donation_type: data.donation_type,
				group_id: data.group_id,
				donation_subject: data.donation_subject,
			});
			setShowGroupList(form.getFieldValue("donation_type") === 2)
			setDonationCampaignDetail(data);
		});
	}, [selectedLanguage, donationFormData]);

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		//setSaving(true);
		const formData = new FormData();
		data.name && formData.append('name', data.name);
		data.address && formData.append('address', data.address);
		data.amount && formData.append('amount', data.amount);
		data.organisation && formData.append('organisation', data.organisation);
		data.donation_type && formData.append('donation_type', data.donation_type);
		data.group_id && formData.append('group_id', data.group_id);
		data.phone_number && formData.append('phone_number', data.phone_number);
		data.donation_subject && formData.append('donation_subject', data.donation_subject);
		data.email && formData.append('email', data.email);
		data.payment_from && formData.append('payment_from', data.payment_from);
		data.zipcode && formData.append('zipcode', data.zipcode);
		data.country_id && formData.append('country_id', data.country_id);
		donationCampaignId && atob(donationCampaignId) && formData.append('donation_campaign_id', atob(donationCampaignId));

		create(formData)
			.then((response: any) => {
				setSaving(false);
				if (data.payment_from == 3) {
					pathHistory.push('/donation/' + response.id + '/pay');
				} else {
					pathHistory.push('/donation');
				}
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				setSaving(false);
				AssignErrorToInput(form, errors);
			});

	};

	return (
		<>
			{donationCampaignDetail && moment() >= moment.utc(donationCampaignDetail.end_date_time).local() && <div className="profile-section">
				<div className="main">
					<div className="profile-wrap">
						<h3 className="no__group__member">
							{t("THIS_DONATION_CAMPAIGN_IS_ALREADY_ENDED")}
						</h3>
					</div>
				</div>
			</div>}

			{donationCampaignDetail && moment() < moment.utc(donationCampaignDetail.start_date_time).local() && <div className="profile-section">
				<div className="main">
					<div className="profile-wrap">
						<h3 className="no__group__member">
							{t("THIS_DONATION_CAMPAIGN_IS_NOT_STARTED")}
						</h3>
					</div>
				</div>
			</div>}

			{donationCampaignDetail && moment() >= moment(donationCampaignDetail.start_date_time) && moment() < moment(donationCampaignDetail.end_date_time) && <>
				<div className="main thankyou-vote-section">
					<Row justify="center">
						<Col span={24} className="pay__info">
							<span><b>{t("NAME")}</b> : {donationCampaignDetail?.name}</span>
							<span><b>{t("DONATION_SUBJECT")}</b> : {donationCampaignDetail?.subject.name}</span>
							<span><b>{t("DONATING_TO")}</b> : {donationCampaignDetail?.group?.name ? donationCampaignDetail.group.name : t("VSLA")}</span>
						</Col>
					</Row>
				</div>

				<FormComponent
					formId="addDonationCampaignForm"
					form={form}
					handleSubmit={handleSubmit}
					saving={saving}
					isEdit={false}
					listRoute={listRoute}
					donationSubjectList={donationSubjectList}
					groupList={groupList}
					paymentFromList={paymentFromList}
					countryList={countryList}
					showGroupList={showGroupList}
					setShowGroupList={setShowGroupList}
					isDonationCampaign={true}
					phoneNo={phoneNo}
					setPhoneNo={setPhoneNo}
						/>
			</>}
		</>
	);
});
export default PayDonationCampaignComponent;
