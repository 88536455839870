import React, { useEffect } from "react";
import { Button, Col, Empty, Row } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import useStore from "../../../store";
import { Link } from "react-router-dom";
import moment from "moment";
import { displayFormats } from "../../../config/Global";
import { RightOutlined } from "@ant-design/icons";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick"

const VillageAgentHomePage: React.FC = observer(() => {
	const { t } = useTranslation();
	const {
		AUTH: { user },
		COMMON: { homePageNotificationList },
	} = useStore();
	useEffect(() => {
		//
	}, [homePageNotificationList]);

	const upcomingSliderSettings = {
		arrows: false,
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		rows: 6,
		prevArrow: (
			<Button className="slick-arrow prev slick-prev">
				<IoIosArrowBack />
			</Button>
		),
		nextArrow: (
			<Button className="slick-arrow next slick-next">
				<IoIosArrowForward />
			</Button>
		),
		responsive: [
			{
				breakpoint: 1450,
				settings: {
					arrows: false,
				},
			},
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 639,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
		],
	};

	const groupSliderSettings = {
		arrows: false,
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		rows: 4,
		// prevArrow: (
		// 	<Button className="slick-arrow prev slick-prev">
		// 		<IoIosArrowBack />
		// 	</Button>
		// ),
		// nextArrow: (
		// 	<Button className="slick-arrow next slick-next">
		// 		<IoIosArrowForward />
		// 	</Button>
		// ),
		responsive: [
			{
				breakpoint: 1450,
				settings: {
					arrows: false,
					dots: true,
				},
			},
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					dots: true,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					dots: false,
				},
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					dots: false,
				},
			},
		],
	};

	return (
		<>

			<section className="group-list-table-section">
				<div className="main">
					<div className="heading-title">
						<Row>
							<Col span={24}>
								<h4>{t("OVERVIEW")}</h4>
							</Col>
						</Row>
					</div>
					<div className="overviewContentWrap">
						<Row>
							{user && (<Col span={8}>
								<div className="overViewBox">
									<div className="myGroupTitle">
										<h6>{t("GROUP_LIST")}</h6>
									</div>
									<div className="upcomingContent">
										<Slider className="three-col-slider" {...upcomingSliderSettings}>
											{user.groups.map((item: any) => {
												return (
													<div className="listing-item" key={"group-list" + item.id}>
														<div className="overviewArrows">
															<p>{item.name}</p>

														</div>
													</div>
												);
											})}
										</Slider>
									</div>

								</div>
							</Col>)}
							<Col offset={2}></Col>
							<Col span={8}>
								<div className="overViewBox">
									<div className="latestNotifications">
										<h6>{t("LATEST_NOTIFICATIONS")}</h6>
									</div>
									<div className="upcomingContent">
										<Slider className="three-col-slider" {...groupSliderSettings}>
											{homePageNotificationList
												?.slice(0, 5)
												.map((data: any, index: number) => {
													return (
														<div className="listing-item" key={"notification" + index}>
															<Link to="/notification" className="overviewArrows">
																<p>{data.title} <span>{moment
																	.utc(data.created_at)
																	.local()
																	.format(displayFormats.DATE_TIME_FORMAT)}</span></p>
																<RightOutlined className="upcomingRightArrow" />
															</Link>
														</div>
													);
												})}
										</Slider>
									</div>
									{(homePageNotificationList?.length <= 0) &&
										<Empty />
									}
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</section>


			{/* <section className="group-list-table-section">
				<div className="main">
					<div className="title-overlap title-with-border">
						<h3>{t("GROUP_LIST")}</h3>
					</div>
					{user && (
						<div className="responsive-table">
							{user.groups.length > 0 && (
								<table>
									<thead>
										<tr>
											<th scope="col">{t("GROUP_ID")}</th>
											<th scope="col">{t("GROUP_NAME")}</th>
										</tr>
									</thead>
									<tbody>
										{user.groups.map((item: any) => {
											return (
												<tr key={"group-list" + item.id}>
													<td data-label={"#" + t("GROUP_ID")}>{item.id}</td>
													<td data-label={t("GROUP_NAME")}>
														{item.name}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							)}

							{user.groups.length == 0 && (
								<caption className="text-center">
									{t("NO_GROUP_ASSIGNED")}
								</caption>
							)}
						</div>
					)}
				</div>
			</section> */}

			<div>
				{/* Notification  Section*/}
				{/* <div
					className="latest-notifications-section"
					style={{ backgroundImage: `url(${Our_group_bg})` }}
				>
					<div className="main">
						<div className="title-overlap">
							<h3>{t("LATEST_NOTIFICATIONS")}</h3>
						</div>

						<Row>
							<Col span={10}>
								<div className="notifications-thumb">
									<figure
										style={{ backgroundImage: `url(${Notifications_Img})` }}
									></figure>
								</div>
							</Col>
							<Col span={14}>
								<div className="notifications-table">
									{homePageNotificationList && (
										<>
											<table>
												<thead>
													<tr>
														<th>{t("NOTIFICATION_TITLE")}</th>
														<th>{t("DATE_AND_TIME")}</th>
													</tr>
												</thead>

												<tbody>
													{homePageNotificationList
														?.slice(0, 5)
														.map((data: any, index: number) => {
															return (
																<tr key={"notification" + index}>
																	<td>{data.title}</td>
																	<td>
																		{moment
																			.utc(data.created_at)
																			.local()
																			.format(displayFormats.DATE_TIME_FORMAT)}
																	</td>
																</tr>
															);
														})}
												</tbody>
											</table>
											{homePageNotificationList?.length > 5 && (
												<div className="btn secondary-btn small-btn text-right">
													<Link to="/notification" title={t("VIEW_ALL")}>
														{t("VIEW_MORE")}
													</Link>
												</div>
											)}
										</>
									)}
									{homePageNotificationList?.length <= 0 && (
										<h4 className="no_record_found no_record_found_bg mt-50">
											{t("NO_RECORD_FOUND")}
										</h4>
									)}
								</div>
							</Col>
						</Row>
					</div>
				</div> */}
			</div>
		</>
	);
});

export default VillageAgentHomePage;
