const API_URL = {
	LOGIN: "login",
	REGISTRATION: "sign-up",
	ME: "me",
	LOGOUT: "logout",
	FORGOT_PASSWORD: "forget-password",
	VERIFY_OTP: (otp: string): string => otp + "/verify-otp",
	RESET_PASSWORD_WITH_OTP: (otp: string): string => otp + "/reset-password-otp",
	RESET_PASSWORD_WITH_LINK: (otp: string): string => otp + "/reset-password-link",
	DONATION_SUBJECT_LIST: "donation-subject-list",
	VERIFY_SOCIAL_LOGIN: "verify-social-login",
	LANGUAGE_DATA: (local_key: string): string => "language/" + local_key,
	RESEND_OTP: "resend-otp",
	USER: {
		LIST: "user/list",
		UPDATE_MY_LANGUAGE: "/user/update-my-language",
		DETAILS: (id: string): string => "/user/" + id + "/details",
		HOME_PAGE: "/user/home-page",
		REPORT_LIST: "/user/report/list",
		UPDATE_MY_DEVICE_TOKEN: "/user/update-my-device-token",
		CREATE_MY_AL_IZZA_ACCOUNT: "/user/create-my-al-izza-account",
		VERIFY_AL_IZZA_ACCOUNT: "/user/verify-al-izza-account",
	},
	All_TRANSACTION: {
		LIST: "trade/transactions/list",
	},
	VERIFY_ACCOUNT: "verify-account",
	CHANGE_PASSWORD: "change-password",
	COMMON: {
		LANGUAGE_LIST: "language-list",
		PROFESSION_LIST: "profession-list",
		CURRENCY_LIST: "currency-list",
		GROUP_DETAILS:  (groupId: string): string => groupId + "/group-details",
		GROUP_DETAILS_UPDATE:  (groupId: string): string =>"group/" + groupId + "/details-update",
		GROUP_USER_LIST:  (groupId: string): string => groupId + "/group-user-list",
		GROUP_LIST: "group-list",
		SITE_SETTING: "footer-section",
		REDIRECT_LINKS: "redirect-links",
		CMS_LIST: "cms/list",
		FINE_TYPE_LIST: "fine-type-list",
		USER_LIST: "user-list",
		FEEDBACK:"send-feedback",
		ELECTION_ROLE_LIST:"election-role-list",
		DONATION_FORM_DATA:"/donation/form-data",
		ADD_MONEY_TO_WALLET_FORM_DATA:"/add-money-to-wallet/form-data",
		WEB_COMMON_DATA:"/web-common-data",
		CONFEDERATION_LIST: "confederation-list",
	},
	HOME_PAGES_LIST:"home-page-content/list",
	NEW_PASSWORD: "change-password",
	GROUP: {
		CREATE_SAVING_GROUP: "create-group-request",
		MY_JOIN_GROUP_REQUEST_LIST: "my-join-group-request-list",
		JOIN_GROUP_REQUEST_LIST: "group/join-group-request-list",
		APPROVE_JOIN_GROUP_REQUEST: "group/approve-join-group-request",
		JOIN_GROUP: "join-group-request",
		PURCHASE_SHARE: "group/purchase-share",
		ACCEPT_JOIN_GROUP_REQUEST: (groupId: number): string => "group/" + groupId + "/accept-join-group-request",
		ADD_USER_TO_GROUP: "group/add-user-to-group",
		ASSIGN_SECRETARY_ACCOUNTANT_TO_GROUP: "group/assign-secretary-accountant-to-group",
		ACCEPT_ADD_USER_TO_GROUP_INVITATION: "group/accept-add-user-to-group-invitation",
		LEAVE_GROUP: "group/leave",
		SEARCH_JOIN_GROUP_LIST: "group/search-join-group-list",
		USER_LIST_TO_ADD_MEMBER_TO_GROUP: "group/user-list-to-add-member-to-group",
		TERMINATE: "group/terminate",
		MY_GROUP_LIST: "/my-group/list",
		CALCULATE_AMOUNT:"/group/calculate-amount",
		PURCHASE_SHARE_OF_USERS:"/group/purchase-share-in-cash"
	},
	FINE: {
		FINE_LIST: "/fine",
		CREATE_FINE: "/fine/create",
		MY_FINE_LIST: "/my-fines",
		MY_FINE_PAY: (fineId: number): string =>  "/my-fines/"+ fineId + "/pay",
		DELETE: (fineId: number): string =>  "/fine/" + fineId + "/delete",
		PAY_CASH: (fineId: number): string =>  "/my-fines/" + fineId + "/pay-cash",
	},

	NEWS: {
		MY_NEWS_LIST: "/news",
		NEWS_DETAILS: (newsId: number): string => "/news/" + newsId + "/details",
	},

	CMS: {
		CMS_DETAILS: "/cms/detail",
	},

	LOAN: {
		USER_LOAN_REQUEST_LIST: "/user-loan",
		USER_LOAN_REQUEST_DETAILS: (userLoanId: number): string => "/user-loan/" + userLoanId + "/details",
		APPROVE_USER_LOAN_REQUEST: "/user-loan/approve",
		MY_LOAN_CREATE: "/my-loan/create",
		MY_LOAN_LIST: "/my-loan",
		MY_USER_LOAN_REQUEST_DETAILS: (userLoanId: number): string => "/my-loan/" + userLoanId + "/details",
		USER_LOAN_DISBURSEMENT_LIST: "/user-loan/disbursement-list",
		PAY_MY_LOAN_INSTALLMENT: (loanInstallmentId: number): string => "/my-loan/" + loanInstallmentId + "/pay-installment",
		CHANGE_PAYMENT_MODE:(id:number):string=>"/my-loan/"+id+"/update",
		PAY_REQUESTED_LOAN:`/user-loan/pay-cash-request-loan`,
		PAY_CASH_INSTALLMENT:(id:number):string=>"/my-loan/"+id+"/pay-installment-in-cash"
	},
	GROUP_EXPENSE: {
		GROUP_EXPENSE_LIST: "/group-expense",
		CREATE_GROUP_EXPENSE: "/group-expense/create",
		CHANGE_STATUS: "/group-expense/approve",
		MY_GROUP_EXPENSE_LIST: "/my-group-expense",
		CREATE_MY_GROUP_EXPENSE: "/my-group-expense/create",
		CHANGE_PAYMENT_MODE:(id:number):string=>"my-group-expense/"+id+"/update",
		PAY_CASH:"group-expense/pay-cash-group-expense"
	},
	TRANSACTION: {
		TRANSACTION_LIST: "/transaction",
		MY_TRANSACTION_LIST: "/my-transaction",
		ADD_MONEY_TO_WALLET: "/my-transaction/add-money-to-wallet",
		WITHDRAW_MONEY_FROM_WALLET: "/my-transaction/withdraw-money-from-wallet",
		PAY_CASH:"transaction/pay-cash-welfare-fund"
	},
	MEETING: {
		MEETING_LIST: "/meeting",
		CREATE: "/meeting/create",
		MEETING_DETAILS: (meetingId: number): string => "/meeting/" + meetingId + "/details",
		UPDATE: (meetingId: number): string => "/meeting/" + meetingId + "/update",
		DELETE: (meetingId: number): string => "/meeting/" + meetingId + "/delete",
		CANCEL: (meetingId: number): string => "/meeting/" + meetingId + "/cancel",
		END: (meetingId: number): string => "/meeting/" + meetingId + "/end",
		TAKE_ATTENDANCE: "/meeting/take-attendance",
		REMARK: "/meeting/remark",
		MY_MEETING_LIST: "/my-meeting",
		ZOOM_DETAILS: "/meeting/zoom-details",
		MY_MEETING_DETAILS: (meetingId: number): string => "/my-meeting/" + meetingId + "/details",
		JOIN_MY_MEETING: "/my-meeting/join",
	},
	PASSBOOK: {
		MY_TRANSACTION_LIST: "/my-transaction",
		MY_TRANSACTION_DETAILS: (id: any): string => "/my-transaction/" + id + "/details",
		GROUP_MEMBERS_LIST: "/passbook/group-members",
		USER_MEMBERS_LIST: (user_id: number): string => "/passbook/transaction-list" + "?user_id=" + user_id,
		USER_MEMBERS_DETAILS: (user_id: number, id: any): string => "/passbook/transaction/" + id + "/details?user_id=" + user_id,
	},
	DONATION_CAMPAIGN: {
		LIST:"donation-campaign",
		CREATE:"donation-campaign/create",
		DETAILS:(id: number): string => "donation-campaign/" + id + "/details",
		UPDATE:(id: number): string => "donation-campaign/" + id + "/update",
		DELETE:(id: number): string =>"donation-campaign/" + id + "/delete",
		CHANGE_STATUS:(id: number): string => "donation-campaign/" + id + "/change-status",
		END:(id: number): string => "donation-campaign/" + id + "/end",
	},
	DONATION_SUBJECT: {
		LIST:"donation-subject",
		CREATE:"donation-subject/create",
		DETAILS:(id: number): string => "donation-subject/" + id + "/details",
		UPDATE:(id: number): string => "donation-subject/" + id + "/update",
		DELETE:(id: number): string =>"donation-subject/" + id + "/delete",
		CHANGE_STATUS:(id: number): string => "donation-subject/" + id + "/change-status",
	},
	DONATION: {
		LIST:"donation",
		CREATE:"donation/create",
		DETAILS:(id: number): string => "donation/" + id + "/details",
		UPDATE_PAYMENT_RESPONSE:(id: number): string => "donation/" + id + "/update-payment-response",
		UPDATE:(id: number): string => "donation/" + id + "/update",
	},
	NOTIFICATION: {
		HOMEPAGE_LIST:"notification/home-page",
		LIST:"notification",
		DETAILS: (id: number): string => `notification/${id}/details`,
		MARK_AS_READ:"notification/mark-as-read"
	},
	BANNER :{
		LIST:(displayType: number): string => displayType + "/banner",
	},
	SLIDER :{
		LIST:(displayType: number): string => displayType + "/slider-list",
	},
	ELECTION: {
		LIST:"election",
		CREATE:"election/create",
		DETAILS:(id: number): string => "election/" + id + "/details",
		VOTE_CANDIDATE:"election/vote-candidate",
		VOTE_VIEW:(id: number): string => "election/" + id + "/vote-view",
	},
	ELECTION_REFEREE: {
		LIST:"/election-refree/my-election",
		DETAILS:(id: number): string => "/election-refree/election/" + id + "/details",
		ADD_ELECTION_CANDIDATES:"/election-refree/manage-election",
		ELECTION_FEEDBACK:"/election-refree/feedback",
		ELECTION_RESULT:"/election-refree/election/result",
		CANCEL:(id: number): string => "election-refree/" + id + "/cancel",
	},
	ADD_CASH: {
		LIST: "/transaction/cash-list",
		CREATE: "/transaction/add-cash",
	},
	WELFARE_REQUEST: {
		WELFARE_REQUEST_LIST: "/welfare-request",
		CREATE_WELFARE_REQUEST: "/welfare-request/create",
		CHANGE_STATUS: "/welfare-request/approve",
		MY_WELFARE_REQUEST_LIST: "/my-welfare-request",
		CREATE_MY_WELFARE_REQUEST: "/my-welfare-request/create",
		CHANGE_PAYMENT_MODE:(id:number):string=>"welfare-request/"+id+"/update",
		PAY_CASH:"welfare-request/pay-cash-welfare"
	},
	GENERATED_OTP:{
		CREATE:"/otp/create",
		LIST:"/otp"
	},
	TRANSFER_FUND:{
		CREATE:"transfer-fund/create",
		LIST:"transfer-fund"
	}
};

export default API_URL;
