import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class WalletStore {
	private rootStore: RootStore;
	public transactionType = 0;
	public myTransactionListGrid: any = {};
	public groupTransactionListGrid: any = {};

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setTransactionType = (transactionType:number):void => {
		this.transactionType = transactionType;
	}

	private setMyTransactionListGrid = (value: any, index: any) => {
		this.myTransactionListGrid[index] = value;
	};

	private setGroupTransactionListGrid = (value: any, index: any) => {
		this.groupTransactionListGrid[index] = value;
	};

	// Grid Setup Functions
	public setupMyTransactionListGrid = (params: GridReadyEvent) => {
		this.setMyTransactionListGrid(params, this.transactionType);
		const datasource = this.getMyTransactionListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setupGroupTransactionListGrid = (params: GridReadyEvent) => {
		this.setGroupTransactionListGrid(params, this.transactionType);
		const datasource = this.getGroupTransactionListDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	// API Functions
	public getMyTransactionList = (payload: any): Promise<any> => {
		payload.transaction_type = this.transactionType;
		return axios
			.post(API_URL.TRANSACTION.MY_TRANSACTION_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public getGroupTransactionList = (payload: any): Promise<any> => {
		payload.transaction_type = this.transactionType;
		return axios
			.post(API_URL.TRANSACTION.TRANSACTION_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	private getMyTransactionListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getMyTransactionList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setMyTransactionListGrid(params, this.transactionType);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	private getGroupTransactionListDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getGroupTransactionList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setGroupTransactionListGrid(params, this.transactionType);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// Other
	public changeTransactionType = (type: string, transactionType : number): void => {
		this.setTransactionType(transactionType);
		if(type == 'my') {
			this.myTransactionListGrid[this.transactionType] && this.setupMyTransactionListGrid(this.myTransactionListGrid[this.transactionType]);
		}
		if(type == 'group') {
			this.groupTransactionListGrid[this.transactionType] && this.setupGroupTransactionListGrid(this.groupTransactionListGrid[this.transactionType]);
		}
	}

	public exportMyTransactionList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.myTransactionListGrid[this.transactionType]);
		payLoad.export_type = exportType;
		return this.getMyTransactionList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public exportGroupTransactionList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.groupTransactionListGrid[this.transactionType]);
		payLoad.export_type = exportType;
		return this.getGroupTransactionList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public addMoneyToWallet = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.TRANSACTION.ADD_MONEY_TO_WALLET, payload).then(({ data }) => {
				return data.data;
			});
	};

	public withdrawMoneyFromWallet = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.TRANSACTION.WITHDRAW_MONEY_FROM_WALLET, payload).then(({ data }) => {
				return data.data;
			});
	};

	public payCash = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.TRANSACTION.PAY_CASH, payload)
			.then(({ data }) => {
				this.setupGroupTransactionListGrid(this.groupTransactionListGrid[this.transactionType])
				return data.data;
			});
	};
}
