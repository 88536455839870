import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "antd";
import { Form, Button } from "antd";
import description from "../../../assets/images/description.svg";
import profile_icon from "../../../assets/images/profile-icon.svg";
import contact_us from "../../../assets/images/contact-us-img.svg";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import useStore from "../../../store";
import { observer } from "mobx-react";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import Calling from "../../../assets/images/Calling.svg";
import { ContactUsRequest } from "../../../requests/ContactUsRequest";

const ContactUs: React.FC = observer(() => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const [saving, setSaving] = useState<boolean>(false);
	const {
		CMS: { getCMSDetails, myCms },
		COMMON: { selectedLanguage, getFeedBackData },
		ROOT: { AssignErrorToInput }
	} = useStore();
	useEffect(() => {
		const obj = {
			slug: "contact-us",
			language_id: selectedLanguage ? selectedLanguage.id : null
		};
		selectedLanguage && getCMSDetails(obj);
	}, [selectedLanguage, getCMSDetails]);

	const [elecctionsSubmit, setcontactSubmitModal] = useState(false);

	const opencontactSubmitModal = () => {
		setcontactSubmitModal(false);
	};

	const HandleSubmit = (data: any) => {
		setSaving(true);
		data.type = 1;
		getFeedBackData(data)
			.then(() => {
				setcontactSubmitModal(true);
				setSaving(false);
				form.resetFields();
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
			});
	};
	return (
		<>
			<section className="common-page-section">
				<div className="main">
					<div className="heading-title">
						<h4>
							{myCms && myCms.page_title}
							{/* <span></span> */}
						</h4>
					</div>
					<div className="contact-form-wrap">
						<Row>
							<Col span={13}>
								<div className="thumb-img">
									<img src={contact_us} alt="Profile" />
								</div>
							</Col>
							<Col span={11}>
								<FormBox form={form} onFinish={HandleSubmit}>
									<div className="field">
										<i>
											<img src={profile_icon} alt="Profile" />
										</i>
										<InputBox.Text
											name="Name"
											placeholder={t("NAME")}
											label={t("NAME")}
											required
											rules={ContactUsRequest(t).name}
										/>
									</div>
									<div className="field">
										<i>
											<img src={profile_icon} alt="Profile" />
										</i>
										<InputBox.Text
											name="Email"
											placeholder={t("EMAIL")}
											label={t("EMAIL")}
											required
											rules={ContactUsRequest(t).email}
										/>
									</div>
									<div className="field">
										<i>
											<img src={Calling} alt="Profile" />
										</i>
										<InputBox.Text
											name="phone_number"
											placeholder={t("PHONE_NUMBER")}
											label={t("PHONE_NUMBER")}
											required
											rules={ContactUsRequest(t).phone_number}
										/>
									</div>
									<div className="field">
										<i>
											<img src={description} alt="Profile" />
										</i>
										<InputBox.TextArea
											name="description"
											placeholder={t("MESSAGE")}
											label={t("MESSAGE")}
											required
											rules={ContactUsRequest(t).description}
										/>
									</div>
									<div className="btn">
										<Button
											type="primary"
											htmlType="submit"
											className="btn btn-yellow"
											loading={saving}
										//onClick={opencontactSubmitModal}
										>
											{t("SUBMIT")}
										</Button>
									</div>
								</FormBox>
							</Col>
						</Row>
					</div>
				</div>
			</section>
			<Modal
				centered
				visible={elecctionsSubmit}
				onOk={() => setcontactSubmitModal(false)}
				onCancel={() => setcontactSubmitModal(false)}
				className="custom-popup info-popup contact-us-popup"
				footer={null}
			>
				<div className="info-text">
					<p>
						{t("SUBMIT_REQUEST")}
					</p>
				</div>
				<div className="btn">
					<Button onClick={opencontactSubmitModal} className="btn-secondary">{t("OK")}</Button>
				</div>
			</Modal>
		</>
	);
});

export default ContactUs;
