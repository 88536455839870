import React from "react";
import { Button, Modal, } from "antd";
import { useTranslation } from "react-i18next";
import loader from "../assets/images/loader.gif";
interface ExportPopupProps {
	callbackExportFunction: (type: any) => void;
	setVisible: (type: boolean) => void;
	visible?: boolean;
	saving?: boolean;
}

const ExportPopupComponent: React.FC<ExportPopupProps> = ({
	callbackExportFunction,
	setVisible,
	visible,
	saving,
}) => {
	const { t } = useTranslation();
	
	return (
		<Modal
			centered
			visible={visible}
			onCancel={()=>setVisible(false)}
			onOk={()=>setVisible(false)}
			className="custom-popup info-popup"
			footer={null}
			destroyOnClose={true}
		>
			<div className="info-text">
				<p>
					{t('FORMAT_EXPORT')}
				</p>
			</div>
			<img src={loader} hidden={!saving}/>
			<div className="btn">
				<Button type="primary" className="btn-yellow" disabled={saving} onClick={() => callbackExportFunction('csv')}>
					{t("EXPORT_CSV")}
				</Button>
				<Button type="primary" className="btn-yellow" disabled={saving} onClick={() => callbackExportFunction('xlsx')}>
					{t("EXPORT_XLS")}
				</Button>
			</div>
		</Modal>
	);
};

export default ExportPopupComponent;
