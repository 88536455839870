import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Button, } from "antd";
import duration from "../../../assets/images/duration.svg";
import transaction from "../../../assets/images/transaction.svg";
import useStore from "../../../store";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { ZoomMeetingDetailsRequest } from "../../../requests/MeetingRequest";
import { observer } from "mobx-react";

const MeetingsSettings: React.FC = observer(() => {
	const [form] = Form.useForm();
	const { 
		ROOT: {AssignErrorToInput}, 
		AUTH: { selectedGroup },
		MEETING: {getZoomMeetingDetails, saveZoomMeetingDetails},
	} = useStore();
	const history = useHistory();
	const { t } = useTranslation();
	const [saving, setSaving] = useState<boolean>(false);

	const handleSubmit = (data: any) => {
		setSaving(true);
		saveZoomMeetingDetails(data).then(() => {
			history.replace("/meetings");
		}).catch((e:any) => {
			setSaving(false);
			AssignErrorToInput(form, e?.data?.errors);
		});
	};

	const goBack = () => {
		history.replace("/meetings");
	};

	useEffect(() => {
		selectedGroup && getZoomMeetingDetails().then((data: any) => {
			form.resetFields();
			form.setFieldsValue({
				zoom_key: data?.zoom_key,
				zoom_secrete: data?.zoom_secrete,
			});
		});
	}, [selectedGroup, form, getZoomMeetingDetails]);

	return (
		<>
			<div className="register-section section-wrap">
				<div className="main">
					<h3>{t("ZOOM_MEETING_SETTINGS")}</h3>
					<FormBox form={form} onFinish={handleSubmit}>
						<Row>
							<Col span={12}>
								<div className="field">
									<i>
										<img src={duration} alt="Profile" />
									</i>
									<InputBox.Text
										name="zoom_key"
										placeholder={t("CLIENT_ID")}
										label={t("CLIENT_ID")}
										rules={ZoomMeetingDetailsRequest(t).zoom_key}
									/>
								</div>
								<div className="field">
									<i>
										<img src={transaction} alt="Profile" />
									</i>
									<InputBox.Text
										name="zoom_secrete"
										placeholder={t("CLIENT_SECRET_KEY")}
										label={t("CLIENT_SECRET_KEY")}
										rules={ZoomMeetingDetailsRequest(t).zoom_secrete}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<div className="btn btn-wrap">
									<Button
										type="primary"
										htmlType="submit"
										className="btn btn-primary"
										loading={saving}
									>
										{t("SAVE")}
									</Button>
									<Button
										type="primary"
										onClick={goBack}
										className="btn btn-danger"
									>
										{t("CANCEL")}
									</Button>
								</div>
							</Col>
						</Row>
					</FormBox>
				</div>
			</div>
		</>
	);
});

export default MeetingsSettings;
