import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class PassbookStore {
	private rootStore: RootStore;
	public myPassbookListGrid?: any;
	public PassbookListGrid?: any;
	public myPassbookDetails?: any;
	public PassbookDetails?: any;
	public membersPassbookList?: any;
	public passbookUserId?: any;
	public passbookMemberId?: any;
	public passbookUserDetailsId?: any;
	public membersPassbookDetails?: any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setMyPassbookGrid = (value: any) => {
		this.myPassbookListGrid = value;
	};

	private setMyPassbookDetails = (value: any) => {
		this.myPassbookDetails = value;
	};

	private setMembersPassbookList = (value: any) => {
		this.membersPassbookList = value;
	};

	private setMembersPassbookDetails = (value: any) => {
		this.membersPassbookDetails = value;
	};

	private setGroupPassbookGrid = (value: any) => {
		this.PassbookListGrid = value;
	};

	private setPassbookDetails = (value: any) => {
		this.PassbookDetails = value;
	};

	private setPassbookUserId = (value: any) => {
		this.passbookUserId = value;
	};
	private setPassbookUserDetailsId = (value: any) => {
		this.passbookUserDetailsId = value;
	};

	private setPassbookMemberId = (value: any) => {
		this.passbookMemberId = value;
	};
	// Grid Setup Functions
	public setupMyPassbookListGrid = (params: GridReadyEvent) => {
		this.setMyPassbookGrid(params);
		const datasource = this.getMyPassbookListDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};


	private getMyPassbookListDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getMyPassbookList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setMyPassbookGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	public setupGroupPassbookListGrid = (params: GridReadyEvent) => {
		this.setGroupPassbookGrid(params);
		const datasource = this.getGroupPassbookListDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};


	private getGroupPassbookListDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getGroupPassbookList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setGroupPassbookGrid(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};
	
	public setupMembersPassbookListGrid = (params: GridReadyEvent) => {
		this.setMembersPassbookList(params);
		const datasource = this.getMembersPassbookListDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private getMembersPassbookListDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.getMembersPassbookList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setMembersPassbookList(params);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	
	public getMyPassbookList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.PASSBOOK.MY_TRANSACTION_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	// public getmyPassbookDetails = (transactionId: any): Promise<any> => {
	// 	return axios
	// 		.get(API_URL.PASSBOOK.MY_TRANSACTION_DETAILS(transactionId))
	// 		.then(({ data }) => {
	// 			this.setMyPassbookDetails(data.data);
	// 			return data.data;
	// 		});
	// };
	public getmyPassbookDetails = (id: any): Promise<any> => {
		return axios.get(API_URL.PASSBOOK.MY_TRANSACTION_DETAILS(encodeURIComponent(id))).then(({ data }) => {
			this.setMyPassbookDetails(data.data);
			return data.data;
		});
	};

	public getGroupPassbookList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.PASSBOOK.GROUP_MEMBERS_LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public getMembersPassbookList = (payload: any): Promise<any> => {
		return axios.post(API_URL.PASSBOOK.USER_MEMBERS_LIST(this.passbookUserId), payload).then(({ data }) => {
			return data.data;
		});
	};

	public getMembersPassbookDetails = (user_id:any): Promise<any> => {
		return axios.post(API_URL.PASSBOOK.USER_MEMBERS_DETAILS(this.passbookMemberId, encodeURIComponent(user_id))).then(({ data }) => {
			this.setMembersPassbookDetails(data.data);
			return data.data;
		});
	};

	public exportGroupPassbookList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.PassbookListGrid);
		payLoad.export_type = exportType;
		return this.getGroupPassbookList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

	public exportMembersPassbookList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.membersPassbookList);
		payLoad.export_type = exportType;
		return this.getMembersPassbookList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

}
