import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { AgGridColumn } from "ag-grid-react";
import { observer } from "mobx-react";
import moment from "moment";
import DateComparator from "../../../utils/DateComparator";
import { displayFormats } from "../../../config/Global";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import useStore from "../../../store";
import AllPermissionList from "../../../config/AllPermissionList";
import { EyeOutlined } from "@ant-design/icons";
import TransferFundDetails from "./TransferFundDetail";


const TransferFundsList: React.FC = observer(() => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
        TRANSFER_FUND: { setupTransferFundListGrid },
        AUTH:{permissions}
    } = useStore();
    const [width, setWidth] = useState(window.innerWidth);
    const [displayTransferFundDetail,setDisplayTransferFundDetail]=useState()
    const [showTransferFundDetailsModel, setShowTransferFundDetailsModel] = useState(false);
    const [fileList,setFileList]=useState([])
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const goToAddTransferFunds = () => {
        history.push('/add-transfer-funds')
    }

    const detailTransferFundRenderer = (params: any) => {
		return (
			<div>
				<Button
					type="default"
					icon={<EyeOutlined />}
					style={{ color: "green" }}
					onClick={() => displayTransferFundDetails(params.data)}
				/>
			</div>
		);
	};

    const displayTransferFundDetails=(data:any)=>{
        setDisplayTransferFundDetail(data)
        setShowTransferFundDetailsModel(true)
        let list:any=[];
        list=data?.image?.map((img: any)=>{
            return {
               // uid: "-1",
                name:img?.name,
                status: "done",
                url: img?.url,
                thumbUrl: img?.url,
            };
        })
        console.log(list)
        setFileList(list)
    }
    return (
        <>
            <div className="member-details-section">
                <div className="main grid-class">
                    <div className="heading-title-wrap">
                        <h3>{t("TRANSFER_FUNDS")}</h3>
                        <div className="btn-wrap">
                           {(permissions && permissions.indexOf(AllPermissionList.ADD_FUND_TRANSFER) >= 0) && <div className="text-center btn btn-primary btn-yellow">
                                <Button type="primary" onClick={goToAddTransferFunds}>
                                    {t("TRANSFER_FUNDS")}
                                </Button>
                            </div>} 
                        </div>
                    </div>
                    <AgGridWrapper
                        type="serverSide"
                        onGridReady={setupTransferFundListGrid}
                        rowSelection={"multiple"}
                        className="member-table"
                        immutableData={true}
                        overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
                        frameworkComponents={{ detailTransferFundRenderer }}
                    >
                        <AgGridColumn
                            field="id"
                            headerName={"# " + t("ID")}
                            width={100}
                            pinned={width > 769 ? "left" : ""}
                            filter="agNumberColumnFilter"
                        />
                        <AgGridColumn field="transfer_fund_type_name" headerName={t("TRANSFER")} filter="agTextColumnFilter" />
                        <AgGridColumn field="fund_type_name" headerName={t("FUND_TYPE")} filter="agTextColumnFilter" />
                        <AgGridColumn field="actual_amount" headerName={t("ACTUAL_AMOUNT")} filter="agNumberColumnFilter" />
                        <AgGridColumn field="commission_fees" headerName={t("COMMISSION_FEES")} filter="agNumberColumnFilter" />
                        <AgGridColumn field="final_amount" headerName={t("FINAL_AMOUNT")} filter="agNumberColumnFilter" />
                        <AgGridColumn field="date" headerName={t("DATE")}
                            filter="agDateColumnFilter"
                            valueFormatter={(data: any) =>
                                data?.data?.date && moment.utc(data?.data?.date).local().format(displayFormats.DATE_FORMAT)
                            }
                            filterParams={{
                                comparator: DateComparator,
                            }}
                        />
                        <AgGridColumn
								field="id"
								headerName={t("ACTION")}
								cellRenderer="detailTransferFundRenderer"
								width={120}
								pinned={width > 769 ? "right" : ""}
								sortable={false}
								filter={false}
							/>
                    </AgGridWrapper>
                </div>
            </div>
            <TransferFundDetails
				showTransferFundDetailsModel={showTransferFundDetailsModel}
				setShowTransferFundDetailsModel={setShowTransferFundDetailsModel}
				displayTransferFundDetail={displayTransferFundDetail}
				setDisplayTransferFundDetail={setDisplayTransferFundDetail}
                fileList={fileList}
			/>
        </>
    );
});

export default TransferFundsList;
